import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState, useRef } from "react";
import { saveReflectionjourney } from "../Redux/Actions/userActions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AudioPlayer = ({
  filterAudio,
  isPlaying,
  setIsPlaying,
  id,
  audio_completed,
  ref_id,
  from,
  backpageid
}) => {

  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate = useNavigate();
  console.log(`${BASE_URL}${filterAudio}`, "audio");
  const audioRef = useRef(new Audio(`${BASE_URL}${filterAudio}`));
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.75);
  const [isMuted, setIsMuted] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);

  // ==========================================get reflectionId from redux===================================================

  const reflectionId = useSelector(
    (state) => state.reflectionData.reflectionId
  );

  console.log(from,"ref")

  // ===========================================hit save reflection after completion of audio=====================================

  const { mutate } = useMutation({
    mutationFn: saveReflectionjourney,
    onSuccess: (res) => {
      
      console.log(res, "res");
      if (res?.success) {
        // console.log(res?.data?.journeystatus[0]?.audio_completed, "check");
        // dispatch(Userdata(res?.data));
        // toast.success(res?.message);
       
        if (res?.data?.journeystatus[0]?.audio_completed == 1 && res?.data?.journeytype == 0) {
          navigate(
            `/questionnaire-type/${id}`
           
          );
        }
        if(res?.data?.journeystatus[0]?.audio_completed == 1 && res?.data?.journeytype == 1){
          navigate(
            `/story-timeline-add/${id}`
            
          );
        }
        if(res?.data?.journeystatus[0]?.audio_completed == 1 && res?.data?.journeytype == 2){
          navigate(
            `/wheel-of-life/${id}`
            
          );
        }
      } 
      else {
        from=="Theory" && backpageid ? navigate(`/theory/${backpageid}`) :
        navigate("/reflection-journey-page")
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      
      toast.error(res?.message);
    },
  });

  useEffect(() => {
    const audio = audioRef.current;

    // Set up event listeners when audio is loaded
    audio.addEventListener("loadeddata", () => {
      setDuration(audio.duration);
    });

    audio.addEventListener("timeupdate", () => {
      setCurrentTime(audio.currentTime);
    });

    audio.addEventListener("ended", () => {
      setIsPlaying(false);
      setHasFinished(true);
      audio_completed == 1
        ? navigate("/reflection-journey-page")
        : mutate({
            id: id,
            type: 0,
          });
    });

    console.log(hasFinished, "hashhhhhhh");

    return () => {
      // Clean up event listeners
      audio.removeEventListener("loadeddata", () => {});
      audio.removeEventListener("timeupdate", () => {});
      audio.addEventListener("ended", () => {});
    };
  }, [filterAudio, isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;

    // Toggle play/pause when isPlaying changes
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }

    return()=>{
      audio.pause()
    }
  }, [isPlaying]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  //   const toggleMute = () => {
  //     const audio = audioRef.current;
  //     audio.muted = !audio.muted;
  //     setIsMuted(audio.muted);
  //   };

  //   const handleVolumeChange = (e) => {
  //     const newVolume = parseFloat(e.target.value);
  //     setVolume(newVolume);
  //     audioRef.current.volume = newVolume;
  //     setIsMuted(newVolume === 0);
  //   };

  const handleSeek = (e) => {
    const audio = audioRef.current;
    const seekTime = (e.nativeEvent.offsetX / e.target.clientWidth) * duration;
    audio.currentTime = seekTime;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="audio-player">
      <div className="timeline" onClick={handleSeek}>
        <div
          className="progress"
          style={{ width: `${(currentTime / duration) * 100}%` }}
        ></div>
      </div>
      <div className="controls">
        <div className="audio-ply-time">
          <p>
            <div>{formatTime(currentTime)} min</div>
            <div>/</div>
            <div>{formatTime(duration)} min</div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
