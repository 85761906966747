import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Overview from "../Assets/images/Overview.png";
import Overviewger from "../Assets/images/Overviewger.png";
import ProfileImg from "../Assets/images/Profile.png";
import Profileger from "../Assets/images/Profileger.png";
import Avilabilities from "../Assets/images/Avilabilities.png";
import Avilabilitiesger from "../Assets/images/Avilabilitiesger.png";

export default function AboutTheApp() {
  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  const { t } = useTranslation();
  const { mainheading, midscreen } = t("AboutTheApp");
  console.log(midscreen);

  return (
    <Layout>
      <section className="about-app-banner">
        <Container className="about-bnr-img">
          <Row className="">
            <Col lg={6} className="">
              <div className="about-bnr-left">
                <p>SELFCARE</p>
                <div className="left-text-contain">
                  <p>{mainheading?.desc1}</p>
                </div>
              </div>
            </Col>
            <Col lg={6} className="">
              <div className="about-bnr-right">
                <div className="right-text-contin">
                  <p>{mainheading?.h2}</p>
                  <div className="">
                    <p>{mainheading?.desc2}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="phone-tabs-section">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="mobile-tab-sec">
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column side-tabs-m">
                      <Nav.Item>
                        <Nav.Link eventKey="first">{midscreen?.h1}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second"> {midscreen?.h2}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third"> {midscreen?.h3}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            {ingerman === "de" ? (
                              <img src={Overviewger} />
                            ) : (
                              <img src={Overview} />
                            )}
                            {/* <img
                              src={require("../Assets/images/Overview.png")}
                            /> */}
                          </div>
                          <div className="text-side-mobile">
                            <p>{midscreen?.desc1}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            {ingerman === "de" ? (
                              <img src={Profileger} />
                            ) : (
                              <img src={ProfileImg} />
                            )}
                          </div>
                          <div className="text-side-mobile">
                            <p>{midscreen?.desc2}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            {ingerman === "de" ? (
                              <img src={Avilabilitiesger} />
                            ) : (
                              <img src={Avilabilities} />
                            )}
                          </div>
                          <div className="text-side-mobile">
                            <p>{midscreen?.desc3}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
