import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { getreflectioncategorydetails } from "../Redux/Actions/userActions";
import {
  reflectionCategoryDetails,
  reflectionIdforEditStoryTimeline,
} from "../Redux/Reducers/reflectionSlice";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";

export default function ReflectionJourneyView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [germanTitle, setGermanTitle] = useState(null);
  const [title, setTitle] = useState(null);

  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // ========================================fetch data by usequery===============================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["reflectionCategoryDetails"],
    queryFn: () => getreflectioncategorydetails(id),
  });

  {
    isSuccess &&
      dispatch(reflectionCategoryDetails(data)) &&
      dispatch(reflectionIdforEditStoryTimeline(id));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  useEffect(() => {
    let result = data?.data?.selfReflections?.find((item) =>{ 
      return (item?.categoryId
        ===id)
     
      });
    console.log(result,"res")

    setTitle(result?.title)
    setGermanTitle(result?.germen_title)
  }, [data]);

  console.log(data, "datadtadtadta");

  return (
    <>
      <LayoutNew>
        <div className="right-side" style={{ position: "relative" }}>
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}
          <div className="back-page-i">
            <Link to="/reflection-journey-page">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="main-content">
            <h2>{ingerman == "de" ? germanTitle : title}</h2>

            <div className="reflection-journey-listing">
              {data?.data?.selfReflections?.map((reflection, i) => {
                const duration = moment.duration(
                  ingerman == "de"
                    ? reflection?.duration_germen
                    : reflection?.duration,
                  "seconds"
                );
                const formattedTime = moment
                  .utc(duration.asMilliseconds())
                  .format("HH:mm:ss");

                return (
                  <div className="reflection-view-list-item">
                    <div className="course-tym">
                      <h4></h4>
                      <p>
                        {formattedTime[1] == "0"
                          ? formattedTime.slice(3, 8)
                          : formattedTime}{" "}
                        min
                      </p>
                    </div>
                    <div className="course-part">
                      <div className="left-part">
                        <h3>
                          {ingerman == "de"
                            ? reflection?.germen_title
                            : reflection?.title}
                        </h3>
                        <p>
                          {ingerman == "de"
                            ? reflection?.germen_subTitle
                            : reflection?.subTitle}
                        </p>
                      </div>
                      <div className="right-part">
                        {reflection?.audio_completed == "1" && (
                          <Link
                            to={
                              reflection?.journeytype == 0
                                ? reflection?.questions_completed == 1
                                  ? `/Q&A/${reflection?._id}`
                                  : `/questionnaire-type/${reflection?._id}`
                                : reflection?.journeytype == 1
                                ? `/story-timeline-add/${reflection?._id}`
                                : `/wheel-of-life/${reflection?._id}`
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="48"
                              height="38"
                              viewBox="0 0 48 38"
                              fill="none"
                            >
                              <path
                                d="M44.6847 29.6136H39.6407L36.8949 33.2136L31.9932 29.6136C31.9932 29.6136 16.6983 29.6339 16.6983 29.6136C14.8475 29.5932 13.3831 27.9458 13.3831 26.2576V3.33559C13.3627 1.50509 14.8475 0 16.678 0H44.6847C46.5153 0 48 1.50509 48 3.33559V26.2576C48 28.1288 46.5153 29.6136 44.6847 29.6136ZM29.5932 24.8136H32.2373V22.1288H29.5932V24.8136ZM35.3492 6.85424C34.2508 5.83729 32.7864 5.32881 30.9356 5.32881C29.1864 5.32881 27.783 5.79661 26.7254 6.75254C25.6678 7.68814 25.0169 9.07119 24.8136 10.861L27.1932 11.1458C27.3966 9.82373 27.8441 8.82712 28.4949 8.21695C29.1661 7.58644 29.9797 7.28136 30.9763 7.28136C31.9932 7.28136 32.8678 7.62712 33.5593 8.31865C34.2712 9.01017 34.6169 9.80339 34.6169 10.7186C34.6169 11.2271 34.4949 11.6949 34.2508 12.122C34.0068 12.5492 33.4983 13.1186 32.6847 13.8508C31.8915 14.5627 31.322 15.1119 31.0169 15.478C30.5898 15.9864 30.2847 16.4949 30.1017 17.0034C29.8373 17.6746 29.7153 18.4678 29.7153 19.4034C29.7153 19.5661 29.7153 19.7898 29.7356 20.1153H31.9729C31.9932 19.1797 32.0542 18.4881 32.1559 18.0814C32.2576 17.6746 32.4407 17.2881 32.6644 16.9627C32.8881 16.6373 33.3966 16.1492 34.1492 15.4576C35.2678 14.4407 36.0203 13.5864 36.4068 12.8949C36.7932 12.1831 36.9966 11.4305 36.9966 10.5763C36.9966 9.11187 36.4475 7.87119 35.3492 6.85424ZM25.4034 31.8509V32.7458C25.4034 34.1085 24.3254 35.2068 22.983 35.2068H11.7356L8.15593 37.8305L6.14237 35.2068H2.42034C1.07797 35.2068 0 34.1085 0 32.7458V15.9458C0 14.5831 1.07797 13.4847 2.42034 13.4847L11.0847 13.261L11.0644 27.6814C11.0644 27.6814 10.9424 29.7559 11.7559 30.6305C12.8339 31.8305 14.7661 31.8509 14.7661 31.8509H25.4034Z"
                                fill="#4CA890"
                              />
                            </svg>
                          </Link>
                        )}

                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              i > 0 &&
                              data?.data?.selfReflections[i - 1]
                                ?.audio_completed == "1"
                            ) {
                              navigate("/reflection-journey-audio", {
                                state: {
                                  id: reflection?._id,
                                  audio: reflection?.audio,
                                  title: reflection?.title,
                                  german_title: reflection?.germen_title,
                                  subTitle: reflection?.subTitle,
                                  germen_subTitle: reflection?.germen_subTitle,
                                  audio_completed: reflection?.audio_completed,
                                  germen_audio: reflection?.germen_audio,
                                },
                              });
                            } else if (i == 0) {
                              navigate("/reflection-journey-audio", {
                                state: {
                                  id: reflection?._id,
                                  audio: reflection?.audio,
                                  title: reflection?.title,
                                  german_title: reflection?.germen_title,
                                  subTitle: reflection?.subTitle,
                                  germen_subTitle: reflection?.germen_subTitle,
                                  audio_completed: reflection?.audio_completed,
                                  germen_audio: reflection?.germen_audio,
                                },
                              });
                            } else {
                              toast.error("First, listen to the audio above");
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="49"
                            height="49"
                            viewBox="0 0 49 49"
                            fill="none"
                          >
                            <circle
                              cx="24.5"
                              cy="24.5"
                              r="24.5"
                              fill="#4CA890"
                            />
                            <path
                              d="M18.0569 34.8774C17.4741 34.8762 17 34.3649 17 33.7373V15.954C17 15.7582 17.0467 15.5654 17.135 15.3963C17.3226 15.0374 17.6762 14.8145 18.0578 14.8145C18.234 14.8145 18.4094 14.8632 18.565 14.9555L33.5932 23.8468C33.767 23.9494 33.9094 24.1018 34.0052 24.2876C34.294 24.8402 34.1089 25.5382 33.5929 25.8447L18.5652 34.7357C18.4111 34.8278 18.2356 34.8767 18.0569 34.8774Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="reflection-view-list-item">
                <div className="course-tym">
                  <h4>Course</h4>
                  <p>8.00 min</p>
                </div>
                <div className="course-part">
                  <div className="left-part">
                    <h3>The Universe and You</h3>
                    <p>Part 2: Personal Storytelling with the Storyimeline</p>
                  </div>
                  <div className="right-part">
                    <Link to="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49"
                        height="49"
                        viewBox="0 0 49 49"
                        fill="none"
                      >
                        <circle cx="24.5" cy="24.5" r="24.5" fill="#4CA890" />
                        <path
                          d="M18.0569 34.8774C17.4741 34.8762 17 34.3649 17 33.7373V15.954C17 15.7582 17.0467 15.5654 17.135 15.3963C17.3226 15.0374 17.6762 14.8145 18.0578 14.8145C18.234 14.8145 18.4094 14.8632 18.565 14.9555L33.5932 23.8468C33.767 23.9494 33.9094 24.1018 34.0052 24.2876C34.294 24.8402 34.1089 25.5382 33.5929 25.8447L18.5652 34.7357C18.4111 34.8278 18.2356 34.8767 18.0569 34.8774Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="reflection-view-list-item">
                <div className="course-tym">
                  <h4>Course</h4>
                  <p>8.00 min</p>
                </div>
                <div className="course-part">
                  <div className="left-part">
                    <h3>The Universe and You</h3>
                    <p>Part 3: Highs, Lows and Turning Points in Your Life</p>
                  </div>
                  <div className="right-part">
                    <Link to="/reflection-journey-audio">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49"
                        height="49"
                        viewBox="0 0 49 49"
                        fill="none"
                      >
                        <circle cx="24.5" cy="24.5" r="24.5" fill="#4CA890" />
                        <path
                          d="M18.0569 34.8774C17.4741 34.8762 17 34.3649 17 33.7373V15.954C17 15.7582 17.0467 15.5654 17.135 15.3963C17.3226 15.0374 17.6762 14.8145 18.0578 14.8145C18.234 14.8145 18.4094 14.8632 18.565 14.9555L33.5932 23.8468C33.767 23.9494 33.9094 24.1018 34.0052 24.2876C34.294 24.8402 34.1089 25.5382 33.5929 25.8447L18.5652 34.7357C18.4111 34.8278 18.2356 34.8767 18.0569 34.8774Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
