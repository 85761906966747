import { createSlice } from "@reduxjs/toolkit";

export const reflectionSlice = createSlice({
  name: "userMgmtSlice",
  initialState: {
    list: null,
    catDetails: null,
    questions: null,
    reflectionId: null,
    storyTimeline: null,
    id:null,
    reflectionIdforedit:null,
    wheel:null
  },
  reducers: {
    reflectionList: (state, action) => {
      console.log(action, "action");
      state.list = action.payload;
    },

    reflectionCategoryDetails: (state, action) => {
      console.log(action, "action");
      state.catDetails = action.payload;
    },

    questions: (state, action) => {
      console.log(action, "action");
      state.questions = action.payload;
    },

    reflection: (state, action) => {
      console.log(action, "action");
      state.reflectionId = action.payload;
    },

    storyTimeLine: (state, action) => {
      console.log(action, "action");
      state.storyTimeline = action.payload;
    },

    storyTimeLineID: (state, action) => {
      console.log(action, "action");
      state.id = action.payload;
    },

    reflectionIdforEditStoryTimeline: (state, action) => {
      console.log(action, "action");
      state.reflectionIdforedit = action.payload;
    },

    wheelData: (state, action) => {
      console.log(action, "action");
      state.wheel = action.payload;
    },



  },
});

export const {
  reflectionList,
  reflectionCategoryDetails,
  questions,
  reflection,
  storyTimeLine,
  storyTimeLineID,
  reflectionIdforEditStoryTimeline,wheelData
} = reflectionSlice.actions;

export default reflectionSlice.reducer;
