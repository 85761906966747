import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import {
  saveStoryTimeline,
  saveWheelofLife,
} from "../Redux/Actions/userActions";
import { useSelector } from "react-redux";

export default function AddWheelOfLife() {
  const navigate = useNavigate();
  const storyTimeLineId = useSelector((state) => state.reflectionData.id);
  // ==========================================================react state===============================================

  const [wheelData, setwheelData] = useState({
    category: "",
    point: "",
  });
  let wheelDatatoSend = [];

  // ==========================================post data using react query=====================================

  const { isPending, mutate } = useMutation({
    mutationFn: saveWheelofLife,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        navigate(`/wheel-of-life/${storyTimeLineId}`);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ===========================================get data from inputs====================================================

  const handlechange = (e) => {
    setwheelData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    wheelDatatoSend.push(wheelData);
  }, [wheelData]);

  const handlewheel = () => {
    let isvalid = true;

    if (!wheelData?.category) {
      toast.error("Please add a category");
      isvalid = false;
    } else if (!wheelData?.point) {
      toast.error("Please add Number");
    } else if (
      (wheelData?.point && wheelData.point < 1) ||
      wheelData.point > 5
    ) {
      toast.error("Number must be in between of 1 to 5");
    } else {
     mutate({
        id: storyTimeLineId,
        wheelOfLife: wheelDatatoSend,
      });
    }
  };

  return (
    <>
      <LayoutNew>
        <div className="right-side" style={{ position: "relative" }}>
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )}
          <div className="back-page-i">
            <Link
              onClick={() => {
                navigate(-1);
              }}
              // to="/wheel-of-life"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="main-content">
            <div className="right-headings">
              <h2>Add a Category to the Wheel of Life</h2>
            </div>
            <div className="add-life-event">
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Category</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="E.g.  family, career, relationship, etc"
                  name="category"
                  value={wheelData?.category}
                  onChange={handlechange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Number</Form.Label>
                <Form.Control
                  name="point"
                  value={wheelData?.point}
                  onChange={handlechange}
                  type="text"
                  placeholder="1 to 5"
                />
              </Form.Group>
              <div
                onClick={handlewheel}
                className="bck-nxt-btns justify-content-end"
              >
                <Link
                  to="#"
                  // to="/wheel-of-life"
                >
                  Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
