import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormControl, FormLabel } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import ReactStars from "react-rating-stars-component";
import { leaveReview } from "../Redux/Actions/userActions";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

export default function LeaveReview() {
  const location = useLocation();
  const navigate = useNavigate();

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  const [rating, setRating] = useState(null);
  const [comment, setcomment] = useState(null);
  const [coachId, setcoachId] = useState(null);
  const [appId, setappId] = useState(null);

  useEffect(() => {
    if (location?.state) {
      setappId(location.state?.appId);
      setcoachId(location.state?.coachId);
    }
  }, [location]);

  const ratingChanged = (newRating) => {
    setRating(newRating);
    // You can perform any other actions with the new rating here
  };

  // ===========================================POST DATA USING REACT QUERY==========================

  const { isPending, mutate } = useMutation({
    mutationFn: leaveReview,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        // res?.data?.role== 0 ?  navigate("/get-started") : ""
        //  sessionStorage.clear()
        navigate("/get-started");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  const handlerating = () => {
    if (!rating) {
      toast.error("Please select the rating");
    } else if (!comment) {
      toast.error("Please add a comment");
    } else {
      mutate({
        rating: rating,
        review: comment,
        appointment_id: appId,
        coach_id: coachId,
        // langType:0
      });
    }
  };

  return (
    <>
      <LayoutNew>
        <div style={{ position: "relative" }} className="right-side">
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}

          <div className="main-content">
            <div className="leave-review-img">
              <img src={require("../Assets/images/appointment-created.png")} />
            </div>
            <div className="appointment-created-txt">
              <h2>
                {ingerman == "de"
                  ? "Bitte bewerten Sie die Servicequalität!"
                  : "Please rate the quality of service !"}

                {/* <br /> of service ! */}
              </h2>
              <div className="rating-row">
                <ReactStars
                  count={5}
                  // value={value}
                  onChange={ratingChanged}
                  size={30}
                  activeColor="#ffd700"
                />
                {/* <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M20.0002 3.33203L25.1502 13.7654L36.6668 15.4487L28.3335 23.5654L30.3002 35.032L20.0002 29.6154L9.70016 35.032L11.6668 23.5654L3.3335 15.4487L14.8502 13.7654L20.0002 3.33203Z"
                      fill="#A2AA96"
                      stroke="#A2AA96"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M20.0002 3.33203L25.1502 13.7654L36.6668 15.4487L28.3335 23.5654L30.3002 35.032L20.0002 29.6154L9.70016 35.032L11.6668 23.5654L3.3335 15.4487L14.8502 13.7654L20.0002 3.33203Z"
                      fill="#A2AA96"
                      stroke="#A2AA96"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M20.0002 3.33203L25.1502 13.7654L36.6668 15.4487L28.3335 23.5654L30.3002 35.032L20.0002 29.6154L9.70016 35.032L11.6668 23.5654L3.3335 15.4487L14.8502 13.7654L20.0002 3.33203Z"
                      fill="#A2AA96"
                      stroke="#A2AA96"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M20.0002 3.33203L25.1502 13.7654L36.6668 15.4487L28.3335 23.5654L30.3002 35.032L20.0002 29.6154L9.70016 35.032L11.6668 23.5654L3.3335 15.4487L14.8502 13.7654L20.0002 3.33203Z"
                      fill="#A2AA96"
                      stroke="#A2AA96"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M20.0002 3.33203L25.1502 13.7654L36.6668 15.4487L28.3335 23.5654L30.3002 35.032L20.0002 29.6154L9.70016 35.032L11.6668 23.5654L3.3335 15.4487L14.8502 13.7654L20.0002 3.33203Z"
                      fill="#A2AA96"
                      stroke="#A2AA96"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span> */}
              </div>
              <p className="invoice-para">
                {ingerman == "de"
                  ? "Ihre Kommentare und Vorschläge helfen uns"
                  : "Your comments and suggestions help us"}

                <br />
                {ingerman == "de"
                  ? "Verbessern Sie die Servicequalität besser!"
                  : "improve the service quality better!"}
              </p>
            </div>
            <div className="write-review-box questionnaire-type">
              <Form.Control
                onChange={(e) => setcomment(e.target.value)}
                as="textarea"
                rows={6}
                placeholder={
                  ingerman == "de" ? "Geben Sie hier ein..." : "Type here..."
                }
              />
            </div>
            <div onClick={handlerating} className="invoice-details-link">
              <Link to="#">{ingerman == "de" ? "Einreichen" : "Submit"}</Link>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
