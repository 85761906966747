import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useQuery } from "@tanstack/react-query";
import { getMedicationFavList, getReflectionJourney } from "../Redux/Actions/userActions";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { reflectionList } from "../Redux/Reducers/reflectionSlice";
import Refbanner from "../Assets/images/reflection-journey-bg.png";
export default function ReflectionJourneyPage() {
  const dispatch = useDispatch();
  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate = useNavigate();

  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // ===================================fetch data using usequery===================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["reflectionJourney"],
    queryFn: getReflectionJourney,
  });

  {
    isSuccess && dispatch(reflectionList(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data, "data");
  console.log(`${data?.data?.welcome_audio?.color}`);

  return (
    <LayoutNew>
      <div style={{ position: "relative" }} className="right-side">
        {isPending && (
          <div
            style={{
              position: "absolute",
              width: "110%",
              height: "100%",
              zIndex: "1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              // backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <div class="loader"></div>
          </div>
        )}
        <div className="main-content">
          <h2>Reflection Journey</h2>
          <div className="reflection-journey-listing">
            <div
              style={{
                backgroundImage: `url(${BASE_URL}${data?.data?.welcome_audio?.image})`,
              }}
              className="reflection-list-item"
            >
              <h3 style={{ color: `${data?.data?.welcome_audio?.color}` }}>
                {ingerman == "de"
                  ? data?.data?.welcome_audio?.germen_title
                  : data?.data?.welcome_audio?.title}
              </h3>

              <Link to="/reflection-journey-audio">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                >
                  <circle cx="24.5" cy="24.5" r="24.5" fill="#4CA890" />
                  <path
                    d="M18.0569 34.8774C17.4741 34.8762 17 34.3649 17 33.7373V15.954C17 15.7582 17.0467 15.5654 17.135 15.3963C17.3226 15.0374 17.6762 14.8145 18.0578 14.8145C18.234 14.8145 18.4094 14.8632 18.565 14.9555L33.5932 23.8468C33.767 23.9494 33.9094 24.1018 34.0052 24.2876C34.294 24.8402 34.1089 25.5382 33.5929 25.8447L18.5652 34.7357C18.4111 34.8278 18.2356 34.8767 18.0569 34.8774Z"
                    fill="white"
                  />
                </svg>
              </Link>
            </div>

            {data?.data?.data?.map((reflection) => {
              return (
                <div
                  className="reflection-list-item"
                  style={{
                    backgroundImage: `url(${BASE_URL}${reflection?.category_img})`,
                    cursor:"pointer"
                  }}
                >
                  <h3 style={{ color: `${reflection?.color}` }}>
                    {ingerman == "de"
                      ? reflection?.germen_title
                      : reflection?.title}
                  </h3>
                  <div style={{textAlign:"end"}}
                    onClick={() => {
                      navigate(`/reflection-journey-view/${reflection?._id}`, {
                        state: {
                          title: reflection?.title,

                          germen_title: reflection?.germen_title,
                        },
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                    >
                      <rect width="50" height="50" rx="6.125" fill="#4CA890" />
                      <path
                        d="M20 15.625L29.1875 24.8125L20 34"
                        stroke="white"
                        stroke-width="1.8375"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </LayoutNew>
  );
}
