import React, { useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import LayoutCoach from "../Components/LayoutCoach";
import { dashboard, getAppointments } from "../Redux/Actions/coachAction";
import {
  appointmentList,
  coachAppointmentList,
} from "../Redux/Reducers/appointmentSlice";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment/moment";

export default function Dashboard() {
  const dispatch = useDispatch();
  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate=useNavigate()

  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

   // // ======================================check for language=======================================

   const ingerman = useSelector((state) => state.homedata.selectedLang);
   console.log(ingerman);


   
 



  // ================================================react State================================================

  const [type, setType] = useState(null);

  // =================================================get list of appointments=====================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["CoachappointmentList", type],
    queryFn: () => getAppointments(type),
    // enabled: userrole === 'coach',
  });

  {
    isSuccess && dispatch(coachAppointmentList(data));
  }

  {
    // isError && toast.error(data?.message || "Something went wrong");
  }

  // =================================================get list upcoming and past appointments=====================================

  const {
    isPending: pending,
    data: dashData,
    isSuccess: dashSuccess,
    isError: dashError,
  } = useQuery({
    queryKey: ["dashboardappointmentList", type],
    queryFn: () => (type ? dashboard(type) : Promise.resolve()),
  });

  {
    dashSuccess && dispatch(coachAppointmentList(data));
  }

  {
    // dashError && toast.error(data?.message || "Something went wrong");
  }

  console.log(dashData, "data");

  return (
    <>
      <LayoutCoach>
        <div style={{ position: "relative" }} className="right-side">
          {(isPending || pending) && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}

          {/* <div className="back-page-i">
            <Link to="/settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div> */}

          <div className="main-content">
            <h2>{ingerman=="de" ? "Dashboard" : "Dashboard"}</h2>
            <div className="appointments-list-box">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav
                      variant="pills"
                      className="appointments-tabs dashboard-tabs"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">{ingerman=="de" ? "Neu" : "New"}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => setType("1")}
                          eventKey="second"
                        >
                         {ingerman=="de" ? "Heute" : "Today"} 
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third" onClick={() => setType("2")}>
                        {ingerman=="de" ? "Bevorstehende" : "Upcoming"}  
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="coaching-listing">
                          <h2 className="text-start">{ingerman=="de" ? "Neue Termine" : "New Appointments"}</h2>
                          {/* <Link to="/requested-details-coach" className="coaching-card-item appointments-main-card"> */}
                          {data?.data?.length > 0 ? (
                            data?.data?.map((data) => {
                              return (
                                <div style={{cursor:"pointer"}} onClick={() =>
                                    navigate("/requested-details", {
                                      state: {
                                        img:data?.user_id?.profile_image,
                                        name: data?.user_id?.full_name,
                                        createdAt: data?.date,
                                        country_code:
                                        data?.user_id?.country_code,
                                        phone_number:
                                        data?.user_id?.phone_number,
                                        slot: data?.timeSlot,
                                        status: data?.status,
                                        coachDetails: data?.user_id,
                                        appointmentId: data?._id,
                                      },
                                    })
                                  } className="coaching-card-item">
                                  <div className="coach-img-detail">
                                    <div className="appointment-date-card">
                                      <img
                                        src={require("../Assets/images/date-bg.png")}
                                      />
                                      <div className="appointment-date-box">
                                        <h2>
                                          {moment(data?.date).format("DD")}
                                        </h2>
                                        <p>
                                          {moment(data?.date).format("MMMM")}
                                        </p>
                                      </div>
                                    </div>
                                    <img
                                      src={
                                        data?.user_id?.profile_image
                                          ? `${BASE_URL}${data?.user_id?.profile_image}`
                                          : require("../Assets/images/place_holder.png")
                                      }
                                    />
                                    <div className="coach-card-info">
                                      <h3>{data?.user_id?.full_name}</h3>
                                      <p>
                                        {data?.user_id?.country_code}{" "}
                                        {data?.user_id?.phone_number}
                                      </p>
                                      <p className="coach-date">
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M10.0013 18.6667C14.6037 18.6667 18.3346 14.9357 18.3346 10.3333C18.3346 5.73096 14.6037 2 10.0013 2C5.39893 2 1.66797 5.73096 1.66797 10.3333C1.66797 14.9357 5.39893 18.6667 10.0013 18.6667Z"
                                              stroke="black"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M10 5.33398V10.334L13.3333 12.0007"
                                              stroke="black"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                        {moment(data?.createdAt).format("hh:mm a")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                marginTop: "100px",
                              }}
                            >
                              {ingerman=="de" ? "Keine Daten gefunden" : "Data not found"}
                            </p>
                          )}
                          {/* </Link> */}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="coaching-listing">
                          <h2 className="text-start">{ingerman=="de" ? "Heute Termine" : 'Today Appointments'}</h2>

                          {dashData?.data?.length > 0 ? (
                            dashData?.data?.map((data) => {
                              return (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate("/requested-details", {
                                      state: {
                                        img:data?.user_id?.profile_image,
                                        name: data?.user_id?.full_name,
                                        createdAt: data?.date,
                                        country_code:
                                        data?.user_id?.country_code,
                                        phone_number:
                                        data?.user_id?.phone_number,
                                        slot: data?.timeSlot,
                                        status: data?.status,
                                        coachDetails: data?.user_id,
                                        appointmentId: data?._id,
                                      },
                                    })
                                  }
                                  className="coaching-card-item"
                                >
                                  <div className="coach-img-detail">
                                    <div className="appointment-date-card">
                                      <img
                                        src={require("../Assets/images/date-bg.png")}
                                      />
                                      <div className="appointment-date-box">
                                        <h2>
                                          {moment(data?.date).format("DD")}
                                        </h2>
                                        <p>
                                          {moment(data?.date).format("MMMM")}
                                        </p>
                                      </div>
                                    </div>
                                    <img
                                      src={
                                        data?.user_id?.profile_image
                                          ? `${BASE_URL}${data?.user_id?.profile_image}`
                                          : require("../Assets/images/place_holder.png")
                                      }
                                    />
                                    <div className="coach-card-info">
                                      <h3>{data?.user_id?.full_name}</h3>
                                      <p>
                                        {data?.user_id?.country_code}{" "}
                                        {data?.user_id?.phone_number}
                                      </p>
                                      <p className="coach-date">
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M10.0013 18.6667C14.6037 18.6667 18.3346 14.9357 18.3346 10.3333C18.3346 5.73096 14.6037 2 10.0013 2C5.39893 2 1.66797 5.73096 1.66797 10.3333C1.66797 14.9357 5.39893 18.6667 10.0013 18.6667Z"
                                              stroke="black"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M10 5.33398V10.334L13.3333 12.0007"
                                              stroke="black"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                        {moment(data?.createdAt).format("hh:mm a")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                marginTop: "100px",
                              }}
                            >
                              {ingerman=="de" ? "Keine Daten gefunden" : "Data not found"}
                            </p>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="coaching-listing">
                          <h2 className="text-start">{ingerman=="de" ? "Bevorstehende Termine" : "Upcoming Appointments"}</h2>
                          {dashData?.data?.length > 0 ? (
                            dashData?.data?.map((data) => {
                              return (
                                <div style={{cursor:"pointer"}} onClick={() =>
                                    navigate("/requested-details", {
                                      state: {
                                        img:data?.user_id?.profile_image,
                                        name: data?.user_id?.full_name,
                                        createdAt: data?.date,
                                        country_code:
                                        data?.user_id?.country_code,
                                        phone_number:
                                        data?.user_id?.phone_number,
                                        slot: data?.timeSlot,
                                        status: data?.status,
                                        coachDetails: data?.user_id,
                                        appointmentId: data?._id,
                                      },
                                    })
                                  } className="coaching-card-item">
                                  <div className="coach-img-detail">
                                    <div className="appointment-date-card">
                                      <img
                                        src={require("../Assets/images/date-bg.png")}
                                      />
                                      <div className="appointment-date-box">
                                        <h2>
                                          {moment(data?.date).format("DD")}
                                        </h2>
                                        <p>
                                          {moment(data?.date).format("MMMM")}
                                        </p>
                                      </div>
                                    </div>
                                    <img
                                      src={
                                        data?.user_id?.profile_image
                                          ? `${BASE_URL}${data?.user_id?.profile_image}`
                                          : require("../Assets/images/place_holder.png")
                                      }
                                    />
                                    <div className="coach-card-info">
                                      <h3>{data?.user_id?.full_name}</h3>
                                      <p>
                                        {data?.user_id?.country_code}{" "}
                                        {data?.user_id?.phone_number}
                                      </p>
                                      <p className="coach-date">
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M10.0013 18.6667C14.6037 18.6667 18.3346 14.9357 18.3346 10.3333C18.3346 5.73096 14.6037 2 10.0013 2C5.39893 2 1.66797 5.73096 1.66797 10.3333C1.66797 14.9357 5.39893 18.6667 10.0013 18.6667Z"
                                              stroke="black"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M10 5.33398V10.334L13.3333 12.0007"
                                              stroke="black"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                        {moment(data?.createdAt).format("hh:mm a")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                marginTop: "100px",
                              }}
                            >
                              {ingerman=="de" ? "Keine Daten gefunden" : "Data not found"}
                            </p>
                          )}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </LayoutCoach>
    </>
  );
}
