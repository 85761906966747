import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n, { setDefaultNamespace } from "i18next";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import {
  getReflectionquestions,
  saveReflectionjourney,
} from "../Redux/Actions/userActions";
import { questions } from "../Redux/Reducers/reflectionSlice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { coachLogin } from "../Redux/Actions/coachAction";

export default function QuestionnaireType() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const pageParam = parseInt(
    location?.state?.index == 0
      ? location?.state?.index + 1
      : location?.state?.index > 0
      ? location?.state?.index + 1
      : searchParams.get("page") || 1
  );

  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  const reflectionId = useSelector(
    (state) => state.reflectionData.reflectionId
  );

  // ===============================================get points======================================

  const pointsData = useSelector((state) => state.reflectionData.questions);
  console.log(pointsData, "points");

  // ===========================================react state=======================================

  const [currentPage, setCurrentPage] = useState(pageParam);
  const [totalPages, setTotalPages] = useState(0);
  const [questionsPerPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [textAnswer, setTextAnswer] = useState([]);

  // =============================================get questions=====================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["addStoryTimeLine"],
    queryFn: () => getReflectionquestions(id),
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(questions(data));
      setTotalPages(
        Math.ceil(data?.data?.questions?.length / questionsPerPage)
      );

      // Initialize answers with existing answers from location state
      if (location?.state?.answer) {
        const updatedAnswers = [...textAnswer];
        // const index =
        //   indexOfFirstQuestion +
        //   data.data.questions.findIndex(
        //     (question) => question?._id === location.state.answer.question_id
        //   );
        // if (index !== -1) {
        updatedAnswers[location?.state?.index] = {
          ...updatedAnswers[location?.state?.index],
          answer: location?.state?.answer, // Set your desired value here
          question: location.state.question,
          question_id: location.state.question_id,
          type: location.state.type,
          germen_question: location?.state?.germen_question,
          option: location?.state?.option,
        };

        if (location.state.type == 1) {
          setSelectedOption(location?.state?.answer);
        }

        // const updatedAnswers = [...textAnswer];
        // console.log(updatedAnswers);
        // updatedAnswers[location?.state?.index] = {
        //   ...updatedAnswers[location?.state?.index],
        //   answer: location?.state?.answer,
        // };

        setTextAnswer(updatedAnswers);
      }
    }
    // }
  }, [data, dispatch, isSuccess, questionsPerPage, location]);

  useEffect(() => {
    if (isError) {
      toast.error(data?.message || "Something went wrong");
    }
  }, [isError, data]);

  useEffect(() => {
    setCurrentPage(pageParam);
  }, [pageParam]);

  const nextPage = (index) => {
    console.log(pageParam, "pageparam");
    if (textAnswer[index - 1]?.answer == undefined) {
      toast.error("Please give the answer");
      setSearchParams({ page: currentPage });
    } else {
      if (currentPage < totalPages) {
        setSearchParams({ page: currentPage + 1 });

        // let index=currentPage

        // const updatedAnswers = [...textAnswer];
        // updatedAnswers[index] = {
        //   ...updatedAnswers[index],
        //   answer:"",
        // };
        //  setTextAnswer(updatedAnswers)
      }
    }
  };

  console.log(currentPage, "currentPage");

  console.log(textAnswer, "answer");

  const prevPage = () => {
    if (currentPage > 1) {
      setSearchParams({ page: currentPage - 1 });
    }
  };

  const goToPage = (page, index) => {
    // if (textAnswer[index - 1] == undefined) {
    //   toast?.error("All questions are compulsory.");
    // }

    setSearchParams({ page });
  };

  const indexOfLastQuestion = currentPage * questionsPerPage;

  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = data?.data?.questions?.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  // ==================================MCQ=============================================

  // const handleOptionChange = (event) => {
  //   console.log(event.target.value,"vasl")
  //   setSelectedOption(event.target.value);
  // };

  // ==============================text questions=======================================

  const handleAnswers = (e, index, questionId, question, que_type) => {
    console.log(e.target.value, "value");

    if (que_type == 1) {
      setSelectedOption(e.target.value);
    }

    const updatedAnswers = [...textAnswer];
    updatedAnswers[index] = {
      ...updatedAnswers[index],
      [e.target.name]: e.target.value,
    };
    updatedAnswers[index].question = question;
    updatedAnswers[index].question_id = questionId;
    updatedAnswers[index].type = que_type;
    setTextAnswer(updatedAnswers);
  };

  // const handleAnswers = (e, index) => {
  //   const updatedAnswers = [...textAnswer] ;
  //   updatedAnswers[index] = {answer: e.target.value};
  //   setTextAnswer(updatedAnswers);
  // };

  // =========================================empty selected value whe go to next page=================================

  useEffect(() => {
    setSelectedOption("");
  }, [searchParams]);

  // =================================post data using react-query=====================

  const { isPending: pending, mutate } = useMutation({
    mutationFn: saveReflectionjourney,
    onSuccess: (res) => {
      if (res?.success) {
        console.log(res, "resPoint");

        if (res?.data?.journeystatus[0]?.questions[0]?.type == 1) {
          let initialScore = 0;

          let score = res?.data?.journeystatus[0]?.questions?.map(
            (x, index) => {
              if (x?.question_id == pointsData?.data?.questions[index]?._id) {
                if (
                  pointsData?.data?.questions[index]?.correctans == x?.answer
                ) {
                  initialScore =
                    initialScore + pointsData?.data?.questions[index]?.points;
                }
              }
            }
          );

          console.log(initialScore, "scoreererreeeeee");

          navigate("/score", {
            state: {
              score: initialScore,
            },
          });
        } else {
          toast.success(res?.message);
          navigate(
            "/reflection-journey-page"
            //    {
            //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
            // }
          );
        }
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // =================================update data using react-query=====================

  const { isPending: updatepending, mutate: updateMutate } = useMutation({
    mutationFn: saveReflectionjourney,
    onSuccess: (res) => {
      if (res?.success) {
        // dispatch(Userdata(res?.data));
        toast.success(res?.message);
        navigate(
          `/Q&A/${reflectionId}`
          //    {
          //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
          // }
        );
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  console.log(textAnswer, "answer");
  console.log(textAnswer[indexOfFirstQuestion + 0]?.answer, "val");

  return (
    <>
      <LayoutNew>
        <div
          className="right-side"
          style={{
            position: "relative",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {(isPending || pending || updatepending) && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )}

          <div style={{cursor:"pointer"}} className="back-page-i">
            <div onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="main-content">
            {/* <div className="right-headings"> */}
            <h2>{location?.state?.answer ? "Q&A" : "Questionnaire"}</h2>
            {/* </div> */}
            {location?.state?.answer ? (
              ""
            ) : (
              <Row>
                <Col lg={12}>
                  <div className="pagination-controls">
                    <span className="ques-box-contin">
                      {totalPages > 0 &&
                        Array.from({ length: totalPages }, (_, index) => (
                          <span
                            className={`page-circle ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            onClick={() => goToPage(index + 1, index)}
                            key={index}
                          >
                            Q.{index + 1}
                          </span>
                        ))}
                    </span>
                  </div>
                </Col>
              </Row>
            )}

            {currentQuestions?.map((question, index) => (
              <div className="questionnaire-type" key={index}>
                <Form.Group
                  className="mb-3 mt-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="mb-3">
                    {ingerman === "de"
                      ? question.german_question
                      : question.question}
                  </Form.Label>
                  {question?.type == "1" ? (
                    <div className="option-sec-que">
                      {question?.answers?.map((options) => {
                        return (
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption === options?.key && "selected"
                            }`}
                          >
                            <Form.Control
                              type="radio"
                              name="answer"
                              value={options?.key}
                              checked={selectedOption === options?.key}
                              onChange={(e) =>
                                handleAnswers(
                                  e,
                                  pageParam - 1,
                                  question?._id,
                                  question.question
                                    ? question.question
                                    : question.german_question,
                                  question.type
                                )
                              }
                            />
                            {options?.key}. {options?.option}
                          </Form.Label>
                        );
                      })}
                    </div>
                  ) : (
                    <Form.Control
                      as="textarea"
                      rows={8}
                      placeholder="Type here"
                      onChange={(e) =>
                        handleAnswers(
                          e,
                          pageParam - 1,
                          question?._id,
                          question.question
                            ? question.question
                            : question.german_question,
                          question.type
                        )
                      }
                      name="answer"
                      value={
                        textAnswer[indexOfFirstQuestion + index]?.answer || ""
                      }
                    />
                  )}
                </Form.Group>
                <div className="bck-nxt-btns">
                  <div onClick={prevPage} disabled={currentPage === 1}>
                    {location?.state?.answer ? "" : <Link>Back</Link>}
                  </div>
                  <div
                    onClick={() => {
                      if (location?.state?.answer) {
                        let editdata = textAnswer.filter(
                          (data) => data != undefined
                        );

                        updateMutate({
                          editKey: "yes",
                          id: reflectionId,
                          questions: editdata,
                          type: "1",
                        });
                      } else {
                        if (pageParam == totalPages) {
                          let findMissingAnswer =
                            textAnswer?.indexOf(undefined);
                          if (findMissingAnswer != -1) {
                            toast.error(
                              `Please give answer of question ${
                                findMissingAnswer + 1
                              }`
                            );
                          }
                          if (findMissingAnswer == -1) {
                            if (textAnswer?.length != totalPages) {
                              toast.error(
                                `Please give answer of question ${
                                  textAnswer?.length + 1
                                }`
                              );
                            } else {
                              if (location?.state?.answer) {
                              } else {
                                mutate({
                                  id: id,
                                  questions: textAnswer,
                                  type: 1,
                                });
                              }
                            }
                          }
                        } else {
                          nextPage(pageParam);
                        }
                      }
                    }}
                    disabled={currentPage === totalPages}
                  >
                    <Link>
                      {pageParam == totalPages
                        ? "Submit"
                        : location?.state?.answer
                        ? "Save"
                        : "Next"}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
