import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Overview from "../Assets/images/Overview.png";
import Overviewger from "../Assets/images/Overviewger.png";
import ProfileImg from "../Assets/images/Profile.png";
import Profileger from "../Assets/images/Profileger.png";
import Avilabilities from "../Assets/images/Avilabilities.png";
import Avilabilitiesger from "../Assets/images/Avilabilitiesger.png";
import Messages from "../Assets/images/Messages.png";
import Messagesger from "../Assets/images/Messagesger.png";
import { useSelector } from "react-redux";
import mainimg from "../../src/Assets/images/main_banner.png";
import grmanimg from "../../src/Assets/images/main_banner-ger.png";

export default function ForCoachs() {
  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  const { t } = useTranslation();
  const {
    CoachesHeader,
    CoachesDesc,
    imageHeader,
    image1Desc,
    image2Desc,
    image3Desc,
    overview,
    overviewHead,
    Profile,
    Profilehead,
    Availaibilities,
    Availaibilitiesdesc,
    Messenger,
    Messengerhead,
  } = t("forCoaches");

  return (
    <Layout>
      <section
        className="for-coach-banner"
        style={{
          backgroundImage:
            ingerman === "de" ? `url(${grmanimg})` : `url(${mainimg})`,
        }}
      >
        <Container>
          <Row className="">
            <Col lg={6} className="">
              <div className="for-coach-text">
                <img src="" />
                <h2 className="Platform-font">
                  {/* Coaching has never been so easy. */}
                  {CoachesHeader}
                </h2>
                <p>
                  {/* MindRepublic takes care of scheduling video conference
                  licenses, payment processing and invoicing. Coaches can
                  finally focus on what matters:Their clients */}

                  {CoachesDesc}
                </p>
              </div>
            </Col>
            <Col lg={6} className=""></Col>
          </Row>
        </Container>
      </section>
      <section className="for-coach-how">
        <Container>
          <Row>
            <Col lg={12} className="">
              <h2 className="Platform-font">{imageHeader}</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="">
              <div className="coach-how-card">
                <img src={require("../Assets/images/Graphics_1.png")} />
                <p>{image1Desc}</p>
              </div>
            </Col>
            <Col lg={4} className="">
              <div className="coach-how-card">
                <img src={require("../Assets/images/Graphics_2.png")} />
                <p>{image2Desc}</p>
              </div>
            </Col>
            <Col lg={4} className="">
              <div className="coach-how-card">
                <img src={require("../Assets/images/Graphics_3.png")} />
                <p>{image3Desc}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="phone-tabs-section">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="mobile-tab-sec">
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column side-tabs-m">
                      <Nav.Item>
                        <Nav.Link eventKey="first">{overviewHead}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">{Profilehead}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          {Availaibilitiesdesc}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Four">{Messengerhead}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            {ingerman === "de" ? (
                              <img src={Overviewger} />
                            ) : (
                              <img src={Overview} />
                            )}
                            {/* <img
                              src={require("../Assets/images/Overview.png")}
                            /> */}
                          </div>
                          <div className="text-side-mobile">
                            <p>{overview}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            {ingerman === "de" ? (
                              <img src={Profileger} />
                            ) : (
                              <img src={ProfileImg} />
                            )}
                            {/* <img
                              src={require("../Assets/images/Profile.png")}
                            /> */}
                          </div>
                          <div className="text-side-mobile">
                            <p>{Profile}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            {ingerman === "de" ? (
                              <img src={Avilabilitiesger} />
                            ) : (
                              <img src={Avilabilities} />
                            )}
                            {/* <img
                              src={require("../Assets/images/Avilabilities.png")}
                            /> */}
                          </div>
                          <div className="text-side-mobile">
                            <p>{Availaibilities}</p>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="Four">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            {ingerman === "de" ? (
                              <img src={Messagesger} />
                            ) : (
                              <img src={Messages} />
                            )}
                          </div>
                          <div className="text-side-mobile">
                            <p>{Messenger}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
            <Col lg={12}>
              <div className="text-center mt-3">
                <h3>
                  {ingerman == "de"
                    ? `Zulassungskriterien`
                    : `Eligibility criteria
`}
                </h3>
                <p>
                  {ingerman == "de"
                    ? `MindRepublic garantiert gegenüber seinen Nutzern/Coachees, dass nur qualifizierte CoachInnen auf der Plattform zur Verfügung stehen werden. Daher müssen CoachInnen, die sich bei MindRepublic registrieren wollen, dies entweder durch Vorlage ihres Zertifikats nachweisen oder eine mindestens zweijährige Berufserfahrung besitzen.`
                    : `MindRepublic guarantees towards its users/coaches that only qualified coaches will be available on the platform. Consequently, coaches who intend to register with MindRepublic are required to demonstrate this via either submitting his/her certificate or demonstrate that he/she has professional experience of at least two years.`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
