import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n, { setDefaultNamespace } from "i18next";
import { useTranslation } from "react-i18next";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import {
  getQueAns,
  getReflectionquestions,
  saveReflectionjourney,
} from "../Redux/Actions/userActions";
import { questions, reflection } from "../Redux/Reducers/reflectionSlice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function QandA() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

   // ===================================reflection id from redux========================================

   const reflectionId = useSelector((state)=>state.reflectionData.reflectionIdforedit)
   console.log(reflectionId,"ref")

  // =======================================fetch data using usequery================================

  const { isPending, data, isError, isSuccess, refetch } = useQuery({
    queryKey: ["courseCategory", parseInt(id)],
    queryFn: () => getQueAns(id),
  });

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  {
    isSuccess && dispatch(reflection(id));
  }

  console.log(data?.data[0]?.journeystatus[0]);

  return (
    <LayoutNew>
      <div className="right-side" style={{ position: "relative" }}>
      {(isPending) && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )}
        <div className="pagination-controls">
          <span></span>
        </div>
        <div style={{cursor:"pointer"}} className="back-page-i">
          <div onClick={() => navigate(`/reflection-journey-view/${reflectionId}`)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
            >
              <path
                d="M12 22L2 12L12 2"
                stroke="black"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="main-content">
          <div className="right-headings">
            <h2>Q&A</h2>
          </div>

          <div className="questionnaire-type">
            {data?.data[0]?.journeystatus[0]?.questions?.map(
              (question, index) => {
                return (
                  <div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-2">{question?.question}</p>

                      {question?.type == 0 && (
                        <button 
                          onClick={() => {
                            navigate(`/questionnaire-type/${id}`, {
                              state: {
                                answer: question?.answer,
                                index: index,
                                question: question?.question,
                                type: question?.type,
                                question_id: question?.question_id,
                                id: question?._id,
                                germen_question: question?.germen_question,
                                option: question?.option,
                              },
                            });
                          }}
                          className="q-a-edit-btn"
                        >
                         <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <rect width="36" height="36" rx="18" fill="white"/>
  <path d="M13.1596 23.0561C12.7204 23.0561 12.31 22.9054 12.0148 22.6255C11.6404 22.2738 11.4604 21.7427 11.5252 21.1686L11.7916 18.8432C11.842 18.4054 12.1084 17.8241 12.418 17.5083L18.3292 11.2716C19.8052 9.71418 21.346 9.67112 22.9084 11.1424C24.4708 12.6137 24.514 14.1495 23.038 15.7069L17.1268 21.9437C16.8244 22.2666 16.2628 22.5681 15.8236 22.6398L13.5052 23.0346C13.3828 23.0417 13.2748 23.0561 13.1596 23.0561ZM20.6404 11.1352C20.086 11.1352 19.6036 11.4797 19.114 11.9964L13.2028 18.2404C13.0588 18.3911 12.8932 18.7499 12.8644 18.9581L12.598 21.2834C12.5692 21.5202 12.6268 21.714 12.7564 21.836C12.886 21.958 13.0804 22.0011 13.318 21.9652L15.6364 21.5705C15.8452 21.5346 16.1908 21.348 16.3348 21.1973L22.246 14.9605C23.1388 14.0132 23.4628 13.1376 22.1596 11.9175C21.5836 11.3649 21.0868 11.1352 20.6404 11.1352Z" fill="black"/>
  <path d="M21.6581 16.908H21.6077C20.5165 16.8013 19.492 16.3358 18.6955 15.5847C17.899 14.8337 17.3758 13.8398 17.2085 12.7597C17.1653 12.4655 17.3669 12.1928 17.6621 12.1425C17.9573 12.0995 18.2309 12.3004 18.2813 12.5947C18.4131 13.4391 18.8227 14.216 19.4456 14.8032C20.0686 15.3904 20.8696 15.7546 21.7229 15.8386C22.0181 15.8674 22.2341 16.1329 22.2053 16.4272C22.1693 16.6999 21.9317 16.908 21.6581 16.908Z" fill="black"/>
  <path d="M24.2944 25.3769H11.3344C11.0392 25.3769 10.7944 25.1321 10.7944 24.8369C10.7944 24.5417 11.0392 24.2969 11.3344 24.2969H24.2944C24.5896 24.2969 24.8344 24.5417 24.8344 24.8369C24.8344 25.1321 24.5896 25.3769 24.2944 25.3769Z" fill="black"/>
</svg>
                        </button>
                      )}
                    </div>

                    <p> Ans: {question?.answer}</p>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </LayoutNew>
  );
}
