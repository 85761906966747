import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import FirstCoach from "./pages/FirstCoach";
import ForCoachs from "./pages/ForCoachs";
import SecondCoach from "./pages/SecondCoach";
import ThirdCoach from "./pages/ThirdCoach";
import DataProtection from "./pages/DataProtection";
import TermsConditions from "./pages/TermsConditions";
import Imprint from "./pages/Imprint";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import VerifyEmail from "./pages/VerifyEmail";
import FourthCoach from "./pages/FourthCoach";
import ReflectionJourneys from "./pages/ReflectionJourneys";
import AboutTheApp from "./pages/AboutTheApp";
import GetStarted from "./pages/GetStarted";
import ReflectionJourneyPage from "./pages/ReflectionJourneyPage";
import ReflectionJourneyView from "./pages/ReflectionJourneyView";
import ReflectionJourneyAudio from "./pages/ReflectionJourneyAudio";
import QuestionnaireType from "./pages/QuestionnaireType";
import QuestionnaireOption from "./pages/QuestionnaireOption";
import StoryTimelineAdd from "./pages/StoryTimelineAdd";
import AddLifeEvent from "./pages/AddLifeEvent";
import WheelOfLife from "./pages/WheelOfLife";
import AddWheelOfLife from "./pages/AddWheelOfLife";
import Meditation from "./pages/Meditation";
import Theory from "./pages/Theory";
import DeStressAudio from "./pages/DeStressAudio";
import Coaching from "./pages/Coaching";
import CoachDetails from "./pages/CoachDetails";
import MakeAppointment from "./pages/MakeAppointment";
import InvoiceDetails from "./pages/InvoiceDetails";
import AppointmentCreated from "./pages/AppointmentCreated";
import MyProfile from "./pages/MyProfile";
import Settings from "./pages/Settings";
import EditProfile from "./pages/EditProfile";
import Notification from "./pages/Notification";
import RequestedDetails from "./pages/RequestedDetails";
import Faqs from "./pages/Faqs";
import Appointments from "./pages/Appointments";
import LeaveReview from "./pages/LeaveReview";
import ImprintUser from "./pages/ImprintUser";
import ChangePassword from "./pages/ChangePassword";
import ChangeLanguage from "./pages/ChangeLanguage";
import FavouriteCourse from "./pages/FavouriteCourse";
import InvoicePrice from "./pages/InvoicePrice";
import Inbox from "./pages/Inbox";
import InboxChatBox from "./pages/InboxChatBox";
import CreateProfile from "./pages/CreateProfile";
import UploadCertificate from "./pages/UploadCertificate";
import GetStartedCoach from "./pages/GetStartedCoach";
import Dashboard from "./pages/Dashboard";
import RequestedDetailsCoach from "./pages/RequestedDetailsCoach";
import InboxCoach from "./pages/InboxCoach";
import InboxChatBoxCoach from "./pages/InboxChatBoxCoach";
import NotificationCoach from "./pages/NotificationCoach";
import MyProfileCoach from "./pages/MyProfileCoach";
import SettingsCoach from "./pages/SettingsCoach";
import ReviewsCoach from "./pages/ReviewsCoach";
import FaqsCoach from "./pages/FaqsCoach";
import ImprintCoach from "./pages/ImprintCoach";
import ChangePasswordCoach from "./pages/ChangePasswordCoach";
import ChangeLanguageCoach from "./pages/ChangeLanguageCoach";
import WalletCoach from "./pages/WalletCoach";
import LogoutPage from "./pages/LogoutPage";
import CreateProfileThree from "./pages/CreateProfileThree";
import CreateProfileRate from "./pages/CreateProfileRate";
import TimeAvailability from "./pages/TimeAvailability";
import AccountCreated from "./pages/AccountCreated";
import SignUpAs from "./pages/SignUpAs";
import AccountCreatedSign from "./pages/AccountCreatedSign";
import ForgotPassword from "./pages/ForgotPassword";
import YourPasswordReset from "./pages/YourPasswordReset";
import ChangePasswordSign from "./pages/ChangePasswordSign";
import Career from "./pages/Career";
import ResetPassword from "./pages/ResetPassword";
import QandA from "./pages/QandA.jsx";
import Score from "./pages/Score.jsx";
import CheckoutForm from "./Utils/stripeCheckout.jsx";
import StripeCheckout from "./Utils/stripeCheckout.jsx";
import CancelPayment from "./pages/cancelPayment.jsx";
import AppointmentDetails from "./pages/AppointmentDetails.jsx";
import ConfirmPayment from "./pages/confirmPayment.jsx";
import SeeInvoice from "./pages/SeeInvoice.jsx";
import Deleteaccount from "./pages/DeleteAccount.jsx";
import ProtectedRoutes from "./Utils/ProtectedRoutes.jsx";
import DeleteAccountweb from "./pages/DeleteAccountweb.js";
import Coachingtab from "./pages/coaching.jsx";
import AccessAgreement from "./pages/AccessAgreement.js";
import BankAccount from "./pages/BankAccountCreated.jsx";
import { requestPermission } from "./Utils/Firebase.js";

function App() {

   requestPermission()
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="career" element={<Career />} />
          <Route path="beatrice-rock" element={<FirstCoach />} />
          <Route path="carolin-wesche" element={<SecondCoach />} />
          <Route path="Catherine-Hanek" element={<ThirdCoach />} />
          <Route path="julia-preiß" element={<FourthCoach />} />
          <Route path="for-coachs" element={<ForCoachs />} />
          <Route path="coaching-tab" element={<Coachingtab />} />
          <Route path="data-protection" element={<DataProtection />} />
          <Route path="terms-and-conditions" element={<TermsConditions />} />
          <Route path="access-agreement" element={<AccessAgreement />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="sign-up-as" element={<SignUpAs />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route
            path="change-pcontact-formassword-sign"
            element={<ChangePasswordSign />}
          />

          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="your-password-reset" element={<YourPasswordReset />} />

          <Route path="account-created-sign" element={<AccountCreatedSign />} />
          <Route path="verify-email" element={<VerifyEmail />} />
          <Route path="reflection-journeys" element={<ReflectionJourneys />} />
          <Route path="about-the-app" element={<AboutTheApp />} />
          <Route path="delete-account" element={<DeleteAccountweb />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="get-started" element={<GetStarted />} />
            <Route
              path="reflection-journey-page"
              element={<ReflectionJourneyPage />}
            />
            <Route
              path="reflection-journey-view/:id"
              element={<ReflectionJourneyView />}
            />
            <Route
              path="reflection-journey-audio"
              element={<ReflectionJourneyAudio />}
            />
            <Route
              path="questionnaire-type/:id"
              element={<QuestionnaireType />}
            />
            <Route
              path="questionnaire-option"
              element={<QuestionnaireOption />}
            />
            <Route
              path="story-timeline-add/:id"
              element={<StoryTimelineAdd />}
            />
            <Route path="add-life-event" element={<AddLifeEvent />} />
            <Route path="wheel-of-life/:id" element={<WheelOfLife />} />
            <Route path="add-wheel-of-life" element={<AddWheelOfLife />} />
            <Route path="meditation" element={<Meditation />} />
            <Route path="theory/:id" element={<Theory />} />
            <Route path="de-stress-audio" element={<DeStressAudio />} />
            <Route path="coaching" element={<Coaching />} />
            <Route path="coach-details" element={<CoachDetails />} />
            <Route
              path="appointment-details"
              element={<AppointmentDetails />}
            />
            <Route path="make-appointment" element={<MakeAppointment />} />
            <Route path="invoice-details" element={<InvoiceDetails />} />
            <Route
              path="appointment-created"
              element={<AppointmentCreated />}
            />
            <Route path="cancel-Payment" element={<CancelPayment />} />
            <Route path="confirm-Payment" element={<ConfirmPayment />} />
            <Route path="my-profile" element={<MyProfile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="notification" element={<Notification />} />
            <Route path="requested-details" element={<RequestedDetails />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="leave-review" element={<LeaveReview />} />
            <Route path="user-imprint" element={<ImprintUser />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="change-language" element={<ChangeLanguage />} />
            <Route path="favorite-course" element={<FavouriteCourse />} />
            <Route path="invoice-price" element={<InvoicePrice />} />
            <Route path="Invoice-pdf" element={<SeeInvoice />} />
            <Route path="inbox" element={<Inbox />} />
            <Route path="inbox-chat-box" element={<InboxChatBox />} />

            {/* Coach side start  */}
            <Route path="create-profile" element={<CreateProfile />} />
            <Route
              path="create-profile-three"
              element={<CreateProfileThree />}
            />
            <Route path="create-profile-rate" element={<CreateProfileRate />} />
            <Route path="time-availability" element={<TimeAvailability />} />
            <Route path="account-created" element={<AccountCreated />} />

            <Route path="upload-certificate" element={<UploadCertificate />} />
            <Route path="get-started-coach" element={<GetStartedCoach />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="requested-details-coach"
              element={<RequestedDetailsCoach />}
            />
            <Route path="inbox-coach" element={<InboxCoach />} />
            <Route path="inbox-coach-chat" element={<InboxChatBoxCoach />} />
            <Route path="notification-coach" element={<NotificationCoach />} />
            <Route path="my-profile-coach" element={<MyProfileCoach />} />
            <Route path="settings-coach" element={<SettingsCoach />} />
            <Route path="reviews-coach" element={<ReviewsCoach />} />
            <Route path="faqs-coach" element={<FaqsCoach />} />
            <Route path="imprint-coach" element={<ImprintCoach />} />
            <Route
              path="change-password-coach"
              element={<ChangePasswordCoach />}
            />
            <Route
              path="change-language-coach"
              element={<ChangeLanguageCoach />}
            />
            <Route path="wallet-coach" element={<WalletCoach />} />
            <Route path="logout-page" element={<LogoutPage />} />

            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="Q&A/:id" element={<QandA />} />
            <Route path="score" element={<Score />} />
            <Route path="delete-user-account" element={<Deleteaccount />} />
            <Route path="user/createaccount" element={<BankAccount />} />
          </Route>
          {/* <CustomCheckoutProvider
    stripe={stripe}
    options={{clientSecret}}
  > */}
          {/* <Route path="stripe" element={<Wrapper clientSecret={"test"} />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
