import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import LayoutNew from "../Components/LayoutNew";

export default function ImprintUser() {
  return (
    <>
      <LayoutNew>
        <div className="right-side">
          <div className="back-page-i">
            <Link to="/settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>Imprint</h2>
            <div className="imprint-data">
              <div className="imprint-txt">
                <h3>Responsible in the sense of press law</h3>
                <p>Dr. Christian Hoops</p>
              </div>
              <div className="imprint-txt">
                <h3>Address:</h3>
                <p>Leidenhofener Straße 7B 35085 Ebsdorfergrund</p>
              </div>
              <div className="imprint-txt">
                <h3>Contact:</h3>
                <p>support@mindrepublic.com</p>
              </div>
              <div className="imprint-txt">
                <h3>VAT ID No:</h3>
                <p>DE364842079</p>
              </div>
              <div className="imprint-txt">
                <h3>GTC:</h3>
                <p>
                  The General Terms and Conditions and the Access Agreement for
                  Coaches can be accessed here:
                  <Link to="[http://product.ec/consumer/adr.]">
                    {" "}
                    [http://product.ec/consumer/adr.]
                  </Link>
                </p>
              </div>
              <div className="imprint-txt">
                <h3>Data Protections:</h3>
                <p>
                  The Data Protection Declaration can be found here:
                  <Link to="[http://product.ec/consumer/adr.]">
                    [http://product.ec/consumer/adr.]
                  </Link>
                </p>
              </div>
              <div className="imprint-txt">
                <h3>European Dispute Resolution Platform</h3>
                <p>
                  The European Commission has established a European Online
                  Dispute Resolution platform (ODR platform) at
                  <Link to="https://ec.europa.eu/consumers/odr/">
                    https://ec.europa.eu/consumers/odr/
                  </Link>
                  . Consumers can use the ODR platform for the out-of-court
                  resolution of a dispute arising from online contracts with a
                  company based in the EU.
                </p>
              </div>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
