import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

export default function Career() {
  const { t } = useTranslation();
  const career = t("career");
  console.log(career);

  return (
    <Container>
      <Row className="">
        <div className="col-md-12">
          <div className="no-career-contin">
            <div className="no-career-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="301"
                height="300"
                viewBox="0 0 301 300"
                fill="none"
              >
                <path
                  d="M200.019 140.014C200.019 133.594 200.029 127.58 200.019 121.556C200.009 116.684 198.341 115.026 193.438 115.026C184.375 115.016 175.323 115.007 166.261 115.026C161.784 115.036 160.057 116.783 160.037 121.278C160.007 127.401 160.027 133.524 160.027 139.826C136.641 139.826 113.484 139.826 90.0289 139.826C90.0289 133.703 90.0388 127.699 90.0289 121.685C90.019 116.624 88.3911 115.016 83.2693 115.016C74.3161 115.007 65.3628 114.997 56.4096 115.016C51.7444 115.026 50.0569 116.733 50.047 121.447C50.0272 127.481 50.047 133.504 50.047 139.498C32.0313 144.073 13.1025 136.879 4.68523 122.31C1.81662 117.358 0.168906 111.99 0.119276 106.284C0.000164518 92.4399 -0.0296135 78.5863 0.0894984 64.7426C0.149054 56.4662 6.74984 50.0951 15.177 50.0554C30.8998 49.976 46.6225 50.0356 62.3453 50.0356C119.191 50.0356 176.048 50.0356 232.894 50.0356C244.15 50.0356 250.016 55.9104 250.016 67.164C250.016 79.0329 250.016 90.9017 250.016 102.771C250.006 125.268 235.197 140.014 212.595 140.024C208.555 140.014 204.505 140.014 200.019 140.014Z"
                  fill="#E4ECD8"
                />
                <path
                  d="M10.0353 142.218C22.4825 151.189 36.2003 150.137 50.0669 149.968C49.5011 158.155 50.9701 165.4 57.5908 170.629C61.5909 173.795 66.1668 175.244 71.2589 174.966C82.9715 174.331 89.344 166.064 90.1381 150.266C113.276 150.266 136.433 150.266 160.027 150.266C160.027 151.705 159.819 153.253 160.067 154.731C160.563 157.679 160.851 160.795 162.072 163.445C163.174 165.836 162.896 167.126 161.248 169.061C141.982 191.757 135.847 217.638 142.845 246.596C143.083 247.598 143.371 248.591 143.748 250C142.438 250 141.356 250 140.264 250C105.791 250 71.3085 250.01 36.8355 250C20.547 250 10.0353 239.52 10.0254 223.285C10.0155 197.562 10.0254 171.84 10.0254 146.108C10.0353 145.086 10.0353 144.063 10.0353 142.218Z"
                  fill="#E4ECD8"
                />
                <path
                  d="M225.003 150.018C266.682 150.097 300.152 183.759 300.013 225.448C299.884 266.642 266.066 300.125 224.725 299.996C183.323 299.867 149.902 266.116 150.022 224.555C150.141 183.491 183.899 149.938 225.003 150.018ZM255.644 179.551C235.961 165.39 205.002 166.223 185.1 187.192C165.447 207.913 166.469 238.022 179.621 255.567C204.912 230.291 230.214 204.976 255.644 179.551ZM194.45 270.373C212.853 284.098 244.547 284.624 265.242 262.474C285.621 240.652 282.633 209.997 270.315 194.526C245.033 219.802 219.732 245.097 194.45 270.373Z"
                  fill="#E4ECD8"
                />
                <path
                  d="M175.015 39.953C168.166 39.953 161.824 39.953 155.143 39.953C155.143 33.3934 155.143 26.9032 155.143 20.2245C134.974 20.2245 115.152 20.2245 95.1011 20.2245C95.1011 26.7544 95.1011 33.1651 95.1011 39.8041C88.3812 39.8041 81.8896 39.8041 75.3781 39.8041C75.3781 32.0239 74.6436 24.1742 75.5568 16.523C76.7182 6.73812 85.1354 0.0991076 94.972 0.0594125C114.973 -0.0199778 134.964 -0.0299015 154.965 0.0594125C166.261 0.109031 174.866 8.79234 175.005 20.1054C175.085 26.6452 175.015 33.1751 175.015 39.953Z"
                  fill="#E4ECD8"
                />
                <path
                  d="M60.0723 125.159C66.8914 125.159 73.3036 125.159 80.0334 125.159C80.0334 133.177 80.0334 141.047 80.0334 148.926C80.0334 151.218 80.1426 153.511 79.9739 155.793C79.5868 161.013 75.13 165.042 69.9486 165.003C64.8963 164.963 60.2609 161.003 60.1417 155.932C59.9134 145.76 60.0723 135.579 60.0723 125.159Z"
                  fill="#E4ECD8"
                />
                <path
                  d="M170.4 159.515C169.596 147.735 170.251 136.521 169.993 125.169C176.723 125.169 183.135 125.169 190.023 125.169C190.023 129.327 189.567 133.604 190.123 137.742C191.066 144.748 189.16 149.333 182.073 151.953C178.122 153.422 174.797 156.587 170.4 159.515Z"
                  fill="#E4ECD8"
                />
              </svg>
              <p>
                <p> {career?.career}</p>
                {career?.career1}
              </p>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}
