import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutCoach from "../Components/LayoutCoach";

export default function InboxChatBoxCoach() {
  return (
    <>
      <LayoutCoach>
        <div className="right-side">
          <div className="back-page-i">
            <Link to="/inbox-coach">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content chat-box-set ">
            <h2>Joanna Mccullough</h2>

            <div className="inbox-chat-box">
              <div className="support-chat-box-main">
                <div className="support-inner">
                  <div className="support-msg-box">
                    <div className="user-box right-part-txt">
                      <div className="user-msg-box">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box left-part-txt">
                      <div className="user-msg-box ">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box right-part-txt">
                      <div className="user-msg-box">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box left-part-txt">
                      <div className="user-msg-box ">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box right-part-txt">
                      <div className="user-msg-box">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box left-part-txt">
                      <div className="user-msg-box ">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box right-part-txt">
                      <div className="user-msg-box">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box left-part-txt">
                      <div className="user-msg-box ">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>

                    <div className="user-box right-part-txt">
                      <div className="user-msg-box">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                    <div className="user-box left-part-txt">
                      <div className="user-msg-box ">
                        <p>Hey I'm John Smith</p>
                      </div>
                      <div className="user-top-hdng">
                        <h4>08:13 pm</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <form>
                  <div class="send-box">
                    <div class="mb-3 input-group">
                      {/* <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="attach-icon">@</InputGroup.Text>
                        <Form.Control
                          placeholder="Type your message here..."
                          aria-label="send"
                          aria-describedby="basic-addon2"
                          name="message"
                          type="text"
                          class="send-feild form-control"
                          value=""
                        />
                      </InputGroup> */}
                      <InputGroup.Text
                        id="basic-addon1"
                        className="attach-icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="26"
                          viewBox="0 0 24 26"
                          fill="none"
                        >
                          <path
                            d="M23 12.2512L12.5989 22.9525C11.3247 24.2635 9.59649 25 7.79448 25C5.99247 25 4.26427 24.2635 2.99006 22.9525C1.71584 21.6415 1 19.8634 1 18.0094C1 16.1554 1.71584 14.3773 2.99006 13.0663L13.3912 2.365C14.2406 1.491 15.3928 1 16.5941 1C17.7954 1 18.9476 1.491 19.7971 2.365C20.6465 3.23899 21.1238 4.42438 21.1238 5.6604C21.1238 6.89641 20.6465 8.0818 19.7971 8.9558L9.38464 19.6571C8.9599 20.0941 8.38383 20.3396 7.78316 20.3396C7.18249 20.3396 6.60643 20.0941 6.18169 19.6571C5.75695 19.2201 5.51834 18.6274 5.51834 18.0094C5.51834 17.3914 5.75695 16.7987 6.18169 16.3617L15.7905 6.48716"
                            stroke="#123258"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </InputGroup.Text>
                      <input
                        placeholder="Type your message here..."
                        aria-label="send"
                        aria-describedby="basic-addon2"
                        name="message"
                        type="text"
                        class="send-feild form-control"
                        value=""
                      />
                      <button
                        class="input-group-text"
                        id="basic-addon2"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LayoutCoach>
    </>
  );
}
