import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { store } from "./Redux/store";

// const getCurrentLanguage = () => {
//     return store.getState().homedata.selectedLang;
//   };

//   console.log(data,"function")

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    // lng:"en",
    lng: localStorage.getItem("selectedLang") || "en",
    returnObjects: true,
    resources: {
      en: {
        translation: {
          homeHeader:{
            ForCoaches :"For Coaches"
          },
          HomePageMainHeader: "Live More Consciously and Relaxed",
          description: {
            line1: "Listen to yourself and discover yourself with the ",
            line2: "Reflection Journeys, ",
            line3: "develop yourself further with ",
            line4: "Coaching ",
            line5: "and treat yourself to some time out with ",
            line6: "Meditation",
            line7: ". The app is your ",
            line8: "Self-Care Hub, ",
            line9: "so that you can take time and space for yourself.",
          },
          AppDownload: "Download our app",
          waysOfPractice: {
            header: "Count the ways you can personalize your practice",
            way1: {
              header: "Various Reflection Journeys",
              description:
                "Discover Reflection Journeys tailored to enable you to discover yourself.",
            },
            way2: {
              header: "Meditations",
              description:
                "Whether you are a beginner or advanced meditator, we offer a variety of meditations.",
            },
            way3: {
              header: "2-30 Minute Courses",
              description: "Enjoy short or even longer courses, as you wish.",
            },
            way4: {
              header: "Curated Collections",
              description:
                "Our courses are hand-crafted and tailored for your needs.",
            },
          },
          slogan: {
            headers:
              "Find greater well-being by getting to know yourself, achieving your goals, and allowing peace and serenity into your life.",
            description:
              "With the Selfcare Hub offered by MindRepublic you will be more self-conscious and increase your general well-being.",
          },
          images: {
            ReflectionJourneys: {
              // head:"Reflection Journeys",
              desc: "Reflection Journeys invite you to reflect on yourself by combining story-telling, knowledge and interactive methods. Reflection Journeys start with an audio impulse and your journey to yourself continues with an interactive coaching method, such as a deep-dive into your timeline. Based on coaching methods that would also be used by coaches in a (physical) session, you go into self-reflection.",
            },
            Coaching: {
              // head:"Coaching",
              desc: "MindRepublic offers access to a variety of qualified coaches. Coaching helps you to achieve your goals, develop certain skills or deal better with certain situations. In other words, it's about helping you to get to where you want to be. Coaching is therefore personality development.",
            },
            Meditation: {
              // head:"Meditation",
              desc: "Meditation allows you to consciously control your attention. The effects of meditation training have been scientifically proven. Whether you are stressed or struggling with other challenges, meditation exercises can improve your general well-being and give you time for yourself.",
            },
            viewAllServices:"More Information"

            
          },
          SelectedCoaches: {
            name: "Selected Coaches",
            data: "MindRepublic offers access to a variety of hand-picked coaches who specialize in different fields of expertise",
          },
          subHeadingCoach:{
            Beatrice:"MBSR Trainer/Relaxation Educator",
            Cateherine:"Systemic Coach/MBSR Trainer",
            Carolin:"Systemic Coach",
            jilie:"Pschologist (M.Sc)/Systemic Coach"

          },
          scanData:
            "Contrary to popular belief, inner growth is just one click away.",
          offerForm: {
            offer: "Stay Up to Date with All News and Exclusive Offers",
            buttontext: "Register Now.",
          },
          BottomMenu: {
            Company: "Company",
            about: "About the App",
            science: "Science",
            aboutmr: "About MindRepublic",
            career: "Career",
            help: "Help",
            contact: "Contact",
            gtc: "General Terms and Conditions",
            agreement:"Access Agreement for Coaches",
            data: "Data Protection",
            Imprint: "Imprint",
            Cookie: "Cookie Settings",
            Links: "Links",
            Language: "Language",
            Login: "Login",
          },

          // ====================================For Coaches=============================================

          forCoaches: {
            CoachesHeader: "Coaching has never been so easy.",
            CoachesDesc:
              "MindRepublic takes care of scheduling video conference licenses, payment processing and invoicing. Coaches can finally focus on what matters:Their clients",
            imageHeader: "Coaching with MindRepublic, this is how it works:",
            image1Desc:
              "Register with MindRepublic, create a meaningful profile, set your rate and your availabilities and create your wallet at Stripe, our payment provider",
            image2Desc:
              "Coachees can book a session with you in the app. MindRepublic distributes the calendar invite with the Zoom link via e-mail to both of you. Payment will be processed prior to the session.",
            image3Desc:
              "You can concentrate on what matters: Your coachee. Conduct your coaching, MindRepublic creates your invoices. Via the MindRepublic App and Website you can stay in contact with your coachee even outside the sessions.",
            overview:
              "In the overview section of the MindRepublic App, coachees have the possibility to select a coach. Coachees may view your profile, getting in touch with you via the chat or schedule a session with you directly in the app based on your availabilities.",
            overviewHead: "Overview",
            Profile:
              "In the profile section, you, as coach, can tell others who you are, what you area of expertise is and what drives you as a coach. Coachees may view your availabilities, leave a review or view existing review and ratings as well as book a session with you.",
            Profilehead: "Profile",
            Availaibilities:
              "As a coach, you can specify when you have time to conduct a session. Coachees may view in a calendar when you have time and may request a session with you. After having approved a request and payment was done by the coachee, MindRepublic sends both of you an e-mail with the calendar invite as well as the Zoom link. You may also select to auto-approve requests, which is saving time if you already know you will be available for sure.",
            Availaibilitiesdesc: "Availabilities",
            Messenger:
              "Coaches need to be in contact with their coachees. At MindRepuiblic, we offer you our own messenger system which enables you to chat and submit files to your coachees. Have drafted a short presentation of the last milestones your coachee achieved in the last session? No worries, you can share everything via the MindRepublic Messenger.",
            Messengerhead: "Messenger",
          },

          //  ==============================coaches profile====================================================

          BeatriceRock: {
            duty1: "Certified MBSR-Trainer ",
            duty2: "Relaxation Educator ",
            duty3: "Trainer for mindfulness-based methods Mering ",
            about: "About Me",
            header:
              "Change requires courage, courage requires clarity, clarity requires honesty - especially towards yourself.",
            p1: "My aim is to enable people to deal well with everyday stress and not to have to accept the state of being stressed as normal. To motivate people to develop personally, to perceive and deal with themselves in mindfulness and kindness. Because when people perceive themselves better, they have the opportunity to react, influence stress and make good use of their resources. Whether in the family or at work, an awareness of the aspects of mental health is the basis for a balanced, meaningful and fulfilling life.",
            p2: "What skills can I offer? As a mother, wife and executive assistant and manager for many years, I have found from my own experience that this belief of always having to function has its limits at some point and that regeneration is immensely important. Self-care is a fundamental pillar of mental and physical health. I have been dealing with this topic for many years and have gained further experience through in-depth training and further education, which I would like to share.",
            p3: "I have been actively involved in the Mindful Economy Network since the beginning of 2023. We are people from all fields of business who integrate mindfulness into business life and practice meditation.",
            p4: "I support the Inner Development Goals of the Sustainable Development Goals.",
            tag: "Book a coaching via MindRepublic",
          },

          CarolinWesche: {
            duty1: "Systemic coach ",
            duty2: "Leadership-Consultant ",

            about: "About Me",
            header:
              "I am convinced that emotional intelligence is the key to effective leadership.",
            p1: "By training healthcare professionals in this skill, I help them to not only lead their teams with more empathy and understanding, but also to be more responsive to the needs of their patients.",
            p2: "This component is critical to building trust and fostering a positive work environment.",
            // p3:"I have been actively involved in the Mindful Economy Network since the beginning of 2023. We are people from all fields of business who integrate mindfulness into business life and practice meditation.",
            // p4:"I support the Inner Development Goals of the Sustainable Development Goals.",
            tag: "Book a coaching via MindRepublic",
          },

          CatherineHanek: {
            duty1: "Systemic coach ",
            duty2: "MBSR teacher ",
            duty3: "Stress management ",
            duty4: "personal development Wiesbaden",
            about: "About Me",
            header:
              "With me, you can be completely human and be picked up wherever you are in your professional or private life.",
            p1: "No matter what the reason for this coaching, I am convinced that behind every challenge and every change - both personal and professional - there is always an opportunity for growth and further development. And it is important to discover and realize these in a trusting collaboration.",
            p2: "Sometimes it takes an impulse and an outside perspective, a neutral sparring partner who asks the right questions and provides the necessary impetus to enable new thought processes and perspectives on things",
            p3: "I would like to accompany you in this - with heart, joy and my full attention.",
            p4: "If you want to know more about me, please have a look at my homepage: www.raum-achtsamkeit.de ",
            p5: "I look forward to meeting you! Feel free to contact me via DM for a free introductory talk or book an appointment directly here.",
            tag: "Book a coaching via MindRepublic",
          },

          JuliaPreiß: {
            duty1: "Psychologist",
            duty2: "Systemic coach ",
            duty3: "Personal development",
            duty4: "Frankfurt am Main",
            about: "About Me",
            header:
              "Sometimes we have to break new ground, climb steep mountains, go through ups and downs or even take a break to change something. No matter how small the steps May be, it is always worth starting the journey.",
            p1: "For more than six years, I have been supporting people who (have to) overcome challenges and are often stigmatised by society. Whether children and young people with serious chronic illnesses or adults with mental and physical disabilities: all of these people have a unique story, with individual questions and wishes. It motivates me to see how these people deal with their individual challenges and how I can help them achieve their goals and dreams.",
            p2: "As a psychologist and systemic coach, I have the necessary tools to be able to support you individually with your issues. “Cheerful, international, artistic-creative and courageous” – that is how my surroundings describe me. With these qualities, I want to encourage other people to find their own path and follow it with confidence.",
            p3: "Are you ready to change your life? Then I look forward to meet you! Get in touch via DM for a free introductory conversation or book an appointment directly here.",
            // p4: "I support the Inner Development Goals of the Sustainable Development Goals.",
            tag: "Book a coaching via MindRepublic",
          },

          // ==========================================Imprint page========================================

          imprint: {
            header: "Responsible in the sense of press law:",
            addhesd: "Address",
            addDesc: "Leidenhofener Straße 7B 35085 Ebsdorfergrund",
            vatId: "VAT ID No",
            vatIdDeac: "DE364842079",
            Contact: "Contact",
            constactdesc: "support@mindrepublic.com",
            GTC: "GTC",
            gtcdesc:
              "The General Terms and Conditions and the Access Agreement for Coaches can be accessed here: ",
            dataL: "Data Protections",
            l1: "The Data Protection Declaration can be found here:",
            l2: "European Dispute Resolution Platform ",
            l4:"The European Commission has established a European Online Dispute Resolution platform (ODR platform) at ",
            link: "https://ec.europa.eu/consumers/odr/.",
            l3: " Consumers can use the ODR platform for the out-of-court resolution of a dispute arising from online contracts with a company based in the EU.",
          },

          // =========================================Data Protection======================================

          DataProtection: {
            
            header: "Privacy Policy MindRepublic",
            date: "Dated 18. March 2024",
            name: "Dr. Christian Hoops",
            cmpny: "MindRepublic",
            Address: "Leidenhofener Straße 7b, 35085 Ebsdorfergrund, Germany",
            add: "Address",
            PrivacyPolicy: "Privacy Policy ",
            p1: "We are delighted to have your interest in our company. Privacy protection is of paramount importance to the management of MindRepublic. The use of MindRepublic's websites is generally possible without providing any personal data. However, if an individual wishes to utilize specific services offered by our company through our website, the processing of personal data may become necessary. If the processing of personal data is necessary and there is no legal basis for such processing, we generally seek the consent of the individual concerned.",
            p2: "The processing of personal data, such as the name, address, email address, or telephone number of an individual, always takes place in accordance with the General Data Protection Regulation (GDPR) and in compliance with the country-specific data protection regulations applicable to MindRepublic. Through this Privacy Policy, MindRepublic aims to inform the public about the nature, scope, and purpose of the personal data we collect, use, and process. Furthermore, this Privacy Policy enlightens individuals about their rights.",
            p3: "MindRepublic, as the data controller, has implemented numerous technical and organizational measures to ensure the most comprehensive protection of the personal data processed through this website. However, internet-based data transmissions may in principle have security gaps, so absolute protection cannot be guaranteed. For this reason, it is open to any individual to transmit personal data to us via alternative means, such as by phone.",
            Definitions: {
              Definitions: "Definitions",
              p1: "The Privacy Policy of MindRepublic is based on the terms used by the European legislator for the adoption of the General Data Protection Regulation (GDPR). Our Privacy Policy aims to be easily understandable for the public, as well as for our customers and business partners. To ensure this, we would like to explain the terms used in advance.",
              p2: "In this Privacy Policy, we use, among other terms, the following definitions:",
            },
            PersonalData: {
              PersonalData: "Personal Data",
              p1: 'Personal data refers to any information relating to an identified or identifiable natural person ("data subject"). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.',
            },
            DataSubject: {
              DataSubject: "Data Subject",
              p1: "Data subject is any identified or identifiable natural person whose personal data is processed by the data controller.",
            },
            Processing: {
              Processing: "Processing",
              p1: "Processing is any operation or set of operations which is performed on personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment or combination, restriction, erasure, or destruction.",
            },
            RestrictionofProcessing: {
              RestrictionProcessing: "Restriction of Processing",
              p1: "Restriction of processing is the marking of stored personal data with the aim of limiting their processing in the future.",
            },
            Profiling: {
              Profiling: "Profiling",
              p1: "Profiling is any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behavior, location, or movements.",
            },
            Pseudonymization: {
              Pseudonymization: "Pseudonymization",
              p1: "Pseudonymization is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organizational measures to ensure that the personal data are not attributed to an identified or identifiable natural person.",
            },
            Controller: {
              Controller: "Controller or Data Controller",
              p1: "Controller or data controller is the natural or legal person, public authority, agency, or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law.",
            },
            Processor: {
              Processor: "Processor",
              p1: "Processor is a natural or legal person, public authority, agency, or other body which processes personal data on behalf of the controller.",
            },
            Recipient: {
              Recipient: "Recipient",
              p1: "Recipient is a natural or legal person, public authority, agency, or another body, to which the personal data are disclosed, whether a third party or not. However, authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients.",
            },
            ThirdParty: {
              ThirdParty: "Third Party",
              p1: "Third party is a natural or legal person, public authority, agency, or body other than the data subject, controller, processor, and persons who, under the direct authority of the controller or processor, are authorized to process personal data.",
            },
            Consent: {
              Consent: "Consent",
              p1: "Consent of the data subject is any freely given, specific, informed, and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.",
            },
            NameandAddressoftheDataProtectionOfficer: {
              NameandAddressoftheDataProtectionOfficer:
                "Name and Address of the Data Controller",

   
              l1: "The data controller, in terms of the General Data Protection Regulation, other data protection laws applicable in Member states of the European Union, and other provisions related to data protection, is:",
              name: "Dr. Christian Hoops",
              add: "Address",
              address: " Leidenhofener Straße 7b, 35085 Ebsdorfergrund, Germany",
              h1: "Any data subject can contact our data protection officer directly at any time with all questions and suggestions regarding data protection.",
            },
            Contactpossibilityviathewebsite: {
              Contactpossibilityviathewebsite:
                "Contact possibility via the website",
              p1: "The website of MindRepublic contains information that enables a quick electronic contact to our company, as well as direct communication with us, which also includes a general address of the so-called electronic mail (email address). If a data subject contacts the data controller via email or a contact form, the personal data transmitted by the data subject is automatically stored. Such personal data voluntarily provided by a data subject to the data controller will be stored for the purpose of processing or contacting the data subject. There is no disclosure of this personal data to third parties.",
            },
            CoachingbyMindRepublic: {
              CoachingbyMindRepublic: "Coaching by MindRepublic",
              p1: "MindRepublic provides coaching services. As part of the coaching process, personal data is collected on a voluntary basis. The data is collected and processed for the purpose of conducting coaching sessions. There is no disclosure to third parties. The basis for data collection is the client's prior consent.",
              p2: "The personal data will be automatically deleted upon completion of the coaching sessions.",
            },
            ReflectionJourneysbyMindRepublic: {
              ReflectionJourneysbyMindRepublic:
                "Reflection Journeys by MindRepublic",
              p1: "MindRepublic offers so-called Reflection Journeys. These are audio-guided self-reflection courses that utilize coaching methods to support the user in their self-reflection. The results of the self-reflection can be stored in the app or on the website if the user wishes. Personal data is collected on a voluntary basis. The data is collected and processed for the purpose of conducting self-reflection. Disclosure may occur to coaches mediated by MindRepublic if the user agrees to it beforehand. The basis for data collection is a previously given consent statement from the client.",
              p2: "The personal data will be automatically deleted upon termination of the service relationship with MindRepublic.",
            },
            MessengerbyMindRepublic: {
              MessengerbyMindRepublic: "Messenger by MindRepublic",
              p1: "MindRepublic offers a messenger on the website and the app. Users can connect with coaches here. Personal data is collected on a voluntary basis. The data is collected and processed for the purpose of communication with the coach or the user. Disclosure may occur to coaches mediated by MindRepublic if the user agrees to it beforehand. The basis for data collection is a previously given consent statement from the user.",
              p2: "The personal data will be automatically deleted upon termination of the service relationship with MindRepublic.",
            },
            ListofAvailableCoachesMindRepublic: {
              ListofAvailableCoachesMindRepublic:
                "List of Available Coaches MindRepublic",
              p1: "offers a coaching matchmaking platform. Coaches can register as such and display themselves to users, potential coachees, to be booked as a coach. Personal data is collected on a voluntary basis. The data is collected and processed for the purpose of promoting the coach. Disclosure may occur to registered users of MindRepublic and other coaches if the coach agrees to it beforehand. The basis for data collection is a previously given consent statement from the coach.",
              p2: "The personal data will be automatically deleted upon termination of the service relationship with MindRepublic.",
            },
            StripeasPaymentProcessor: {
              StripeasPaymentProcessor: "Stripe as Payment Processor",
              p1: "MindRepublic offers a coaching matchmaking platform. Coachings can be paid for via Stripe ",
              url: "(https://www.stripe.de)",
              p2: ". Personal data is collected on a voluntary basis. The data is collected and processed for the purpose of payment processing for a booked coaching. The basis for data collection is a previously given consent statement from the coach.",
            },
            RoutineDeletionandBlockingofPersonalData: {
              RoutineDeletionandBlockingofPersonalData:
                "Routine Deletion and Blocking of Personal Data",
              p1: "The controller processes and stores personal data of the data subject only for the period necessary to achieve the purpose of storage or as required by the European legislator or other legislators in laws or regulations to which the controller is subject. If the purpose of storage ceases to apply or if a storage period prescribed by the European legislator or another competent legislator expires, the personal data is routinely blocked or deleted in accordance with legal requirements.",
            },
            RightsoftheDataSubject: {
              RightsoftheDataSubject: "Rights of the Data Subject",
              RighttoConfirmation: {
                RighttoConfirmation: "Right to Confirmation",
                p1: "Every data subject has the right granted by the European legislator to obtain from the controller confirmation as to whether or not personal data concerning them are being processed. If a data subject wishes to exercise this right to confirmation, they may contact an employee of the controller at any time.",
              },
              RighttoInformation: {
                RighttoInformation: "Right to Information",
                l1: "Every data subject has the right granted by the European legislator to obtain free information from the controller at any time about the personal data stored about them and a copy of this information. Furthermore, the European legislator has granted the data subject access to the following information:",
                l2: "the purposes of processing",
                l3: "the categories of personal data processed",
                l4: "the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organizations",
                l5: "if possible, the planned duration for which the personal data will be stored, or, if this is not possible, the criteria for determining this duration",
                l6: "the existence of a right to rectification or erasure of personal data concerning them or to restriction of processing by the controller or a right to object to such processing",
                l7: "the existence of a right to lodge a complaint with a supervisory authority",
                l8: "if the personal data are not collected from the data subject: all available information on the origin of the data",

                l12: "the existence of automated decision-making, including profiling in accordance with Article 22(1) and (4) of the GDPR and, at least in those cases, meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing for the data subject",
                l13: "Furthermore, the data subject has the right to obtain information as to whether personal data have been transferred to a third country or to an international organization. If this is the case, the data subject also has the right to obtain information about the appropriate safeguards in connection with the transfer. If a data subject wishes to exercise this right to information, they may contact an employee of the controller at any time.",
              },
              RighttoRectification: {
                RighttoRectification: "Right to Rectification",
                p1: "Every data subject affected by the processing of personal data has the right granted by the European legislator to request the immediate correction of inaccurate personal data concerning them. Furthermore, the data subject has the right, taking into account the purposes of the processing, to request the completion of incomplete personal data — also by means of a supplementary declaration. If a data subject wishes to exercise this right to rectification, they may contact an employee of the controller at any time.",
              },
              RighttoErasure: {
                RighttoErasure: "Right to Erasure (Right to be Forgotten)",
                p1: "Every data subject affected by the processing of personal data has the right granted by the European legislator to request from the controller that the personal data concerning them be erased immediately, provided that one of the following reasons applies and insofar as processing is not necessary:",
                p2: "The personal data were collected or otherwise processed for purposes for which they are no longer necessary.",
                p3: "The data subject withdraws their consent on which the processing was based pursuant to Art. 6(1)(a) GDPR or Art. 9(2)(a) GDPR, and there is no other legal basis for the processing.",
                p4: "The data subject objects to the processing pursuant to Art. 21(1) GDPR and there are no overriding legitimate reasons for the processing, or the data subject objects to the processing pursuant to Art. 21(2) GDPR.",
                p5: "The personal data were processed unlawfully.",
                p6: "The erasure of personal data is necessary to fulfill a legal obligation under Union law or the law of the Member States to which the controller is subject.",
                p7: "The personal data were collected in relation to information society services offered pursuant to Art. 8(1) GDPR.",
                p8: "",
                p9: "If one of the above reasons applies and a data subject wishes to have personal data stored at MindRepublic deleted, they may contact an employee of the controller at any time. An employee of MindRepublic will ensure that the deletion request is complied with immediately.",
              },
              RighttoRestrictionofProcessing: {
                RighttoRestrictionofProcessing:
                  "Right to Restriction of Processing",
                l1: "Every data subject affected by the processing of personal data has the right granted by the European legislator to request the controller to restrict processing if one of the following conditions is met:",
                l2: "The accuracy of the personal data is contested by the data subject for a period enabling the controller to verify the accuracy of the personal data.",
                l3: "The processing is unlawful, the data subject opposes the erasure of the personal data and requests instead the restriction of their use instead.",
                l4: "The controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defense of legal claims.",
                l5: "The data subject has objected to processing pursuant to Art. 21(1) GDPR pending the verification whether the legitimate grounds of the controller override those of the data subject.",
                l6: "If one of the above conditions is met and a data subject wishes to request the restriction of personal data stored at MindRepublic, they may contact an employee of the controller at any time. The employee of MindRepublic will arrange the restriction of processing.",
              },
              RighttoDataPortability: {
                RighttoDataPortability: "Right to Data Portability",
                l1: "Every data subject affected by the processing of personal data has the right granted by the European legislator to receive the personal data concerning them, which have been provided by the data subject to a controller, in a structured, commonly used and machine-readable format. They also have the right to transmit those data to another controller without hindrance from the controller to which the personal data have been provided if the processing is based on consent pursuant to Art. 6(1)(a) GDPR or Art. 9(2)(a) GDPR or on a contract pursuant to Art. 6(1)(b) GDPR and the processing is carried out by automated means, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.",
                l2: "Furthermore, in exercising their right to data portability pursuant to Art. 20(1) GDPR, the data subject has the right to have personal data transmitted directly from one controller to another, where technically feasible and when doing so does not adversely affect the rights and freedoms of others.",
                l3: "In order to assert the right to data portability, the data subject may at any time contact an employee of MindRepublic.",
              },
              RighttoObject: {
                RighttoObject: "Right to Object",
                l1: "Every data subject affected by the processing of personal data has the right granted by the European legislator to object, on grounds relating to their particular situation, at any time, to processing of personal data concerning them, which is based on Art. 6(1)(e) or (f) GDPR. This also applies to profiling based on these provisions.",
                l2: "MindRepublic will no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the establishment, exercise or defense of legal claims.",
                l3: "If MindRepublic processes personal data for direct marketing purposes, the data subject has the right to object at any time to processing of personal data concerning them for such marketing. This applies to profiling to the extent that it is related to such direct marketing.",
                l4: "If the data subject objects to MindRepublic processing for direct marketing purposes, MindRepublic will no longer process the personal data for these purposes.",
              },
              AutomatedIndividualDecision: {
                AutomatedIndividualDecision:
                  "Automated Individual Decision-making, including Profiling",
                l1: "Every data subject affected by the processing of personal data has the right granted by the European legislator not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning them or similarly significantly affects them, as long as the decision (1) is not necessary for entering into, or the performance of, a contract between the data subject and a data controller, or (2) is not authorized by Union or Member State law to which the controller is subject and which also lays down suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, or (3) is not based on the data subject's explicit consent.",
                l2: "If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and a data controller, or (2) it is based on the data subject's explicit consent, MindRepublic shall implement suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, at least the right to obtain human intervention on the part of the controller, to express their point of view and contest the decision.",
                l3: "If the data subject wishes to exercise the rights concerning automated individual decision-making, they may contact an employee of MindRepublic at any time.",
              },
              RighttoWithdrawDataProtectionConsent: {
                RighttoWithdrawDataProtectionConsent:
                  "Right to Withdraw Data Protection Consent",
                l1: "Every data subject affected by the processing of personal data has the right granted by the European legislator to withdraw consent to the processing of personal data at any time.",
                l2: "If the data subject wishes to exercise the right to withdraw consent, they may contact an employee of MindRepublic at any time.",
              },
              PrivacyPolicyfortheUseofLinkedIn: {
                PrivacyPolicyfortheUseofLinkedIn:
                  "Privacy Policy for the Use of LinkedIn",
                l1: "The controller has integrated components of LinkedIn Corporation into this website. LinkedIn is an internet-based social network that enables users to connect with existing business contacts and establish new business contacts. Over 400 million registered individuals use LinkedIn in more than 200 countries, making it the largest platform for business contacts and one of the most visited websites in the world.",

                l2: "The operating company of LinkedIn is LinkedIn Corporation, 2029 Stierlin Court Mountain View, CA 94043, USA. For data protection matters outside the USA, LinkedIn Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin 2, Ireland, is responsible.",

                l4: ". As part of this technical process, LinkedIn becomes aware of which specific subpage of our website is visited by the data subject.",
                url1: "https://developer.linkedin.com/plugins",
                l3: "Each time our website, which is equipped with a LinkedIn component (LinkedIn plug-in), is accessed, this component causes the browser used by the data subject to download a corresponding display of the LinkedIn component. Further information on LinkedIn plug-ins can be found at ",
                l5: "If the data subject is logged into LinkedIn at the same time, LinkedIn recognizes with each visit to our website by the data subject and throughout the duration of the respective stay on our website, which specific subpage of our website the data subject visits. This information is collected by the LinkedIn component and assigned by LinkedIn to the respective LinkedIn account of the data subject. If the data subject clicks on a LinkedIn button integrated on our website, LinkedIn assigns this information to the personal LinkedIn user account of the data subject and stores this personal data.",
                l6: "LinkedIn always receives information via the LinkedIn component that the data subject has visited our website if the data subject is logged into LinkedIn at the time of accessing our website; this occurs regardless of whether the data subject clicks on the LinkedIn component or not. If such transmission of information to LinkedIn is not desired by the data subject, they can prevent this transmission by logging out of their LinkedIn account before accessing our website.",
                l7: "LinkedIn always receives information via the LinkedIn component that the data subject has visited our website if the data subject is logged into LinkedIn at the time of accessing our website; this occurs regardless of whether the data subject clicks on the LinkedIn component or not. If such transmission of information to LinkedIn is not desired by the data subject, they can prevent this transmission by logging out of their LinkedIn account before accessing our website.",
                l8: "LinkedIn offers the possibility to unsubscribe from email messages, SMS messages, and targeted advertisements, as well as to manage advertisement settings at ",
                url: " https://www.linkedin.com/psettings/guest-controls",
                l9: ". LinkedIn also uses partners such as Quantcast, Google Analytics, BlueKai, DoubleClick, Nielsen, Comscore, Eloqua, and Lotame, which may set cookies. Such cookies can be rejected at ",
                url2: " https://www.linkedin.com/legal/cookie-policy",
                l10: ". The applicable privacy policy of LinkedIn can be accessed at ",
                url3: " https://www.linkedin.com/legal/privacy-policy",
                l11: ". LinkedIn's cookie policy is available at ",
                utl4: " https://www.linkedin.com/legal/cookie-policy.",
              },
              DataprotectionYouTube: {
                DataprotectionYouTube:
                  "Data protection provisions about the application and use of YouTube",
                l1: "On this website, the controller has integrated components of YouTube. YouTube is an Internet video portal that enables video publishers to set video clips and other users free of charge, which also provides free viewing, review, and commenting on them. YouTube allows you to publish all kinds of videos, so you can access both full movies and TV broadcasts, as well as music videos, trailers, and videos made by users via the Internet portal.",
                l2: `The operating company of YouTube is YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. YouTube, LLC is a subsidiary of Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.`,
                l3: "With each call-up to one of the individual pages of this Internet site, which is operated by the controller and on which a YouTube component (YouTube video) was integrated, the Internet browser on the information technology system of the data subject is automatically prompted to download a display of the corresponding YouTube component. Further information about YouTube may be obtained under ",
                url: "https://www.youtube.com/yt/about/en/",
                l4: ". During the course of this technical procedure, YouTube and Google gain knowledge of what specific sub-page of our website was visited by the data subject.",
                l5: "If the data subject is logged in on YouTube, YouTube recognizes with each call-up to a sub-page that contains a YouTube video, which specific sub-page of our Internet site was visited by the data subject. This information is collected by YouTube and Google and assigned to the respective YouTube account of the data subject.",
                l6: "YouTube and Google will receive information through the YouTube component that the data subject has visited our website, if the data subject at the time of the call to our website is logged in on YouTube; this occurs regardless of whether the person clicks on a YouTube video or not. If such a transmission of this information to YouTube and Google is not desirable for the data subject, the delivery may be prevented if the data subject logs off from their own YouTube account before a call-up to our website is made.",
                l7: "YouTube's data protection provisions, available at ",
                url2: "https://www.google.com/intl/en/policies/privacy/",
                l8: ", provide information about the collection, processing, and use of personal data by YouTube and Google.",
              },
              Legalbasis: {
                Legalbasis: "Legal basis for the processing",
                l1: "Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is party, as is the case, for example, when processing operations are necessary for the supply of goods or to provide any other service, the processing is based on Article 6(1) lit. b GDPR. The same applies to such processing operations which are necessary for carrying out pre-contractual measures, for example in the case of inquiries concerning our products or services. Is our company subject to a legal obligation by which processing of personal data is required, such as for the fulfillment of tax obligations, the processing is based on Art. 6(1) lit. c GDPR.",
                l2: "In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or of another natural person. This would be the case, for example, if a visitor were injured in our company and their name, age, health insurance data, or other vital information would have to be passed on to a doctor, hospital, or other third party. Then the processing would be based on Art. 6(1) lit. d GDPR.",
                l3: "Finally, processing operations could be based on Article 6(1) lit. f GDPR. This legal basis is used for processing operations which are not covered by any of the abovementioned legal grounds, if processing is necessary for the purposes of the legitimate interests pursued by our company or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data. Such processing operations are particularly permissible because they have been specifically mentioned by the European legislator. He considered that a legitimate interest could be assumed if the data subject is a client of the controller (Recital 47 Sentence 2 GDPR).",
              },
              Thelegitimateinterests: {
                Thelegitimateinterests:
                  "The legitimate interests pursued by the controller or by a third party",
                l1: "Where the processing of personal data is based on Article 6(1) lit. f GDPR our legitimate interest is to carry out our business in favor of the well-being of all our employees and the shareholders.",
              },
              Period: {
                Period: "Period for which the personal data will be stored",
                l1: "The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no longer necessary for the fulfillment of the contract or the initiation of a contract.",
              },
              provision: {
                provision:
                  "Provision of personal data as statutory or contractual requirement; Requirement necessary to enter into a contract; Obligation of the data subject to provide the personal data; possible consequences of failure to provide such data",
                l1: "We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner).",
                l2: "Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with them. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded.",
                l3: "Before personal data is provided by the data subject, the data subject must contact any employee. The employee clarifies to the data subject whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.",
              },
              Existence: {
                Existence: "Existence of automated decision-making",
                l1: "As a responsible company, we do not use automatic decision-making or profiling.",
              },
            },
          },

          // ==========================================General T&C=========================================

          TermCondition: {
            gtc: "General Terms and Conditions MindRepublic",
            date: "Dated 6 May 2024",
            GeneralandScope: {
              GeneralandScope: "General and Scope",
              p1: 'Dr. Christian Hoops, Leidenhofener Straße 7B, 35085 Ebsdorfergrund (hereinafter referred to as "MindRepublic"), offers users opportunities for Reflection Journeys (self-reflection), meditation, mindfulness, and booking coaching sessions through the MindRepublic website (',
              p2: ' hereinafter "Website") and the MindRepublic app.',
              p3: 'These General Terms and Conditions (hereinafter "Terms and Conditions") apply to the use of the Website and the MindRepublic app and for booking coaching sessions through the Website or the MindRepublic app (collectively "Services") between the user and MindRepublic, unless otherwise agreed. Agreements made via email, within registration forms on the Website, or in other contracts shall prevail over these Terms and Conditions.',
              p4: "These Terms and Conditions (in their respective current version) are incorporated into the contract between MindRepublic and the user for the use of the Website and the app by notifying the user of the applicability of the Terms and Conditions and using the Website.",
              p5: "These Terms and Conditions (in their respective current version) are incorporated into the contract between MindRepublic and the user for booking a coaching session by either expressly confirming the Terms and Conditions by marking a corresponding checkbox during the booking process or being notified of the applicability of the Terms and Conditions and completing the booking of the coaching session.",
              P6: "Both MindRepublic and the user can terminate the contract via the Website with a notice period of 14 days." ,
              P9:"Termination by the user can be declared via email to  ",
              P8:" or within the login area.",
              P7: "The distribution and sales conditions of the app distributor platform through which the app is downloaded apply to the acquisition of the MindRepublic app. These Terms and Conditions do not apply to the acquisition of the app.",
            },

            Confidentiality: {
              Confidentiality: "Confidentiality",
              P1: "MindRepublic and the coaches acting on behalf of the company undertake to treat all information received from the user in the course of the service relationship confidentially in accordance with legal provisions.",
            },
            UseoftheServices: {
              UseoftheServices: "Use of the Services",
              P1: "To use the Services, the user must have a compatible and internet-enabled device and internet access. The user must consider contractual agreements with third parties, especially their internet service provider, when using the Services.",
              P2: "The user can create a user account via the Website and the MindRepublic app. The user is obliged to provide true and complete information about themselves when creating the user account. The user is required to always provide their current email address in their user account. The user can only book courses through such a user account.",
              P3: "The user may only use their user account themselves. It is prohibited for the user to allow third parties to use their user account.",
              P4: "The Services are offered for a fee. The user can book coaching sessions through the Website and the app. Therefore, the user may need to acquire the app from an app distributor platform and agree to the distributor's distribution and sales terms in this context. MindRepublic provides the coaching sessions in accordance with Section 4 once the respective contract has been concluded, i.e., when the user has received a confirmation email from MindRepublic regarding the booking.",
              P5: "MindRepublic's obligation to provide the Services ends upon termination of the contractual relationship based on these Terms and Conditions. The obligation to provide a coaching session ends upon completion of the coaching session. Each coaching session must be booked separately. Access to the MindRepublic app ends with the paid usage period.",
              P6: "The provision of the Services explicitly does not include the obligation to provide the Services without interruption. In particular, technical maintenance, system adjustments, adjustments to security measures, or other technical circumstances on the Website may temporarily make the Services inaccessible to the user. Technical errors can affect the usability and access to the Services.",
              P7: "The Services are provided without additional accessories, instructions, and customer service.",
              P8: "MindRepublic may occasionally make legally required updates to the Services. Updates will only be provided during the provision period as set out in Section 3.5 and Section 3.6. These updates are made to maintain the contractual conformity of the Services, including for security reasons. MindRepublic may automatically install updates that fix significant security risks. For other updates, the user can decide whether to install them. Furthermore, MindRepublic has the right to change the Services and their functionalities if there is a valid reason. A valid reason exists in particular if changed legal requirements or case law require it, (ii) to improve the Services themselves, their design, and/or security, (iii) to adapt to a new technical environment due to increased user numbers or changed market conditions, (iv) in the interest of the user or to their advantage (v), and/or if there are other significant operational reasons.",
              P9: "If a change to the Services under Section 3.8 affects their accessibility or usability, the user can terminate the contract free of charge within 30 days. The period begins upon receipt of the information. If the change occurs after the information is received, the period begins at the time of the change. Termination of the contract under this section is excluded if the impairment of accessibility or usability is only minor or if the user retains access to the unchanged Service and the usability of the unchanged Service without additional costs.",
              P10: "MindRepublic may offer users paid content for a certain period free of charge. After the free period expires, the user is required to pay the price specified on the Website for the respective content, unless the user cancels the course at least 14 days before the end of the free period as specified on the Website. The user will be notified of this on the Website and must agree to it. It is at MindRepublic's sole discretion to determine which user can receive and use such temporarily free content. MindRepublic may require the user to provide their payment details when ordering such a course.",
              P11: "MindRepublic is entitled to change or adjust the services offered via the Website and the MindRepublic app or to change the prices for the services. However, these changes do not affect services that have already been legally booked by the user.",
            },
            Coachings: {
              Coachings: "Coachings",
              P1: "Information is provided on the Website and the MindRepublic app on how the user can definitively book a coaching session. The booking of courses can also be done through the Website and the MindRepublic app.",
              P2: "By submitting the declaration to book a coaching session through the Website or the MindRepublic app, the user makes a binding offer to conclude a contract for the provision of a coaching session by MindRepublic and the payment of a price by the user to MindRepublic. The receipt of the booking at MindRepublic is confirmed to the user via email. This confirmation email also constitutes MindRepublic's acceptance and leads to the conclusion of a contract between MindRepublic and the user for the provision of a coaching session under these Terms and Conditions.",
              P3: "A coaching contract is a service contract within the meaning of § 611 of the German Civil Code (BGB). Success within the meaning of § 631 BGB is not owed.",
              P4: "Coaching appointments can be canceled free of charge up to 24 hours before the agreed appointment. In case of cancellation within 24 hours before the agreed appointment, the hourly fee will be charged at 100%.",
            },
            PersonalSuitability: {
              PersonalSuitability: "Personal Suitability for the Services",
              P1: "The exercises presented as part of the Services are designed for users who are of average health and are physically and mentally reasonably resilient. In the context of special patient support programs, the use of mindfulness training, reflection journeys, or coaching may be based on and subject to special information and explanations from the responsible project partner and, if necessary, consultation with the treating physician.",
              P2: "MindRepublic does not verify a user's personal suitability to participate in a particular service. The user is personally responsible for ensuring that they have the physical and mental abilities or health requirements to participate in the courses. In case of doubt, the user should consult a doctor or refrain from participating.",
            },
            Rights: {
              Rights: "Rights and Obligations of the User",
              P1: "The Services are provided to the user for personal and non-commercial use only. The user may only use the Services for their own private purposes or for purposes agreed upon with MindRepublic.",
              P2: "Section 7 applies to the user's obligation to pay for services provided by MindRepublic.",
              P3: "The Website and the MindRepublic app are each copyrighted software programs. The user is granted a simple, non-exclusive, non-transferable, non-sublicensable, and revocable right to use the Website for personal use in accordance with these Terms and Conditions. It is expressly prohibited for the user to lease, rent, transfer, or otherwise make the Website available to third parties. Unless provided for in these Terms and Conditions for the contractually intended use, the user is not entitled to reproduce, modify, modify, adapt, translate, edit, arrange, rework, decompile, distribute, or publicly reproduce the Website. The statutory rights under §§ 69d and 69e of the German Copyright Act (UrhG) remain unaffected.",
              P4: "The user is granted a simple, non-exclusive, non-transferable, non-sublicensable, and revocable right to use the course content regarding the Reflection Journeys and meditations in accordance with these Terms and Conditions. It is expressly prohibited for the user to lease, rent, transfer, or otherwise allow third parties to participate in these courses. Unless provided for in these Terms and Conditions for the contractually intended use, the user is not entitled to reproduce, modify, modify, adapt, translate, edit, arrange, rework, decompile, distribute, or publicly reproduce the provided courses. The statutory rights under §§ 69d and 69e of the German Copyright Act (UrhG) remain unaffected.",
              P5: "Furthermore, the user is not allowed to:",
              P6: "use paid content on multiple devices simultaneously, unless such use is expressly permitted;",
              P7: "make access data for authentication and identification accessible to third parties or pass them on to third parties;",
              P8: "bypass access control systems for paid courses or take other measures to use courses unauthorized;",
              P9: "post or distribute content on the Website that is unlawful, misleading, malicious, discriminatory, pornographic, threatening, offensive, obscene, defamatory, ethically offensive, glorifying violence, harassing, unsuitable for minors, racist, inciting hatred, xenophobic, or otherwise objectionable and/or reprehensible, as well as containing viruses, worms, Trojans, or other malicious code that could endanger or impair the functionality of the Services;",
              P10: "remove or make unrecognizable copyright notices and/or notices of trademarks or other intellectual property rights of MindRepublic, associated companies, or third-party companies; and",

              P11: "transfer or assign rights or obligations under these Terms and Conditions to third parties.",
              P12: "Fee",
              P13: "MindRepublic provides its services for a fee. However, until further notice, the obligation to pay for the use of the app and the Website is waived. The payment of the agreed fee is the main obligation of the user or the coach. Unless otherwise agreed, the fees are due upon receipt of the invoice.",
              P14: "For workshops, (multi-part) courses, and seminars, the full fee must be paid before the start of the first course/seminar session or must have been received in MindRepublic's account. Otherwise, participation is not possible.",
              P15: "The bank details are as follows:",
              Accountholder:
                "Account holder: Christian Hoops/MindRepublic IBAN: DE97100110012933599914 BIC: NTSBDEB1XXX",
              P16: "MindRepublic informs the user about the payment methods accepted by MindRepublic on the Website. Payment processing is usually carried out via an external payment service provider. Separate general terms and conditions and privacy policies of the payment service provider may apply. If the user is unable to make the payment successfully or within the specified period, the user bears all resulting costs and expenses, provided that the event causing the costs is attributable to the user. MindRepublic is entitled to grant the user access to the courses only after receiving the payment.",
              P17: "MindRepublic makes the Coach’s invoice for the conducted coaching session available towards the client outlining the Coach’s hourly rate and VAT, if applicable. For this purpose, the client must keep his or her personal information accurate at all times. The client is required to check the invoices within seven (7) business days and notify MindRepublic if an invoice is erroneous or information is missing.",
            },
            Liability: {
              Liability: "Liability",
              P1: "Under this contract, MindRepublic is fully liable for the following cases:",
              P2: "for damages resulting from a violation of life, body, and health that were caused at least negligently by MindRepublic, its legal representatives, or agents;",
              P3: "for any other damages that were intentionally or grossly negligently caused by MindRepublic, its legal representatives, or agents;",
              P4: "for damages resulting from a breach of warranty or legal requirement under the Product Liability Act (Produkthaftungsgesetz).",
              P5: "In all other cases, MindRepublic is only liable for damages resulting from a breach of a material contractual obligation by MindRepublic, its legal representatives, or agents. A material contractual obligation is an obligation that enables the proper performance of the contract and on whose compliance the contracting partner may regularly rely and may rely. In the event of a breach of a material contractual obligation caused by simple negligence, MindRepublic's liability is limited to the replacement of damages that were typically foreseeable at the time the service was provided and in amount.",
            },
            OnlineDisputeResolution: {
              OnlineDisputeResolution: "Online Dispute Resolution, Dispute Resolution Procedure ",
              P1: "The platform of the EU Commission for online dispute resolution with information on online dispute resolution for consumers or alternative dispute resolution for consumers can be reached at the following link: ",
              P2: "MindRepublic is neither willing nor obliged to participate in dispute resolution proceedings before a consumer arbitration board.",
            },
            DataProtection: {
              DataProtection: "Data Protection",
              P1: "Details on the protection and use of the personal data provided by the user to MindRepublic can be found in our privacy policy ",
            },
            chnagestoTandC: {
              chnagestoTandC: "Changes to these Terms and Conditions",
              P1: "MindRepublic is entitled to change provisions of these Terms and Conditions with a notice period of at least two weeks in advance based on the following in order to avoid unreasonable disadvantage for MindRepublic that were not foreseeable for MindRepublic or the user at the time of the conclusion of the contract. MindRepublic is only entitled to such changes if necessary (i) due to a change in applicable legislation or case law or (ii) for clarification purposes to eliminate ambiguities existing in the Terms and Conditions. The amended Terms and Conditions become effective two weeks after the notification of the change.",
              P2: "In addition to 11.1 and 11.2, MindRepublic is also entitled to change provisions of these Terms and Conditions with a notice period of at least six weeks in advance taking into account the interests of the users based on the following. The user will be informed in writing or by email about the changed conditions at least six weeks before they come into effect. The changes are deemed approved if the customer does not object to them in writing or by email. The objection must be received by MindRepublic within six weeks after receipt of the information about the changed conditions. MindRepublic will particularly point out the possibility of objection, the possible consequences of the objection, and the significance of the six-week period in the information letter about the changed conditions. If the customer exercises their right of objection, MindRepublic's proposed changes are deemed rejected. The contract will then continue without the proposed changes. MindRepublic's right to terminate the contract remains unaffected.",
              P3: "In addition to clauses 11.1 and 11.2, MindRepublic is also entitled to change provisions of these Terms and Conditions if the user agrees to the amended version of the Terms and Conditions. If the user does not agree, the previous version of the Terms and Conditions will continue to apply. MindRepublic's right to terminate the contract also remains unaffected.",
            },
            Miscellaneous: {
              Miscellaneous: "Miscellaneous",
              P1: "All technical requirements, including information on interoperability and compatibility, as well as the respective functionalities and technical protective measures in connection with the services, are described on the Website.",
              P2: "MindRepublic is entitled to transfer the rights and obligations arising from a contract to a third party. MindRepublic will inform the user in writing about the transfer; in this case, the user has a right to terminate the contract without notice. The termination must be received in writing by MindRepublic within 14 days after the user receives the notification of the transfer.",
              P3: "The contractual relationships governed by these Terms and Conditions and these Terms and Conditions themselves are subject to the law of the Federal Republic of Germany, excluding the UN Convention on Contracts for the International Sale of Goods.",
              P4: "Should individual provisions of these Terms and Conditions be or become invalid or unenforceable, this shall not affect the validity of the remaining provisions. The above provisions apply mutatis mutandis in the event that the provisions prove to be incomplete.",
            },
          },

          otpVerify:{
            Verifyyouremail:"Verify your email",
            enterOTP:"Enter your OTP code here.",
            didnotrec:"Didn't receive the OTP?",
            resend:"Resend",
            verify:"VERIFY"

          },

          login:{
            welcome:"Welcome Back!",
            signIn:"Sign In to continue",
            remeber:"Remember me",
            forgot:"Forgot password?",
            dont:"Don't have an account?",


          },

          signUpAs:{
            welcome:"Welcome to MindRepublic!",
            signup:"Sign Up As"

          },

          AccountCreated:{
            created:"Account Created!",
            success:"Your account had been created successfully."
          },

          // =========================================About the app========================================

          AboutTheApp: {
            mainheading: {
              selfcare: "SELFCARE",
              desc1:
                "Listen to yourself and discover yourself with the Reflection Journeys and treat yourself to some time out with meditation. The app is your Self-Care Hub, so that you can take time and space for yourself.",
              h2: "INDIVIDUAL SUPPORT THROUGH COACHING",
              desc2:
                "Coaching helps you to achieve your goals. develop certain skills or deal better with certain situations. In other words, it's about helping you to get to where you want to be.",
            },
            midscreen: {
              h1: "Reflection Journeys",
              desc1:
                "Get to know yourself with Reflection Journeys: engage in interactive self- reflection based on story-driven audio sessions. Reflection Journeys are a mixture of knowledge transfer, self-reflection instructions with captivating stories as a basis and coaching methods that get you into action. Visualize your motivation, your drives and your life or better understand your habits and patterns. You can book your session with a coach at any time to deepen your understanding.",
              h2: "Meditation",
              desc2:
                "Meditation is an integral part of Mindfulness-based Stress Reduction (MBSR). Numerous studies have proven the positive influence of meditation on general well-being and therefore on health. Your Selfcare Hub offers you quiet minutes so that you can press the pause button for everyday life.",
              h3: "Coaching",
              desc3:
                "Self-reflection is one of the most common reasons for booking a coaching session. Deal with yourself either on the basis of the Reflection Journeys or get professional support. Coaching is personal development with support. MindRepublic offers a large number of qualified coaches who are only listed if they meet the minimum requirements (several years of training and/or professional experience).",
            },
          },

          // =========================================reflection journey===================================

          reflectionjourney: {
            head1: "Reflection Journeys : ",
            head2: "The Journey to Yourself.",
            desc1: "Find out who you are and what you want. Gain a deep",
            desc2: "understanding of yourself and discover personal freedom.",
            desc3:
              "Story-driven audio sessions give you an impulse: Reflection Journeys are a mixture of knowledge transfer and instructions for self-reflection based on captivating stories. Reflection Journeys are interactive and coaching methods are offered after the audio impulse. These are recognized coaching methods that would also be used by coaches in the context of a (physical) session to get the coachee thinking. You are free to record the results in the app and use them for a later coaching session.",
            desc4:
              "Your Selfcare.Hub gets you thinking and acting with Reflection Journeys",
            subhead: "Reflect without limits",
            p1: "Stories that move you",
            p2: "On-demand and unlimited access: start everywhere and when you want",
            p3: "Record your results if you want: GDPR- compliant and confidential",
            p4: "There are no subscription fees.",
            end:"Start now with MindRepublic"
          },

          career:{
            career:"There are currently no vacancies." ,
            career1:"Nevertheless, there is always the opportunity to submit unsolicited applications."
          }
        },
      },

      //================================================= in german=========================================

      de: {
        translation: {
          homeHeader:{
            ForCoaches :"Für Coaches"
          },
          HomePageMainHeader: "Bewusster und entspannter leben",
          description: {
            line1: "Höre in Dich hinein und entdecke Dich selbst mit den ",
            line2: "Reflection Journeys, ",
            line3: "entwickle Dich mit ",
            line4: "Coaching ",
            line5: "weiter und gönne Dir eine Auszeit mit ",
            line6: "Meditation. ",
            line7: "Die App ist Dein ",
            line8: "Self-Care Hub, ",
            line9:
              "damit Du Dir Zeit und Raum für Dich selbst nehmen kannst.",
          },
          AppDownload: "Downloade die App",
          waysOfPractice: {
            header: "Zahllose Möglichkeiten, Deine Praxis zu personalisieren",
            way1: {
              header: "Verschiedene Reflection Journeys",
              description:
                "Verschiedene Reflection Journeys Entdecke Reflection Journeys, die darauf zugeschnitten sind, Dich selbst kennenzulernen.",
            },
            way2: {
              header: "Meditationen",
              description:
                "Egal, ob Du Anfänger oder Fortgeschrittener bist, wir bieten eine Vielzahl von Meditationen an.",
            },
            way3: {
              header: "2-30 Minuten Kurse",
              description:
                "Genieße kurze oder auch längere Kurse, ganz wie Du magst.",
            },
            way4: {
              header: "Kuratierte Kollektionen",
              description:
                "Unsere Kurse sind handgefertigt und auf Deine Bedürfnisse zugeschnitten.",
            },
          },
          slogan: {
            headers:
              "Finde zu mehr Wohlbefinden, indem Du Dich selbst kennenlernst, Deine Ziele erreichst und nebenbei Ruhe und Gelassenheit in Dein Leben lässt.",
            description:
              "Mit dem von Mindrepublic angebotenen Selfcare Hub wirst Du selbstbewusster und steigerst Dein allgemeines Wohlbefinden.",
          },
          images: {
            ReflectionJourneys: {
              // head:"Reflection Journeys",
              desc: "Reflection Journeys laden Dich ein, Dich selbst zu reflektieren, indem sie Storytelling, Wissen und interaktive Methoden kombinieren. Reflection Journeys beginnen mit einem Audio-Impuls und die Reise zu Dir selbst wird mit einer interaktiven Coaching-Methode fortgesetzt, z.B. einem Deep-Dive in Deine Timeline. Basierend auf Coaching-Methoden, die auch von CoachInnen in einer (physischen) Sitzung eingesetzt werden, gehst Du in die Selbstreflexion.",
            },
            Coaching: {
              // head:"Coaching",
              desc: "MindRepublic bietet Zugang zu einer Vielzahl von qualifizierten CoachInnen. Coaching hilft Dir, Deine Ziele zu erreichen, bestimmte Fähigkeiten zu entwickeln oder mit bestimmten Situationen besser umzugehen. Mit anderen Worten: Es geht darum, Dir dabei zu helfen, dorthin zu gelangen, wo Du hinwillst. Coaching ist folglich Persönlichkeitsentwicklung.",
            },
            Meditation: {
              // head:"Meditation",
              desc: "Meditation ermöglicht es Dir, Deine Aufmerksamkeit bewusst zu steuern. Die Wirkung von Meditationstraining ist wissenschaftlich erwiesen. Ob Du gestresst bist oder mit anderen Herausforderungen zu kämpfen hast, Meditationsübungen können Dein allgemeines Wohlbefinden verbessern und Dir Zeit für Dich selbst geben.",
            },
            viewAllServices:"Weitere Informationen"
          },
          SelectedCoaches: {
            name: "Ausgewählte CoachInnen",
            data: "MindRepublic bietet Zugang zu einer Vielzahl von ausgewählten CoachInnen, die sich auf unterschiedliche Fachgebiete spezialisiert haben.",
          },
          subHeadingCoach:{
            Beatrice:"MBSR-Lehrerin/Entspannungspädagogin",
            Cateherine:"Systemische Coachin/MBSR-Lehrerin",
            Carolin:"Systemische Coachin",
            jilie:"Psychologin (M.Sc.)/Syst. Coachin"

          },
          scanData:
            "Entgegen der landläufigen Meinung ist inneres Wachstum nur ein Klick entfernt.",
          offerForm: {
            offer:
              "Bleibe auf dem Laufenden mit aktuellen News und exklusiven Angeboten",
            buttontext: "Registriere dich jetzt.",
          },
          BottomMenu: {
            Company: "Unternehmen",
            about: "Über die App",
            science: "Wissenschaft",
            aboutmr: "Über MindRepublic",
            career: "Karriere",
            help: "Hilfe",
            contact: "Kontakt",
            gtc: "Allgemeine Geschäftsbedingungen",
            agreement:"Zugangsvertrag für Coaches",
            data: "Datenschutz",
            Imprint: "Impressum ",
            Cookie: "Cookie-Einstellungen",
            Links: "Links",
            Language: "Sprache",
            Login: "Anmeldung",
          },

          // ====================================For Coaches=============================================

          forCoaches: {
            CoachesHeader: "Coaching war noch nie so einfach.",
            CoachesDesc:
              "Terminsfindung. Videokonferenz-Lizenz. Zahlungsabwicklung und Rechnungsstellung werden von MindRepublic übernommen. CoachInnen können sich endlich auf das fokussieren, was zählt: Ihre KlientInnen.",
            imageHeader: "Coaching mit MindRepublic, so funktioniert es:",
            image1Desc:
              "Registriere Dich bei MindRepublic, erstelle ein aussagekräftiges Profil, lege Dein Honorar und Verfügbarkeiten fest und setze Deine Wallet bei Stripe auf, unserem Zahlungsanbieter",
            image2Desc:
              "Coachees können über die App eine Sitzung mit Dir buchen. MindRepublic verschickt die Kalendereinladung mit dem Zoom-Link per E-Mail an Euch beide. Die Zahlung wird vor der Sitzung abgewickelt.",
            image3Desc:
              "Du kannst Dich auf das Wesentliche konzentrieren: Deinen Coachee. Führe Dein Coaching durch, MindRepublic erstellt Deine Rechnungen. Über die MindRepublic App und Website kannst Du auch außerhalb der Sessions mit Deinem Coachee in Kontakt bleiben.",
            overview:
              "Im Übersichtsbereich der Mindrepublic-App haben Coaches die Möglichkeit, einen Coach auszuwählen. Coachees können Ihr Profil einsehen, über den Chat mit Ihnen in Kontakt treten oder basierend auf Ihren Verfügbarkeiten direkt in der App eine Sitzung mit Ihnen vereinbaren.",
            overview:
              "Im Übersichtsbereich der MindRepublic-App haben Coaches die Möglichkeit, eine/n CoachIn auszuwählen. Coachees können Dein Profil einsehen, mit Dir über den Messenger in Kontakt treten oder direkt in der App eine Session mit Dir vereinbaren, je nach Deiner Verfügbarkeit.",
            overviewHead: "Übersicht",
            Profile:
              "Im Profilbereich kannst Du als CoachIn anderen mitteilen, wer Du bist, was Dein Fachgebiet ist und was Dich als CoachIn antreibt. Coachees können Deine Verfügbarkeiten einsehen, eine Bewertung abgeben oder bestehende Bewertungen einsehen und eine Sitzung mit Dir buchen.",
            Profilehead: "Profil",
            Availaibilities:
              "Als CoachIn kannst Du angeben, wann Du Zeit hast, eine Session durchzuführen. Coachees können in einem Kalender sehen, wann Du Zeit hast und können eine Sitzung mit Dir anfragen. Nachdem Du eine Anfrage angenommen hast und die Zahlung erfolgt ist, schickt MindRepublic Euch beiden eine E-Mail mit der Kalendereinladung und dem Zoom-Link. Du kannst auch festlegen, dass Anfragen automatisch angenommen werden, was Zeit spart, wenn Du bereits weißt, dass Du sicher verfügbar sein wirst.",
            Availaibilitiesdesc: "Buchung",
            Messenger:
              "CoachInnen müssen mit ihren Coachees in Kontakt bleiben. Bei MindRepublic bieten wir Dir ein eigenes Messenger-System, mit dem Du mit Deinen Coachees chatten und ihnen Dateien schicken kannst. Du hast eine kurze Präsentation über die letzten Meilensteine erstellt, die Dein Coachee in der letzten Sitzung erreicht hat? Kein Problem, Du kannst alles über den MindRepublic Messenger teilen.",
            Messengerhead: "Messenger",
          },

          //  ==============================coaches profile====================================================

          BeatriceRock: {
            duty1: "Zertifizierte MBSR-Lehrerin ",
            duty2: "Entspannungspädagogin ",
            duty3: "Trainerin für achtsamkeitsbasierte Verfahren Mering ",
            about: "Über mich",
            header:
              "Vor Veränderung braucht es Mut, vor Mut braucht es Klarheit, vor Klarheit braucht es Ehrlichkeit – vor allem zu sich selbst-",
            p1: "Es geht mir darum Menschen zu befähigen gut mit dem alltäglichen Stress umgehen zu können und den Zustand des Gestresst-Seins nicht als normal akzeptieren zu müssen. Menschen zu motivieren sich persönlich weiter zu entwickeln, in Achtsamkeit und Güte sich wahrzunehmen und mit sich umzugehen. Denn wenn der Mensch sich besser wahrnimmt hat er die Möglichkeit zu reagieren, auf Stress Einfluss zu nehmen und gut mit seinen Ressourcen umzugehen. Ob im familiären oder im beruflichen Umfeld ist ein Bewusstsein über die Aspekte der psychischen Gesundheit die Basis für ein ausgeglichenes, sinngebendes und erfülltes Leben.",
            p2: "Welche Kompetenzen kann ich dazu anbieten. Als Mutter, Ehefrau und jahrelange Geschäftsleitungsassistentin und Führungskraft habe ich aus eigenem Erleben festgestellt, dass dieser Glaube immer funktionieren zu müssen irgendwann seine Grenzen hat und die Regeneration immens wichtig ist. Eine grundlegende Stütze für die seelische und körperliche Gesundheit ist eine gewisse Selbstfürsorge. Ich beschäftige mich schon viele Jahre mit der Thematik und habe mit fundierten Aus- und Fortbildungen weitere Erfahrungen gesammelt, die ich gerne teilen möchte",
            p3: "Seit Anfang 2023 bin ich aktiv im Netzwerk Achtsame Wirtschaft tätig. Wir sind Menschen aus allen Feldern der Wirtschaft, die Achtsamkeit in das wirtschaftliche Leben integrieren und Meditation praktizieren.",
            p4: "Ich unterstütze die Inner Development Goals der Ziele für nachhaltige Entwicklung.",
            tag: "Buche ein Coaching via MindRepublic",
          },

          CarolinWesche: {
            duty1: "Systemische Coachin ",
            duty2: "Leadership-Begleitung ",

            about: "Über mich",
            header:
              "Ich bin überzeugt, dass emotionale Intelligenz der Schlüssel zu effektiver Führung ist.",
            p1: "Indem ich medizinische Fachkräfte in dieser Fähigkeit schule, helfe ich ihnen, nicht nur ihre Teams mit mehr Empathie und Verständnis zu führen, sondern auch besser auf die Bedürfnisse ihrer Patienten einzugehen.",
            p2: "Diese Komponente ist entscheidend für den Aufbau von Vertrauen und die Förderung eines positiven Arbeitsumfeldes.",
            // p3:"I have been actively involved in the Mindful Economy Network since the beginning of 2023. We are people from all fields of business who integrate mindfulness into business life and practice meditation.",
            // p4:"I support the Inner Development Goals of the Sustainable Development Goals.",
            tag: "Buche ein Coaching via MindRepublic",
          },

          CatherineHanek: {
            duty1: "Systemische Coachin ",
            duty2: "MBSR-Lehrerin",
            duty3: "Stressmanagement",
            duty4: "Persönlichkeitsentwicklung Wiesbaden",
            about: "Über mich",
            header:
              "Bei mir darfst Du ganz Mensch sein und da abgeholt werden, wo immer Du gerade im beruflichen oder privaten Kontext stehst.",
            p1: "Egal was der Anlass für dieses Coaching ist, ich bin überzeugt, dass hinter jeder Herausforderung und jeder Veränderung – privat wie beruflich – immer auch eine Chance für Wachstum und Weiterentwicklung steckt. Und diese gilt es in einer vertrauensvollen Zusammenarbeit zu entdecken und zu verwirklichen. ",
            p2: "Manchmal braucht es einen Impuls und den Blick von außen, ein neutraler Sparring-Partner, der die „richtigen“ Fragen und die notwendigen Impulse gibt, um neue Denkprozesse und Sichtweisen auf Dinge zu ermöglichen.",
            p3: "Hierbei möchte ich Dich begleiten – mit Herz, Freude und mit meiner vollen Aufmerksamkeit.",
            p4: "Wenn Du mehr über mich wissen willst, schaue gerne auf meiner Homepage: www.raum-achtsamkeit.de ",
            p5: "Ich freue mich auf Dich! Melde dich gerne per DM Kontakt für ein kostenloses Kennenlerngespräch oder buche direkt hier einen Termin.",
            tag: "Buche ein Coaching via MindRepublic",
          },

          JuliaPreiß: {
            duty1: "Psychologin (Master of Science) ",
            duty2: "Systemische Coachin",
            duty3: "Persönlichkeitsentwicklung",
            duty4: "Frankfurt am Main",
            about: "Über mich",
            header:
              "Manchmal müssen wir neue Wege gehen, steile Berge besteigen, Höhen und Tiefen durchlaufen oder auch einmal eine Pause einlegen, damit etwas mit uns passiert. So klein die Schritte auch sein mögen, es lohnt sich immer aufzubrechen!",
            p1: "Seit mehr als sechs Jahren begleite ich Menschen, die Herausforderungen meistern (müssen) und oft gesellschaftlich stigmatisiert werden. Ob Kinder und Jugendliche mit schweren chronischen Erkrankungen oder Erwachsene mit psychischen und körperlichen Einschränkungen: alle diese Menschen haben eine einzigartige Geschichte mitgebracht, mit individuellen Fragen und Wünschen. Es motiviert mich, zu sehen, wie diese Menschen mit ihren individuellen Herausforderungen umgehen und wie ich ihnen helfen kann ihre Ziele und Träume zu erreichen.",
            p2: "Als Psychologin und systemische Coachin bringe ich das notwendige Rüstzeug mit, um auch Dich ganz individuell bei Deinen Themen unterstützen zu können. „Fröhlich, international, künstlerisch-kreativ und mutig“ – so bezeichnet mich mein Umfeld. Mit diesen Eigenschaften möchte ich auch andere Menschen ermutigen, ihren ganz eigenen Weg zu finden und selbstbewusst zu beschreiten.",
            p3: "Bist du bereit, dein Leben zu verändern? Ich freue mich auf Dich! Melde dich gerne per DM für ein kostenloses Kennenlerngespräch oder buche direkt hier einen Termin.",
            // p4: "I support the Inner Development Goals of the Sustainable Development Goals.",
            tag: "Buche ein Coaching via MindRepublic",
          },

          // ==========================================Imprint page========================================

          imprint: {
            header: "Verantwortlich im Sinne des Presserechts:",
            addhesd: "Adresse",
            addDesc: "Leidenhofener Straße 7B 35085 Ebsdorfergrund",
            vatId: "USt-IdNr.",
            vatIdDeac: "DE364842079",
            Contact: "Kontakt:",
            constactdesc: "support@mindrepublic.com",
            GTC: "AGB",
            gtcdesc:
              "Die Allgemeinen Geschäftsbedingungen bzw. der Zugangsvertrag für Coaches kann hier abgerufen werden: ",
            dataL: "Datenschutz",
            l1: "Die Datenschutzerklärung kann hier abgerufen werden:",
            l2: "Europäische Streitbeilegungsplattform  ",
            link: "https://ec.europa.eu/consumers/odr/.",
            l3: " eine Europäische Online-Streitbeilegungsplattform (OS-Plattform) errichtet. Die OS Plattform kann ein verbraucher für die außergerichtliche Beilegung einer Streitigkeit aus Online-Verträgen mit einem in der EU niedergelassenen Unternehmen nutzen.",
          },

          // =========================================Data Protection======================================

          DataProtection: {
            header: "Datenschutzerklärung MindRepublic",
            date: "Stand: 18. März 2024",
            add: "Postadresse",
            name: "Dr. Christian Hoops",
            cmpny: "MindRepublic",
            Address: "Leidenhofener Straße 7b, 35085 Ebsdorfergrund, Germany",
            PrivacyPolicy: "Datenschutzerklärung",
            p1: "Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsleitung von MindRepublic. Eine Nutzung der Internetseiten von MindRepublic ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.",
            p2: "Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für MindRepublic geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte MindRepublic die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.",
            p3: "MindRepublic hat als für die Verarbeitung Verantwortlicher zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.",
            Definitions: {
              Definitions: "Begriffsbestimmungen",
              p1: "Die Datenschutzerklärung von MindRepublic beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.",
              p2: "Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:",
            },
            PersonalData: {
              PersonalData: "personenbezogene Daten",
              p1: "Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.",
            },
            DataSubject: {
              DataSubject: "betroffene Person",
              p1: "Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.",
            },
            Processing: {
              Processing: "Verarbeitung",
              p1: "Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.",
            },
            RestrictionofProcessing: {
              RestrictionProcessing: "Einschränkung der Verarbeitung",
              p1: "Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.",
            },
            Profiling: {
              Profiling: "Profiling",
              p1: "Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.",
            },
            Pseudonymization: {
              Pseudonymization: "Pseudonymisierung",
              p1: "Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.",
            },
            Controller: {
              Controller:
                "Verantwortlicher oder für die Verarbeitung Verantwortlicher",
              p1: "Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.",
            },
            Processor: {
              Processor: "Auftragsverarbeiter",
              p1: "Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.",
            },
            Recipient: {
              Recipient: "Empfänger",
              p1: "Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.",
            },
            ThirdParty: {
              ThirdParty: "Dritter",
              p1: "Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.",
            },
            Consent: {
              Consent: "Einwilligung",
              p1: "Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.",
            },
            NameandAddressoftheDataProtectionOfficer: {
              NameandAddressoftheDataProtectionOfficer:
                "Name und Anschrift des für die Verarbeitung Verantwortlichen ",
              l1: `Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der
Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit
datenschutzrechtlichem Charakter ist:`,
              name: "Dr. Christian Hoops",
              add: "Postadresse",
              address: "Leidenhofener Straße 7b, 35085 Ebsdorfergrund",
              h1: "",
            },
            Contactpossibilityviathewebsite: {
              Contactpossibilityviathewebsite:
                "Kontaktmöglichkeit über die Internetseite ",
              p1: "Die Internetseite von MindRepublic enthält aufgrund von gesetzlichen Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme zu unseres Unternehmens sowie eine unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per E-Mail oder über ein Kontaktformular den Kontakt mit dem für die Verarbeitung Verantwortlichen aufnimmt, werden die von der betroffenen Person übermittelten personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger Basis von einer betroffenen Person an den für die Verarbeitung Verantwortlichen übermittelten personenbezogenen Daten werden für Zwecke der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt keine Weitergabe dieser personenbezogenen Daten an Dritte.",
            },
            CoachingbyMindRepublic: {
              CoachingbyMindRepublic: "Coaching von MindRepublic",
              p1: "MindRepublic erbringt Coaching-Dienstleistungen. Im Rahmen des Coachings werden auf freiwilliger Basis personenbezogene Daten erhoben. Die Daten werden zum Zwecke der Durchführung des Coachings erhoben und verarbeitet. Eine Weitergabe an Dritte erfolgt nicht. Grundlage für die Datenerhebung ist eine vorher abgegebene Einwilligungserklärung des Klienten.",
              p2: "Die personenbezogenen Daten werden nach Abschluss des Coachings automatisch gelöscht.",
            },
            ReflectionJourneysbyMindRepublic: {
              ReflectionJourneysbyMindRepublic:
                "Reflection Journeys von MindRepublic",
              p1: "MindRepublic bietet sog. Reflection Journeys an. Hierbei handelt es sich um audio-gestützte Selbstreflektionskurse, die Coaching-Methoden nutzen, um den Nutzer bei seiner Selbstreflektion zu unterstützen. Die Ergebnisse der Selbstreflektion können in der App oder auf der Website gespeichert werden, sofern der Nutzer dies möchte. Hierbei werden auf freiwilliger Basis personenbezogene Daten erhoben. Die Daten werden zum Zwecke der Durchführung Selbstreflektion erhoben und verarbeitet. Eine Weitergabe kann an durch MindRepublic vermittelte Coaches erfolgen, sofern der Nutzer eine Weitergabe vorher zustimmt. Grundlage für die Datenerhebung ist eine vorher abgegebene Einwilligungserklärung des Klienten.",
              p2: "Die personenbezogenen Daten werden nach Beendigung der Dienstleistungsbeziehung mit MindRepublic automatisch gelöscht.",
            },
            MessengerbyMindRepublic: {
              MessengerbyMindRepublic: "Messenger von MindRepublic",
              p1: "MindRepublic bietet einen Messenger auf der Website und der App an. Nutzer können hier mit Coaches in Verbindung treten. Hierbei werden auf freiwilliger Basis personenbezogene Daten erhoben. Die Daten werden zum Zwecke der Kommunikation mit dem Coach bzw. mit dem Nutzer erhoben und verarbeitet. Eine Weitergabe kann an durch MindRepublic vermittelte Coaches erfolgen, sofern der Nutzer eine Weitergabe vorher zustimmt. Grundlage für die Datenerhebung ist eine vorher abgegebene Einwilligungserklärung des Nutzers.",
              p2: "Die personenbezogenen Daten werden nach Beendigung der Dienstleistungsbeziehung mit MindRepublic automatisch gelöscht.",
            },
            ListofAvailableCoachesMindRepublic: {
              ListofAvailableCoachesMindRepublic:
                "Liste von zur Verfügung stehenden Coaches",
              p1: "MindRepublic bietet eine Coaching-Vermittlungsplattform an. Coaches können sich als solche registrieren und sich für Nutzer, also potentielle Coachees, anzeigen lassen, um als Coach gebucht werden zu können. Hierbei werden auf freiwilliger Basis personenbezogene Daten erhoben. Die Daten werden zum Zwecke der Bewerbung des Coaches erhoben und verarbeitet. Eine Weitergabe kann bei MindRepublic registrierte Nutzer und andere Coaches erfolgen, sofern der Coach einer Weitergabe vorher zustimmt. Grundlage für die Datenerhebung ist eine vorher abgegebene Einwilligungserklärung des Coaches.",
              p2: "Die personenbezogenen Daten werden nach Beendigung der Dienstleistungsbeziehung mit MindRepublic automatisch gelöscht.",
            },
            StripeasPaymentProcessor: {
              StripeasPaymentProcessor: "Stripe als Zahlungsabwickler",
              p1: "MindRepublic bietet eine Coaching-Vermittlungsplattform an. Coachings können via Stripe ",
              url: "(https://www.stripe.de)",
              p2: "gezahlt werden. Hierbei werden auf freiwilliger Basis personenbezogene Daten erhoben. Die Daten werden zum Zwecke der Zahlungsabwicklung für ein gebuchtes Coaching erhoben und verarbeitet. Grundlage für die Datenerhebung ist eine vorher abgegebene Einwilligungserklärung des Coaches.",
            },
            RoutineDeletionandBlockingofPersonalData: {
              RoutineDeletionandBlockingofPersonalData:
                "Routinemäßige Löschung und Sperrung von personenbezogenen Daten ",
              p1: `Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der betroffenen Person nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den Europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde. Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder
einem anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder
gelöscht.`,
            },
            RightsoftheDataSubject: {
              RightsoftheDataSubject: "Rechte der betroffenen Person ",
              RighttoConfirmation: {
                RighttoConfirmation: "Recht auf Bestätigung",
                p1: "Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte Recht, von dem für die Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden. Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.",
              },
              RighttoInformation: {
                RighttoInformation: "Recht auf Auskunft",
                l1: "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende Informationen zugestanden:",
                l2: "die Verarbeitungszweckeg",
                l3: "die Kategorien personenbezogener Daten, die verarbeitet werden",
                l4: "die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen",
                l5: "falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer",
                l6: "das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung",
                l7: "das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde",
                l8: "wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten",

                l12: "das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen — aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person",
                l13: "Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.",
              },
              RighttoRectification: {
                RighttoRectification: "Recht auf Berichtigung",
                p1: `Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu verlangen. Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.`,
              },
              RighttoErasure: {
                RighttoErasure:
                  "Recht auf Löschung (Recht auf Vergessen werden",
                p1: "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden 10 personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:",
                p2: "Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind.",
                p3: "Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.",
                p4: "Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.",
                p5: "Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.",
                p6: "Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.",
                p7: "Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.",
                p8: "Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von personenbezogenen Daten, die bei von MindRepublic gespeichert sind, veranlassen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter von MindRepublic wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird",
                p9: "Wurden die personenbezogenen Daten von von MindRepublic öffentlich gemacht und ist MindRepublic als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten verpflichtet, so trifft MindRepublic unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen, dass die betroffene Person von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der Mitarbeiter von MindRepublic wird im Einzelfall das Notwendige veranlassen.",
              },
              RighttoRestrictionofProcessing: {
                RighttoRestrictionofProcessing:
                  "Recht auf Einschränkung der Verarbeitung",
                l1: "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:",
                l2: "Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.",
                l3: "Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten.",
                l4: "Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
                l5: "Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.",
                l6: "Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die Einschränkung von personenbezogenen Daten, die bei von MindRepublic gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter von MindRepublic wird die Einschränkung der Verarbeitung veranlassen.",
              },
              RighttoDataPortability: {
                RighttoDataPortability: "Recht auf Datenübertragbarkeit",
                l1: "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten, welche durch die betroffene Person einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.",
                l2: "Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.",
                l3: "Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an einen Mitarbeiter von MindRepublic wenden.",
              },
              RighttoObject: {
                RighttoObject: "Recht auf Widerspruch",
                l1: "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.",
                l2: "MindRepublic verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
                l3: "Verarbeitet MindRepublic personenbezogene Daten, um Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widerspricht die betroffene Person gegenüber von MindRepublic der Verarbeitung für Zwecke der Direktwerbung, so wird MindRepublic die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.",
                l4: "Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden Mitarbeiter von MindRepublic oder einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.",
              },
              AutomatedIndividualDecision: {
                AutomatedIndividualDecision:
                  "Automatisierte Entscheidungen im Einzelfall einschließlich Profiling",
                l1: "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.",
                l2: "Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich oder (2) erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person, trifft MindRepublic angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.",
                l3: "Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.",
              },
              RighttoWithdrawDataProtectionConsent: {
                RighttoWithdrawDataProtectionConsent:
                  "Recht auf Widerruf einer datenschutzrechtlichen Einwilligung",
                l1: "Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.",
                l2: "Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.",
              },
              PrivacyPolicyfortheUseofLinkedIn: {
                PrivacyPolicyfortheUseofLinkedIn:
                  "Datenschutzbestimmungen zu Einsatz und Verwendung von LinkedIn",
                l1: "Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten der LinkedIn Corporation integriert. LinkedIn ist ein Internetbasiertes soziales Netzwerk, das eine Konnektierung der Nutzer mit bestehenden Geschäftskontakten sowie das Knüpfen von neuen Businesskontakten ermöglicht. Über 400 Millionen registrierte Personen nutzen LinkedIn in mehr als 200 Ländern. Damit ist LinkedIn derzeit die größte Plattform für Businesskontakte und eine der meistbesuchten Internetseiten der Welt.",
                l2: "Betreibergesellschaft von LinkedIn ist die LinkedIn Corporation, 2029 Stierlin Court Mountain View, CA 94043, USA. Für Datenschutzangelegenheiten außerhalb der USA ist LinkedIn Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place, Dublin 2, Ireland, zuständig.",
                l4: " abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält LinkedIn Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch die betroffene Person besucht wird.",
                url1: "https://developer.linkedin.com/plugins",
                l3: "Bei jedem einzelnen Abruf unserer Internetseite, die mit einer LinkedIn-Komponente (LinkedIn-Plug-In) ausgestattet ist, veranlasst diese Komponente, dass der von der betroffenen Person verwendete Browser eine entsprechende Darstellung der Komponente von LinkedIn herunterlädt. Weitere Informationen zu den LinkedIn-Plug-Ins können unter ",
                l5: "Sofern die betroffene Person gleichzeitig bei LinkedIn eingeloggt ist, erkennt LinkedIn mit jedem Aufruf unserer Internetseite durch die betroffene Person und während der gesamten Dauer des jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete Unterseite unserer Internetseite die betroffene Person besucht. Diese Informationen werden durch die LinkedIn-Komponente gesammelt und durch LinkedIn dem jeweiligen LinkedIn-Account der betroffenen Person zugeordnet. Betätigt die betroffene Person einen auf unserer Internetseite integrierten LinkedIn-Button, ordnet LinkedIn diese Information dem persönlichen LinkedIn-Benutzerkonto der betroffenen Person zu und speichert diese personenbezogenen Daten.",
                l6: "LinkedIn erhält über die LinkedIn-Komponente immer dann eine Information darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufes unserer Internetseite gleichzeitig bei LinkedIn eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person die LinkedIn-Komponente anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an LinkedIn von der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem LinkedIn-Account ausloggt.",
                l7: "",
                l8: "LinkedIn bietet unter ",
                url: "https://www.linkedin.com/psettings/guest-controls",
                l9: " die Möglichkeit, E-Mail-Nachrichten, SMS-Nachrichten und zielgerichtete Anzeigen abzubestellen sowie Anzeigen-Einstellungen zu verwalten. LinkedIn nutzt ferner Partner wie Quantcast, Google Analytics, BlueKai, DoubleClick, Nielsen, Comscore, Eloqua und Lotame, die Cookies setzen können. Solche Cookies können unter ",
                url2: "https://www.linkedin.com/legal/cookie-policy",
                l10: " abgelehnt werden. Die geltenden Datenschutzbestimmungen von LinkedIn sind unter ",
                url3: "https://www.linkedin.com/legal/privacy-policy",
                l11: " abrufbar. Die Cookie-Richtlinie von LinkedIn ist unter ",
                utl4: "https://www.linkedin.com/legal/cookie-policy ",
              },
              DataprotectionYouTube: {
                DataprotectionYouTube:
                  "Datenschutzbestimmungen zu Einsatz und Verwendung von YouTube ",
                l1: "Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite Komponenten von YouTube integriert. YouTube ist ein Internet-Videoportal, dass Video-Publishern das kostenlose Einstellen von Videoclips und anderen Nutzern die ebenfalls kostenfreie Betrachtung, Bewertung und Kommentierung dieser ermöglicht. YouTube gestattet die Publikation aller Arten von Videos, weshalb sowohl komplette Film- und Fernsehsendungen, aber auch Musikvideos, Trailer oder von Nutzern selbst angefertigte Videos über das Internetportal abrufbar sind.",
                l2: `Betreibergesellschaft von YouTube ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Die YouTube, LLC ist einer Tochtergesellschaft der Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.`,
                l3: "Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch den für die Verarbeitung Verantwortlichen betrieben wird und auf welcher eine YouTube-Komponente (YouTube-Video) integriert wurde, wird der Internetbrowser auf dem informationstechnologischen System der betroffenen Person automatisch durch die jeweilige YouTube-Komponente veranlasst, eine Darstellung der entsprechenden YouTube-Komponente von YouTube herunterzuladen. Weitere Informationen zu YouTube können unter ",
                url: " https://www.youtube.com/yt/about/en/ ",
                l4:  " abgerufen werden. Im Rahmen dieses technischen Verfahrens erhalten YouTube und Google Kenntnis darüber, welche konkrete Unterseite unserer Internetseite durch die betroffene Person besucht wird.",
                l5: "Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt ist, erkennt YouTube mit dem Aufruf einer Unterseite, die ein YouTube-Video enthält, welche konkrete Unterseite unserer Internetseite die betroffene Person besucht. Diese Informationen werden durch YouTube und Google gesammelt und dem jeweiligen YouTube-Account der betroffenen Person zugeordnet.",
                l6: "YouTube und Google erhalten über die YouTube-Komponente immer dann eine Information darüber, dass die betroffene Person unsere Internetseite besucht hat, wenn die betroffene Person zum Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei YouTube eingeloggt ist; dies findet unabhängig davon statt, ob die betroffene Person ein YouTube-Video anklickt oder nicht. Ist eine derartige Übermittlung dieser Informationen an YouTube und Google von der betroffenen Person nicht gewollt, kann diese die Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf unserer Internetseite aus ihrem YouTube-Account ausloggt.",
                l7: "Die von YouTube veröffentlichten Datenschutzbestimmungen, die unter ",
                url2: "https://www.google.com/intl/en/policies/privacy/",
                l8: " abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung und Nutzung personenbezogener Daten durch YouTube und Google.",
              },
              Legalbasis: {
                Legalbasis: "Rechtsgrundlage der Verarbeitung ",
                l1: "Art. 6 I lit. a DS-GVO dient unseres Unternehmens als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO.",
                l2: "Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt unseres Unternehmens einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO.",
                l3: "In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene Person ein Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).",
              },
              Thelegitimateinterests: {
                Thelegitimateinterests:
                  "Berechtigte Interessen an der Verarbeitung, die von dem Verantwortlichen oder einem Dritten verfolgt werden",
                l1: "Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist unser berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.",
              },
              Period: {
                Period:
                  "Dauer, für die die personenbezogenen Daten gespeichert werden ",
                l1: "Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.",
              },
              provision: {
                provision:
                  "Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten; Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person, die personenbezogenen Daten bereitzustellen; mögliche Folgen der Nichtbereitstellung",
                l1: "Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.",
                l2: "Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns personenbezogene Daten bereitzustellen, wenn MindRepublic mit ihr einen Vertrag abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung personenbezogener Daten durch den Betroffenen muss sich der Betroffene an einen unserer Mitarbeiter wenden.",
                l3: "Unser Mitarbeiter klärt den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen die Nichtbereitstellung der personenbezogenen Daten hätte.",
              },
              Existence: {
                Existence:
                  "Bestehen einer automatisierten Entscheidungsfindung ",
                l1: "Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische Entscheidungsfindung oder ein Profiling.",
              },
            },
          },

          // ==========================================General T&C=========================================

          TermCondition: {
            gtc: "Allgemeine Geschäftsbedingungen von MindRepublic",
            date: "Stand: 6. Mai 2024",
            GeneralandScope: {
              GeneralandScope: "Allgemeines und Geltungsbereich",
              p1: "Dr. Christian Hoops, Leidenhofener Straße 7B, 35085 Ebsdorfergrund (im Folgenden,  „MindRepublic“) bietet Nutzern über die MindRepublic-Website (",
              p2: " im Folgenden 'Website') und der MindRepublic-App Möglichkeiten an, um Reflection Journeys (Selbstreflektion), Meditation und Achtsamkeit zu beschäftigen sowie Coachings zu buchen.",
              p3: "Diese Allgemeinen Geschäftsbedingungen (im Folgenden „AGB“) gelten für die Nutzung der Website und der MindRepublic-App und für die Buchung von Coachings über die Website oder der MindRepublic-App (zusammenfassend „Dienste“) zwischen dem Nutzer und MindRepublic, sofern nichts anderes vereinbart wurde. Abreden, die per E-Mail, im Rahmen von Anmeldeformularen auf der Website oder sonstigen Verträgen getroffen worden sind, gehen diesen AGB vor.",
              p4: `Für den Vertrag zwischen MindRepublic
und dem Nutzer zur Nutzung der Website
und der App werden die AGB (in ihrer
jeweils geltenden Fassung) einbezogen,
indem der Nutzer auf die Geltung der AGB
hingewiesen wird und die Website nutzt.`,
              p5: `Für einen Vertrag zwischen MindRepublic
und dem Nutzer über die Buchung eines
Coachings werden die AGB (in ihrer
jeweils geltenden Fassung) einbezogen,
indem der Nutzer im Rahmen des
Buchungsprozesses die AGB entweder
durch Markierung einer entsprechenden
Checkbox ausdrücklich bestätigt oder auf
die Geltung der AGB hingewiesen wird,
und der Nutzer die Buchung des
Coachings abschließt.`,
              P6: "Sowohl MindRepublic als auch der Nutzer können den Vertrag über die Website jederzeit mit einer Frist von 14 Tagen kündigen.",
              P7: `Für den Erwerb der MindRepublic-App
gelten die Vertriebs- und
Verkaufsbedingungen des Betreibers der
App-Vertriebsplattform, über die die App
heruntergeladen wird. Die vorliegenden
AGB finden nicht auf den Erwerb der App
Anwendung.`,
              P9:"Die Kündigung durch den Nutzer kann per E-Mail an ",
              P8:" oder im Login-Bereich selbst erklärt werden."
            },
            Confidentiality: {
              Confidentiality: "Vertraulichkeit",
              P1: "MindRepublic und die für das Unternehmen handelnden Coaches verpflichten sich, alle Informationen, die sie im Rahmen der Dienstleistungsbeziehung zum Nutzer erhält, im Rahmen der gesetzlichen Bestimmungen vertraulich zu behandeln.",
            },
            UseoftheServices: {
              UseoftheServices: "Nutzung der Dienste",
              P1: "Für die Nutzung der Dienste muss der Nutzer über ein kompatibles und internetfähiges Endgerät sowie einen Internetzugang verfügen. Der Nutzer hat bei der Nutzung der Dienste vertragliche Vereinbarungen mit Dritten, insbesondere seinem Internetzugangsanbieter, zu berücksichtigen.",
              P2: "Über die Website und der MindRepublic-App kann der Nutzer ein Nutzerkonto anlegen. Der Nutzer ist verpflichtet, bei der Erstellung des Nutzerkontos wahre und vollständige Angaben zu seiner Person zu machen. Der Nutzer ist verpflichtet, stets seine aktuelle E-Mail-Adresse in seinem Nutzerkonto zu hinterlegen. Der Nutzer kann die Kurse nur über ein solches Nutzerkonto buchen.",
              P3: "Der Nutzer darf sein Nutzerkonto nur selbst nutzen. Dem Nutzer ist es verboten, sein Nutzerkonto Dritten zur Nutzung zu überlassen.",
              P4: "Die Dienste werden gegen Zahlung eines Preises (kostenpflichtig) angeboten. Die Coachings kann der Nutzer über die Website und der App buchen. Für die Nutzung der Coachings muss der Nutzer daher ggf. die App aus einer App-Vertriebsplattform erwerben und in diesem Zusammenhang den Vertriebs- und Verkaufsbedingungen des Betreibers der App-Vertriebsplattform zustimmen. MindRepublic stellt die Coachings gemäß Ziffer 4 bereit, sobald der diesbezügliche Vertragsschluss durchgeführt worden ist, d.h. wenn der Nutzer eine Bestätigungs-E-Mail von MindRepublic über die Buchung erhalten hat.",
              P5: "Die Pflicht von MindRepublic zur Bereitstellung der Dienste endet mit Beendigung des Vertragsverhältnisses beruhend auf diesen AGB. Die Pflicht zur Bereitstellung eines Coachings endet mit Durchführung des Coachings. Jedes Coaching muss einzeln gebucht werden. Der Zugang zur MindRepublic-App endet mit der entgeltlich erworbenen Nutzungslaufzeit.",
              P6: "Die Bereitstellung der Dienste umfasst ausdrücklich nicht die Verpflichtung, die Dienste ohne Unterbrechung bereitzustellen. Insbesondere können technischen Wartungen, systemseitige Anpassungen, Anpassung von Sicherheitsmaßnahmen oder sonstigen technischen Umstände bei der Website dazu führen, dass die Dienste für den Nutzer vorübergehend nicht zugänglich sind. Technische Fehler können die Nutzbarkeit und Zugriffsmöglichkeiten der Dienste beeinträchtigen.",
              P7: "Die Dienste werden ohne sonstiges Zubehör, Anleitungen und Kundendienst bereitgestellt.",
              P8: "MindRepublic kann gelegentlich rechtlich erforderliche Aktualisierungen (Updates) an den Diensten vornehmen. Aktualisierungen werden nur während des Bereitstellungszeitraums, wie in Ziffer 3.5 und Ziffer 3.6 festgelegt, erbracht. Diese Aktualisierungen erfolgen zur Aufrechterhaltung der Vertragsgemäßheit der Dienste, einschließlich aus Sicherheitsgründen. Aktualisierungen, die schwerwiegende Sicherheitsrisiken beheben, kann MindRepublic automatisch installieren. Bei anderen Aktualisierungen kann der Nutzer selbst entscheiden, ob er sie installieren möchten. Außerdem hat MindRepublic das Recht, die Dienste und deren Funktionsweisen, zu ändern, wenn ein triftiger Grund vorliegt. Ein triftiger Grund liegt insbesondere vor, wenn geänderte gesetzliche Anforderungen oder die Rechtsprechung dies erfordern, (ii) um die Dienste selbst, deren Design und/oder Sicherheit zu verbessern, (iii) um Anpassung an eine neue technische Umgebung, wegen erhöhter Nutzerzahl oder geänderter Marktbedingungen vorzunehmen, (iv) im Interesse des Nutzers oder zu dessen Vorteil (v) und/oder wenn sonstige wichtige betriebstechnische Gründe dies erfordern.",
              P9: "Falls eine Änderung der Dienste nach Ziffer 3.8 deren Zugriffsmöglichkeit oder die Nutzbarkeit beeinträchtigt, kann der Nutzer den Vertrag innerhalb von 30 Tagen unentgeltlich beenden. Die Frist beginnt mit dem Zugang der Information darüber. Erfolgt die Änderung nach dem Zugang der Information, so beginnt die Frist mit dem Zeitpunkt der Änderung. Die Beendigung des Vertrags nach dieser Ziffer ist ausgeschlossen, wenn die Beeinträchtigung der Zugriffsmöglichkeit oder der Nutzbarkeit nur unerheblich ist oder dem Nutzer die Zugriffsmöglichkeit auf den unveränderten Dienst und die Nutzbarkeit des unveränderten Dienstes ohne zusätzliche Kosten erhalten bleiben.",
              P10: "MindRepublic kann Nutzern kostenpflichtige Inhalte für eine gewisse Zeit kostenfrei anbieten. Nach Ablauf der kostenfreien Zeit ist der Nutzer verpflichtet, den auf der Website angegebenen Preis für den jeweiligen Inhalt zu zahlen, es sei denn, der Nutzer kündigt den Kurs mindestens 14 Tage vor Ablauf der kostenfreien Zeit wie auf der Website angegeben. Der Nutzer wird hierauf auf der Website hingewiesen und muss dem zustimmen. Es steht im alleinigen Ermessen von MindRepublic festzulegen, welcher Nutzer einen solchen vorübergehend kostenfreien Inhalt erhalten und nutzen kann. MindRepublic kann verlangen, dass der Nutzer bei der Bestellung eines solchen Kurse seine Zahlungsdaten angibt.",
              P11: "MindRepublic ist berechtigt, die über die Website und der MindRepublic-App angebotenen Dienste zu ändern oder die Preise für die Dienste zu ändern. Diese Änderungen betreffen jedoch keine Dienste, die von dem Nutzer bereits rechtsverbindlich gebucht wurden.",
            },
            Coachings: {
              Coachings: "Coachings",
              P1: "Auf der Website und der MindRepublic-App werden Informationen bereitgestellt, wie der Nutzer ein Coaching verbindlich buchen kann. Die Buchung von Kursen kann ebenfalls über die Website und der MindRepublic-App erfolgen.",
              P2: "Mit Abgabe der Erklärung zur Buchung eines Coachings über die Website oder der MindRepublic-App gibt der Nutzer ein verbindliches Angebot ab zum Abschluss eines Vertrages für die Durchführung eines Coachings durch MindRepublic und über die Zahlung eines Preises durch den Nutzer an MindRepublic. Der Eingang der Buchung bei MindRepublic wird dem Nutzer per E-Mail bestätigt. Diese Bestätigungsmail stellt auch die Annahme von MindRepublic dar und führt zum Vertragsschluss zwischen MindRepublic und dem Nutzer über die Durchführung eines Coachings unter Geltung dieser AGB.",
              P3: "Bei einem Coaching-Vertrag handelt es sich um einen Dienstvertrag i.S.v. § 611 BGB. Ein Erfolg i.S.v. § 631 BGB ist nicht geschuldet.",
              P4: "Coachingtermine können bis zu 24 Stunden vor dem vereinbarten Termin kostenfrei abgesagt werden. Bei einer Absage innerhalb von 24 Stunden vor dem vereinbarten Termin wird das Stundenhonorar zu 100% in Rechnung gestellt.",
            },
            PersonalSuitability: {
              PersonalSuitability: "Persönliche Eignung für die Dienste",
              P1: "Die Übungen, die im Rahmen der Dienste vorgestellt werden, sind für durchschnittlich gesunde und körperlich und geistig durchschnittlich belastbare Nutzer ausgelegt. Im Rahmen von speziellen Patientenbegleitprogrammen kann die Nutzung des Achtsamkeitstrainings, der Reflection Journeys oder eines Coachings auf der Grundlage und unter Beachtung von besonderen Informationen und Erläuterungen des verantwortlichen Projektpartners sowie nach gegebenenfalls erforderlicher Rücksprache mit dem behandelnden Arzt erfolgen.",
              P2: "MindRepublic überprüft die persönliche Eignung eines Nutzers zur Teilnahme an einem bestimmten Dienst nicht. Der Nutzer ist persönlich dafür verantwortlich zu überprüfen, dass er die körperlichen und geistigen Fähigkeiten hat bzw. gesundheitlichen Voraussetzungen erfüllt, um an den Kursen teilnehmen zu können. Im Zweifel hat der Nutzer einen Arzt zu konsultieren oder/und die Teilnahme zu unterlassen.",
            },
            Rights: {
              Rights: "Rechte und Pflichten des Nutzers",
              P1: "Die Dienste werden dem Nutzer nur zur persönlichen und nichtkommerziellen Nutzung zur Verfügung gestellt. Der Nutzer darf die Dienste nur für seine eigenen privaten bzw. mit MindRepublic abgestimmten weiteren Zwecke nutzen.",
              P2: "Für die Entgeltpflicht von Leistungen, die MindRepublic zur Verfügung stellt, gilt Ziffer 7.",
              P3: "Die Website und die MindRepublic-App sind jeweils ein urheberrechtlich geschütztes Software-Programm. Der Nutzer erhält ein einfaches, nicht ausschließliches, nicht übertragbares, nicht unterlizenzierbares und widerrufliches Recht zur persönlichen Nutzung der Website nach Maßgabe dieser AGB. Es ist dem Nutzer ausdrücklich untersagt, die Website zu verpachten, zu vermieten, anderweitig zu übertragen oder in sonstiger Weise Dritten zur Nutzung zu überlassen. Soweit für die vertraglich vorgesehene Nutzung in diesen AGB nicht vorgesehen, ist der Nutzer nicht berechtigt die Website zu vervielfältigen, zu ändern, zu modifizieren, anzupassen, zu übersetzen, zu bearbeiten, zu arrangieren, umzuarbeiten, zu dekompilieren, zu verbreiten, oder öffentlich wiederzugeben. Die gesetzlichen Rechte aus § 69d und 69e UrhG bleiben unberührt.",
              P4: "Der Nutzer erhält ein einfaches, nicht ausschließliches, nicht übertragbares, nicht unterlizenzierbares und widerrufliches Recht zur persönlichen Nutzung der Kursinhalte bzgl. der Reflection Journeys und Meditationen nach Maßgabe dieser AGB. Es ist dem Nutzer ausdrücklich untersagt, diese Kurse zu verpachten, zu vermieten, anderweitig zu übertragen oder in sonstiger Weise Dritten zur Teilnahme zu überlassen. Soweit für die vertraglich vorgesehene Nutzung in diesen AGB nicht vorgesehen, ist der Nutzer nicht berechtigt die vorg. Kurse zu vervielfältigen, zu ändern, zu modifizieren, anzupassen, zu übersetzen, zu bearbeiten, zu arrangieren, umzuarbeiten, zu dekompilieren, zu verbreiten, oder öffentlich wiederzugeben. Die gesetzlichen Rechte aus § 69d und 69e UrhG bleiben unberührt.",
              P5: "Es ist dem Nutzer ferner nicht gestattet:",
              P6: "kostenpflichtige Inhalte gleichzeitig auf mehreren Endgeräten zu nutzen, es sei denn, eine solche Nutzung ist ausdrücklich gestattet;",
              P7: "die zur Authentifikation und Identifikation übersandten bzw. verwendeten Zugangsdaten Dritten zugänglich zu machen oder an Dritte weiterzugeben;",
              P8: "Zugangskontrollsysteme zu kostenpflichtigen Kursen zu umgehen bzw. andere Maßnahmen zu ergreifen, um Kurse unberechtigt zu nutzen;",
              P9: "auf der Website Inhalte einzustellen bzw. darüber zu verbreiten, die rechtswidrig, irreführend, bösartig, diskriminierend, pornographisch, bedrohlich, beleidigend, obszön, diffamierend, ethisch anstößig, gewaltverherrlichend, belästigend, für Minderjährige ungeeignet, rassistisch, volksverhetzend, ausländerfeindlich oder sonst verabscheuungswürdig und/oder verwerflich sind sowie Viren, Würmern, Trojanern oder anderen bösartigen Schadcodes enthalten, die die Funktionsfähigkeit der Dienste gefährden oder beeinträchtigen können;",
              P10: "Urheberrechtsvermerke und/oder Vermerke zu Marken oder anderen Schutzrechten von MindRepublic, mit MindRepublic verbundenen oder Dritten Unternehmen zu entfernen oder unkenntlich zu machen; und",
              P11: "Rechte oder Pflichten aus diesen AGB an Dritte zu übertragen oder abzutreten.",
              P12: "Entgelt",
              P13: "MindRepublic erbringt seine Leistungen gegen Entgelt. Bis auf Weiteres entfällt allerdings die Entgeltpflicht für die Nutzung der App und die Website. Die Zahlung des vereinbarten Entgelts ist die Hauptleistungspflicht des Nutzers bzw. des Coaches. Sofern nichts anderes vereinbart wurde, werden die Entgelte nach Erhalt der Rechnung fällig.",
              P14: "Bei Workshops, (mehrteiligen) Kursen und Seminaren ist das vollständige Entgelt vor Beginn der ersten Kurs-/Seminarstunde zu entrichten oder muss auf dem Konto von MindRepublic eingegangen sein. Eine Teilnahme ist ansonsten nicht möglich.",
              P15: "Die Bankverbindung lautet:",
              Accountholder:
                "Kontoinhaber: Christian Hoops/MindRepublic IBAN: DE97100110012933599914 BIC: NTSBDEB1XXX",
              P16: "MindRepublic informiert den Nutzer über die von MindRepublic akzeptierten Zahlungsmittel auf der Website. Die Abwicklung des Bezahlvorgangs erfolgt in der Regel über einen externen Zahlungsdienstanbieter. Hierfür können separate allgemeine Geschäftsbedingungen und Datenschutzerklärungen des Zahlungsdienstanbieters gelten. Kann der Nutzer die Zahlung nicht erfolgreich oder nicht fristgerecht leisten, trägt der Nutzer alle dadurch entstehenden Kosten und Aufwendungen, soweit er das die Kosten veranlassende Ereignis zu vertreten hat. MindRepublic ist berechtigt, dem Nutzer den Zugang zu den Kursen erst zu gewähren, wenn MindRepublic die Zahlung erhalten hat.",
              P17: "MindRepublic stellt dem Nutzer die Rechnung des Coaches für die geleistete Coaching-Session zur Verfügung, welche sein Entgelt und die Steuer, sofern anwendbar, ausweist. Zu diesem Zweck muss der Nutzer seine persönlichen Angaben stets korrekt halten. Der Nutzer ist verpflichtet, Rechnungen innerhalb von sieben (7) Geschäftstagen zu überprüfen und zu reklamieren, falls diese fehlerhaft sein oder Informationen fehlen sollten.",
            },
            Liability: {
              Liability: "Haftung",
              P1: "Unter diesem Vertrag haftet MindRepublic in vollem Umfang für die folgenden Fälle:",
              P2: "bei Schäden, die aus einer Verletzung von Leben, Körper und Gesundheit resultieren und zumindest fahrlässig durch MindRepublic, ihren gesetzlichen Vertretern oder Erfüllungsgehilfen verursacht wurden;",
              P3: "für jedwede anderen Schäden, die vorsätzlich oder grob fahrlässig durch MindRepublic, ihren gesetzlichen Vertretern oder Erfüllungsgehilfen verursacht wurden;",
              P4: "für Schäden, die aus einer Garantieverletzung oder gesetzlichen Vorgabe des Produkthaftungsgesetzes resultieren.",
              P5: "In allen anderen Fällen haftet MindRepublic nur für Schäden, die aus einer Verletzung einer wesentlichen Vertragspflicht durch MindRepublic, ihren gesetzlichen Vertretern oder Erfüllungsgehilfen resultieren. Eine wesentliche Vertragspflicht ist eine Vertragspflicht, welche die ordnungsgemäße Erfüllung des Vertrages erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und auch vertrauen darf. Im Falle einer Verletzung einer wesentlichen Vertragspflicht, die mit einfacher Fahrlässigkeit verursacht wurde, ist die Haftung von MindRepublic begrenzt auf den Ersatz von Schäden, die zu dem Zeitpunkt als die Leistung erbracht wurde typischerweise im Rahmen dieses Vertrages eintreten und der Höhe nach vorhersehbar waren.",
            },
            OnlineDisputeResolution: {
              OnlineDisputeResolution:
                "Online-Streitbeilegung, Streitschlichtungsverfahren",
              P1: "Die Plattform der EU-Kommission zur Online-Streitbeilegung mit Informationen zur Online-Streitbeilegung für Verbraucher oder zu alternativen Möglichkeiten der Streitbeilegung für Verbraucher finden ist unter folgendem Link erreichbar: ",
              P2: "MindRepublic ist weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
            },
            DataProtection: {
              DataProtection: "Datenschutz",
              P1: "Einzelheiten zum Schutz und der Nutzung der durch den Nutzer zur Verfügung gestellten personenbezogenen Daten durch MindRepublic können unserer Datenschutzerklärung entnommen werden    ",
            },
            chnagestoTandC: {
              chnagestoTandC: "Änderungen an diesen AGB",
              P1: "MindRepublic ist berechtigt, Bestimmungen dieser AGB mit einer Frist von mindestens zwei Wochen im Voraus basierend auf dem Nachfolgenden zu ändern, um eine unangemessene Benachteiligung für MindRepublic zu vermeiden, die zum Zeitpunkt des Abschlusses des Vertrags weder für MindRepublic noch für den Nutzer vorhersehbar waren. MindRepublic ist zu solchen Änderungen nur berechtigt, wenn dies notwendig ist (i) aufgrund einer Änderung der geltenden Gesetzgebung oder höchstrichterlichen Rechtsprechung oder (ii) zu Klärungszwecken zur Beseitigung von in den AGB existierenden Unklarheiten. Die geänderten AGB werden zwei Wochen nach Zugang der Information zur Änderung wirksam.",
              P2: "MindRepublic ist darüber hinaus berechtigt, Bestimmungen dieser AGB mit einer Frist von mindestens sechs Wochen im Voraus unter Berücksichtigung der Interessen der Nutzer basierend auf dem Nachfolgenden ändern. Der Nutzer wird über geänderten Bedingungen mindestens sechs Wochen vor ihrem Inkrafttreten schriftlich oder per E-Mail informiert. Die Änderungen gelten als genehmigt, wenn der Kunde ihnen nicht schriftlich oder per E-Mail widerspricht. Der Widerspruch muss innerhalb von sechs Wochen nach Zugang der Information zu den geänderten Bedingungen bei MindRepublic eingegangen sein. MindRepublic wird auf die Widerspruchsmöglichkeit, mögliche Folgen des Widerspruchs und die Bedeutung der Sechswochenfrist im Informationsschreiben zu den geänderten Bedingungen besonders hinweisen. Übt der Kunde sein Widerspruchsrecht aus, gilt der Änderungswunsch von MindRepublic als abgelehnt. Der Vertrag wird dann ohne die vorgeschlagenen Änderungen fortgesetzt. Das Recht von MindRepublic zur Kündigung des Vertrages bleibt hiervon unberührt.",
              P3: "Neben Ziffer 10.1 und Ziffer 10.2 ist MindRepublic auch berechtigt, Bestimmungen dieser AGB zu ändern, wenn der Nutzer der geänderten Fassung der AGB zustimmt. Stimmt der Nutzer nicht zu, gilt die frühere Fassung der AGB weiter. Das Recht von MindRepublic zur Kündigung des Vertrages bleibt auch hiervon unberührt.",
            },
            Miscellaneous: {
              Miscellaneous: "Sonstiges",
              P1: "Alle technischen Anforderungen einschließlich Informationen zur Interoperabilität und Kompatibilität, sowie die jeweiligen Funktionalitäten und technischen Schutzmaßnahmen im Zusammenhang mit den Diensten werden auf der Website beschrieben.",
              P2: "MindRepublic ist berechtigt, die sich aus einem Vertrag ergebenden Rechte und Pflichten auf einen Dritten zu übertragen. MindRepublic wird den Nutzer schriftlich über die Übertragung informieren; dem Nutzer steht in diesem Fall ein außerordentliches Kündigungsrecht zu. Die Kündigung muss binnen 14 Tagen nach Eingang der Übertragungsmitteilung beim Nutzer schriftlich bei MindRepublic eingehen.",
              P3: "Die mit diesen AGB geregelten Vertragsverhältnisse sowie diese AGB selbst unterliegen dem Recht der Bundesrepublik Deutschland unter Ausschluss von UN-Kaufrecht.",
              P4: "Sollten einzelne Bestimmungen dieser AGB unwirksam oder undurchführbar sein oder unwirksam oder undurchführbar werden, bleibt davon die Wirksamkeit der Bestimmungen im Übrigen unberührt. Die vorstehenden Bestimmungen gelten entsprechend für den Fall, dass sich die Bestimmungen als lückenhaft erweisen.",
            },
          },

          // =========================================About the app========================================

          AboutTheApp: {
            mainheading: {
              selfcare: "SELFCARE",
              desc1:
                "Hören Sie auf sich selbst und entdecken Sie sich selbst mit den Reflection Journeys und gönnen Sie sich eine Auszeit mit Meditation. Die App ist Ihr Self-Care Hub, sodass Sie sich Zeit und Raum für sich selbst nehmen können.",
              h2: "INDIVIDUELLE UNTERSTÜTZUNG DURCH COACHING",
              desc2:
                "Coaching hilft Ihnen, Ihre Ziele zu erreichen. bestimmte Fähigkeiten entwickeln oder mit bestimmten Situationen besser umgehen können. Mit anderen Worten: Es geht darum, Ihnen dabei zu helfen, dorthin zu gelangen, wo Sie sein möchten.",
            },
            midscreen: {
              h1: "Reflection Journeys",
              desc1:
                "Lernen Sie sich selbst mit Reflection Journeys kennen: Nehmen Sie an interaktiver Selbstreflexion teil, die auf geschichtenbasierten Audiositzungen basiert. Reflection Journeys sind eine Mischung aus Wissensvermittlung, Anleitungen zur Selbstreflexion mit fesselnden Geschichten als Basis und Coaching-Methoden, die Sie in die Tat umsetzen. Visualisieren Sie Ihre Motivation, Ihre Antriebe und Ihr Leben oder verstehen Sie Ihre Gewohnheiten und Muster besser. Sie können jederzeit Ihre Sitzung mit einem Coach buchen, um Ihr Verständnis zu vertiefen.",
              h2: "Meditation",
              desc2:
                "Meditation ist ein wesentlicher Bestandteil der achtsamkeitsbasierten Stressreduktion (MBSR). Zahlreiche Studien belegen den positiven Einfluss von Meditation auf das allgemeine Wohlbefinden und damit auf die Gesundheit. Ihr Selfcare Hub bietet Ihnen ruhige Minuten, damit Sie die Pause-Taste für den Alltag drücken können.",
              h3: "Coaching",
              desc3:
                "Selbstreflexion ist einer der häufigsten Gründe für die Buchung einer Coaching-Sitzung. Setzen Sie sich entweder anhand der Reflection Journeys mit sich selbst auseinander oder holen Sie sich professionelle Unterstützung. Coaching ist persönliche Weiterentwicklung mit Unterstützung. MindRepublic verfügt über eine Vielzahl qualifizierter Coaches, die nur dann gelistet werden, wenn sie die Mindestanforderungen (mehrjährige Ausbildung und/oder Berufserfahrung) erfüllen.",
            },
          },

          // ==========================================reflection Jpurney=========================================


          reflectionjourney: {
            head1: "Reflection Journeys : ",
            head2: "Die Reise zu Dir selbst.",
            desc1: "Finde heraus, wer Du bist und was Du willst. Erlange ein tiefes Verständnis von Dir und entdecke damit persönliche Freiheit.",
            // desc2: "Sich selbst verstehen und die persönliche Freiheit entdecken.",
            desc3:
              "Story-getriebene Audio Sessions geben Dir einen Impuls: Reflection Journeys sind eine Mischung aus Wissensvermittlung und Anleitungen zur Selbstreflektion auf Basis von fesselnden Geschichten. Reflection Journeys sind interaktiv und nach dem Audio-Impuls werden Coaching-Methoden angeboten. Es handelt sich hier um anerkannte Coaching-Methoden, die von CoachInnen im Rahmen einer (physischen) Session auch angewandt werden würden, um den Coachee ins Denken zu bringen. Du bist frei, die Ergebnisse in der App festzuhalten und für eine spätere Coaching-Sessions nutzbar zu machen. ",
            desc4:
              "Dein Selfcare.Hub bringt Dich mit Reflection Journeys ins Denken und Handeln",
            subhead: "Grenzenlos reflektieren",
            p1: "Geschichten, die bewegen",
            p2: "On-demand und unlimitierter Zugang: Beginne jederzeit und überall",
            p3: "Halte Deine Ergebnisse fest, wenn Du willst: DSGVO-konform und vertraulich",
            p4: "Es gibt keine Abo-Gebühren.",
            end:"Starte jetzt mit MindRepublic"
          },

          otpVerify:{
            Verifyyouremail:"Bestätigen Sie Ihre email",
            enterOTP:"Geben Sie hier Ihren OTP-Code ein.",
            didnotrec:"Sie haben das OTP nicht erhalten?",
            resend:"Erneut senden",
            verify:"Verifizieren"

          },

          signUpAs:{
            welcome:"Willkommen bei MindRepublic!",
            signup:"Registrieren Sie sich als"

          },

          login:{
            welcome:"Willkommen zurück!",
            signIn:"Melden Sie sich an, um fortzufahren",
            remeber:"Erinnere dich an mich",
            forgot:"Passwort vergessen?",
            dont:"Sie haben noch kein Konto?",
            

          },

          AccountCreated:{
            created:"Account erstellt!",
            success:"Ihr Konto wurde erfolgreich erstellt."
          },
          career:{
            career:"Gegenwärtig gibt es keine offenen Stellen." ,
            career1:"Nichtsdestotrotz besteht immer die Möglichkeit für Initiativbewerbungen."
          }
        },
      },
    },
  });
