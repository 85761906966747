import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCourseCategory } from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { courseCategory } from "../Redux/Reducers/meditationSlice";

export default function Meditation() {

  const navigate=useNavigate()
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const BASE_URL = "https://api.mindrepublic.com/";


  // ======================================check for language=======================================

    const ingerman = useSelector((state)=>state.homedata.selectedLang)
    console.log(ingerman)

  

  // ===================================fetch data using usequery===================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["courseCategory"],
    queryFn: getCourseCategory,
  });

  

  {isSuccess && dispatch(courseCategory(data))}

  {isError && toast.error(data?.message || "Something went wrong")}



  return (
    <LayoutNew>
      <div style={{ position: "relative" }} className="right-side">
        {isPending && (
          <div
            style={{
              position: "absolute",
              width: "110%",
              height: "100%",
              zIndex: "1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              // backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <div class="loader"></div>
          </div>
        )}
        <div className="notify-page-i">
          <Link to="/notification">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="30"
              viewBox="0 0 26 30"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.2579 10.1945C23.2579 12.0784 23.7655 13.1887 24.8828 14.4684C25.7294 15.4111 26 16.6212 26 17.934C26 19.2453 25.5608 20.4901 24.6806 21.5008C23.5285 22.7125 21.9035 23.4859 20.2451 23.6205C17.842 23.8213 15.4374 23.9905 13.0008 23.9905C10.5626 23.9905 8.15949 23.8894 5.75632 23.6205C4.09647 23.4859 2.47156 22.7125 1.32091 21.5008C0.440807 20.4901 0 19.2453 0 17.934C0 16.6212 0.272083 15.4111 1.11722 14.4684C2.2694 13.1887 2.74364 12.0784 2.74364 10.1945V9.55545C2.74364 7.03251 3.38509 5.38278 4.706 3.76779C6.66986 1.41255 9.81783 0 12.9324 0H13.0691C16.2506 0 19.5003 1.48053 21.4309 3.93699C22.6833 5.51874 23.2579 7.09897 23.2579 9.55545V10.1945ZM8.52444 27.0912C8.52444 26.3359 9.23125 25.9899 9.88486 25.8418C10.6494 25.6833 15.3083 25.6833 16.0729 25.8418C16.7266 25.9899 17.4333 26.3359 17.4333 27.0912C17.3952 27.8103 16.9652 28.4479 16.3708 28.8528C15.6002 29.442 14.6958 29.815 13.7503 29.9496C13.2274 30.016 12.7137 30.0175 12.209 29.9496C11.262 29.815 10.3576 29.442 9.58846 28.8513C8.9926 28.4479 8.56244 27.8103 8.52444 27.0912Z"
                fill="#4CA890"
              />
            </svg>
          </Link>
        </div>
        <div className="main-content">
          <h2>Meditation</h2>
          <div   className="meditation-listing">
            {data?.data?.map((course) => {
              return (
                <div style={{cursor:"pointer"}} onClick={()=>navigate(`/theory/${course?._id}`)} className="meditation-card-item">
                  <div className="medi-icon-txt">
                    <div className="inbox-img-detail">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="56"
                        height="84"
                        viewBox="0 0 56 84"
                        fill="none"
                      >
                        <path
                          d="M49.7778 7V0H43.5556V7H37.3333V0H31.1111V7H24.8889V0H18.6667V7H12.4444V0H6.22222V7H0V73.5C0 76.2848 0.983331 78.9555 2.73367 80.9246C4.48401 82.8938 6.85798 84 9.33333 84H46.6667C49.142 84 51.516 82.8938 53.2663 80.9246C55.0167 78.9555 56 76.2848 56 73.5V7H49.7778ZM31.1111 59.5H12.4444V52.5H31.1111V59.5ZM43.5556 45.5H12.4444V38.5H43.5556V45.5ZM43.5556 31.5H12.4444V24.5H43.5556V31.5Z"
                          fill="#C1C9B6"
                        />
                      </svg> */}

                      <img
                       
                        src={course?.image ? `${BASE_URL}${course?.image}` : require("../Assets/images/place_holder.png")}
                      />
                    </div>
                    <div className="medi-catg-nme">
                      <h3>{ingerman =="de" ? course?.germen_title : course?.title}</h3>
                      <p>{course?.course_count} {ingerman=="de" ? "Teile":"Parts"}</p>
                    </div>
                  </div>
                  <div className="">
                    <Link >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                      >
                        <rect
                          width="50"
                          height="50"
                          rx="6.125"
                          fill="#4CA890"
                        />
                        <path
                          d="M20 15.625L29.1875 24.8125L20 34"
                          stroke="white"
                          stroke-width="1.8375"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </LayoutNew>
  );
}
