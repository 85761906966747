import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import LayoutCoach from "../Components/LayoutCoach";

export default function RequestedDetailsCoach() {
  return (
    <>
      <LayoutCoach>
        <div className="right-side">
          <div className="back-page-i">
            <Link to="/dashboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>Requested Details</h2>
            <div className="requested-details-coach-box">
              <div className="coach-img-detail">
                <div className="appointment-date-card">
                  <img src={require("../Assets/images/date-bg.png")} />
                  <div className="appointment-date-box">
                    <h2>21</h2>
                    <p>January</p>
                  </div>
                </div>
                <img src={require("../Assets/images/coach-img.png")} />
                <div className="coach-card-info">
                  <h3>Anny Smith</h3>
                  <p>+44 987 654 7895</p>
                  <p className="coach-date">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M10.0013 18.6667C14.6037 18.6667 18.3346 14.9357 18.3346 10.3333C18.3346 5.73096 14.6037 2 10.0013 2C5.39893 2 1.66797 5.73096 1.66797 10.3333C1.66797 14.9357 5.39893 18.6667 10.0013 18.6667Z"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 5.33398V10.334L13.3333 12.0007"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    at 5:15 pm
                  </p>
                </div>
              </div>
              <div className="request-date">
                <h3>Request Details</h3>
                <div className="date-time">
                  <div className="date-time-set">
                    <div className="">
                      <h4>Date</h4>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
                            stroke="#585858"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.5 5H10.5"
                            stroke="#585858"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 1V3"
                            stroke="#585858"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4 1V3"
                            stroke="#585858"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        10-05-2023
                      </p>
                    </div>
                    <div className="">
                      <h4>Time</h4>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4329_26413)">
                            <path
                              d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                              stroke="#585858"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6 3V6L8 7"
                              stroke="#585858"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4329_26413">
                              <rect width="12" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        10 am - 11 am
                      </p>
                    </div>
                  </div>
                  <Link className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                    >
                      <path
                        d="M18.5 0C23.2682 0 27.8582 1.8899 31.2242 5.27373C34.6082 8.65936 36.5 13.2293 36.5 18.0171C36.5 24.3168 33.1862 30.1665 27.7862 33.4243C22.3862 36.6821 15.6722 36.8621 10.094 33.8923H10.0382C9.5 33.6763 9.0662 33.2983 8.5622 33.0463C8.006 32.8123 7.3742 32.7763 6.8 32.9581C5.4662 33.4243 4.1162 33.8023 2.7302 34.1263C2.0102 34.1443 1.7942 33.7123 1.7942 33.0103C2.1182 31.5884 2.5502 30.1827 3.056 28.8165C3.2522 28.2226 3.2 27.5926 2.894 27.0328L2.534 26.3327C1.2002 23.7948 0.5 20.9689 0.5 18.1089V17.9991C0.5 13.2293 2.3882 8.63956 5.7722 5.27373C9.1562 1.8899 13.7282 0 18.5 0ZM26.7962 15.7132C25.5362 15.7132 24.494 16.7392 24.494 18.0171C24.494 19.277 25.5362 20.321 26.7962 20.321C28.0742 20.321 29.1002 19.277 29.1002 18.0171C29.1002 16.7392 28.0742 15.7132 26.7962 15.7132ZM18.5 15.7132C17.222 15.7132 16.1942 16.7392 16.1942 18.0171C16.1942 19.277 17.222 20.321 18.5 20.321C19.778 20.321 20.8022 19.277 20.8022 18.0171C20.8022 16.7392 19.778 15.7132 18.5 15.7132ZM10.2002 15.7132C8.9222 15.7132 7.8962 16.7392 7.8962 18.0171C7.8962 19.277 8.9222 20.321 10.2002 20.321C11.4602 20.321 12.506 19.277 12.506 18.0171C12.506 16.7392 11.4602 15.7132 10.2002 15.7132Z"
                        fill="white"
                      />
                    </svg>
                    Chat Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="requested-retails-btns">
              <Link to="" className="white-btn">
                Reject
              </Link>
              <Link to="" className="theme-btn">
                Accept
              </Link>
            </div>
          </div>
        </div>
      </LayoutCoach>
    </>
  );
}
