import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getCoachAppointments,
  makeAppointment,
} from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import { appointmentData, appointmentID } from "../Redux/Reducers/CoachSlice";

export default function MakeAppointment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // ===========================================get coach id from store==========================================

  const coachId = useSelector((state) => state?.coachData?.id);

  console.log(coachId, "id");

  const coachData = useSelector((state) => state?.coachData?.details);

  console.log(coachData, "coach");

  // =================================================react state==================================================
  const [selectedOption, setSelectedOption] = useState(""); // Initialize with empty string for selected time slot
  const [value, onChange] = useState(new Date()); // State for date selection, initialized with current date
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (value) {
      let date = moment(value).format("YYYY-MM-DD");
      setDate(date);
    }
  }, [value]);

  // const tileDisabled = ({ date }) => {
  //   const today = new Date();
  //   const tomorrow = new Date(today);
  //   tomorrow.setDate(today.getDate() + 1);

  //   return date <= today || date <= tomorrow;
  // };

  const tileDisabled = ({ date }) => {
    const today = new Date();
    return date < today;
  };

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["appointment time", { date: date }],
    queryFn: () => getCoachAppointments(coachId, date),
  });

  // {
  //   isSuccess && dispatch(coachDetails(data));
  // }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data, "data1");

  useEffect(()=>{
    setSelectedOption("")
  },[value])

  

  // ==============================================get data of timeslot from input fields==============================

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // ==========================================post data using react query=====================================

  const { isPending: makeAppointmentPending, mutate } = useMutation({
    mutationFn: makeAppointment,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        dispatch(appointmentID(res?.data?._id));
        dispatch(appointmentData(res));

        coachData?.data?.coachDetails?.auto_approve== 0 ||
        coachData?.auto_approve == 0
          ? navigate("/appointment-created", {
              state: {
                autoApprove:
                  coachData?.data?.coachDetails?.auto_approve ||
                  coachData?.auto_approve,
              },
            })
          : navigate(`/invoice-details`);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res);
    },
  });

  // ==========================================Book Appointment==========================================

  const bookAppointment = () => {
    let isvalid = true;
    if (!date) {
      toast.error("Please select a datet");
      isvalid = false;
    } else if (!selectedOption) {
      toast.error("Please select a time Slot");
      isvalid = false;
    } else {
      mutate({
        date: date,
        coach_id: coachId,
        timeSlot: selectedOption,
        langType: ingerman == "de" ? 1 : 0,
      });
    }
  };

  console.log(selectedOption, "selected");

  return (
    <>
      <LayoutNew>
        <div style={{ position: "relative" }} className="right-side">
          {(isPending || makeAppointmentPending) && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}
          <div className="back-page-i">
            <Link to="/coaching">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>{ingerman=="de" ?"Einen Termin machen" : "Make Appointment"}</h2>
            <Row>
              <Col lg={6}>
                <div className="celender-box" style={{ marginTop: "20px" }}>
                  <Calendar tileDisabled={tileDisabled} onChange={onChange} value={value} />
                </div>
              </Col>
              <Col lg={6}>
                <div className="availability-details">
                  <h3>{ingerman=="de" ? "Verfügbarkeit" : "Availability"}</h3>
                  <div className="availability-days">
                    {data?.data?.length == 0 ? (
                      <p>{ingerman=="de" ? "Der Trainer ist zum gewählten Zeitpunkt nicht verfügbar." : "The coach is not available at the selected time."}</p>
                    ) : (
                      data?.data?.map((time) => {
                        const isBooked = time.isBooked == 1;
                        return (
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption === time.timeslot || isBooked ? "selected" : ""
                            }`}
                          >
                            <Form.Control
                              type="radio"
                              value={time?.timeslot}
                              checked={selectedOption === time?.timeslot}
                              onChange={handleOptionChange}
                            />
                            {time?.timeslot}
                          </Form.Label>
                        );
                      })
                    )}

                    {/* <Form.Label
                      className={`radio-button-label ${
                        selectedOption === " 10 Am - 11 Am" && "selected"
                      }`}
                    >
                      <Form.Control
                        type="radio"
                        value="10 Am - 11 Am"
                        checked={selectedOption === " 10 Am - 11 Am"}
                        // onChange={handleOptionChange}
                      />
                      10 Am - 11 Am
                    </Form.Label>
                    <Form.Label
                      className={`radio-button-label ${
                        selectedOption === "  11 Am - 12 Am" && "selected"
                      }`}
                    >
                      <Form.Control
                        type="radio"
                        value="11 Am - 12 Am"
                        checked={selectedOption === "  11 Am - 12 Am"}
                        // onChange={handleOptionChange}
                      />
                      11 Am - 12 Am
                    </Form.Label>
                    <Form.Label
                      className={`radio-button-label ${
                        selectedOption === "09 Am - 10 Am" && "selected"
                      }`}
                    >
                      <Form.Control
                        type="radio"
                        value="09 Am - 10 Am"
                        checked={selectedOption === "09 Am - 10 Am"}
                        // onChange={handleOptionChange}
                      />
                      09 Am - 10 Am
                    </Form.Label>
                    <Form.Label
                      className={`radio-button-label ${
                        selectedOption === "10 Am - 11 Am" && "selected"
                      }`}
                    >
                      <Form.Control
                        type="radio"
                        value="10 Am - 11 Am"
                        checked={selectedOption === "10 Am - 11 Am"}
                        // onChange={handleOptionChange}
                      />
                      10 Am - 11 Am
                    </Form.Label>
                    <Form.Label
                      className={`radio-button-label ${
                        selectedOption === "11 Am - 12 Am" && "selected"
                      }`}
                    >
                      <Form.Control
                        type="radio"
                        value="11 Am - 12 Am"
                        checked={selectedOption === "11 Am - 12 Am"}
                        // onChange={handleOptionChange}
                      />
                      11 Am - 12 Am
                    </Form.Label> */}
                  </div>

                  {data?.data?.length !== 0 && (
                    <>
                      <div className="availability-status">
                        <Form>
                          <div
                            className="mb-3"
                            style={{ display: "flex", gap: "40px" }}
                          >
                            <p><span className="white"></span> {ingerman=="de" ?"Verfügbar" :"Available"}</p>

                            <p><span className="theme"></span>{ingerman=="de" ? "Nicht verfügbar" : "Not Available"}</p>
                          </div>
                        </Form>
                      </div>
                      <div
                        onClick={bookAppointment}
                        className="invoice-details-link"
                      >
                        <Link
                        // to="/invoice-details"
                        >
                        {ingerman=="de" ? "Buchen" : "Book Appointment"}  
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
