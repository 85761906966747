import { createSlice } from "@reduxjs/toolkit";


export const registrationSlice = createSlice({
  name: "registrationSlice",
  initialState: {
    user:"",
    role:"",
    forgetPass:"",
    registrationData:"",
    locationId:"",
    address:"",
    rememberMe:null
  },
  reducers: {
    Userdata:(state,action)=>{
        console.log(action,"action")
        state.user=action.payload
    },
    remmbermeData:(state,action)=>{
      console.log(action,"action")
      state.rememberMe=action.payload
  },
    Userrole:(state,action)=>{
        console.log(action,"action")
        state.role=action.payload
    },
    forgetPass:(state,action)=>{
        console.log(action,"action")
        state.forgetPass=action.payload
    },
    locationId:(state,action)=>{
        console.log(action,"action")
        state.locationId=action.payload
    },
    address:(state,action)=>{
        console.log(action,"action")
        state.address=action.payload
    },
    registrationData:(state,action)=>{
      console.log(action,"action")
      state.registrationData=action.payload
  },
  },
  
});

export const {Userdata,Userrole,forgetPass,registrationData,locationId,address,remmbermeData} = registrationSlice.actions

export default registrationSlice.reducer
