import { createSlice } from "@reduxjs/toolkit";


export const homeSlice = createSlice({
  name: "userMgmtSlice",
  initialState: {
    selectedLang: null,
  },
  reducers: {
    language:(state,action)=>{
        console.log(action,"action")
        state.selectedLang=action.payload
    }
  },
  
});

export const {language} = homeSlice.actions

export default homeSlice.reducer
