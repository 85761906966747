import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useSelector } from "react-redux";
import AudioPlayer from "../Utils/AudioComponent";

export default function DeStressAudio() {
  const location = useLocation();
  const navigate = useNavigate();
  const BASE_URL = "https://api.mindrepublic.com/";

  

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // ===================================================React State==========================================

  const [filterData, setFilterData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [from, setfrom] = useState(false);
  const [backpageid, setbackpageid] = useState(false);

  // ========================================play audio=====================================================

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  


  // ==================================================get course list========================================

  const category = useSelector((state) => state.meditationData.couseCat);
  console.log(category);

  useEffect(() => {
    let filterData = category?.data?.courses?.filter(
      (course) => course?._id == location.state.id
    );
    setFilterData(filterData);
    setfrom(location?.state?.from)
    setbackpageid(location?.state?.backpageid)
  }, []);

 

  console.log(backpageid,"dedtressaudio");

  return (
    <>
      <LayoutNew>
        <div className="right-side">
          <div className="back-page-i">
            <div
              onClick={() => {
                navigate(-1);
                setIsPlaying(false)
              }}

              // to={`theory/${courseID}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="main-content">
            <div className="right-headings">
              <h2>
                {ingerman == "de"
                  ? filterData && filterData[0]?.germanyTitle
                  : filterData && filterData[0]?.title}
              </h2>
            </div>
            <div className="reflection-journey-audio">
              <img
                 onClick={togglePlay}
                style={{ cursor: "pointer" }}
                src={ isPlaying
                    ? require("../Assets/images/pausebutton.png")
                    : require("../Assets/images/plauButton.png")}
              />
          
                {/* <audio id="audioPlayer" controls>
                  <source src={`${BASE_URL}${filterData[0]?.audio}`} />
                </audio> */}
              {
                filterData && filterData[0]?.audio &&  <AudioPlayer backpageid={backpageid} from={from} filterAudio={ingerman=="de" ? filterData[0]?.germen_audio :filterData[0]?.audio} isPlaying={isPlaying} setIsPlaying={setIsPlaying}/>
              }

               
              

             
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
