import React from "react";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { language } from "../Redux/Reducers/HomeSlice";
import { useDispatch, useSelector } from "react-redux";
// import { ReactComponent as UKFlag } from "./uk.svg";
// import { ReactComponent as GermanFlag } from "./germany.svg";

const languages = [
  { code: "en", lang: "EN" },
  { code: "de", lang: "DE" },
];

function LanguageSelector() {
 
    const dispatch=useDispatch()
    
  const { i18n } = useTranslation();

  const handleLangChange = (lng) => {
    
    dispatch(language(lng))
    localStorage.setItem("selectedLang",lng)
    i18n.changeLanguage(lng);
  };

  // const LanguageSelector=()=>{

  // }

  return (
    // <div>
    //   <select
    //     onChange={(e) => handleLangChange(e.target.value)}
    //     // value={selectedCountry}
    //   >
    //     {Languages.map((country) => {
    //       return <option value={country?.code}>{country?.lang}</option>;
    //     })}
    //   </select>
    // </div>

    <div className=" lang-change">
    <div className="lang-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
      >
        <path
          d="M4.87394 7.58602L4.44711 8.96185L3.29061 8.98669L5.128 3.63362L6.46579 3.60488L8.50008 8.87479L7.28419 8.90091L6.80457 7.54455L4.87394 7.58602ZM6.56129 6.76761L5.81235 4.63488L5.76129 4.63598L5.09535 6.7991L6.56129 6.76761Z"
          fill="#222222"
        />
        <path
          d="M0.0410474 2.68047C0.0301609 2.117 0.239279 1.57189 0.622398 1.16507C1.00552 0.758239 1.53125 0.523016 2.08395 0.511144L9.37779 0.354468C9.93048 0.342595 10.4649 0.555046 10.8634 0.945083C11.2619 1.33512 11.4919 1.87079 11.5028 2.43426L11.5644 5.6211L14.6903 5.55395C15.243 5.54208 15.7774 5.75453 16.1759 6.14457C16.5744 6.53461 16.8044 7.07028 16.8153 7.63375L16.959 15.0697C16.9698 15.6332 16.7607 16.1783 16.3776 16.5851C15.9945 16.9919 15.4687 17.2272 14.916 17.239L7.62221 17.3957C7.06951 17.4076 6.53513 17.1951 6.13661 16.8051C5.7381 16.4151 5.5081 15.8794 5.49721 15.3159L5.43564 12.1291L2.30971 12.1962C1.75701 12.2081 1.22263 11.9956 0.824114 11.6056C0.425599 11.2156 0.1956 10.6799 0.184713 10.1164L0.0410474 2.68047ZM2.10448 1.57342C1.82813 1.57936 1.56526 1.69697 1.3737 1.90038C1.18214 2.1038 1.07758 2.37635 1.08302 2.65809L1.22669 10.094C1.23213 10.3758 1.34713 10.6436 1.54639 10.8386C1.74565 11.0336 2.01284 11.1399 2.28919 11.1339L9.58302 10.9773C9.85937 10.9713 10.1222 10.8537 10.3138 10.6503C10.5054 10.4469 10.6099 10.1743 10.6045 9.8926L10.4608 2.45664C10.4554 2.17491 10.3404 1.90707 10.1411 1.71205C9.94185 1.51704 9.67466 1.41081 9.39831 1.41675L2.10448 1.57342ZM9.74724 12.0311C9.95451 12.3466 10.1781 12.6415 10.421 12.9157C9.65345 13.5433 8.69836 14.0165 7.56337 14.3502C7.7533 14.5767 8.04633 15.0146 8.15946 15.2587C9.32432 14.8522 10.3095 14.3156 11.1359 13.6071C11.9592 14.2961 12.9719 14.8057 14.2191 15.1052C14.3525 14.8324 14.6367 14.381 14.8563 14.1457C13.6789 13.9021 12.6987 13.4545 11.8916 12.8448C12.5858 12.0361 13.131 11.0636 13.524 9.87984L14.7733 9.853L14.7519 8.74079L11.6259 8.80794L11.6474 9.92015L12.4445 9.90302C12.1305 10.8067 11.7052 11.5619 11.1628 12.1942C11.0065 12.0311 10.8588 11.8598 10.7203 11.6808C10.43 11.8793 10.0953 11.9998 9.74724 12.0311Z"
          fill="#222222"
        />
      </svg>
    </div>
    <select
      className="lang-sec"
      value={localStorage.getItem("selectedLang")}
    onChange={(e) => handleLangChange(e.target.value)}
    >
      {languages.map((language) => (
        <option
          key={language.code}
          value={language.code}
          data-content=' <span class="flag-icon flag-icon-us"></span>'
        >
          {language.lang}
        </option>
      ))}
    </select>
  </div>
  );
}

export default LanguageSelector;



