import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutNew from "../Components/LayoutNew";
import { useDispatch, useSelector } from "react-redux";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import countries from "../Utils/countryCode.json";
import ReactCountryFlag from "react-country-flag";
import GoogleMapAutocomplete from "../Utils/GoogleMapAutoComplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useMutation } from "@tanstack/react-query";
import { editProfile } from "../Redux/Actions/userActions";
import toast from "react-hot-toast";
import {
  Userdata,
  address,
  locationId,
} from "../Redux/Reducers/registrationSlice";
import { editcoachProfile } from "../Redux/Actions/coachAction";
import LayoutCoach from "../Components/LayoutCoach";

export default function EditProfile() {
  const dispatch = useDispatch();
  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate=useNavigate()

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // =========================================chefk for user role========================================
  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");


  // ================================================get user data from redux===========================================

  const userData = useSelector((state) => state?.registerUserdata?.user);
  console.log(userData, "userData");

  // const locationID = useSelector(
  //   (state) => state?.registerUserdata?.locationId
  // );

  // // ===============================================dtore place=======================================================

  // const initialPlace = {
  //   value: {
  //     description: userData?.address || "",
  //     place_id: locationID || "",
  //   },
  //   label: userData?.address || "",
  // };

  // =================================================REACT STATES======================================================

  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [profilePic, setprofilePic] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [language, setlanguage] = useState(null);
  const [location, setlocation] = useState(null);
  const [prev, setprev] = useState(null);
  const [certificate, setCertificate] = useState("");
  const [prevcer, setPrevcer] = useState("");

  // ========================================FLAGS===========================================

  const options = countries.map((country) => ({
    value: country.dial_code,
    label: (
      <>
        <ReactCountryFlag
          countryCode={country.code}
          svg
          style={{
            marginRight: "10px",
            width: "20px",
            height: "auto",
          }}
          title={country.name}
          placeholder="heii"
        />
        {country.name} ({country.dial_code})
      </>
    ),
  }));

  //==============================Find the German country object=======================

  // const germanyOption = options.find((option) => option.value === "+49");

  //  ===========================================data for edit================================================================

  useEffect(() => {
   
    setName(userData?.full_name);
    setemail(userData?.email);
    setSelectedOption(userData?.gender);
    setCountryCode(userData?.country_code);
    setphoneNumber(userData?.phone_number);
    setprev(`${BASE_URL}${userData?.profile_image}`);
    setSelectedOption((userData?.gender).toString());
    setlocation(userData?.address);
    setlanguage(userData?.language);
    setCertificate(userData?.certification);
  }, [userData]);



 

  // =================================post data using react-query=====================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: userrole == "user" ? editProfile : editcoachProfile,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        toast.success(res?.message);
        userrole == "user" ?  navigate("/my-profile") :
        navigate("/my-profile-coach")
        // navigate(
        //   "/verify-email"
        //   //    {
        //   //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
        //   // }
        // );
        // dispatch(registrationData({}))
      } else {
        toast.error("All fields are required.");
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // =============================================handlers==========================================================

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePic = (e) => {
    setprofilePic(e.target.files[0]);
    setprev(URL?.createObjectURL(e?.target?.files[0]));
  };

  const handlePlaceSelect = (e) => {
    console.log(e.target.value, "place");
    setlocation(e.target.value);
    // // Patch the selected value, e.g., send to an API
    // patchSelectedPlace(place);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    // Handle the selected file here
    console.log("Selected file:", file);
    setCertificate(file);
    setPrevcer(URL?.createObjectURL(file));
  };

  const handleEdit = () => {
    

     if(!name){
      toast.error(ingerman=="de" ? "Bitte geben Sie Ihren Namen ein":"Please enter your name")
    }else if(!email){
      toast.error(ingerman=="de" ? "Bitte geben Sie ihre E-Mail-Adresse ein": "Please enter your email")
    }else if(!selectedOption){
      toast.error(ingerman=="de" ? "Bitte wähle ein Geschlecht":"Please select gender")
    }else if(!countryCode){
      toast.error( ingerman=="de" ? "Bitte geben Sie Ihren Ländercode ein": "Please enter your country code")
    }else if(!phoneNumber){
      toast.error(ingerman=="de" ? "Bitte geben sie ihre Telefonnummer ein" :  "Please enter your Phone number")
    }
    else if(!location){
      toast.error(ingerman=="de" ? "Bitte geben Sie bitte Ihren Standort ein" :  "Please enter your Location")
    }
    else{
      dispatch(locationId(location?.value?.place_id));
      dispatch(address(location?.label));
  
      const formdata = new FormData();
      formdata.append("profile_image", profilePic);
      formdata.append("full_name", name);
      formdata.append("email", email);
      formdata.append("gender", selectedOption);
      formdata.append("country_code", countryCode);
      // formdata.append("country_code", countryCode);
  
      formdata.append("phone_number", phoneNumber);
      // formdata.append("locationId",location?.value?.place_id)
      // if (addressfromredux !== userData?.address) {
      //   formdata.append("address", location?.label);
      // }
      // if (location?.label !== userData?.address) {
      formdata.append("address", location || userData?.address);
  
      // }
  
      userrole == "coach" && formdata.append("language", language);
  
      // userrole == "coach" &&
      //   prevcer &&
      //   ((certificate?.type == "video/mp4" || certificate?.type == "audio/mpeg")
      //     ? toast.error("Cerificate must be a file")
      //     : formdata.append("certification", certificate));
  
      userrole == "coach" && formdata.append("certification", certificate);
  
      mutate(formdata)

    }
   ;
  };

  console.log(location, "LOCATIONsTATE");
  // console.log(initialPlace, "initialPlace");
  console.log(userData?.address, "userData?.address");
  console.log(typeof(selectedOption), "gender");
  // console.log(addressfromredux,"addressfromredux")
  // console.log(userData?.address,"userData?.address")

  return (
    <>
      {userrole == "user" ? (
        <LayoutNew>
          <div style={{ position: "relative" }} className="right-side">
            {isPending && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}

            <div className="back-page-i">
              <Link to="/my-profile">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>

            <div className="main-content">
              <h2>{ingerman=="de" ? "Profil bearbeiten" : "Edit Profile"}</h2>
              <div className="coach-details">
                <Row>
                  <Col lg={6}>
                    <div className="edit-profile-box">
                      <img
                        src={
                          prev !=
                          "https://api.mindrepublic.com/undefined"
                            ? prev
                            : require("../Assets/images/place_holder.png")
                        }
                      />
                      <div className="change-img-icon">
                        <div className="camera-icon">
                          <svg
                            width="106"
                            height="106"
                            viewBox="0 0 106 106"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="106"
                              height="106"
                              rx="53"
                              fill="#B79C6C"
                            />
                            <path
                              d="M77.2918 68.3333C77.2918 69.5047 76.8265 70.6281 75.9982 71.4564C75.1699 72.2847 74.0465 72.75 72.8752 72.75H33.1252C31.9538 72.75 30.8304 72.2847 30.0021 71.4564C29.1738 70.6281 28.7085 69.5047 28.7085 68.3333V44.0417C28.7085 42.8703 29.1738 41.7469 30.0021 40.9186C30.8304 40.0903 31.9538 39.625 33.1252 39.625H41.9585L46.3752 33H59.6252L64.0418 39.625H72.8752C74.0465 39.625 75.1699 40.0903 75.9982 40.9186C76.8265 41.7469 77.2918 42.8703 77.2918 44.0417V68.3333Z"
                              stroke="white"
                              stroke-width="3.3125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M52.9998 63.9167C57.8784 63.9167 61.8332 59.9618 61.8332 55.0833C61.8332 50.2048 57.8784 46.25 52.9998 46.25C48.1213 46.25 44.1665 50.2048 44.1665 55.0833C44.1665 59.9618 48.1213 63.9167 52.9998 63.9167Z"
                              stroke="white"
                              stroke-width="3.3125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Form.Control
                            // value={profilePic}
                            // name="profilePic"
                            onChange={(e) => handlePic(e)}
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input-field-list">
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Sylvuia"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            // name="email"
                            value={email}
                            // onChange={(e) => setemail(e.target.value)}
                            type="email"
                            placeholder="Sylvuia@gmail.com"
                          />
                        </Form.Group>
                        <Form.Label className="label-text">{ingerman=="de" ? "Geschlecht" : "Gender"} </Form.Label>
                        <div className="availability-days gander-select">
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption == "1" && "selected"
                            }`}
                          >
                            <Form.Control
                              //  onChange={(e)=>setemail(e.target.value)}
                              type="radio"
                              value="1"
                              checked={selectedOption == "1"}
                              onChange={handleOptionChange}
                            />
                            {
                              ingerman=="de" ? "Männlich" : "Male"
                            }
                            
                          </Form.Label>
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption == "0" && "selected"
                            }`}
                          >
                            <Form.Control
                              type="radio"
                              value="0"
                              checked={selectedOption == "0"}
                              onChange={handleOptionChange}
                            />
                            {
                              ingerman=="de" ?"Weiblich" : "Female"
                            }
                            
                          </Form.Label>
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption == "2" && "selected"
                            }`}
                          >
                            <Form.Control
                              type="radio"
                              value="2"
                              checked={selectedOption == "2"}
                              onChange={handleOptionChange}
                            />
                            {
                              ingerman=="de" ?"Mache lieber keine Angabe" : "Prefer not to say"
                            }
                            
                          </Form.Label>
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          {/* <Form.Label className="label-text">
                          Phone Number
                        </Form.Label> */}
                          <InputGroup className="mb-3 ad-height-input conutry-selects">
                            <InputGroup.Text id="basic-addon3">
                              <Select
                                defaultValue={countryCode}
                                onChange={(e) => setCountryCode(e?.value)}
                                options={options}
                                value={options.find(
                                  (option) => option.value === countryCode
                                )}
                                name="countryCode"
                              />
                            </InputGroup.Text>
                            <Form.Control
                              name="phoneNumber"
                              value={phoneNumber}
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              onChange={(e) => setphoneNumber(e.target.value)}
                            />
                          </InputGroup>

                          {/* <Form.Control   type="number" placeholder="1234567890" /> */}
                        </Form.Group>
                        <Form.Group className="mb-3">
                        <Form.Label className="label-text">{ingerman=="de" ? "Ort auswählen" : "Add Location"} </Form.Label>
                          <div>
                            {/* <GooglePlacesAutocomplete
                          
                              value={location==undefined ? "" : location}
                              selectProps={{
                                value: location,
                                onChange: handlePlaceSelect,
                                placeholder: ingerman=="de" ? "Ort auswählen" : "Select Location",
                              }}
                              // selectProps={{
                              //   location,
                              //   onChange: setlocation,
                              // }}
                              onError={(status, clearSuggestions) =>
                                console.log("Error occurred: ", status)
                              }
                              debounce={300}
                              searchOptions={{
                                types: ["address"],
                                componentRestrictions: { country: "in" }, // Adjust as needed
                              }}
                            /> */}
                            <Form.Control
                            
                           
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              placeholder={ingerman=="de" ? "Ort auswählen" : "Add Location"}
                              onChange= {handlePlaceSelect}
                              value={location}
                              
                            />
                          </div>
                          {/* <Form.Select>
                          <option>Choose a Preferred Language</option>
                          <option>Disabled select</option>
                        </Form.Select> */}
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        ></Form.Group>
                        <Button
                          onClick={handleEdit}
                          variant="primary"
                          className="field-list-btn"
                          // type="submit"
                        >
                        {
                          ingerman=="de" ? "Änderungen speichern" : "Save Changes"
                        }
                          
                        </Button>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </LayoutNew>
      ) : (
        <LayoutCoach>
          <div style={{ position: "relative" }} className="right-side">
            {isPending && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            <div className="back-page-i">
              <Link to="/my-profile-coach">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>

            <div className="main-content">
              <h2>{ingerman=="de" ? "Profil bearbeiten" : "Edit Profile"}</h2>
              <div className="coach-details">
                <Row>
                  <Col lg={6}>
                    <div className="edit-profile-box">
                      <img
                        src={
                          prev !=
                          "https://api.mindrepublic.com/undefined"
                            ? prev
                            : require("../Assets/images/place_holder.png")
                        }
                      />
                      <div className="change-img-icon">
                        <div className="camera-icon">
                          <svg
                            width="106"
                            height="106"
                            viewBox="0 0 106 106"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="106"
                              height="106"
                              rx="53"
                              fill="#B79C6C"
                            />
                            <path
                              d="M77.2918 68.3333C77.2918 69.5047 76.8265 70.6281 75.9982 71.4564C75.1699 72.2847 74.0465 72.75 72.8752 72.75H33.1252C31.9538 72.75 30.8304 72.2847 30.0021 71.4564C29.1738 70.6281 28.7085 69.5047 28.7085 68.3333V44.0417C28.7085 42.8703 29.1738 41.7469 30.0021 40.9186C30.8304 40.0903 31.9538 39.625 33.1252 39.625H41.9585L46.3752 33H59.6252L64.0418 39.625H72.8752C74.0465 39.625 75.1699 40.0903 75.9982 40.9186C76.8265 41.7469 77.2918 42.8703 77.2918 44.0417V68.3333Z"
                              stroke="white"
                              stroke-width="3.3125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M52.9998 63.9167C57.8784 63.9167 61.8332 59.9618 61.8332 55.0833C61.8332 50.2048 57.8784 46.25 52.9998 46.25C48.1213 46.25 44.1665 50.2048 44.1665 55.0833C44.1665 59.9618 48.1213 63.9167 52.9998 63.9167Z"
                              stroke="white"
                              stroke-width="3.3125"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Form.Control
                            // value={profilePic}
                            // name="profilePic"
                            onChange={(e) => handlePic(e)}
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="input-field-list">
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            placeholder="Sylvuia"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            
                            value={email}
                            // onChange={(e) => setemail(e.target.value)}
                            type="email"
                            placeholder="Sylvuia@gmail.com"
                          />
                        </Form.Group>
                        <Form.Label className="label-text">{ingerman=="de" ? "Geschlecht" : "Gender"} </Form.Label>
                        <div className="availability-days gander-select">
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption == "1" && "selected"
                            }`}
                          >
                            <Form.Control
                              //  onChange={(e)=>setemail(e.target.value)}
                              type="radio"
                              value="1"
                              checked={selectedOption == "1"}
                              onChange={handleOptionChange}
                            />
                           {
                              ingerman=="de" ? "Männlich" : "Male"
                            }
                          </Form.Label>
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption == "0" && "selected"
                            }`}
                          >
                            <Form.Control
                              type="radio"
                              value="0"
                              checked={selectedOption == "0"}
                              onChange={handleOptionChange}
                            />
                             {
                              ingerman=="de" ?"Weiblich" : "Female"
                            }
                          </Form.Label>
                          <Form.Label
                            className={`radio-button-label ${
                              selectedOption == "2" && "selected"
                            }`}
                          >
                            <Form.Control
                              type="radio"
                              value="2"
                              checked={selectedOption == "2"}
                              onChange={handleOptionChange}
                            />
                            {
                              ingerman=="de" ?"Mache lieber keine Angabe" : "Prefer not to say"
                            }
                          </Form.Label>
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          {/* <Form.Label htmlFor="basic-url">
                          Phone Number
                        </Form.Label> */}
                          <InputGroup className="mb-3 ad-height-input conutry-selects">
                            <InputGroup.Text id="basic-addon3">
                              <Select
                                defaultValue={countryCode}
                                onChange={(e) => setCountryCode(e?.value)}
                                options={options}
                                value={options.find(
                                  (option) => option.value === countryCode
                                )}
                                name="countryCode"
                              />
                            </InputGroup.Text>
                            <Form.Control
                              name="phoneNumber"
                              value={phoneNumber}
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              onChange={(e) => setphoneNumber(e.target.value)}
                            />
                          </InputGroup>

                          {/* <Form.Control   type="number" placeholder="1234567890" /> */}
                        </Form.Group>

                        {/* <Form.Label className="label-text">
                      Choose a Preferred Language{" "}
                    </Form.Label> */}
                        <Form.Group className="mb-3">
                          <InputGroup className="mb-3 ad-height-input conutry-selects">
                            <InputGroup.Text id="basic-addon3"></InputGroup.Text>

                            <Form.Control
                              name="language"
                              value={language}
                              // id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              onChange={(e) => setlanguage(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                        <Form.Label className="label-text">{ingerman=="de" ? "Ort auswählen" : "Select Location"} </Form.Label>
                          <div>
                            {/* <GooglePlacesAutocomplete
                              value={location}
                              selectProps={{
                                value: location,
                                onChange: handlePlaceSelect,
                                placeholder: "Search for a place...",
                              }}
                              // selectProps={{
                              //   location,
                              //   onChange: setlocation,
                              // }}
                              onError={(status, clearSuggestions) =>
                                console.log("Error occurred: ", status)
                              }
                              debounce={300}
                              searchOptions={{
                                types: ["address"],
                                componentRestrictions: { country: "in" }, // Adjust as needed
                              }}
                            /> */}
                            <Form.Control
                            
                           
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              placeholder={ingerman=="de" ? "Ort auswählen" : "Add Location"}
                              onChange= {handlePlaceSelect}
                              value={location}
                              
                            />
                          </div>
                          {/* <Form.Select>
                          <option>Choose a Preferred Language</option>
                          <option>Disabled select</option>
                        </Form.Select> */}
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <div className="image-show">
                            {(certificate || prevcer) && (
                              <>
                                {(
                                  prevcer
                                    ? certificate?.type === "application/pdf"
                                    : certificate?.endsWith(".pdf")
                                ) ? (
                                  <embed
                                    style={{ cursor: "pointer" }}
                                    src={
                                      prevcer
                                        ? prevcer
                                        : `${BASE_URL}${certificate}`
                                    }
                                    type="application/pdf"
                                    width="100%"
                                    height="200px"
                                  />
                                ) : (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={
                                      prevcer
                                        ? prevcer
                                        : `${BASE_URL}${certificate}`
                                    }
                                    alt="Certificate"
                                  />
                                )}
                              </>
                            )}
                            <input
                              type="file"
                              // ref={fileInputRef}
                              // style={{ display: "none" }}
                              onChange={handleFileSelect}
                            />
                          </div>
                        </Form.Group>
                        <Button
                          onClick={handleEdit}
                          variant="primary"
                          className="field-list-btn"
                          // type="submit"
                        >
                          Save Changes
                        </Button>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </LayoutCoach>
      )}
    </>
  );
}
