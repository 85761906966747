// src/Utils/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2xA5tG4BQVWyDUJmhsAzLRZ8Z5eMOlCg",
  authDomain: "mind-republic.firebaseapp.com",
  projectId: "mind-republic",
  storageBucket: "mind-republic.appspot.com",
  messagingSenderId: "12010412085",
  appId: "1:12010412085:web:266357479affac4063397c",
  measurementId: "G-YL7JHK783S",
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const currentToken = await getToken(messaging, {
        vapidKey: "BLFO5sxyYak23Daz-3UtpJ5qzjrqdSp9r-fhohyPJXje35qZGKbaJ1iiGu_Sn9L6IMpJ571rXJWYXZW_nla-iks",
        
        serviceWorkerRegistration: await navigator.serviceWorker.register('/firebase-messaging-sw.js')
      });
      if (currentToken) {
        console.log("FCM Token:", currentToken);
        sessionStorage.setItem("FCM Token", currentToken)
        // Send the token to your server and save it
      } else {
        console.error("No registration token available. Request permission to generate one.");
      }
    } else {
      console.error("Unable to get permission to notify.");
    }
  } catch (error) {
    console.error("Error requesting permission or getting token:", error);
  }
};


// requestPermission();


onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  toast.success(payload?.data?.title)

  // Customize the UI to display the notification content
});


export { auth, googleProvider };
