import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { Userrole } from "../Redux/Reducers/registrationSlice";

export default function SignUpAs() {
  // ===============================================LANGUAGE=============================================

  const { t } = useTranslation();
  const signUpAs = t("signUpAs");
  console.log(signUpAs);

  // ===================================send role to redux============================================

  const dispatch= useDispatch()

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);


  return (
    <section className="Login-sec">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                <div className="sign-up-logo">
                  <img src={require("../Assets/images/upd-logo.png")} />
                </div>
                <h2>{signUpAs?.welcome}</h2>
                <p>{signUpAs?.signup}</p>
              </div>
              <Form className="contact-form">
                <div className="sign-up-as-btn">
                  <Link onClick={()=>dispatch(Userrole("user"))} to="/login" className="theme-btn">
                   {
                    ingerman == "de" ? "Nutzer" : "User"
                   } 
                  </Link>
                  <Link
                    // to="/create-profile"
                    onClick={()=>dispatch(Userrole("coach"))} 
                    to="/login"
                    className="theme-btn"
                  >
                    Coach
                  </Link>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
