import { createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "../../../services/userApi";
import toast, { Toaster } from "react-hot-toast";

// Contact support message

export const contactMessage = createAsyncThunk(
  "contactMessage",
  async (details) => {
    const { data } = await userApi.post("user/createContactUs", details);
    let lang = localStorage.getItem("selectedLang");
    if (data?.success) {
      toast.success(
        lang == "en" ? "Message sent successfully" : "Nachricht gesendet"
      );
    } else {
      toast.error(data?.message);
    }
    return data;
  }
);

// user Registration

export const registerUser = async (details) => {
  const { data } = await userApi.post("user/auth/register", details);

  return data;
};

// verify OTP

export const verifyOTP = async (details) => {
  const { data } = await userApi.post("user/auth/verifyOtp", details);

  return data;
};

// resend

export const resendOTP = async (details) => {
  const { data } = await userApi.post("user/auth/resendOtp", details);

  return data;
};

// login

export const userLogin = async (details) => {
  const { data } = await userApi.post("user/auth/login", details);

  return data;
};

// forget password

export const forgetPassword = async (details) => {
  const { data } = await userApi.post("user/auth/forgotPass", details);

  return data;
};

// reset password

export const resetPassword = async (details) => {
  const { data } = await userApi.put("user/auth/resetPass", details);

  return data;
};

// ================================================Meditation=======================================

// get courses category

export const getCourseCategory = async (details) => {
  const { data } = await userApi.get("user/getCourseCategories", details);

  return data;
};

// get courses category by id

export const getCourseCategorybyId = async (details) => {
  const { data } = await userApi.get(`user/getCourses?id=${details}`, details);

  return data;
};

// like meditation/course

export const manageLikes = async (details) => {
  const { data } = await userApi.post("user/manageMedicationLike", details);

  return data;
};

// get reflectio journey

export const getReflectionJourney = async (details) => {
  const { data } = await userApi.get("user/getCategoryMobileView", details);

  return data;
};

// get reflection category details

export const getreflectioncategorydetails = async (details) => {
  const { data } = await userApi.get(
    `user/getReflections?categoryId=${details}`
  );

  return data;
};

// save reflection journey

export const saveReflectionjourney = async (details) => {
  const { data } = await userApi.post("user/saveReflectionJourney", details);

  return data;
};

// get Reflection questions

export const getReflectionquestions = async (details, page) => {
  const { data } = await userApi.get(`user/getReflectionDetails?id=${details}`);

  return data;
};

// get questionAnswer

export const getQueAns = async (details, page) => {
  const { data } = await userApi.get(`user/getReflectionJourney?id=${details}`);

  return data;
};

// add story timeline

export const saveStoryTimeline = async (details) => {
  const { data } = await userApi.post("user/saveStoryTimeline", details);

  return data;
};

// edit story timeline

export const editStoryTimeline = async (details) => {
  const { data } = await userApi.post("user/editStoryTimeline", details);

  return data;
};

// save wheel of life

export const saveWheelofLife = async (details) => {
  const { data } = await userApi.post("user/saveWheelofLife", details);

  return data;
};

// coach list

export const getCoaches = async (details) => {
  let url = "user/getCoaches";

  const { data } = await userApi.get(url);

  return data;
};

// coach detail

export const getCoachDetails = async (details) => {
  const { data } = await userApi.get(`user/getCoachDetails?id=${details}`);

  return data;
};
// coach detail

export const getCoachReviews = async (details) => {
  const { data } = await userApi.get(`user/getCoachReviews?id=${details}`);

  return data;
};

// appointment time slots

export const getCoachAppointments = async (details, date) => {
  const { data } = await userApi.get(
    `user/getCoachAppointments?id=${details}&date=${date}`
  );

  return data;
};

// make appointment

export const makeAppointment = async (details) => {
  const { data } = await userApi.post(`user/makeAppointment`, details);

  return data;
};

// create payment intent

export const createPaymentIntent = async (details) => {
  const { data } = await userApi.get(
    `user/createPaymentIntent?appointment_id=${details}`
  );

  return data;
};

// Appointment confirmed

export const paymentDoneforAppointment = async (details) => {
  const { data } = await userApi.post(
    `user/paymentDoneforAppointment`,
    details
  );

  return data;
};

// get appointment list

export const getUserAppointments = async (details) => {
  const { data } = await userApi.get(
    `user/getUserAppointments?type=${details}`
  );

  return data;
};

// social login
export const socialLogin = async (details) => {
  const { data } = await userApi.post("user/auth/socialLogin", details);

  return data;
};

// Edit user profile

export const editProfile = async (details) => {
  const { data } = await userApi.post("user/editProfile", details);

  return data;
};

// change password

export const changePassword = async (details) => {
  const { data } = await userApi.put("user/changePassword", details);

  return data;
};
// favourite course list

export const getMedicationFavList = async (details) => {
  const { data } = await userApi.get("user/getMedicationFavList", details);

  return data;
};

// faq

export const getAllFaqs = async (details) => {
  const { data } = await userApi.get("user/getAllFaqs", details);

  return data;
};

// logout

export const logOut = async (details) => {
  const { data } = await userApi.get("user/logOut", details);

  return data;
};

// getInvoice

export const generateInvoice = async (details) => {
  const { data } = await userApi.get("user/generateInvoice", details);

  return data;
};

// createStripeAccount

export const createStripeAccount = async (details) => {
  const { data } = await userApi.get("user/createAccount", details);

  return data;
};
// accountdelete

export const accountdelete = async (details) => {
  const { data } = await userApi.post("user/accountdelete", details);

  return data;
};
// getNotifications

export const getNotifications = async (details) => {
  console.log(details,"details")
  const { data } = await userApi.get(`user/getNotifications?page=${details}`);

  return data;
};

// Chat

export const chat = async (details) => {
  const { data } = await userApi.post("user/chat", details);

  return data;
};

// getNotifications

export const getChats = async (details) => {

  const { data } = await userApi.get(`user/getChats?chat_id=${details}`);

  return data;
};
// getChatRooms

export const getChatRooms = async (details) => {

  const { data } = await userApi.get(`user/getChatRooms`);

  return data;
};

// cancelAppointment

export const cancelAppointment = async (details) => {

  const { data } = await userApi.post(`user/cancelAppointment`,details);

  return data;
};


// cancelAppointment

export const redeemAppointmentPayment = async (details) => {

  const { data } = await userApi.post(`user/redeemAppointmentPayment`,details);

  return data;
};
// leaveReview

export const leaveReview = async (details) => {

  const { data } = await userApi.post(`user/leaveReview`,details);

  return data;
};

// delete request from mmobile

export const SubmitDeleteRequest = createAsyncThunk(
  "SubmitDeleteRequest",
  async (details) => {
    const data = await userApi.put(
      `user/auth/delete-account`, details
    );
    return data;
  }
);

// block

export const block = async (details) => {

  const { data } = await userApi.post(`user/block`,details);

  return data;
};
// report

export const report = async (details) => {

  const { data } = await userApi.post(`user/report`,details);

  return data;
};

