import React, { useState } from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormControl, FormLabel } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useMutation } from "@tanstack/react-query";
import { paymentDoneforAppointment } from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function ConfirmPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman,"lang");
  // ===========================================get APPOINTMENT id from store==========================================

  const appointmentId = useSelector((state) => state?.coachData?.appointmentId);

  console.log(appointmentId, "id");
  const paymnetIntent = useSelector((state) => state?.coachData?.paymentIntent);

  console.log(paymnetIntent, "paymnetIntent");

  // ========================================================post data for confirm payment====================================

  const { isPending, mutate } = useMutation({
    mutationFn: paymentDoneforAppointment,
    onSuccess: (res) => {
      if (res?.success) {
        navigate("/coaching");
        dispatch(paymnetIntent(null));
      } 
      // else {
      //   toast.error(res?.message);
      // }
    },
    // onError: (res) => {
    //   toast.error(res?.message);

    // },
  });

  return (
    <>
      <LayoutNew>
        <div className="right-side" style={{ position: "relative" }}>
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )}

          <div className="main-content">
            <div className="appointment-created-img">
              <img src={require("../Assets/images/appointment-created.png")} />
            </div>
            <div className="appointment-created-txt">
              <h2>
              {
                ingerman=="de" ? "Ihr Termin hat" : "your appointment has"
              }
                
                <br />{ingerman=="de" ? "wurde bestätigt!" : 'been confirmed!'} 
              </h2>
              <p className="invoice-para">
                {/* A request has been sent to the coach. Next, the coach must
                process the request. You will receice a message as soon as the
                coach has accepted or rejected the request.
                <br />
                successfully. */}
              </p>
            </div>
            <div
              onClick={() => {
                // if (location?.state?.autoApprove == 1) {
                  mutate({
                    appointment_id: appointmentId,
                    payment_intent: paymnetIntent?.data?.id,
                    langType: ingerman == "de" ? 1 : 0,
                  });
                // }else{
                  
                // }
              }}
              className="invoice-details-link"
            >
              <Link
              // to="/coaching"
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
