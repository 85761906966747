import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function DataProtection() {
  const { t } = useTranslation();
  const DataProtection = t("DataProtection");
  console.log(DataProtection, "DataProtection");

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  return (
    <Layout>
      <section>
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="data-protection-page">
                <h2 className="text-center Platform-font">
                  {DataProtection?.header}
                </h2>
                <p className="text-center">
                  <b>{DataProtection?.date}</b>
                </p>
                <div className="">
                  <p>Dr. Christian Hoops</p>
                  <p>MindRepublic</p>
                  <p>
                    {DataProtection?.add}: {DataProtection?.Address}
                  </p>
                </div>
                <div className="">
                  <p>
                    E-Mail:{" "}
                    <Link to="mailto:support@mindrepublic.com">
                      support@mindrepublic.com
                    </Link>
                  </p>
                  <p>
                  {
                    ingerman=="de" ? "Webseite:" : " Website:"
                  }
                   {" "}
                    <Link to="https://mindrepublic.com/">www.mindrepublic.com</Link>
                  </p>
                  <p>{ingerman == "de" ? "Telefon:" : "Phone:"} 01782699448</p>
                </div>
                <div className="">
                  <h3 className="text-center">
                    {DataProtection?.PrivacyPolicy}
                  </h3>
                  <p>
                    {/* We are delighted to have your interest in our company.
                    Privacy protection is of paramount importance to the
                    management of MindRepublic. The use of MindRepublic's
                    websites is generally possible without providing any
                    personal data. However, if an individual wishes to utilize
                    specific services offered by our company through our
                    website, the processing of personal data may become
                    necessary. If the processing of personal data is necessary
                    and there is no legal basis for such processing, we
                    generally seek the consent of the individual concerned. */}
                    {DataProtection?.p1}
                  </p>
                  <p>
                    {/* The processing of personal data, such as the name, address,
                    email address, or telephone number of an individual, always
                    takes place in accordance with the General Data Protection
                    Regulation (GDPR) and in compliance with the
                    country-specific data protection regulations applicable to
                    MindRepublic. Through this Privacy Policy, MindRepublic aims
                    to inform the public about the nature, scope, and purpose of
                    the personal data we collect, use, and process. Furthermore,
                    this Privacy Policy enlightens individuals about their
                    rights. */}
                    {DataProtection?.p2}
                  </p>
                  <p>
                    {/* MindRepublic, as the data controller, has implemented
                    numerous technical and organizational measures to ensure the
                    most comprehensive protection of the personal data processed
                    through this website. However, internet-based data
                    transmissions may in principle have security gaps, so
                    absolute protection cannot be guaranteed. For this reason,
                    it is open to any individual to transmit personal data to us
                    via alternative means, such as by phone. */}
                    {DataProtection?.p3}
                  </p>
                  <ol>
                    <li>
                      <b>{DataProtection?.Definitions?.Definitions}</b>

                      <p>{DataProtection?.Definitions?.p1}</p>
                      <p>{DataProtection?.Definitions?.p2}</p>
                      <ul>
                        <li>
                          <b>{DataProtection?.PersonalData?.PersonalData}</b>
                        </li>
                        <p>{DataProtection?.PersonalData?.p1}</p>

                        <li>
                          <b>{DataProtection?.DataSubject?.DataSubject}</b>
                        </li>
                        <p>{DataProtection?.DataSubject?.p1}</p>

                        <li>
                          <b>{DataProtection?.Processing?.Processing}</b>
                        </li>
                        <p>{DataProtection?.Processing?.p1}</p>
                        <li>
                          <b>
                            {
                              DataProtection?.RestrictionofProcessing
                                ?.RestrictionProcessing
                            }{" "}
                          </b>
                        </li>
                        <p>{DataProtection?.RestrictionofProcessing?.p1}</p>
                        <li>
                          <b>{DataProtection?.Profiling?.Profiling} </b>
                        </li>
                        <p>{DataProtection?.Profiling?.p1}</p>
                        <li>
                          <b>
                            {DataProtection?.Pseudonymization?.Pseudonymization}{" "}
                          </b>
                        </li>
                        <p>{DataProtection?.Pseudonymization?.p1}</p>
                        <li>
                          <b>{DataProtection?.Controller?.Controller} </b>
                        </li>
                        <p>{DataProtection?.Controller?.p1}</p>
                        <li>
                          <b>{DataProtection?.Processor?.Processor}</b>
                        </li>
                        <p>{DataProtection?.Processor?.p1}</p>
                        <li>
                          <b>{DataProtection?.Recipient?.Recipient}</b>
                        </li>
                        <p>{DataProtection?.Recipient?.p1}</p>
                        <li>
                          <b>{DataProtection?.ThirdParty?.ThirdParty}</b>
                        </li>
                        <p>{DataProtection?.ThirdParty?.p1}</p>
                        <li>
                          <b>{DataProtection?.Consent?.Consent}</b>
                        </li>
                        <p>{DataProtection?.Consent?.p1}</p>
                      </ul>
                    </li>

                    <li>
                      <b>
                        {
                          DataProtection
                            ?.NameandAddressoftheDataProtectionOfficer
                            ?.NameandAddressoftheDataProtectionOfficer
                        }
                      </b>
                    </li>
                    <p>
                      {
                        DataProtection?.NameandAddressoftheDataProtectionOfficer
                          ?.l1
                      }
                    </p>
                    <p>
                      Dr. Christian Hoops MindRepublic Postal{" "}
                      {
                        DataProtection?.NameandAddressoftheDataProtectionOfficer
                          ?.add
                      }
                      :
                      {
                        DataProtection?.NameandAddressoftheDataProtectionOfficer
                          ?.address
                      }{" "}
                      Email:{" "}
                      <Link to="mailto:support@mindrepublic.com">
                        support@mindrepublic.com
                      </Link>{" "}
                      Website:{" "}
                      <Link to="https://mindrepublic.com/">
                        www.mindrepublic.com
                      </Link>
                    </p>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? "Name und Anschrift des Datenschutzbeauftragten"
                          : `Name and Address of the Data Protection Officer`}
                      </b>
                    </li>
                    <p>
                      {ingerman == "de"
                        ? `Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist:`
                        : `The data protection officer of the data controller is:`}
                    </p>
                    <p>Dr. Christian Hoops {ingerman=="de" ? `Angaben wie oben.` : `Details as above.`}</p>
                    <p>
                      {ingerman == "de"
                        ? `Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an
unseren Datenschutzbeauftragten wenden.
`
                        : `Any data subject can contact our data protection officer
                      directly at any time with all questions and suggestions
                      regarding data protection.`}
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection?.Contactpossibilityviathewebsite
                            ?.Contactpossibilityviathewebsite
                        }
                      </b>
                    </li>
                    <p>{DataProtection?.Contactpossibilityviathewebsite?.p1}</p>
                    <li>
                      <b>
                        {
                          DataProtection?.CoachingbyMindRepublic
                            ?.CoachingbyMindRepublic
                        }{" "}
                      </b>
                    </li>
                    <p>{DataProtection?.CoachingbyMindRepublic?.p1}</p>
                    <p>{DataProtection?.CoachingbyMindRepublic?.p2}</p>
                    <li>
                      <b>
                        {
                          DataProtection?.ReflectionJourneysbyMindRepublic
                            ?.ReflectionJourneysbyMindRepublic
                        }
                      </b>
                    </li>
                    <p>
                      {DataProtection?.ReflectionJourneysbyMindRepublic?.p1}
                    </p>
                    <p>
                      {DataProtection?.ReflectionJourneysbyMindRepublic?.p2}
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection?.MessengerbyMindRepublic
                            ?.MessengerbyMindRepublic
                        }{" "}
                      </b>
                    </li>
                    <p>{DataProtection?.MessengerbyMindRepublic?.p1}</p>
                    <p>{DataProtection?.MessengerbyMindRepublic?.p2}</p>
                    <li>
                      <b>
                        {
                          DataProtection?.ListofAvailableCoachesMindRepublic
                            ?.ListofAvailableCoachesMindRepublic
                        }
                      </b>
                      <p>
                        {DataProtection?.ListofAvailableCoachesMindRepublic?.p1}
                      </p>
                      <p>
                        {DataProtection?.ListofAvailableCoachesMindRepublic?.p2}
                      </p>
                    </li>
                    <li>
                      <b>
                        {
                          DataProtection?.StripeasPaymentProcessor
                            ?.StripeasPaymentProcessor
                        }{" "}
                      </b>
                    </li>
                    <p>
                      {DataProtection?.StripeasPaymentProcessor?.p1}(
                      <Link to="https://www.stripe.de">
                        https://www.stripe.de
                      </Link>
                      ){DataProtection?.StripeasPaymentProcessor?.p2}
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection
                            ?.RoutineDeletionandBlockingofPersonalData
                            ?.RoutineDeletionandBlockingofPersonalData
                        }
                      </b>
                    </li>
                    <p>
                      {
                        DataProtection?.RoutineDeletionandBlockingofPersonalData
                          ?.p1
                      }
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection?.RightsoftheDataSubject
                            ?.RightsoftheDataSubject
                        }
                      </b>
                      <ul>
                        <li>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoConfirmation?.RighttoConfirmation
                            }
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoConfirmation?.p1
                          }
                        </p>
                        <li>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.RighttoInformation
                            }{" "}
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoInformation?.l1
                          }
                        </p>
                        <ul className="list-style-dot">
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l2
                            }
                          </li>

                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l3
                            }{" "}
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l4
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l5
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l6
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l7
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l8
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l12
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoInformation?.l13
                            }
                          </li>

                          {
                            ingerman=="de" && `Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu
jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
`
                          }
                        </ul>
                        <li style={{ marginTop: "20px" }}>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoRectification?.RighttoRectification
                            }{" "}
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoRectification?.p1
                          }
                        </p>
                        <li>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoErasure?.RighttoErasure
                            }{" "}
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoErasure?.p1
                          }
                        </p>
                        <ul className="list-style-dot">
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoErasure?.p2
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoErasure?.p3
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoErasure?.p4
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoErasure?.p5
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoErasure?.p6
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoErasure?.p7
                            }
                          </li>
                          {
                            ingerman=="de" && <li>
                          {
                            ingerman=="de" && `Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von
personenbezogenen Daten, die bei von MindRepublic gespeichert sind, veranlassen möchte, kann sie
sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der
Mitarbeiter von MindRepublic wird veranlassen, dass dem Löschverlangen unverzüglich
nachgekommen wird.`

                          }
                            
                          </li>
                          }
                          
                        </ul>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoErasure?.p9
                          }
                        </p>

                        <li>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoRestrictionofProcessing
                                ?.RighttoRestrictionofProcessing
                            }
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoRestrictionofProcessing?.l1
                          }
                        </p>
                        <ul className="list-style-dot">
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoRestrictionofProcessing?.l2
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoRestrictionofProcessing?.l3
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoRestrictionofProcessing?.l4
                            }
                          </li>
                          <li>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoRestrictionofProcessing?.l5
                            }
                          </li>
                        </ul>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoRestrictionofProcessing?.l6
                          }
                        </p>
                        <li>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoDataPortability?.RighttoDataPortability
                            }
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoDataPortability?.l1
                          }
                        </p>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoDataPortability?.l2
                          }
                        </p>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoDataPortability?.l3
                          }
                        </p>
                        <li>
                          <b>
                            {" "}
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoObject?.RighttoObject
                            }
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoObject?.l1
                          }
                        </p>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoObject?.l2
                          }
                        </p>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoObject?.l3
                          }
                        </p>
                        {
                          ingerman=="de" &&  <p>
                          Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
gegen die sie betreffende Verarbeitung personenbezogener Daten, die bei von MindRepublic zu
wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs.
1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im
öffentlichen Interesse liegenden Aufgabe erforderlich.

                        </p>
                        }
                       
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoObject?.l4
                          }
                        </p>
                        <li>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.AutomatedIndividualDecision
                                ?.AutomatedIndividualDecision
                            }
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.AutomatedIndividualDecision?.l1
                          }
                        </p>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.AutomatedIndividualDecision?.l2
                          }
                        </p>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.AutomatedIndividualDecision?.l3
                          }
                        </p>
                        <li>
                          <b>
                            {
                              DataProtection?.RightsoftheDataSubject
                                ?.RighttoWithdrawDataProtectionConsent
                                ?.RighttoWithdrawDataProtectionConsent
                            }
                          </b>
                        </li>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoWithdrawDataProtectionConsent?.l1
                          }
                        </p>
                        <p>
                          {
                            DataProtection?.RightsoftheDataSubject
                              ?.RighttoWithdrawDataProtectionConsent?.l2
                          }
                        </p>
                      </ul>
                    </li>

                    <li>
                      <b>
                        {" "}
                        {
                          DataProtection?.RightsoftheDataSubject
                            ?.PrivacyPolicyfortheUseofLinkedIn
                            ?.PrivacyPolicyfortheUseofLinkedIn
                        }
                      </b>
                    </li>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l1
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l2
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l3
                      }
                      <Link to="https://developer.linkedin.com/plugins">
                        https://developer.linkedin.com/plugins
                      </Link>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l4
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l5
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l6
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l8
                      }
                      <Link to="https://www.linkedin.com/psettings/guest-controls">
                        https://www.linkedin.com/psettings/guest-controls
                      </Link>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l9
                      }
                      <Link to="https://www.linkedin.com/legal/cookie-policy">
                        https://www.linkedin.com/legal/cookie-policy
                      </Link>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l10
                      }
                      <Link to="https://www.linkedin.com/legal/privacy-policy">
                        https://www.linkedin.com/legal/privacy-policy
                      </Link>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.PrivacyPolicyfortheUseofLinkedIn?.l11
                      }
                      <Link to="https://www.linkedin.com/legal/cookie-policy ">
                        https://www.linkedin.com/legal/cookie-policy 
                      </Link>
                      {
                        ingerman=="de" &&  <span>{" "}abrufbar.</span>
                      }
                    
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection?.RightsoftheDataSubject
                            ?.DataprotectionYouTube?.DataprotectionYouTube
                        }
                      </b>
                    </li>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l1
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l2
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l3
                      }
                      <Link to="https://www.youtube.com/yt/about/en/">
                        https://www.youtube.com/yt/about/en/
                      </Link>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l4
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l5
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l6
                      }
                    </p>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l7
                      }
                      <Link to="https://www.google.com/intl/en/policies/privacy/">
                        https://www.google.com/intl/en/policies/privacy/
                      </Link>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.DataprotectionYouTube?.l8
                      }
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection?.RightsoftheDataSubject?.Legalbasis
                            ?.Legalbasis
                        }
                      </b>
                    </li>
                    <p>
                      {DataProtection?.RightsoftheDataSubject?.Legalbasis?.l1}
                    </p>
                    <p>
                      {DataProtection?.RightsoftheDataSubject?.Legalbasis?.l2}
                    </p>
                    <p>
                      {DataProtection?.RightsoftheDataSubject?.Legalbasis?.l3}
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection?.RightsoftheDataSubject
                            ?.Thelegitimateinterests?.Thelegitimateinterests
                        }
                      </b>
                    </li>
                    <p>
                      {
                        DataProtection?.RightsoftheDataSubject
                          ?.Thelegitimateinterests?.l1
                      }
                    </p>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? "Dauer, für die die personenbezogenen Daten gespeichert werden"
                          : "Period for which the personal data will be stored"}{" "}
                      </b>
                    </li>
                    <p>
                      {ingerman == "de"
                        ? `Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche
Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht,
sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.`
                        : "The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no longer necessary for the fulfillment of the contract or the initiation of a contract."}
                    </p>
                    <li>
                      <b>
                        {
                          DataProtection?.RightsoftheDataSubject?.provision
                            ?.provision
                        }
                      </b>
                    </li>
                    <p>
                      {DataProtection?.RightsoftheDataSubject?.provision?.l1}
                    </p>
                    <p>
                      {DataProtection?.RightsoftheDataSubject?.provision?.l2}
                    </p>
                    <p>
                      {DataProtection?.RightsoftheDataSubject?.provision?.l3}
                    </p>
                    <li>
                      <b>
                        {" "}
                        {
                          DataProtection?.RightsoftheDataSubject?.Existence
                            ?.Existence
                        }
                      </b>
                    </li>
                    <p>
                      {DataProtection?.RightsoftheDataSubject?.Existence?.l1}
                    </p>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
