// src/components/GoogleLogin.js
import React from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "./Firebase";
import { socialLogin } from "../Redux/Actions/userActions"; // Adjust the path as needed
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Userdata } from "../Redux/Reducers/registrationSlice";
import { useDispatch, useSelector } from "react-redux";
import { coachsocialLogin } from "../Redux/Actions/coachAction";
const GoogleLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // =======================================check for userrole==============================

  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending, mutate } = useMutation({
    mutationFn: userrole == "user" ? socialLogin : coachsocialLogin,
    onSuccess: (res) => {
      console.log(res, "social");
      if (res?.success) {
        sessionStorage.setItem("token", res?.data?.token);
        dispatch(Userdata(res?.data));
        toast.success(res?.message);
        if (res?.success || res.message == "Please verify your account first") {
          // res?.data?.role== 0 ?  navigate("/get-started") : ""
  
          if (res?.data?.email_verified == 0) {
            navigate("/verify-email");
          } else {
            toast.success(res?.message);
            if (userrole == "coach" && res?.data?.profile_status == 0) {
               window.location.href ="/create-profile";
            } else if(userrole == "coach" && res?.data?.profile_status == 1){
              window.location.href ="/get-started-coach";
            }
            else {
              window.location.href = "/get-started";
            }
          }
        } else {
          toast.error(res?.message);
        }
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("hii");
    try {
      console.log("first");
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User Info: ", result?.user);
      if (result?.user) {
        mutate({
          social_id: result?.user?.uid,
          full_name: result?.user?.displayName,
          login_type: 2,
          device_token: result?.user?.accessToken,
          device_type: 2,
          email: result?.user?.email,
        });
      }
    } catch (error) {
      console.error("Error during login: ", error.message);
    }
  };

  return (
    <div>
      <div  style={{ cursor: "pointer" }} onClick={handleLogin}>
        <img style={{height:"50px"}} src={require("../Assets/images/google.png")}></img>
      </div>
    </div>
  );
};

export default GoogleLogin;
