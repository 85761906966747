import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import {
  Container,
  Row,
  Col,
  FormControl,
  Button,
  InputGroup,
} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutCoach from "../Components/LayoutCoach";
import countries from "../Utils/countryCode.json";
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  coachLocationId,
  coachRegistrationdata,
} from "../Redux/Reducers/coachRegistrationSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function CreateProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);


  // =====================================get data from redux=============================

  const submittedData = useSelector((state)=>state.coachregistrationData.coachregister)
  console.log(submittedData,"submitteddata")
  const locationID = useSelector((state)=>state.coachregistrationData.locationId)
  console.log(locationID,"ID")

  // ========================================FLAGS===========================================

  const options = countries.map((country) => ({
    value: country.dial_code,
    label: (
      <>
        <ReactCountryFlag
          countryCode={country.code}
          svg
          style={{
            marginRight: "10px",
            width: "20px",
            height: "auto",
          }}
          title={country.name}
          placeholder="heii"
        />
        {country.name} ({country.dial_code})
      </>
    ),
  }));

  //==============================Find the German country object=======================

  const germanyOption = options.find((option) => option.value === "+49");

  // // ===============================================dtore place=======================================================

  // const initialPlace = {
  //   value: {
  //     description: submittedData?.location?.label|| "",
  //     place_id:locationID ||  "",
  //   },
  //   label:submittedData?.location?.label || "",
  // };

  // =================================================REACT STATES======================================================

  const [profilePic, setprofilePic] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [countryCode, setCountryCode] = useState(germanyOption?.value);
  const [phoneNumber, setphoneNumber] = useState("");
  const [language, setlanguage] = useState(null);
  const [location, setlocation] = useState(null);
  const [prev, setprev] = useState(null);

  console.log(countryCode, "");


  useEffect(()=>{
 

    if(submittedData){
      setSelectedOption(submittedData?.gender)
      setCountryCode(submittedData?.country_code)
      setphoneNumber(submittedData?.phone_number)
      setlanguage(submittedData?.language)
      if(submittedData?.profile_image){
        setprev(URL?.createObjectURL(submittedData?.profile_image))

      }else{
        return
      }
      setprofilePic(submittedData?.profile_image)
     
      setlocation(submittedData?.location)
    }

  },[submittedData])

  // =============================================handlers==========================================================

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePic = (e) => {
    console.log(e.target.files[0])
    setprofilePic(e.target.files[0]);
    setprev(URL?.createObjectURL(e?.target?.files[0]));
  };

  console.log(profilePic,"pic")

  const handlePlaceSelect = (e) => {
  
    setlocation(e.target.value);
    // // Patch the selected value, e.g., send to an API
    // patchSelectedPlace(place);
  };

  console.log(location,"loc")

  return (
    <>
      {/* <LayoutCoach> */}
      <div className="right-side w-100">
        {/* <div className="back-page-i">
            <Link to="/my-profile">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div> */}

        <div className="main-content">
          <h2>Create Profile</h2>
          <div className="coach-details">
            <Row>
              <Col lg={6}>
                <div className="edit-profile-box">
                  <img
                    src={
                      prev ? prev : require("../Assets/images/place_holder.png")
                    }
                  />
                  <div className="change-img-icon">
                    <div className="camera-icon">
                      <svg
                        width="106"
                        height="106"
                        viewBox="0 0 106 106"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="106" height="106" rx="53" fill="#B79C6C" />
                        <path
                          d="M77.2918 68.3333C77.2918 69.5047 76.8265 70.6281 75.9982 71.4564C75.1699 72.2847 74.0465 72.75 72.8752 72.75H33.1252C31.9538 72.75 30.8304 72.2847 30.0021 71.4564C29.1738 70.6281 28.7085 69.5047 28.7085 68.3333V44.0417C28.7085 42.8703 29.1738 41.7469 30.0021 40.9186C30.8304 40.0903 31.9538 39.625 33.1252 39.625H41.9585L46.3752 33H59.6252L64.0418 39.625H72.8752C74.0465 39.625 75.1699 40.0903 75.9982 40.9186C76.8265 41.7469 77.2918 42.8703 77.2918 44.0417V68.3333Z"
                          stroke="white"
                          stroke-width="3.3125"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M52.9998 63.9167C57.8784 63.9167 61.8332 59.9618 61.8332 55.0833C61.8332 50.2048 57.8784 46.25 52.9998 46.25C48.1213 46.25 44.1665 50.2048 44.1665 55.0833C44.1665 59.9618 48.1213 63.9167 52.9998 63.9167Z"
                          stroke="white"
                          stroke-width="3.3125"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <Form.Control
                        // value={profilePic}
                        // name="profilePic"
                        onChange={(e) => handlePic(e)}
                        type="file"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-field-list">
                  <Form>
                    <Form.Label className="label-text">Gender </Form.Label>
                    <div className="availability-days gander-select">
                      <Form.Label
                        className={`radio-button-label ${
                          selectedOption == "1" && "selected"
                        }`}
                      >
                        <Form.Control
                          //  onChange={(e)=>setemail(e.target.value)}
                          type="radio"
                          value="1"
                          checked={selectedOption == "1"}
                          onChange={handleOptionChange}
                        />
                        Male
                      </Form.Label>
                      <Form.Label
                        className={`radio-button-label ${
                          selectedOption == "0" && "selected"
                        }`}
                      >
                        <Form.Control
                          type="radio"
                          value="0"
                          checked={selectedOption == "0"}
                          onChange={handleOptionChange}
                        />
                        Female
                      </Form.Label>
                      <Form.Label
                        className={`radio-button-label ${
                          selectedOption == "2" && "selected"
                        }`}
                      >
                        <Form.Control
                          type="radio"
                          value="2"
                          checked={selectedOption == "2"}
                          onChange={handleOptionChange}
                        />
                        Prefer not to say
                      </Form.Label>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label className="label-text">
                        Phone Number{" "}
                      </Form.Label>
                      <InputGroup className="mb-3 ad-height-input conutry-selects country-border">
                        <InputGroup.Text id="basic-addon3" className="">
                          <Select 
                            defaultValue={countryCode}
                            onChange={(e) => setCountryCode(e?.value)}
                            options={options}
                            value={options.find(
                              (option) => option.value === countryCode
                            )}
                            name="countryCode"
                          />
                        </InputGroup.Text>
                        <Form.Control className="border-none"
                          name="phoneNumber"
                          value={phoneNumber}
               
                          type="text"
                          aria-describedby="basic-addon3"
                          onChange={(e) => setphoneNumber(e.target.value)}
                        />
                      </InputGroup>

                      {/* <Form.Control   type="number" placeholder="1234567890" /> */}
                    </Form.Group>
                    <Form.Label className="label-text">
                      Choose a Preferred Language{" "}
                    </Form.Label>
                    <Form.Group className="mb-3">
                      <InputGroup className="mb-3 ad-height-input conutry-selects border-15-radius">
                        <InputGroup.Text id="basic-addon3"></InputGroup.Text>

                        <Form.Control
                          name="language"
                          value={language}
                          
                          // id="basic-url"
                          type="text"
                          onChange={(e) => setlanguage(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox"
                    ></Form.Group>
                    <Form.Group className="mb-3 border-radius-15">
                      <div>
                        <Form.Label className="label-text">
                          Location{" "}
                        </Form.Label>
                        {/* <GooglePlacesAutocomplete
                          value={location}
                          selectProps={{
                            value: location,
                            onChange: handlePlaceSelect,
                            placeholder: "Location",
                          }}
                          // selectProps={{
                          //   location,
                          //   onChange: setlocation,
                          // }}
                          onError={(status, clearSuggestions) =>
                            console.log("Error occurred: ", status)
                          }
                          debounce={300}
                          searchOptions={{
                            types: ["address"],
                            componentRestrictions: { country: "in" }, // Adjust as needed
                          }}
                        /> */}

                        <Form.Control
                            
                           
                            id="basic-url"
                            type="text"
                            aria-describedby="basic-addon3"
                           
                            onChange= {handlePlaceSelect}
                            value={location}
                            
                          />
                      </div>
                      {/* <Form.Select>
                          <option>Choose a Preferred Language</option>
                          <option>Disabled select</option>
                        </Form.Select> */}
                    </Form.Group>
                    <div
                      onClick={() => {
                        let isvalid = true;

                        if (!profilePic) {
                          toast.error("Please upload a Picture");
                          isvalid = false;
                        } else if (!selectedOption) {
                          toast.error("Please Select gender");
                          isvalid = false;
                        } else if (!phoneNumber) {
                          toast.error("Please Enter Phone Number");
                          isvalid = false;
                        } else if (!language) {
                          toast.error("Please Enter the language");
                          isvalid = false;
                        } else if (!location) {
                          toast.error("Please Enter the location");
                          isvalid = false;
                        } else if(isvalid) {
                          dispatch(
                            coachRegistrationdata({
                              profile_image: profilePic,
                              gender: selectedOption,
                              country_code: countryCode,
                              phone_number: phoneNumber,
                              language: language,
                              location: location,
                            })
                          );
                          dispatch(coachLocationId(location?.value?.place_id));
                          navigate("/upload-certificate");
                        }
                      }}
                    >
                      <Link
                        // to="/upload-certificate"
                        variant="primary"
                        className="field-list-btn"
                        // type="submit"
                      >
                        Next
                      </Link>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* </LayoutCoach> */}
    </>
  );
}
