import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import LayoutCoach from "../Components/LayoutCoach";

export default function ChangePasswordCoach() {
  return (
    <>
      <LayoutCoach>
        <div className="right-side">
          <div className="back-page-i">
            <Link to="/settings-coach">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>Change Password</h2>

            <div className="add-life-event">
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Old Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Type here..."
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">New Password</Form.Label>
                <Form.Control type="password" placeholder="Type here..." />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="Type here..." />
              </Form.Group>
              <div className="bck-nxt-btns justify-content-end mt-5">
                <Link to="">Change Password</Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutCoach>
    </>
  );
}
