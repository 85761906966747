import { createSlice } from "@reduxjs/toolkit";


export const coachSlice = createSlice({
  name: "coachSlice",
  initialState: {
    list: null,
    id: null,
    details:null,
    appointmentId:null,
    appointmentData:null,
    paymentIntent:null
  },
  reducers: {
    coachList: (state, action) => {
      console.log(action, "action");
      state.list = action.payload;
    },
    coachId: (state, action) => {
      console.log(action, "action");
      state.id = action.payload;
    },
    coachDetails: (state, action) => {
      console.log(action, "action");
      state.details = action.payload;
    },
    appointmentID: (state, action) => {
      console.log(action, "action");
      state.appointmentId = action.payload;
    },
    appointmentData:(state, action) => {
      console.log(action, "action");
      state.appointmentData = action.payload;
    },
    
    paymentIntent:(state, action) => {
      console.log(action, "action");
      state.paymentIntent = action.payload;
    },
    
  },
});

export const { coachList, coachId,coachDetails,appointmentID,appointmentData,paymentIntent } = coachSlice.actions;

export default coachSlice.reducer;
