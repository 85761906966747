import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

export default function AccountCreatedSign() {



  
  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman,"lang");



  const { t } = useTranslation();
  const AccountCreated = t("AccountCreated");
  console.log(AccountCreated);

  return (
    <section className="Login-sec">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                <div className="sign-up-logo">
                  <img src={require("../Assets/images/account-created.png")} />
                </div>
                <h2>{AccountCreated?.created}</h2>
                <p>{AccountCreated?.success}</p>
              </div>
              <Form className="contact-form">
                <div className="d-flex justify-content-center">
                  <Link
                    onClick={() => {
                      window.location.href = "/get-started";
                    }}
                    variant="primary"
                    type="submit"
                    className="theme-btn"
                  >
                   {ingerman=="de" ? "Erledigt" :"Done"} 
                  </Link>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
