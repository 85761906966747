import React, { useState } from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormControl, FormLabel } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useMutation } from "@tanstack/react-query";
import { paymentDoneforAppointment } from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function AppointmentCreated() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // ===========================================get APPOINTMENT id from store==========================================

  const appointmentId = useSelector((state) => state?.coachData?.appointmentId);

  console.log(appointmentId, "id");

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman,"lang");
 

  


 

  return (
    <>
      <LayoutNew>
        <div className="right-side" >
         
          <div className="main-content">
            <div className="appointment-created-img">
              <img src={require("../Assets/images/appointment-created.png")} />
            </div>
            <div className="appointment-created-txt">
              <h2>
              {ingerman=="de" ? "Ihr Termin hat" : "your appointment has"}  
                <br />{ingerman=="de" ? 'wurde erstellt!' : "been created!"}
              </h2>
              <p className="">
              {
                ingerman=="de" ? `Eine Anfrage wurde an den Trainer gesendet. Als nächstes muss der Trainer
                die Anfrage bearbeiten. Sie erhalten eine Nachricht, sobald die
                Der Trainer hat die Anfrage angenommen oder abgelehnt.` : ` A request has been sent to the coach. Next, the coach must
                process the request. You will receice a message as soon as the
                coach has accepted or rejected the request.`
              }
                <br />
               
              </p>
            </div>
            <div
              onClick={() => {
                if (location?.state?.autoApprove == 1) {
                  // mutate({
                  //   appointment_id: appointmentId,
                  //   payment_intent: paymnetIntent?.data?.id,
                  //   langType: 0,
                  // });
                  navigate("/coaching");
                  // dispatch(paymnetIntent(null));
                }else{
                  navigate("/coaching");
                }
              }}
              className="invoice-details-link"
            >
              <Link
              // to="/coaching"
              >
                Done
              </Link>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
