import React, { useState } from "react";
import Layout from "../Components/Layout";
import {
  Container,
  Row,
  Col,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutNew from "../Components/LayoutNew";
import { useDispatch, useSelector } from "react-redux";
import { getCoachDetails, getCoachReviews } from "../Redux/Actions/userActions";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  appointmentData,
  appointmentID,
  coachDetails,
} from "../Redux/Reducers/CoachSlice";
import RatingComponent from "../Utils/ratingStars";
import moment from "moment/moment";

export default function AppointmentDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate = useNavigate();

  console.log(location);

  const handlePayment = () => {
    // console.log(coachdetails);
    dispatch(appointmentID(location?.state?.appointmentId));
    dispatch(coachDetails(location?.state?.coachDetails));
    dispatch(appointmentData(location?.state?.createdAt));
    // console.log(status, "status");
    // if (status == 3) {
    navigate("/invoice-details",{
        state:{
            back:"back"
            
        }
        
    });
    // }
  };

  return (
    <>
      <LayoutNew>
        <div style={{ position: "relative" }} className="right-side">
          <div onClick={() => navigate(-1)} className="back-page-i">
            <Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>Requested Details</h2>
            <div className="coach-details">
              <Row>
                <Col lg={6}>
                  <div className="coach-profle-img">
                    <img src={`${BASE_URL}${location?.state?.img}`} />
                    <div className="coach-profle-img-txt">
                      <h3>{location?.state?.name}</h3>
                      {/* <p>{data?.data?.coachDetails?.coachTitle}</p> */}
                      {/* <p>MBSR Trainer/Relaxation Educator</p> */}
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="coach-profile-info">
                    <div className="basic-details">
                      <div className="left-side">
                        <h3>{location?.state?.name}</h3>
                        <p>
                          {location?.state?.country_code}{" "}
                          {location?.state?.phone_number} at{" "}
                          {moment(location?.state?.createdAt).format("hh:mm A")}
                        </p>

                        <div className="coach-pro-rate"></div>
                      </div>
                      <div className="info-right-side">
                        {/* <h3>€ {data?.data?.coachDetails?.rateperhour}/h</h3> */}
                        <p>
                          {/* {data?.data?.coachDetails?.free_30_minutes == 1 && (
                            <p>30 Min Free</p>
                          )}{" "} */}
                        </p>
                        {/* <Link to="">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M12.5 0C15.6788 0 18.7388 1.25994 20.9828 3.51582C23.2388 5.77291 24.5 8.81955 24.5 12.0114C24.5 16.2112 22.2908 20.111 18.6908 22.2829C15.0908 24.4548 10.6148 24.5748 6.896 22.5949H6.8588C6.5 22.4509 6.2108 22.1989 5.8748 22.0309C5.504 21.8749 5.0828 21.8509 4.7 21.9721C3.8108 22.2829 2.9108 22.5349 1.9868 22.7508C1.5068 22.7628 1.3628 22.4749 1.3628 22.0069C1.5788 21.0589 1.8668 20.1218 2.204 19.211C2.3348 18.8151 2.3 18.3951 2.096 18.0219L1.856 17.5551C0.9668 15.8632 0.5 13.9793 0.5 12.0726V11.9994C0.5 8.81955 1.7588 5.75971 4.0148 3.51582C6.2708 1.25994 9.3188 0 12.5 0ZM18.0308 10.4755C17.1908 10.4755 16.496 11.1594 16.496 12.0114C16.496 12.8514 17.1908 13.5473 18.0308 13.5473C18.8828 13.5473 19.5668 12.8514 19.5668 12.0114C19.5668 11.1594 18.8828 10.4755 18.0308 10.4755ZM12.5 10.4755C11.648 10.4755 10.9628 11.1594 10.9628 12.0114C10.9628 12.8514 11.648 13.5473 12.5 13.5473C13.352 13.5473 14.0348 12.8514 14.0348 12.0114C14.0348 11.1594 13.352 10.4755 12.5 10.4755ZM6.9668 10.4755C6.1148 10.4755 5.4308 11.1594 5.4308 12.0114C5.4308 12.8514 6.1148 13.5473 6.9668 13.5473C7.8068 13.5473 8.504 12.8514 8.504 12.0114C8.504 11.1594 7.8068 10.4755 6.9668 10.4755Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                          Chat Now
                        </Link> */}
                      </div>
                    </div>
                    <div className="biography-details">
                      {/* <h3>Biography</h3> */}
                    </div>
                    <hr />
                    <div className="availability-details">
                      <h3>Requested Details</h3>
                      <div className="availability-days">
                        <ul>
                          <li>
                            <h4>Date</h4>
                            <p style={{ width: "120px" }}>
                              {moment(location?.state?.createdAt).format(
                                "DD MMMM YYYY"
                              )}
                            </p>
                          </li>
                          <li>
                            <h4>Time</h4>
                            <p style={{ width: "120px" }}>
                              {location?.state?.slot}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr />
                    <div className="reviews-details"></div>
                    <div className="make-appointment">
                      {location?.state?.status == 0 ? (
                        <h3>Not Accepted</h3>
                      ) : location?.state?.status == 1 ? (
                        <h3>Accepted</h3>
                      ) : location?.state?.status == 2 ? (
                        <h3>Rejected</h3>
                      ) : location?.state?.status == 3 ? (
                        <div onClick={handlePayment}>
                          <Link>Pay</Link>
                        </div>
                      ) : location?.state?.status == 4 ? (
                        <h3>Payment Done</h3>
                      ) : (
                        <h3>Cancel Request</h3>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
