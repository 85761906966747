import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutCoach from "../Components/LayoutCoach";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  createProfile,
  editcoachProfile,
  getUserProfile,
} from "../Redux/Actions/coachAction";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  registrationData,
  Userdata,
} from "../Redux/Reducers/registrationSlice";
import { coachRegistrationdata } from "../Redux/Reducers/coachRegistrationSlice";

export default function TimeAvailability() {
  const days = [
    {
      day: "All Days",
      timeSlot: [
        "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
    {
      day: "Monday",
      timeSlot: [
        "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
    {
      day: "Tuesday",
      timeSlot: [
        "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
    {
      day: "Wednesday",
      timeSlot: [
        "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
    {
      day: "Thursday",
      timeSlot: [
       "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
    {
      day: "Friday",
      timeSlot: [
       "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
    {
      day: "Saturday",
      timeSlot: [
       "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
    {
      day: "Sunday",
      timeSlot: [
        "00:00-01:00",
        "01:00-02:00",
        "02:00-03:00",
        "03:00-04:00",
        "04:00-05:00",
        "05:00-06:00",
        "06:00-07:00",
        "07:00-08:00",
        "08:00-09:00",
        "09:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
        "20:00-21:00",
        "21:00-22:00",
        "22:00-23:00",
      ],
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location, "loc");

  
  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);



  const dayTranslations = {
    Monday: ingerman === "de" ? "Montag" : "Monday",
    Tuesday: ingerman === "de" ? "Dienstag" : "Tuesday",
    Wednesday: ingerman === "de" ? "Mittwoch" : "Wednesday",
    Thursday: ingerman === "de" ? "Donnerstag" : "Thursday",
    Friday: ingerman === "de" ? "Freitag" : "Friday",
    Saturday: ingerman === "de" ? "Samstag" : "Saturday",
    Sunday: ingerman === "de" ? "Sonntag" : "Sunday",
    "All Days": ingerman === "de" ? "Alle Tage" : "All Days",
    // ...
  };

    

  // ================================================get user data from redux===========================================

  const userData = useSelector((state) => state?.registerUserdata?.user);

  console.log(userData, "data");

  // ===============================================coach data from redux=======================================

  const coachData = useSelector(
    (state) => state.coachregistrationData.coachregister
  );

  console.log(coachData, "coach");

  // =================================post data using react-query=====================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: createProfile,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        toast.success(res?.message);
        navigate(
          "/account-created"
          //    {
          //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
          // }
        );
        dispatch(coachRegistrationdata(null));
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // =================================post data using react-query=====================

  const { isPending: editPending, mutate: editmutate } = useMutation({
    mutationFn: editcoachProfile,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        toast.success(res?.message);
        navigate("/my-profile-coach");
        // navigate(
        //   "/verify-email"
        //   //    {
        //   //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
        //   // }
        // );
        // dispatch(registrationData({}))
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  //  ==============================================logic========================================

  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTime, setSelectedTime] = useState({});
  const [result, setResult] = useState([]);
  const [task, setTask] = useState(null);

  useEffect(() => {
    setTask(location?.state?.edit);
  }, []);

  const handleCheckboxChange = (e, dayName) => {
    if (dayName === "All Days") {
      if (e.target.checked) {
        setSelectedDays(["All Days"]);
        // Clear selected time slots for all other days
        setSelectedTime({});
      } else {
        setSelectedDays([]);
      }
    } else {
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays.includes(dayName)
          ? prevSelectedDays.filter((day) => day !== dayName)
          : [...prevSelectedDays, dayName]
      );
    }
  };

  console.log(selectedDays, "seleteddays");

  const handleTime = (e, dayName, time) => {
   
    if (selectedDays?.length < 1) {
      toast.error("Please select a day");
    } else {
      setSelectedTime((prevState) => {
        const newState = { ...prevState };
        if (e.target.checked) {
          if (!newState[dayName]) {
            newState[dayName] = [];
          }
          newState[dayName].push(time);
        } else {
          newState[dayName] = newState[dayName]?.filter(
            (slot) => slot !== time
          );
          if (newState[dayName]?.length === 0) {
            delete newState[dayName];
          }
        }
        return newState;
      });
    }
  };

  useEffect(() => {
    if (selectedDays?.length > 0) {
      const newResult = []; // Create a new array to hold the updated results

      selectedDays?.forEach((day, i) => {
        newResult.push(`availability[${i}][day]:${day}`);

        if (selectedTime[day]) {
          selectedTime[day].forEach((time, j) => {
            const [opening, closing] = time.split("-");
            newResult.push(
              `availability[${i}][timeArr][${j}][opening]:${opening}`
            );
            newResult.push(
              `availability[${i}][timeArr][${j}][closing]:${closing}`
            );
          });
        }
      });

      setResult(newResult); // Update the result state once after the loop
    } else {
      setResult([]); // Clear the result if no days are selected
    }
  }, [selectedDays, selectedTime]);

  // ==================================================edit=====================================================

  // ========================================fetch data by usequery===============================

  const { data } = useQuery({
    queryKey: ["coachdetailscoachSide"],
    queryFn: getUserProfile,
  });

  {
    // isSuccess && dispatch(coachDetails(data));
  }

  // {
  //   isError && toast.error(data?.message || "Something went wrong");
  // }

  console.log(data, "profile");

  useEffect(() => {
    
    if (data?.data?.availability) {
      const selectedDays = data?.data?.availability?.map((item) => item.day);
      const selectedTimeSlots = {};
      data?.data?.availability?.forEach((item) => {
        selectedTimeSlots[item?.day] = item?.timeArr?.map(
          (slot) => `${slot.opening}-${slot.closing}`
        );
      });

      // console.log(selectedTimeSlots?.All Days?.
      //   ,"===========================")

      setSelectedDays(selectedDays);
      setSelectedTime(selectedTimeSlots);
    }
  }, [data]);

  console.log(selectedTime, "selectedTime");

  const handleSubmit = () => {
    // console.log({
    //   gender:coachData?.gender,
    //   language:coachData?.language,
    //   address:coachData?.location?.label,
    //   coachTitle:coachData?.title,
    //   coachBio:coachData?.bio,
    //   rateperhour:coachData?.rate,
    //   phone_number:coachData?.phone_number,
    //   country:coachData?.country_code,
    //   availaibility:result

    // })

    console.log(result, "res");

    if (task == "edit") {
      const daysWithNoTimeSelected = selectedDays.filter(
        (day) => !selectedTime[day] || selectedTime[day]?.length === 0
      );

      if (daysWithNoTimeSelected.length > 0) {
        toast.error(ingerman=="de" ?" Bitte wählen Sie Zeitfenster für alle ausgewählten Tage aus": "Please select time slots for all selected days.");
      } else {
        let formdata = new FormData();

        for (let i = 0; i < result?.length; i++) {

          console.log(result)
          console.log(result[i]?.split(":")?.slice(1, 3).join(":"));
          
          formdata.append(
            `${result[i]?.split(":")?.[0]}`,
            result[i]?.split(":")?.slice(1, 3).join(":")
          );
        }

        editmutate(formdata);
      }
    } else {
      const daysWithNoTimeSelected = selectedDays.filter(
        (day) => !selectedTime[day] || selectedTime[day]?.length === 0
      );

      if (daysWithNoTimeSelected.length > 0) {
        toast.error(ingerman=="de" ?" Bitte wählen Sie Zeitfenster für alle ausgewählten Tage aus": "Please select time slots for all selected days.");
      } else {
        let formData = new FormData();

        formData.append("gender", coachData?.gender);
        formData.append("language", coachData?.language);
        formData.append("address", coachData?.location);
        formData.append("coachTitle", coachData?.title);
        formData.append("coachBio", coachData?.bio);
        formData.append("rateperhour", coachData?.rate);
        formData.append("phone_number", coachData?.phone_number);
        //  formData.append("availaibility",result)
        formData.append("profile_image", coachData?.profile_image);
        formData.append("certification", coachData?.certificate);
        formData.append("langType", ingerman == "de" ? 1 : 0);
        for (let i = 0; i < result?.length; i++) {
          console.log(result[i]?.split(":"));
          formData.append(
            `${result[i]?.split(":")?.[0]}`,
            result[i]?.split(":")?.slice(1, 3).join(":")
          );
        }

        if (Object.keys(selectedTime).length == 0) {
          toast.error(ingerman=="de" ? "Bitte wählen Sie Zeitliche Verfügbarkeit aus" :"Please select Time Availability");
        } else if (selectedDays?.length < 1) {
          toast.error(ingerman=="de" ?"Bitte wählen Sie einen Tag aus": "Please select a day");
        } else {
          // alert("hii")
          mutate(formData);
        }
      }
    }
  };

  return (
    <>
      <div className="right-side w-100">
        <div className="back-page-i" onClick={() => navigate(-1)}>
          <Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
            >
              <path
                d="M12 22L2 12L12 2"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>

        <div className="main-content">
          <h2>
            {task == "edit" ?  (ingerman=="de" ? "Zeitverfügbarkeit bearbeiten" : "Edit Time Availability") :(ingerman=="de" ? "Zeitliche Verfügbarkeit" : "Time Availability") }
          </h2>
          <div className="time-availability-list">
            <div className="time-ava-item">
              {days?.map((data, index) => {
                return (
                  <div key={index}>
                    {data?.day === "All Days" ? (
                      <div>
                        <Form>
                          {["checkbox"].map((type, index) => {
                            console.log(index, "index");
                            return (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label={ingerman=="de" ? dayTranslations[data?.day] : data?.day}
                                  name="All Days"
                                  type={type}
                                  id={`inline-${type}-2`}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, data.day)
                                  }
                                  checked={selectedDays?.includes(data.day)}
                                  // Implement "All Days" functionality as needed
                                />
                              </div>
                            );
                          })}
                        </Form>

                        <div className="availability-days">
                          {data?.timeSlot?.map((time, index) => {
                            return (
                              <Form.Label
                                key={index}
                                className={`radio-button-label ${
                                  selectedTime[data.day] &&
                                  selectedTime[data.day].includes(time)
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <Form.Control
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleTime(e, data.day, time)
                                  }
                                  checked={
                                    selectedTime[data.day] &&
                                    selectedTime[data.day].includes(time)
                                  }
                                  // checked={ selectedTime[data.day]?.map((x)=>x.includes(time))}
                                />
                                {time}
                              </Form.Label>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: selectedDays.includes("All Days")
                            ? "none"
                            : "block",
                        }}
                      >
                        <Form>
                          {["checkbox"].map((type, index) => {
                            console.log(index, "index");
                            return (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label={ingerman=="de" ? dayTranslations[data?.day] : data?.day}
                                  name="All Days"
                                  type={type}
                                  id={`inline-${type}-2`}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, data.day)
                                  }
                                  checked={selectedDays?.includes(data.day)}
                                  // Implement "All Days" functionality as needed
                                />
                              </div>
                            );
                          })}
                        </Form>

                        <div className="availability-days">
                          {data?.timeSlot?.map((time, index) => {
                            return (
                              <Form.Label
                                key={index}
                                className={`radio-button-label ${
                                  selectedTime[data.day] &&
                                  selectedTime[data.day].includes(time)
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <Form.Control
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleTime(e, data.day, time)
                                  }
                                  checked={
                                    selectedTime[data.day] &&
                                    selectedTime[data.day].includes(time)
                                  }
                                  // checked={ selectedTime[data.day]?.map((x)=>x.includes(time))}
                                />
                                {time}
                              </Form.Label>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            onClick={handleSubmit}
            className="bck-nxt-btns justify-content-end mt-5"
          >
            <Link
            //  to="/account-created"
            >
              {isPending || editPending ?(ingerman=="de" ? "Einreichen..": "Submitting...") :(ingerman=="de" ? "Einreichen": "Submit")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
