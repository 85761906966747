import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import mainimg from "../../src/Assets/images/main_banner.png";
import grmanimg from "../../src/Assets/images/main_banner-ger.png";
import wetakecare from "../../src/Assets/images/we-take-care.png";
import wetakecareger from "../../src/Assets/images/we-take-care-ger.png";

export default function Coachingtab() {
  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  const { t } = useTranslation();
  const {
    CoachesHeader,
    CoachesDesc,
    imageHeader,
    image1Desc,
    image2Desc,
    image3Desc,
    overview,
    overviewHead,
    Profile,
    Profilehead,
    Availaibilities,
    Availaibilitiesdesc,
    Messenger,
    Messengerhead,
  } = t("forCoaches");

  return (
    <Layout>
      <section
        className="for-coach-banner"
        style={{
          backgroundImage:
            ingerman === "de" ? `url(${grmanimg})` : `url(${mainimg})`,
        }}
      >
        <Container>
          <Row className="">
            <Col lg={6} className="">
              <div className="for-coach-text">
                <img src="" />
                <h2 className="Platform-font">
                  {/* Coaching has never been so easy. */}
                  {CoachesHeader}
                </h2>
                <p>
                  {/* MindRepublic takes care of scheduling video conference
                  licenses, payment processing and invoicing. Coaches can
                  finally focus on what matters:Their clients */}

                  {/* {CoachesDesc} */}
                </p>
              </div>
            </Col>
            <Col lg={6} className=""></Col>
          </Row>
        </Container>
      </section>
      <section className="for-coach-how pb-0">
        <Container>
          <Row>
            <Col lg={6} className="display-table mb-4">
              <div className="coaching-works table-cell">
                <h2>
                  {ingerman == "de"
                    ? "Coaching ist Persönlichkeitsentwicklung"
                    : "Coaching is personality development"}
                </h2>
                <p>
                  {ingerman == "de"
                    ? "Coaching hilft Dir, Deine Ziele zu erreichen, bestimmte Fähigkeiten zu entwickeln oder mit bestimmten Situationen besser umzugehen. Mit anderen Worten: Es geht darum, Dir dabei zu helfen, dorthin zu gelangen, wo Du hinwillst. "
                    : "Coaching helps you to achieve your goals, develop certain skills or deal better with certain situations. In other words, it’s about helping you to get to where you want to be. Coaching is therefore personality development."}
                </p>
              </div>
            </Col>
            <Col lg={6} className="display-table mb-4">
              <div className="coaching-works table-cell">
                <h2>
                  {" "}
                  {ingerman == "de"
                    ? "Coaching ist Begleitung"
                    : "Coaching is companionship"}
                </h2>
                <p>
                  {ingerman == "de"
                    ? "Mit einem/einer CoachIn kannst Du über Deine persönlichen Themen sprechen - sei es Deine Karriereplanung, der Umgang mit persönlichen Themen oder Lebenskrisen. Mit MindRepublic hast Du immer eine/n starke/n BegleiterIn in der Tasche."
                    : "You can talk to a coach about what moves you - be it career planning, relationship matters, dealing with personal topics or life crises. With MindRepublic, you always have a strong companion in your pocket."}
                </p>
              </div>
            </Col>

            <Col lg={6} className="display-table mb-4">
              <div className="coaching-works table-cell">
                <h2>
                  {ingerman == "de"
                    ? "Coaching ist Selbstreflektion "
                    : "Coaching is self-reflection"}
                </h2>
                <p>
                  {ingerman == "de"
                    ? "Sich selbst zu verstehen, ist der erste Schritt hin zu mehr Klarheit im Leben. Verstehe, wer Du bist und schreibe Deine eigene Geschichte. Denn nur wenn Du weißt, wer Du bist, weißt Du auch, was Du willst."
                    : "Understanding yourself is the first step towards more clarity in life. Understand who you are and write your own story. Because only when you know who you are will you know what you want."}
                </p>
              </div>
            </Col>
            <Col lg={6} className="display-table mb-4">
              <div className="coaching-works table-cell">
                <h2>
                  {ingerman == "de" ? "Coaching wirkt" : "Coaching works"}
                </h2>
                
                  {ingerman == "de"
                    ?<p>Für die Metaanalyse "Does Coaching Work?“<sup>1</sup>  wurden mehrere Studien ausgewertet. Das Ergebnis ist eindeutig: Im organisationalen Kontext stattfindendes Coaching hat signifikante positive Effekte auf alle im Rahmen der Analyse untersuchten Wirksamkeits-Kategorien.</p> 
                    :<p>Several studies were evaluated for the "Does Coaching Work?"<sup>1</sup> meta-analysis. The result is clear: coaching that takes place
                    in an organizational context has significant positive effects
                    on all of the effectiveness categories examined in the
                    analysis.</p> }
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="for-coach-how">
        <Container>
          <Row>
            <Col lg={12} className="">
              <h2 className="Platform-font">{imageHeader}</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="">
              <div className="coach-how-card">
                <img src={require("../Assets/images/Graphics_1.png")} />
                <p>
                  {ingerman == "de"
                    ? "Lade die App herunter und registriere Dich bei MindRepublic"
                    : "Download the App and register with MindRepublic"}
                </p>
              </div>
            </Col>
            <Col lg={4} className="">
              <div className="coach-how-card">
                <img src={require("../Assets/images/Graphics_2.png")} />
                <p>
                  {" "}
                  {ingerman == "de"
                    ? "Wähle eine/n CoachIn aus unserer Liste mit handverlesenen CoachInnen. Buche eine Session direkt in der App. MindRepublic verschickt die Kalendereinladung mit dem Zoom-Link per E-Mail. Die Zahlung wird vor der Sitzung abgewickelt."
                    : "You select a coach from our hand-picked list. You can book a session with in the app. MindRepublic distributes the calendar invite with the Zoom link via e-mail. Payment will be processed prior to the session."}
                </p>
              </div>
            </Col>
            <Col lg={4} className="">
              <div className="coach-how-card">
                <img src={require("../Assets/images/Graphics_3.png")} />
                <p>
                  {" "}
                  {ingerman == "de"
                    ? "Über die MindRepublic App und Website kannst Du auch außerhalb der Sessions mit Deinem Coachee in Kontakt bleiben."
                    : "Via the MindRepublic App and Website you can stay in contact with your coach even outside the sessions."}
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <div className="text-center mt-3">
                <p>
                  {ingerman == "de"
                    ? `MindRepublic garantiert, dass nur qualifizierte CoachInnen auf der Plattform zur Verfügung stehen. Daher müssen CoachInnen, bevor sie bei MindRepublic zugelassen werden, ihre Qualifikation nachweisen (Verbandszertifikat mit min. 2-jähriger Ausbildung oder entsprechende langjährige Berufserfahrung).`
                    : `MindRepublic guarantees that only qualified coaches are
                  available on the platform. For this reason, coaches must prove
                  their qualifications (association certificate with at least 2
                  years of training or corresponding years of professional
                  experience) before they are admitted to MindRepublic.`}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="we-take-care-sec">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="">
              <div className="we-take-care-text">
                <p>
                  {ingerman == "de"
                    ? `Dein Selfcare.Hub kann auch Coaching`
                    : `Your Selfcare.Hub can also provide coaching`}
                </p>
                <h3 className="Platform-font">
                  {ingerman == "de" ? `Wir kümmern uns` : "We take care"}
                </h3>
                <p>
                  {ingerman == "de"
                    ? `Bequem und vertraulich: Coaching via MindRepublic`
                    : `Convenient and confidential: coaching via MindRepublic`}
                </p>
                <ul>
                  <li>
                    {ingerman == "de"
                      ? `On-demand und unlimitierter Zugang: Vereinbare einfach eine Session`
                      : `On-demand and unlimited access: simply arrange a session`}
                  </li>
                  <li>
                    {ingerman == "de"
                      ? `Zugriff überall: Die Sessions werden über Zoom durchgeführt, es entstehen hierfür keine zusätzlichen Kosten`
                      : `Access anywhere: Sessions are conducted via Zoom, at no
                    additional cost`}
                  </li>
                  <li>
                    {ingerman == "de"
                      ? `Coaching ergänzt Deine Reise mit den Reflection Journeys`
                      : `Coaching complements your journey with the Reflection
                    Journeys`}
                  </li>
                </ul>
                <p>
                  {ingerman == "de"
                    ? `Es gibt keine Abo-Kosten.`
                    : "There are no subscription costs."}
                </p>
              </div>
            </Col>
            <Col lg={6} className="">
              <div className="we-take-care-img">
                {ingerman === "de" ? (
                  <img src={wetakecareger} />
                ) : (
                  <img src={wetakecare} />
                )}
              </div>
            </Col>
            <Col lg={12}>
              <div className="text-center mt-4 footer-text-add-s">
                <p>
                  1 Theeboom, Tim; Beersma, Bianca & van Vianen, Annelies E.
                  (2014). Does coaching Work? A meta-analysis on the effects of
                  coaching on individual level outcomes in an organizational
                  context. In The Journal of Positive Psychology, 9(1), S. 1–18.
                  https://doi.org/10.1080/17439760.2013.837499.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="phone-tabs-section">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="mobile-tab-sec">
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column side-tabs-m">
                      <Nav.Item>
                        <Nav.Link eventKey="first">{overviewHead}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">{Profilehead}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          {Availaibilitiesdesc}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Four">{Messengerhead}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            <img
                              src={require("../Assets/images/Overview.png")}
                            />
                          </div>
                          <div className="text-side-mobile">
                            <p>{overview}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            <img
                              src={require("../Assets/images/Profile.png")}
                            />
                          </div>
                          <div className="text-side-mobile">
                            <p>{Profile}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            <img
                              src={require("../Assets/images/Avilabilities.png")}
                            />
                          </div>
                          <div className="text-side-mobile">
                            <p>{Availaibilities}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Four">
                        <div className="mobile-slide">
                          <div className="mobile-side">
                            <img
                              src={require("../Assets/images/Messages.png")}
                            />
                          </div>
                          <div className="text-side-mobile">
                            <p>{Messenger}</p>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section> */}
    </Layout>
  );
}
