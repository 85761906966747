import axios from "axios";
import toast from "react-hot-toast";

const userApi = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  // baseURL: "https://api.mindrepublic.com/api/user",
  
  
  headers: {
    Authorization: sessionStorage.getItem("token"),
    // lang:localStorage.getItem("selectedLang") == "de" ? "1" : "0"
  },
});

userApi.interceptors.request.use((config) => {
  config.headers.lang = localStorage.getItem("selectedLang") === "de"? "1" : "0";
  return config;
});

console.log(localStorage.getItem("selectedLang"),"========================================")

userApi.interceptors.response.use(

  (response) => {
    console.log(response,"========================================================")
    return response;
  },
  (error) => {

    // if (error?.response?.status === 401) {
    // //   setTimeout(() => {
    //     sessionStorage.clear();
    //     window.location.reload(false);
    //     window.location.href = "/";
    // //   }, 1000);
    // }

    console.log(error,"============================errrr")
    if(error?.response?.data?.status_code== 501){
      
      sessionStorage.clear();
      localStorage.clear()

      toast.error(error?.response?.data?.message);
      setTimeout(()=>{
        window.location.href = "/"
      },3000)
    }
    return error.response;
  }
);

export default userApi;