import React, { useState } from "react";
import Layout from "../Components/Layout";
import {
  Container,
  Row,
  Col,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutNew from "../Components/LayoutNew";
import { useDispatch, useSelector } from "react-redux";
import {
  chat,
  getCoachDetails,
  getCoachReviews,
} from "../Redux/Actions/userActions";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { coachDetails } from "../Redux/Reducers/CoachSlice";
import RatingComponent from "../Utils/ratingStars";
import moment from "moment/moment";

export default function CoachDetails() {
  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);
  const dispatch = useDispatch();
  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate = useNavigate();

  const dayTranslations = {
    Monday: ingerman === "de" ? "Montag" : "Monday",
    Tuesday: ingerman === "de" ? "Dienstag" : "Tuesday",
    Wednesday: ingerman === "de" ? "Mittwoch" : "Wednesday",
    Thursday: ingerman === "de" ? "Donnerstag" : "Thursday",
    Friday: ingerman === "de" ? "Freitag" : "Friday",
    Saturday: ingerman === "de" ? "Samstag" : "Saturday",
    Sunday: ingerman === "de" ? "Sonntag" : "Sunday",
    "All Days": ingerman === "de" ? "Alle Tage" : "All Days",
    // ...
  };

  // ===========================================get coach id from store==========================================

  const coachId = useSelector((state) => state?.coachData?.id);

  console.log(coachId, "id");

  // ==========================================react states==========================================

  const [show, setshow] = useState(false);

  // ========================================fetch data by usequery===============================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["coachdetails"],
    queryFn: () => getCoachDetails(coachId),
  });

  {
    isSuccess && dispatch(coachDetails(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }
  // ========================================fetch data of reviews===============================

  const {
    data: reviewData,
    isSuccess: reviewSuccess,
    isError: reviewError,
  } = useQuery({
    queryKey: ["reviewList"],
    queryFn: () => getCoachReviews(coachId),
  });

  // {
  //   isSuccess && dispatch(coachDetails(data));
  // }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(reviewData);

  // =============================================Go for chat========================================================

  const { isPending: chatPending, mutate } = useMutation({
    mutationFn: chat,
    onSuccess: (res) => {
      if (res?.success) {
        navigate("/inbox-chat-box", {
          state: {
            roomId: res?.data?._id,
            coachName: data?.data?.coachDetails?.full_name,
            coachId: data?.data?.coachDetails?._id,
          },
        });
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  return (
    <>
      <LayoutNew>
        <div style={{ position: "relative" }} className="right-side">
          {(isPending || chatPending) && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}
          <div className="back-page-i">
            <Link to="/coaching">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="notify-page-i">
            <Link to="/notification">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="30"
                viewBox="0 0 26 30"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.2579 10.1945C23.2579 12.0784 23.7655 13.1887 24.8828 14.4684C25.7294 15.4111 26 16.6212 26 17.934C26 19.2453 25.5608 20.4901 24.6806 21.5008C23.5285 22.7125 21.9035 23.4859 20.2451 23.6205C17.842 23.8213 15.4374 23.9905 13.0008 23.9905C10.5626 23.9905 8.15949 23.8894 5.75632 23.6205C4.09647 23.4859 2.47156 22.7125 1.32091 21.5008C0.440807 20.4901 0 19.2453 0 17.934C0 16.6212 0.272083 15.4111 1.11722 14.4684C2.2694 13.1887 2.74364 12.0784 2.74364 10.1945V9.55545C2.74364 7.03251 3.38509 5.38278 4.706 3.76779C6.66986 1.41255 9.81783 0 12.9324 0H13.0691C16.2506 0 19.5003 1.48053 21.4309 3.93699C22.6833 5.51874 23.2579 7.09897 23.2579 9.55545V10.1945ZM8.52444 27.0912C8.52444 26.3359 9.23125 25.9899 9.88486 25.8418C10.6494 25.6833 15.3083 25.6833 16.0729 25.8418C16.7266 25.9899 17.4333 26.3359 17.4333 27.0912C17.3952 27.8103 16.9652 28.4479 16.3708 28.8528C15.6002 29.442 14.6958 29.815 13.7503 29.9496C13.2274 30.016 12.7137 30.0175 12.209 29.9496C11.262 29.815 10.3576 29.442 9.58846 28.8513C8.9926 28.4479 8.56244 27.8103 8.52444 27.0912Z"
                  fill="#4CA890"
                />
              </svg>
            </Link>
          </div>
          <div className="main-content">
            <h2>Coach Details</h2>
            <div className="coach-details">
              <Row>
                <Col xl={6} lg={5}>
                  <div className="coach-profle-img">
                    <img
                      src={`${BASE_URL}${data?.data?.coachDetails?.profile_image}`}
                    />
                    <div className="coach-profle-img-txt">
                      <h3>{data?.data?.coachDetails?.full_name}</h3>
                      <p>{data?.data?.coachDetails?.coachTitle}</p>
                      {/* <p>MBSR Trainer/Relaxation Educator</p> */}
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={7}>
                  <div className="coach-profile-info">
                    <div className="basic-details">
                      <div className="left-side">
                        <h3>{data?.data?.coachDetails?.full_name}</h3>
                        <p>{data?.data?.coachDetails?.coachTitle}</p>
                        <p>{data?.data?.coachDetails?.address}</p>
                        <div className="coach-pro-rate">
                          {/* {data?.data?.coachDetails?.rating !== 0 ? ( */}
                            {/* data?.data?.coachDetails?.rating && ( */}
                              <div style={{ display: "flex", gap: "5px" }}>
                                <RatingComponent
                                  value={data?.data?.coachDetails?.rating ? data?.data?.coachDetails?.rating :0 }
                                />
                                {data?.data?.coachDetails?.rating ? data?.data?.coachDetails?.rating :0 }
                              </div>
                            {/* ) */}
                          {/* ) : (
                            <p>No ratings</p>
                          )} */}
                        </div>
                      </div>
                      <div className="info-right-side">
                        <h3>€ {data?.data?.coachDetails?.rateperhour}/h</h3>
                        <p>
                          {data?.data?.coachDetails?.free_30_minutes == 1 && (
                            <p>
                              {ingerman == "de"
                                ? "30 Minuten kostenlos"
                                : "30 Min Free"}
                            </p>
                          )}{" "}
                        </p>
                        <div
                          onClick={() => {
                            mutate({
                              coach_id: coachId,
                            });
                          }}
                        >
                          <Link to=" ">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                              >
                                <path
                                  d="M12.5 0C15.6788 0 18.7388 1.25994 20.9828 3.51582C23.2388 5.77291 24.5 8.81955 24.5 12.0114C24.5 16.2112 22.2908 20.111 18.6908 22.2829C15.0908 24.4548 10.6148 24.5748 6.896 22.5949H6.8588C6.5 22.4509 6.2108 22.1989 5.8748 22.0309C5.504 21.8749 5.0828 21.8509 4.7 21.9721C3.8108 22.2829 2.9108 22.5349 1.9868 22.7508C1.5068 22.7628 1.3628 22.4749 1.3628 22.0069C1.5788 21.0589 1.8668 20.1218 2.204 19.211C2.3348 18.8151 2.3 18.3951 2.096 18.0219L1.856 17.5551C0.9668 15.8632 0.5 13.9793 0.5 12.0726V11.9994C0.5 8.81955 1.7588 5.75971 4.0148 3.51582C6.2708 1.25994 9.3188 0 12.5 0ZM18.0308 10.4755C17.1908 10.4755 16.496 11.1594 16.496 12.0114C16.496 12.8514 17.1908 13.5473 18.0308 13.5473C18.8828 13.5473 19.5668 12.8514 19.5668 12.0114C19.5668 11.1594 18.8828 10.4755 18.0308 10.4755ZM12.5 10.4755C11.648 10.4755 10.9628 11.1594 10.9628 12.0114C10.9628 12.8514 11.648 13.5473 12.5 13.5473C13.352 13.5473 14.0348 12.8514 14.0348 12.0114C14.0348 11.1594 13.352 10.4755 12.5 10.4755ZM6.9668 10.4755C6.1148 10.4755 5.4308 11.1594 5.4308 12.0114C5.4308 12.8514 6.1148 13.5473 6.9668 13.5473C7.8068 13.5473 8.504 12.8514 8.504 12.0114C8.504 11.1594 7.8068 10.4755 6.9668 10.4755Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                            {ingerman == "de" ? "Jetzt chatten" : "Chat Now"}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="biography-details">
                      <h3>Biography</h3>
                      <p>{data?.data?.coachDetails?.coachBio}</p>
                    </div>
                    <hr />
                    <div className="availability-details">
                      <h3>
                        {ingerman == "de" ? "Verfügbarkeit" : "Availability"}
                      </h3>
                      <div className="availability-days">
                        <ul>
                          {data?.data?.availability?.map((days) => {
                            const dayTranslation = dayTranslations[days.day];
                            return (
                              <li>
                                <p>
                                  {ingerman == "de"
                                    ? dayTranslation
                                    : days?.day}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <hr />
                    <div className="reviews-details">
                      {data?.data?.reviews?.length > 0 && (
                        <div className="review-count">
                          <h3>
                            {ingerman == "de" ? "Rezensionen" : "Reviews"}(
                            {data?.data?.reviews?.length})
                          </h3>
                          <p
                            onClick={() => setshow(true)}
                            style={{ cursor: "pointer" }}
                          >
                            {ingerman == "de" ? "Alle ansehen" : "View All"}
                          </p>
                        </div>
                      )}

                      {data?.data?.reviews?.length > 0 ? (
                        <div className="review-card">
                          <div className="review-view">
                            <div className="review-pro">
                              <img
                                src={
                                  data?.data?.reviews[0]?.profile_image
                                    ? `${BASE_URL}${data?.data?.reviews[0]?.profile_image}`
                                    : require("../Assets/images/place_holder.png")
                                }
                              />
                              <h3>{data?.data?.reviews[0]?.full_name}</h3>
                            </div>

                            {data?.data?.reviews[0]?.rating && (
                              <div className="review-rate">
                                <p>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M9.23509 1.76562L11.4163 6.18445L16.2939 6.89739L12.7645 10.335L13.5974 15.1915L9.23509 12.8974L4.87274 15.1915L5.70568 10.335L2.17627 6.89739L7.05392 6.18445L9.23509 1.76562Z"
                                        fill="#F5B715"
                                      />
                                    </svg>
                                  </span>
                                  {data?.data?.reviews[0]?.rating}
                                </p>
                              </div>
                            )}
                          </div>
                          <p>{data?.data?.reviews[0]?.review}</p>
                        </div>
                      ) : (
                        <p>No Reviews Updated Yet!</p>
                      )}
                    </div>
                    <div className="make-appointment">
                      <Link to="/make-appointment">
                        {ingerman == "de"
                          ? "Einen Termin machen"
                          : "Make An Appointment"}
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/*===============================view all comments====================================
        
         */}

        <Modal
          show={show}
          onHide={() => setshow(false)}
          className="delete-popup"
        >
          <Modal.Header style={{ textAlign: "center" }} closeButton>
            <Modal.Title>
              <h3>{ingerman == "de" ? "Rezensionen" : "Reviews"}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {reviewData?.data?.length > 0 &&
              reviewData?.data?.map((review) => {
                return (
                  <div className="review-card">
                    <div className="review-view">
                      <div className="review-pro">
                        <img
                          src={
                            review?.user_id?.profile_image
                              ? `${BASE_URL}${review?.user_id?.profile_image}`
                              : require("../Assets/images/place_holder.png")
                          }
                        />
                        <h3>{review?.user_id?.full_name}</h3>
                      </div>

                      <div className="review-rate">
                        <p>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M9.23509 1.76562L11.4163 6.18445L16.2939 6.89739L12.7645 10.335L13.5974 15.1915L9.23509 12.8974L4.87274 15.1915L5.70568 10.335L2.17627 6.89739L7.05392 6.18445L9.23509 1.76562Z"
                                fill="#F5B715"
                              />
                            </svg>
                          </span>
                          {review?.rating}
                        </p>
                      </div>
                    </div>
                    <p>{review?.review}</p>
                    <p>
                      {moment(review?.createdAt).format("DD MMMM YYYY")} at{" "}
                      {moment(review?.createdAt).format("hh:mm A")}
                    </p>
                  </div>
                );
              })}
          </Modal.Body>
        </Modal>
      </LayoutNew>
    </>
  );
}
