import { createSlice } from "@reduxjs/toolkit";


export const appointmentSlice = createSlice({
  name: "appointmentSlice",
  initialState: {
    list: null,
    coachList:null
    
  },
  reducers: {
    appointmentList:(state,action)=>{
        console.log(action,"action")
        state.list=action.payload
    },
    coachAppointmentList:(state,action)=>{
      console.log(action,"action")
      state.coachList=action.payload
  },
    

  },
  
});

export const {courseCategory,appointmentList,coachAppointmentList} = appointmentSlice.actions

export default appointmentSlice.reducer
