import ReactStars from "react-rating-stars-component";
import React from "react";

const RatingComponent = ({value}) => {
    console.log(value)
//   const ratingChanged = (newRating) => {
//     console.log(newRating);
//     // You can perform any other actions with the new rating here
//   };

  return (
    <ReactStars
      count={5}
      value={value}
    //   onChange={ratingChanged}
      size={24}
      activeColor="#ffd700"
    />
  );
};

export default RatingComponent;
