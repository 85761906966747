import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n, { changeLanguage } from "i18next";
import InputGroup from "react-bootstrap/InputGroup";
import ReactCountryFlag from "react-country-flag";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import countries from "../Utils/countryCode.json";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../Redux/Actions/userActions";
import {
  Userdata,
  registrationData,
} from "../Redux/Reducers/registrationSlice";
import { registerCoach } from "../Redux/Actions/coachAction";
import GoogleLogin from "../Utils/GoogleLogin";

export default function SignUp() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman,"lang");

  // =======================================check for userrole==============================

  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // ========================================registration Data===============================

  const registerData = useSelector(
    (state) => state.registerUserdata.registrationData
  );
  console.log(registerData, "data");

  // ========================================FLAGS===========================================

  const options = countries.map((country) => ({
    value: country.dial_code,
    label: (
      <>
        <ReactCountryFlag
          countryCode={country.code}
          svg
          style={{
            marginRight: "10px",
            width: "20px",
            height: "auto",
          }}
          title={country.name}
          placeholder="heii"
        />
        {country.name} ({country.dial_code})
      </>
    ),
  }));

  //==============================Find the German country object=======================

  const germanyOption = options.find((option) => option.value === "+49");

  // ===================================REACT STATES==================================

  const [countryCode, setCountryCode] = useState(germanyOption);
  const [userDetails, setUserDetails] = useState({
    full_name: registerData?.full_name ? registerData?.full_name : "",
    email: registerData?.email ? registerData?.email : "",

    phone_number: registerData?.phone_number ? registerData?.phone_number : "",
    password: registerData?.password ? registerData?.password : "",
    confirmPassword: registerData?.confirmPassword
      ? registerData?.confirmPassword
      : "",
    TandC: registerData?.TandC ? registerData?.TandC : "",
    device_token: sessionStorage.getItem("FCM Token"),
    langType: ingerman == "de" ? 1 : 0,
  });
  // const [isLoading, setIsLoading] = useState(false);

  // =====================================add country code in object===================

  useEffect(() => {
    setUserDetails((prev) => ({
      ...prev,
      country_code: countryCode?.value,
    }));
  }, [countryCode]);
  

  // =====================================Get value from input fields===================

  const handlechange = (e) => {
    setUserDetails((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handlechecked = (e) => {
    setUserDetails((old) => ({ ...old, TandC: e.target.checked }));
  };

  // =================================post data using react-query=====================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: userrole == "user" ? registerUser : registerCoach,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        // userrole == "user" &&  toast.success(res?.message);
        toast.success(res?.message);
        navigate(
          "/verify-email"
          //    {
          //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
          // }
        );
        dispatch(registrationData({}));
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ======================================Submission=================================

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isvalid = true;

    if (!userDetails?.full_name) {
      toast.error(ingerman=="de" ?"Bitte geben Sie den Namen ein": "Please Enter Name");
      isvalid = false;
    } else if (!userDetails?.email) {
      toast.error(ingerman=="de" ?"Bitte geben Sie Ihre E-Mail-Adresse ein": "Please Enter Email");
      isvalid = false;
    } else if (!emailRegex.test(userDetails?.email)) {
      toast.error(ingerman=="de" ? "Bitte geben Sie eine gültige Email-Adresse ein " :"Please Enter a Valid Email");
      isvalid = false;
    } else if (userrole === "user" && !userDetails?.phone_number) {
      toast.error(ingerman=="de"?"Bitte geben Sie die Telefonnummer ein"  :"Please Enter Phone Number");
      isvalid = false;
    } else if (!userDetails?.password) {
      toast.error(ingerman=="de" ? 'Bitte geben Sie Ihr Passwort ein': "Please Enter your Password");
      isvalid = false;
    } else if (!userDetails?.confirmPassword) {
      toast.error(ingerman=="de" ?"Bitte geben Sie Ihr Bestätigungs passwort ein" :"Please Enter your Confirm Password");
      isvalid = false;
    } else if (userDetails?.confirmPassword !== userDetails?.password) {
      toast.error(ingerman=="de" ? "Das Kennwort stimmt nicht überein" : "Password did not match");
      isvalid = false;
    } else if (!userDetails?.TandC == true) {
      toast.error(ingerman=="de" ? "Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen" :"Please accept General Terms and Conditions");
      isvalid = false;
    }

    if (isvalid) {
      if (userrole == "coach") {
        mutate(userDetails);
      } else {
        mutate(userDetails);
      }
    }
  };

  console.log(countryCode, "code");

  return (
    <section className="Login-sec sign-height">
      <Container style={{ position: "relative" }}>
        {/* {isPending && (
          <div
            // style={{
            //   position: "absolute",
            //   width: "110%",
            //   height: "100%",
            //   zIndex: "1",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   display: "flex",
            //   // backgroundColor: "rgba(0,0,0,0.1)",
            // }}
          >
            <div class="loader"></div>
          </div>
        )} */}
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head mb-5">
                <h2>{ingerman=="de" ? "Melden Sie sich an" : "Sign Up"} </h2>
                {/* <p>Sign In to continue</p> */}
              </div>
              <Form onSubmit={handleSubmit} className="contact-form">
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="full_name"
                    value={userDetails?.full_name}
                    onChange={handlechange}
                    type="text"
                    placeholder={ingerman=="de" ? "Geben Sie hier ein..." : "Type here..."}
                  />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    onChange={handlechange}
                    value={userDetails?.email}
                    type="text"
                    placeholder={ingerman=="de" ? "Geben Sie hier ein..." : "Type here..."}
                  />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>

                {userrole == "user" ? (
                  <>
                    <Form.Label htmlFor="basic-url">{ingerman=="de" ? "Telefonnummer":"Phone Number"}</Form.Label>
                    <InputGroup className="mb-3 ad-height-input conutry-selects">
                      <InputGroup.Text id="basic-addon3">
                        <Select
                          defaultValue={countryCode}
                          onChange={(e) => {
                            console.log(e?.value);
                            setCountryCode(e);
                          }}
                          options={options}
                        />
                      </InputGroup.Text>
                      <Form.Control
                        name="phone_number"
                        value={userDetails?.phone_number}
                        id="basic-url"
                        // type="number"
                        aria-describedby="basic-addon3"
                        onChange={handlechange}
                      />
                    </InputGroup>
                  </>
                ) : (
                  ""
                )}

                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>{ingerman=="de" ? "Passwort" : "Password"}</Form.Label>
                  <Form.Control
                    name="password"
                    onChange={handlechange}
                    value={userDetails?.password}
                    type="password"
                    placeholder={ingerman=="de" ? "Geben Sie hier ein..." : "Type here..."}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>{ingerman=="de" ? "Bestätige das Passwort" : "Confirm Password"}</Form.Label>
                  <Form.Control
                    name="confirmPassword"
                    onChange={handlechange}
                    value={userDetails?.confirmPassword}
                    type="password"
                    placeholder={ingerman=="de" ? "Geben Sie hier ein..." : "Type here..."}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 check-box-pp  justify-content-start"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    name="TandC"
                    value={userDetails?.TandC}
                    onChange={handlechecked}
                    type="checkbox"
                    className="me-2"
                    label={ingerman=="de" ? "Ich habe die gelesen und akzeptiere sie" : "I have read and accept the"}
                  />
                  <div
                    onClick={() => {
                      navigate("/data-protection");
                      dispatch(registrationData(userDetails));
                    }}
                  >
                    <Link>{ingerman=="de" ? "Allgemeine Geschäftsbedingungen" : "General Terms and Conditions"}</Link>
                  </div>
                </Form.Group>
                <div className="d-flex justify-content-center">
                  <Button variant="primary" type="submit" className="theme-btn">
                    {isPending ? ingerman=="de" ? "Einreichen...": "Submitting..." : ingerman=="de" ? "SPEICHERN" : "Save"}
                  </Button>
                </div>
                <div className="next-page-signin">
                  <p>
                 {ingerman=="de" ? "Sie haben bereits ein Konto" : " Already have an account?"}  <Link to="/login">{ingerman=="de" ? "Anmelden." :"Sign In."} </Link>
                  </p>
                </div>
                <div className="text-center">
                  <GoogleLogin />
                  {/* <Link to="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <g filter="url(#filter0_d_4311_38125)">
                        <circle cx="32" cy="28" r="28" fill="white" />
                        <circle
                          cx="32"
                          cy="28"
                          r="27.85"
                          stroke="#C1C9B6"
                          stroke-width="0.3"
                        />
                      </g>
                      <path
                        d="M44.0976 28.1108C44.0976 27.2461 44.0139 26.3535 43.8744 25.5166H31.7959V30.454H38.7139C38.4349 32.044 37.5144 33.4388 36.1475 34.3314L40.276 37.5393C42.7028 35.2798 44.0976 31.9882 44.0976 28.1108Z"
                        fill="#4280EF"
                      />
                      <path
                        d="M31.7973 40.6083C35.2563 40.6083 38.1574 39.4646 40.2774 37.5119L36.1489 34.3319C35.0052 35.1129 33.5268 35.5593 31.7973 35.5593C28.4499 35.5593 25.6325 33.2998 24.6004 30.2871L20.3604 33.5508C22.5362 37.8745 26.9436 40.6083 31.7973 40.6083Z"
                        fill="#34A353"
                      />
                      <path
                        d="M24.5999 30.2587C24.0699 28.6687 24.0699 26.9392 24.5999 25.3492L20.3599 22.0576C18.5467 25.684 18.5467 29.9519 20.3599 33.5504L24.5999 30.2587Z"
                        fill="#F6B704"
                      />
                      <path
                        d="M31.7973 20.0774C33.6105 20.0495 35.3957 20.7469 36.7068 22.0021L40.3611 18.32C38.0458 16.1442 34.9773 14.9726 31.7973 15.0005C26.9436 15.0005 22.5362 17.7342 20.3604 22.0579L24.6004 25.3495C25.6325 22.309 28.4499 20.0774 31.7973 20.0774Z"
                        fill="#E54335"
                      />
                      <defs>
                        <filter
                          id="filter0_d_4311_38125"
                          x="0"
                          y="0"
                          width="64"
                          height="64"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.635294 0 0 0 0 0.666667 0 0 0 0 0.588235 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_4311_38125"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_4311_38125"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </Link> */}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
