import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Imprint() {
  const { t } = useTranslation();
  const imprint = t("imprint");
  console.log(imprint);

  return (
    <Layout>
      <section>
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="data-protection-page">
                <h4 className="Platform-font">{imprint?.header}</h4>
                <p className="">
                  <b>Dr. Christian Hoops</b>
                </p>

                <div className="">
                  <b>{imprint?.addhesd}</b>:
                </div>
                <p>{imprint?.addDesc}</p>

                <div className="">
                  <b>{imprint?.vatId}</b>:
                </div>
                <p>{imprint?.vatIdDeac}</p>

                <div className="">
                  <b>{imprint?.Contact}</b>:
                </div>
                <p>
                  <Link to="mailto:support@mindrepublic.com">
                    support@mindrepublic.com
                  </Link>
                </p>

                <div className="">
                  <b>{imprint?.GTC}</b>:
                </div>
                <p>
                  {/* The General Terms and Conditions and the Access Agreement for
                  Coaches can be accessed here: […] */}
                  {imprint?.gtcdesc}
                  <Link to="https://mindrepublic.com/terms-and-conditions">
                    https://mindrepublic.com/terms-and-conditions
                  </Link>
                  ,{" "}
                  <Link to="https://mindrepublic.com/access-agreement">
                    https://mindrepublic.com/access-agreement
                  </Link>
                </p>

                <div className="">
                  <b>{imprint?.dataL}</b>:
                </div>
                <p>
                  {imprint?.l1}{" "}
                  <Link to="https://mindrepublic.com/data-protection">
                    https://mindrepublic.com/data-protection
                  </Link>{" "}
                </p>

                <div className="">
                  <b>{imprint?.l2}</b>
                </div>
                <p>
                  {imprint?.l4}
                  <Link to="https://ec.europa.eu/consumers/odr/">
                    https://ec.europa.eu/consumers/odr/
                  </Link>
                  {imprint?.l3}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
