import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AccessAgreement() {
  const { t } = useTranslation();
  const TermCondition = t("TermCondition");
  console.log(TermCondition, "TermCondition");

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  return (
    <Layout>
      <section>
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="data-protection-page">
                <h2 className="text-center Platform-font">
                  {ingerman == "de" ? "Zugangsvertrag" : "Access Agreement"}
                </h2>
                <p className="text-center">
                  <b>{TermCondition?.date}</b>
                </p>

                <div className="terms-and-conditions">
                  <ol>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? "Allgemeines und Vertragsschluss"
                          : "General and Conclusion of Contract"}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>1.1</span>
                        <p>
                          {ingerman == "de"
                            ? `Dr. Christian Hoops, Leidenhofener Straße 7B, 35085 Ebsdorfergrund (im Folgenden, "MindRepublic“) bietet Nutzern über die MindRepublic-Website `
                            : ` Dr. Christian Hoops, Leidenhofener Straße 7B, 35085
                        Ebsdorfergrund (hereinafter referred to as
                        "MindRepublic") offers users opportunities through the
                        MindRepublic website `}
                          (
                          <Link to="https://www.mindrepublic.com">
                            https://www.mindrepublic.com
                          </Link>
                          
                          {ingerman == "de"
                            ? `; im Folgenden "Website“) und der MindRepublic-App Möglichkeiten an, um Reflection Journeys (Selbstreflektion), Meditation und Achtsamkeit zu beschäftigen sowie Coachings zu buchen.`
                            : `; hereinafter "Website") and the MindRepublic app to
                        engage in Reflection Journeys (self-reflection),
                        meditation, and mindfulness, as well as to book coaching
                        sessions.`}
                        </p>
                      </li>
                      <li>
                        <span>1.2</span>
                        <p>
                          {ingerman == "de"
                            ? `Dieser Coaching-Vermittlungsvertrag wird zwischen MindRepublic und dem Nutzer abgeschlossen, der als Coach zum Angebot von MindRepublic hinzugefügt werden möchte `
                            : ` This coaching brokerage contract is concluded between
                        MindRepublic and the user who wishes to be added as a
                        coach to the MindRepublic offering `}
                          <strong>("Coach")</strong>.
                        </p>
                      </li>
                      <li>
                        <span>1.3</span>
                        <p>
                          {ingerman == "de"
                            ? `Mit der Registrierung des Coaches in der App oder auf der Website von MindRepublic gibt der Coach ein verbindliches Angebot zum Abschluss dieses Coaching-Vertrages ab. MindRepublic nimmt dieses Angebot entweder durch ausdrückliche Erklärung der Annahme an oder dadurch, dass der Coach Zugang zur App und Website von MindRepublic erhält.`
                            : ` By registering as a Coach in the MindRepublic app or
                        website, the Coach submits a binding offer to conclude
                        this coaching contract. MindRepublic accepts this offer
                        either by expressly declaring acceptance or by granting
                        the Coach access to the MindRepublic app and website.`}
                        </p>
                      </li>
                      <li>
                        <span>1.4</span>
                        <p>
                          {ingerman == "de"
                            ? `Teil dieses Vertrages sind die Allgemeinen Geschäftsbedingungen von MindRepublic, wie sie unten diesem Dokument angehangen sind ("AGB“). Für den Fall von Widersprüchen zwischen diesem Vertrag und den AGB, hat dieser Vertrag Vorrang. `
                            : ` Part of this contract are the general terms and
                        conditions of MindRepublic, as attached below this
                        document ("GTC"). In case of conflicts between this
                        contract and the GTC, this contract shall prevail.`}
                        </p>
                      </li>
                    </ul>

                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Rechte und Pflichten von MindRepublic`
                          : "Rights and Obligations of MindRepublic"}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>2.1</span>
                        <p>
                          {ingerman == "de"
                            ? `MindRepublic bietet Nutzern die Dienstleistungen an, wie sie unter Ziffer 1.1 beschrieben sind. Für die Zwecke der Coaching-Vermittlung bietet MindRepublic dem Coach an, ein Profil anzulegen und dieses mit Informationen zu befüllen (Profilbild, Ausrichtung und Spezialisierung des Coaches, zeitliche Verfügbarkeiten, Profil und Lebenslauf, etc.). MindRepublic bietet Coaches eine kostenlose Nutzung der App und Website von MindRepublic für die Zwecke der Coaching-Vermittlung. MindRepublic übernimmt keine Gewährleistung für eine erfolgreiche Coaching-Vermittlung, kontinuierliche Aufrechterhaltung der Dienstleistungen oder störungsfreien Betrieb der App oder Website.`
                            : ` MindRepublic offers users the services as described in
                        Section 1.1. For the purpose of coaching brokerage,
                        MindRepublic offers coaches to create a profile and fill
                        it with information (profile picture, Coach's
                        orientation and specialization, availability, profile,
                        and resume, etc.). MindRepublic offers coaches free use
                        of the MindRepublic app and website for the purpose of
                        coaching brokerage. MindRepublic does not guarantee
                        successful coaching brokerage, continuous maintenance of
                        services, or uninterrupted operation of the app or
                        website.`}
                        </p>
                      </li>
                      <li>
                        <span>2.2</span>
                        <p>
                          {ingerman == "de"
                            ? `MindRepublic zieht das Entgelt für den Coach bei den Nutzern ein, die Coachings bei dem Coach gebucht haben und übernimmt die diesbezügliche Abwicklung. MindRepublic erhält 20% des vom Coach festgelegten Entgelts pro Stunde bei jeder vom Coach durchgeführten Coaching-Session als Entgelt für seine Tätigkeit. Es zählt das vom Coach festgelegte Entgelt pro Stunde ohne Steuer. MindRepublic wird dem Coach eine Rechnung zur Verfügung stellen, aus der sich das Kommissionsentgelt und die Steuer, sofern anwendbar, ergibt. Ferner wird MindRepublic eine Rechnung im Auftrag des Coaches für den Kunden erstellen für die geleistete Coaching-Session, aus der sich sein Entgelt und Steuer, sofern anwendbar, ergeben. Zu diesem Zweck muss der Coach seine persönlichen Angaben zwingend korrekt zu jeder Zeit angeben. Der Coach ist verpflichtet, Rechnungen innerhalb von sieben (7) Werktagen zu überprüfen und zu reklamieren, falls diese fehlerhaft sein oder Informationen fehlen sollten.`
                            : `MindRepublic collects the fee for the Coach from the
                        users who have booked coaching sessions with the Coach
                        and handles the related processing. MindRepublic
                        receives 20% of the fee per hour set by the Coach for
                        each coaching session conducted by the Coach as
                        compensation for its services. The Coach's hourly fee
                        without tax applies. MindRepublic will provide for an
                        invoice outlining the commission fee and the VAT, if
                        applicable. Furthermore, MindRepublic will create an
                        invoice on behalf of the Coach for the conducted
                        coaching session towards the client outlining the
                        Coach’s hourly rate and VAT, if applicable. For this
                        purpose, the Coach must keep his or her personal
                        information accurate at all times. The Coach is required
                        to check the invoices within seven (7) business days and
                        to notify MindRepublic in the event an error occurred or
                        information is missing.`}
                        </p>
                      </li>
                      <li>
                        <span>2.3</span>
                        <p>
                          {ingerman == "de"
                            ? `MindRepublic bestimmt eigenverantwortlich, den Marketing-Auftritt von MindRepublic sowie die Rahmenbedingungen der Dienstleistungserbringung. Der Coach stimmt zu, in der App und der Website von MindRepublic benannt zu werden und darf mit den Informationen, die der Coach zur Verfügung stellt, werben (z.B. im Rahmen eines Showcases auf der Website). Eine weitergehende Nutzung der von dem Coach zur Verfügung gestellten Informationen ist nur dann gestattet, wenn der Coach explizit zustimmt. Der Coach besitzt keinen Anspruch auf explizite Promotion seiner Person durch MindRepublic. Es steht Coaches, die ein Interesse haben, individuell gegenüber Dritten beworben zu werden, jedoch frei eine separate Vereinbarung mit MindRepublic zu treffen, um diese tiefgehender zu promoten.`
                            : ` MindRepublic independently determines the marketing
                        presence of MindRepublic as well as the framework
                        conditions for service provision. The Coach agrees to be
                        named in the MindRepublic app and website and may
                        advertise with the information provided by the Coach
                        (e.g., as part of a showcase on the website). Further
                        use of the information provided by the Coach is only
                        permitted with the Coach's explicit consent. The Coach
                        has no claim to explicit promotion of their person by
                        MindRepublic. However, coaches who are interested in
                        being individually promoted to third parties are free to
                        negotiate a separate agreement with MindRepublic for
                        more extensive promotion.`}
                        </p>
                      </li>
                    </ul>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Rechte und Pflichten des Coaches`
                          : `Rights and Obligations of the Coach`}{" "}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>3.1</span>
                        <p>
                          {ingerman == "de"
                            ? `Es steht dem Coach frei, Informationen, die ihn oder seine Tätigkeit betreffen, in die App oder Website von MindRepublic einzugeben. Es sei darauf hingewiesen, dass die Wahrscheinlichkeit der erfolgreichen Buchung des Coaches mit der Aussagekraft des eigenen Profils steigt. Der Coach ist verpflichtet, seinen Namen, sein Entgelt pro Stunde und seine Bankverbindung als Mindestangaben festzulegen. MindRepublic ist berechtigt weitere Angaben des Coaches zu verlangen, sollten diese für die ordnungsgemäße Abwicklung von Coaching-Sessions notwendig sein.`
                            : ` The Coach is free to enter information concerning
                        themselves or their activities into the MindRepublic app
                        or website. It should be noted that the likelihood of
                        successfully booking the Coach increases with the
                        meaningfulness of their own profile. The Coach is
                        obliged to specify their name, hourly fee, and bank
                        details as minimum information. MindRepublic is entitled
                        to request further information from the Coach if
                        necessary for the proper conduct of coaching sessions.`}
                        </p>
                      </li>
                      <li>
                        <span>3.2</span>
                        <p>
                          {ingerman == "de"
                            ? `Der Coach ist verpflichtet, bei ihm gebuchte Coaching-Sessions auch durchzuführen. Sollte eine gebuchte Coaching-Session wegen Krankheit nicht durchführbar sein, so hat der Coach 24 Stunden vor der gebuchten Coaching-Session dem betroffenen Nutzer und Coachee abzusagen und MindRepublic zu informieren. MindRepublic stellt hierfür in der App und auf der Website eine Chat-Möglichkeit zur Verfügung. Sollte eine technische Störung vorliegen, hat der Coach MindRepublic hierüber zu informieren und MindRepublic wird dem Nutzer und Coachee diese Information weiterleiten. `
                            : `The Coach is obliged to conduct coaching sessions booked
                        with them. If a booked coaching session cannot be
                        carried out due to illness, the Coach must cancel it
                        with the affected user and coachee 24 hours before the
                        booked coaching session and inform MindRepublic.
                        MindRepublic provides a chat option for this purpose in
                        the app and on the website. If a technical malfunction
                        occurs, the Coach must inform MindRepublic, and
                        MindRepublic will forward this information to the user
                        and coachee`}
                        </p>
                      </li>
                      <li>
                        <span>3.3</span>
                        <p>
                          {ingerman == "de"
                            ? `Coachings sind digital durchzuführen und der Coach ist verpflichtet, das relevante Equipment hierfür selbst zu stellen (stabile Internetverbindung, Laptop/PC/Tablet mit Webcam, Lizenz für die Durchführung von Videokonferenzen bei Zoom, MS Teams oder anderen Providern, etc.). Der Coach hat vor Durchführung einer Coaching-Session zu prüfen, ob sein Equipment einsatzbereit und aktuell ist.
`
                            : `Coaching sessions are to be conducted digitally, and the
                        Coach is obliged to provide the relevant equipment
                        themselves (stable internet connection, laptop/PC/tablet
                        with webcam, license for conducting video conferences
                        with Zoom, MS Teams, or other providers, etc.). The
                        Coach must check whether their equipment is ready for
                        use and up-to-date before conducting a coaching session.`}
                        </p>
                      </li>
                      <li>
                        <span>3.4</span>
                        <p>
                          {ingerman == "de"
                            ? `MindRepublic behält sich das Recht vor, bei der unentschuldigten Nichtdurchführung von Coachings den entgangenen Gewinn als Schadensersatz gegenüber dem Coach geltend zu machen.`
                            : `MindRepublic reserves the right to claim damages from
                        the Coach for any unjustified failure to conduct
                        coaching sessions.`}
                        </p>
                      </li>
                      <li>
                        <span>3.5</span>
                        <p>
                          {ingerman == "de"
                            ? `Der Coach verpflichtet sich, alle Informationen, die sie oder er im Rahmen der Dienstleistungsbeziehung zum Nutzer oder über die (internen) Abläufe von MindRepublic als Unternehmung erhält, gemäß Ziffer 4 vertraulich zu behandeln. `
                            : `The Coach undertakes to treat all information they
                        receive in the course of the service relationship with
                        the user or about the (internal) processes of
                        MindRepublic as confidential in accordance with Section
                        4.`}
                        </p>
                      </li>
                      <li>
                        <span>3.6</span>
                        <p>
                          {ingerman == "de"
                            ? `Der Coach ist verpflichtet, bei der Durchführung von Coaching-Sessions unter dem Firmennamen "MindRepublic“ aufzutreten.
`
                            : ` The Coach is obliged to act under the company name
                        "MindRepublic" when conducting coaching sessions.`}
                        </p>
                      </li>
                      <li>
                        <span>3.7</span>
                        <p>
                          {ingerman == "de"
                            ? `Dem Coach ist es freigestellt, neben der Durchführung von Coaching-Sessions selbständig oder in abhängiger Beschäftigung anderen Tätigkeiten nachzugehen. Führt der Coach Coaching-Sessions bei MindRepublic durch, hat er sicherzustellen, zu unterlassen, dass (i) Nutzer zugunsten der selbständigen Tätigkeit des Coaches oder seinem Arbeitgeber durch ihn abgeworben werden, (ii) Werbung für Dritte gemacht wird und (iii) dass der Coach nicht unter anderem Firmennamen als MindRepublic in Erscheinung tritt.
`
                            : ` The Coach is free to engage in other activities
                        independently or as an employee in addition to
                        conducting coaching sessions. If the Coach conducts
                        coaching sessions at MindRepublic, they must ensure that
                        (i) users are not recruited in favor of the Coach's
                        independent activity or their employer by them, (ii)
                        advertising is not made for third parties, and (iii) the
                        Coach does not appear under names other than
                        MindRepublic.`}
                        </p>
                      </li>
                      <li>
                        <span>3.8</span>
                        <p>
                          {ingerman == "de"
                            ? `Nach Durchführung des Coachings hat der Coach die personenbezogenen Daten des Nutzers zu löschen.
`
                            : `After conducting the coaching session, the Coach must
                        delete the personal data of the user`}
                        </p>
                      </li>
                    </ul>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Vertraulichkeit`
                          : `Confidentiality`}{" "}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>4.1</span>
                        <p>
                          {ingerman == "de"
                            ? `Der Coach verpflichtet sich, während
und nach der Laufzeit des Vertrages
Informationen, die ihr oder ihm von den Nutzern, MindRepublic, seinen verbundenen Unternehmen, Beratern oder in deren Auftrag von sonstigen Dritten im Zusammenhang mit der Zusammenarbeit zugänglich gemacht wurden oder zur Kenntnis gelangt sind  („vertrauliche Informationen“), geheim zu halten und gegen unbefugten Zugriff zu sichern. Zu den vertraulichen Informationen zählt auch die vertraglichen Abreden zwischen dem Coach und MindRepublic.`
                            : ` The Coach undertakes to keep confidential and secure
                        against unauthorized access during and after the term of
                        the contract any information made accessible to them by
                        users, MindRepublic, its affiliated companies,
                        consultants, or third parties acting on their behalf in
                        connection with the cooperation ("confidential
                        information"). This also includes the contractual
                        agreements between the Coach and MindRepublic.`}
                        </p>
                      </li>

                      <li>
                        <span>4.2</span>
                        <p>
                          {ingerman == "de"
                            ? `Jede Partei ist berechtigt, Informationen Dritten, insbesondere öffentlichen Stellen, offen zu legen, soweit diese Offenlegung aufgrund geltenden Rechts oder Verfügung von Behörden oder Gerichten erforderlich ist. Die Offenlegung ist der MindRepublic vorab mitzuteilen;
soweit dies rechtlich nicht zulässig ist, erfolgt die Mitteilung unverzüglich nach Entfall des Hinderungsgrundes.
`
                            : `Each party is entitled to disclose information to third
                        parties, in particular public authorities, to the extent
                        that such disclosure is required by applicable law or by
                        orders of authorities or courts. The disclosure must be
                        notified to MindRepublic in advance; if this is not
                        legally permissible, the notification must be made
                        immediately after the obstacle ceases to exist.`}
                        </p>
                      </li>
                      <li>
                        <span>4.3</span>
                        <p>
                          {ingerman == "de"
                            ? `Von der Geheimhaltungs- und Vertraulichkeitsverpflichtung
ausgeschlossen sind Informationen:
`
                            : ` Excluded from the obligation of confidentiality are
                        information:`}
                        </p>
                      </li>
                      <ul className="number-list-add">
                        <li>
                          <span>4.3.1</span>
                          <p>
                            {ingerman == "de"
                              ? `die öffentlich zugänglich sind und der dem Coach zum Zeitpunkt des Erhalts bereits bekannt waren, oder
`
                              : ` that are publicly accessible and were already known
                            to the Coach at the time of receipt, or`}
                          </p>
                        </li>
                        <li>
                          <span>4.3.2</span>
                          <p>
                            {" "}
                            {ingerman == `de`
                              ? `die unabhängig und selbständig von dem Coach entwickelt wurden, ohne gleichartige Informationen von MindRepublic gekannt oder verwendet zu haben, oder
`
                              : `that were independently and autonomously developed
                            by the Coach without having known or used similar
                            information from MindRepublic.`}
                          </p>
                        </li>
                      </ul>
                    </ul>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Änderungen an diesem Vertrag`
                          : `Changes to this Contract`}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>5.1</span>
                        <p>
                          {" "}
                          {ingerman == "de"
                            ? `Änderungen an diesem Vertrag werden durch MindRepublic spätestens einen Monat vor dem vorgeschlagenen Zeitpunkt ihres Inkrafttretens in Textform angeboten.
`
                            : `Changes to this contract will be offered by MindRepublic
                        in text form at least one month before the proposed date
                        of their entry into force.`}
                        </p>
                      </li>
                      <li>
                        <span>5.2</span>
                        <p>
                          {" "}
                          {ingerman == "de"
                            ? `Die von MindRepublic angebotenen Änderungen werden nur wirksam, wenn der Coach diese annimmt, gegebenenfalls im Wege der nachfolgend geregelten Zustimmungsfiktion.
`
                            : `The changes offered by MindRepublic become effective
                        only if the Coach accepts them, if necessary, by means
                        of the approval fiction regulated below.`}
                        </p>
                      </li>
                      <li>
                        <span>5.3</span>
                        <p>
                          {" "}
                          {ingerman == "de"
                            ? `Das Schweigen des Coaches gilt nur dann als Annahme des Änderungsangebots (Zustimmungsfiktion), wenn
`
                            : `The Coach's silence is only considered acceptance of the
                        change offer (approval fiction) if:`}
                        </p>

                       
                      </li>
                      <ul className="number-list-add">
                          <li>
                            <span>5.3.1</span>
                            <p>
                              {" "}
                              {ingerman == "de"
                                ? `die Hauptleistungspflichten unter diesem Vertrag durch das Änderungsangebot nicht betroffen sind oder
`
                                : `the main obligations under this contract are not
                            affected by the change offer, or`}
                            </p>
                          </li>
                          <li>
                            <span>5.3.2</span>
                            <p>
                              {" "}
                              {ingerman == "de"
                                ? `das Änderungsangebot erfolgt, um die Übereinstimmung der vertraglichen Regelungen mit einer veränderten Rechtslage wiederherzustellen, weil eine Regelung dieses Vertrages aufgrund einer gesetzlichen Änderung, einer veränderten Rechtsprechung oder behördlichen Entscheidung gegen geltendes Recht verstoßen würde.
`
                                : `the change offer is made to restore the conformity
                            of the contractual provisions with a changed legal
                            situation because a provision of this contract would
                            violate applicable law due to a legal change, a
                            changed jurisdiction, or an administrative decision.`}
                            </p>
                          </li>
                        </ul>

                      <li>
                        <span>5.4</span>
                        <p>
                          {" "}
                          {ingerman == "de"
                            ? `Macht MindRepublic von der Zustimmungsfiktion Gebrauch, kann der Coach den Vertrag vor dem vorgeschlagenen Zeitpunkt des Inkrafttretens fristlos und kostenfrei kündigen. Auf dieses Kündigungsrecht wird MindRepublic in seinem Änderungsangebot separat hinweisen.
`
                            : `If MindRepublic makes use of the approval fiction, the
                        Coach can terminate the contract without notice and free
                        of charge before the proposed date of entry into force
                        of the changes. MindRepublic will separately notify the
                        Coach of this right of termination in its change offer.`}
                        </p>
                      </li>
                    </ul>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Laufzeit und Kündigung`
                          : `Term and Termination`}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>6.1</span>
                        <p>
                          {ingerman == "de"
                            ? `Dieser Vertrag wird auf unbestimmte Zeit geschlossen. Sowohl MindRepublic als auch der Coach können den Vertrag jederzeit mit einer Frist von 14 Tagen kündigen.
`
                            : `This contract is concluded for an indefinite period.
                        Both MindRepublic and the Coach can terminate the
                        contract at any time with a notice period of 14 days.`}
                        </p>
                      </li>
                      <li>
                        <span>6.2</span>
                        <p>
                          {" "}
                          {ingerman == "de"
                            ? `Die Kündigung schriftlich, per E-Mail an `
                            : `Termination must be made in writing, via email to `}
                          {/* <Link> */}
                          <a href="mailto:support@mindrepublic.com">
                            {" "}
                            support@mindrepublic.com
                          </a>
                          {/* </Link> */}
                          {ingerman == "de"
                            ? " oder im Login-Bereich selbst erklärt werden."
                            : ", or through the login area."}
                        </p>
                      </li>
                      <li>
                        <span>6.3</span>
                        {ingerman == "de"
                          ? `Das Recht der Parteien zur fristlosen
Kündigung aus wichtigem Grund bleibt
unberührt. Eine fristlose Kündigung tritt mit Zugang der Kündigungserklärung in Kraft. Ein wichtiger Grund für MindRepublic ist insbesondere dann gegeben, wenn
`
                          : `The parties' right to terminate immediately for
                        significant cause remains unaffected. Immediate
                        termination takes effect upon receipt of the termination
                        notice. Significant cause for MindRepublic exists, in
                        particular, if:`}

                       
                      </li>
                      <ul className="number-list-add">
                          <li>
                            <span>6.3.1</span>
                            <p>
                            {ingerman == "de"
                              ? `der Coach gegen wesentliche Pflichten aus diesem Vertrag (z.B. Ziffern 3.2, 3.5, 3.6 und 3.7) verstößt;
`
                              : `the Coach breaches essential obligations under this
                            contract (e.g., sections 3.2, 3.5, 3.6, and 3.7);`}
                            </p>
                            
                          </li>
                          <li>
                            <span>6.3.2</span>
                            <p>
                            {ingerman == "de"
                              ? `der Coach das Ansehen der Marke "MindRepublic“ beschädigt`
                              : `the Coach damages the reputation of the brand "MindRepublic";`}
                            </p>
                            
                          </li>
                          <li>
                            <span>6.3.3</span>
                            <p>
                            {ingerman == "de"
                              ? `die Regelungen der AGB wiederholt oder schwerwiegend verletzt, etwa durch unflätige oder ungebührliche Bemerkungen gegenüber Nutzer; oder`
                              : ` the Coach repeatedly or seriously violates the
                            provisions of the terms and conditions, such as
                            through obscene or inappropriate remarks towards
                            users; or`}
                            </p>
                            
                          </li>
                          <li>
                            <span>6.3.4</span>
                            <p>
                            {ingerman == "de"
                              ? `der Coach ein Änderungsangebot (i.S.v. Ziffer 5.2) nicht annimmt.
`
                              : ` the Coach does not accept a change offer (as per
                            Section 5.2).`}
                            </p>
                           
                          </li>
                        </ul>
                      <li>
                        <span>6.4</span>
                        <p>{ingerman == "de"
                          ? `Mit Inkrafttreten der Kündigung ist es dem Coach untersagt, im Namen von MindRepublic aufzutreten und/oder Coachings über die App oder auf der Website von MindRepublic anzubieten oder zu erbringen. Ferner hat sie oder er alle personenbezogenen Daten und sonstige Informationen, die sie oder er im Rahmen ihrer oder seiner Geschäftsbeziehung zu MindRepublic erhalten hat, zu löschen.
`
                          : `Upon the termination taking effect, the Coach is
                        prohibited from acting on behalf of MindRepublic and/or
                        offering or providing coaching sessions via the
                        MindRepublic app or website. Furthermore, they must
                        delete all personal data and other information received
                        in the course of their business relationship with
                        MindRepublic.`}</p>
                        
                      </li>
                    </ul>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Urheberrechte von MindRepublic
`
                          : `Copyrights of MindRepublic`}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>7.1</span>
                        <p>{ingerman == "de"
                          ? `Die Inhalte, die seitens MindRepublic zur Verfügung gestellt werden, sind urheberrechtlich geschützt. 
`
                          : `The content provided by MindRepublic is protected by
                        copyright.`}</p>
                        
                      </li>
                      <li>
                        <span>7.2</span>
                        <p> {ingerman == "de"
                          ? `Der Coach akzeptiert, dass MindRepublic der ausschließliche Berechtigte an den Informationen ist, die seitens MindRepublic zur Verfügung gestellt werden.

`
                          : `The Coach acknowledges that MindRepublic is the
                        exclusive owner of the information provided by
                        MindRepublic.`}</p>
                       
                      </li>
                    </ul>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Keine Arbeitnehmerstellung des Coaches`
                          : `Non-Employee Status of the Coach`}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>8.1</span>
                        <p>{ingerman == "de"
                          ? `Der Coach wird von MindRepublic nicht angestellt und ist daher kein Arbeitnehmer. Er ist frei, sein Entgelt, seine Arbeitszeit und die von ihm verwendeten Arbeitsmaterialien sowie die Art der Ausführung seiner Coaching-Leistung selbst zu bestimmen.
`
                          : `The Coach is not employed by MindRepublic and therefore
                        is not an employee. They are free to determine their
                        compensation, working hours, the materials they use, and
                        the manner in which they perform their coaching
                        services.`}</p>
                        
                      </li>
                      <li>
                        <span>8.2</span>
                        <p>{ingerman == "de"
                          ? `Der Coach ist verpflichtet, sich um die Rahmenbedingungen seiner Tätigkeit, wie z.B. die steuerliche Behandlung seiner Tätigkeit, Sozialabgaben, Rentenversicherung, etc., selbst zu kümmern.`
                          : `The Coach is obligated to take care of the conditions of
                        their work, such as the tax treatment of their work,
                        social security contributions, pension insurance, etc.,
                        themselves.`}</p>
                        
                      </li>
                    </ul>

                    <li>
                      <b>
                        {ingerman == "de" ? `Datenschutz` : "Data Protection"}{" "}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>9.1</span>
                        <p>
                        {ingerman == "de"
                          ? `Einzelheiten zum Schutz und der Nutzung der durch den Coach zur Verfügung gestellten personenbezogenen Daten durch MindRepublic können unserer Datenschutzerklärung entnommen werden `
                          : ` Details regarding the protection and use of the personal
                        data provided by the Coach to MindRepublic can be found
                        in our Privacy Policy `}
                        <Link to="https://mindrepublic.com/data-protection">
                          https://mindrepublic.com/data-protection
                        </Link>
                        .
                        </p>
                       
                      </li>
                    </ul>
                    <li>
                      <b>
                        {ingerman == "de"
                          ? `Anwendbares Recht und Gerichtsstand`
                          : "Applicable Law and Jurisdiction"}{" "}
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>10.1</span>
                        <p>{ingerman == "de"
                          ? `Dieser Vertrag unterliegt dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Gerichtsstand ist Frankfurt/Main. 
`
                          : `This contract is subject to the laws of the Federal
                        Republic of Germany, excluding the UN Convention on
                        Contracts for the International Sale of Goods. The place
                        of jurisdiction is Frankfurt/Main.`}</p>
                        
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
