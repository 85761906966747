import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutCoach from "../Components/LayoutCoach";
export default function NotificationCoachn() {
  return (
    <LayoutCoach>
      <div className="right-side">
        {/* <div className="back-page-i">
          <Link to="/notification">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
            >
              <path
                d="M12 22L2 12L12 2"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div> */}

        <div className="main-content">
          <h2>Notification</h2>
          <div className="notification-list-box">
            <div className="notification-box">
              <div className="notification-check">
                <spna>
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="33" height="33" rx="4" fill="#A2AA96" />
                    <g clip-path="url(#clip0_4396_400)">
                      <path
                        d="M23.8334 11L13.7501 21.0833L9.16675 16.5"
                        stroke="white"
                        stroke-width="2.0625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4396_400">
                        <rect
                          width="19.25"
                          height="13.75"
                          fill="white"
                          transform="translate(6.875 9.625)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </spna>
                <h3>Lorium Ipsum</h3>
              </div>
              <p>
                An appointment with a coach has been successfully scheduled
                Please pay the amount.
              </p>
              <p>23 Jan 2023 at 3:45 pm</p>
            </div>
            <div className="notification-box">
              <div className="notification-check">
                <spna>
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="33" height="33" rx="4" fill="#A2AA96" />
                    <g clip-path="url(#clip0_4396_400)">
                      <path
                        d="M23.8334 11L13.7501 21.0833L9.16675 16.5"
                        stroke="white"
                        stroke-width="2.0625"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4396_400">
                        <rect
                          width="19.25"
                          height="13.75"
                          fill="white"
                          transform="translate(6.875 9.625)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </spna>
                <h3>Lorium Ipsum</h3>
              </div>
              <p>
                An appointment with a coach has been successfully scheduled
                Please pay the amount.
              </p>
              <p>23 Jan 2023 at 3:45 pm</p>
            </div>
          </div>
        </div>
      </div>
    </LayoutCoach>
  );
}
