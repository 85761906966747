import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import LayoutNew from "../Components/LayoutNew";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { getMedicationFavList } from "../Redux/Actions/userActions";
import { useSelector } from "react-redux";

export default function FavouriteCourse() {
  const BASE_URL = "https://api.mindrepublic.com/";

    // ======================================check for language=======================================

    const ingerman = useSelector((state)=>state.homedata.selectedLang)
    console.log(ingerman)

  //  ===================================fetch data using usequery===================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["favouriteCourse"],
    queryFn: getMedicationFavList,
  });

  {
    // isSuccess && dispatch(reflectionList(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data, "data");

  return (
    <>
      <LayoutNew>
        <div style={{ position: "relative" }} className="right-side">
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}
          <div className="back-page-i">
            <Link to="/settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>{ingerman=="de" ? "Lieblingskurse" : "Favorite Course"}</h2>

            <div className="meditation-listing">
              { data?.data?.medication?.length>0 ? data?.data?.medication?.map((fav) => {
                return (
                  <div className="meditation-card-item">
                    <div className="medi-icon-txt">
                      {/* <img src={require("")} /> */}
                      <div className="medi-shw-icon">
                        {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="84"
                    viewBox="0 0 56 84"
                    fill="none"
                  >
                    <path
                      d="M49.7778 7V0H43.5556V7H37.3333V0H31.1111V7H24.8889V0H18.6667V7H12.4444V0H6.22222V7H0V73.5C0 76.2848 0.983331 78.9555 2.73367 80.9246C4.48401 82.8938 6.85798 84 9.33333 84H46.6667C49.142 84 51.516 82.8938 53.2663 80.9246C55.0167 78.9555 56 76.2848 56 73.5V7H49.7778ZM31.1111 59.5H12.4444V52.5H31.1111V59.5ZM43.5556 45.5H12.4444V38.5H43.5556V45.5ZM43.5556 31.5H12.4444V24.5H43.5556V31.5Z"
                      fill="#C1C9B6"
                    />
                  </svg> */}
                        <div className="inbox-img-detail">
                          <img src={`${BASE_URL}${fav?.image}`} />
                        </div>
                      </div>
                      <div className="medi-catg-nme">
                        <h3>{ingerman =="de" ? fav?.germen_title : fav?.title}</h3>
                        <p>{fav?.course_count} Course</p>
                      </div>
                    </div>
                    <div className="">
                      <Link to={`/theory/${fav?._id}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          fill="none"
                        >
                          <rect
                            width="50"
                            height="50"
                            rx="6.125"
                            fill="#4CA890"
                          />
                          <path
                            d="M20 15.625L29.1875 24.8125L20 34"
                            stroke="white"
                            stroke-width="1.8375"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                )
              }):<p style={{textAlign:"center",marginTop:"100px"}}>{ingerman=="de" ? "Keine Daten gefunden" : "Data not found"}</p>
              }


            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
