import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useSelector } from "react-redux";
export default function GetStarted() {
  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  return (
    <LayoutNew>
      <div className="right-side">
        <div className="main-content">
          <h2>{ingerman=="de" ? "Willkommen bei MindRepublic" : "Welcome to MindRepublic"}</h2>
          <div className="get-started-content">
            <h3>Reflection Journeys</h3>
            <p>
              {ingerman == "de"
                ? `Reflection Journeys laden Dich ein, über Dich selbst nachzudenken, in dem sie Story-telling, Wissen und interaktive Methoden miteinander kombinieren. Reflection Journeys beginnen mit einem Audio-Impuls und mit diesem Impuls versehen, beginnt Deine Reise zu Dir selbst. Auf Basis von Coaching-Methoden, die von CoachInnen im Rahmen einer (physischen) Session auch angewandt werden würden, gehst Du in die Selbstreflektion. Du bist frei, die Ergebnisse in der App niederzuschreiben und für eine spätere Coaching-Sessions nutzbar zu machen.`
                : `Reflection Journeys invite you to reflect on yourself by combining
              storytelling, knowledge, and interactive methods. They begin with
              an audio prompt, and with this impulse, your journey of
              self-discovery starts. Based on coaching techniques that would be
              used by coaches in a (physical) session, you engage in
              self-reflection. You are free to write down your findings in the
              app and make them available for later coaching sessions.`}
            </p>
            <h3>Coaching</h3>
            <p>
              {ingerman == "de"
                ? `MindRepublic vermittelt auch Coaching. Coaching unterstützt Dich dabei, Deine Ziele zu erreichen, bestimmte Fähigkeiten zu entwickeln oder mit bestimmten Situationen besser umgehen zu können. Es geht also darum, Dich dabei zu unterstützen, dorthin zu gelangen, wo Du Dich sehen willst. Das kann auch nur darin liegen, Dich selbst besser zu verstehen.`
                : `MindRepublic also offers coaching. Coaching helps you achieve your
              goals, develop specific skills, or better handle certain
              situations. It's about supporting you to get where you want to see
              yourself, which may also involve understanding yourself better.`}
            </p>
            <p>
              {ingerman == "de"
                ? `Eine/n CoachIn kannst Du direkt in der App buchen. Es sind nur CoachInnen bei MindRepublic gelistet, die eine mehrjährige Ausbildung mit Zertifikatsabschluss und/oder eine mehrjährige Berufspraxis nachweisen können. Wähle eine/n CoachIn aus, die Dir zusagt. MindRepublic übernimmt den Rest und Du erhältst für die gebuchte Session eine Einladung per Mail mit den Zoom-Zugangsdaten.`
                : `You can book a coach directly in the app. Only coaches listed on
              MindRepublic have several years of training with a certificate
              completion and/or several years of professional practice. Choose a
              coach that appeals to you. MindRepublic takes care of the rest,
              and you will receive an invitation for the booked session via
              email with Zoom access details.`}
            </p>
            <h3>Meditation/MBSR</h3>
            <p>
              {ingerman == "de"
                ? `Die App ist Dein Selfcare Hub: Höre in Dich hinein und entdecke Dich selbst mit den Reflection Journeys, entwickle Dich weiter mit Coaching und gönne Dir eine Auszeit mit Meditation. Unsere Meditationen sind konform mit den Standards von Mindfulness-Based Stress Reduction (MBSR). Zahlreiche Studien belegen den positiven Einfluss von Meditation auf das allgemeine Wohlbefinden und damit auf die Gesundheit.`
                : `The app is your self-care hub: Tune into yourself and discover
              more with Reflection Journeys, grow with coaching, and take a
              break with meditation. Our meditations are in line with the
              standards of Mindfulness-Based Stress Reduction (MBSR). Numerous
              studies confirm the positive impact of meditation on general
              well-being and health.`}
            </p>

            <div className=" " style={{ textAlign: "center" }}>
              <p>
                {ingerman == "de"
                  ? `Alle Inhalte werden sukzessive erweitert.`
                  : `All content is progressively expanded.`}
              </p>
              {/* <Link to="http://www.mindrepublic.io/bookacoaching">
                http://www.mindrepublic.io/bookacoaching
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </LayoutNew>
  );
}
