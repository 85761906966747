import React, { useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form, FormLabel } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";

export default function QuestionnaireOption() {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <>
      <LayoutNew>
        <div className="right-side">
          <div className="back-page-i">
            <Link to="/reflection-journey-view">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="main-content">
            <div className="right-headings">
              <h2>Questionnaire</h2>
            </div>
            <div className="questionnaire-type">
              <div className="radio-button-containeras">
                <Form.Label className="mb-3">
                  Q2- Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Form.Label>
                <div className="option-sec-que">
                  <Form.Label
                    className={`radio-button-label ${
                      selectedOption === "option1" && "selected"
                    }`}
                  >
                    <Form.Control
                      type="radio"
                      value="option1"
                      checked={selectedOption === "option1"}
                      onChange={handleOptionChange}
                    />
                    Option 1
                  </Form.Label>
                  <Form.Label
                    className={`radio-button-label ${
                      selectedOption === "option2" && "selected"
                    }`}
                  >
                    <Form.Control
                      type="radio"
                      value="option2"
                      checked={selectedOption === "option2"}
                      onChange={handleOptionChange}
                    />
                    Option 2
                  </Form.Label>
                  <Form.Label
                    className={`radio-button-label ${
                      selectedOption === "option3" && "selected"
                    }`}
                  >
                    <Form.Control
                      type="radio"
                      value="option3"
                      checked={selectedOption === "option3"}
                      onChange={handleOptionChange}
                    />
                    Option 3
                  </Form.Label>
                  <Form.Label
                    className={`radio-button-label ${
                      selectedOption === "option4" && "selected"
                    }`}
                  >
                    <Form.Control
                      type="radio"
                      value="option4"
                      checked={selectedOption === "option4"}
                      onChange={handleOptionChange}
                    />
                    Option 4
                  </Form.Label>
                </div>
              </div>

              <div className="bck-nxt-btns">
                <Link to="/questionnaire-type">Back</Link>
                <Link to="/story-timeline-add">Next</Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
