import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userLogin } from "../Redux/Actions/userActions";
import { remmbermeData, Userdata } from "../Redux/Reducers/registrationSlice";
import { useDispatch, useSelector } from "react-redux";
import { coachLogin } from "../Redux/Actions/coachAction";
import GoogleLoginButton from "../Utils/GoogleLogin";
import Googlelogin from "../Utils/GoogleLogin";

export default function Login() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clientId =
    "667130674163-i2n7khlveapedmp2de89v943lm2afd72.apps.googleusercontent.com";

    const ingerman = useSelector((state) => state.homedata.selectedLang);
    console.log(ingerman);

    const fcm = sessionStorage.getItem("FCM Token")

  // ================================================REACT STATES=========================================

  const [userdetails, setUserdetails] = useState({
    email: "",
    password: "",
    device_token: fcm
  });
  const [show,setshow] = useState(false)

  // ================================================get user data from redux===========================================

  const remember = useSelector((state) => state?.registerUserdata?.rememberMe);

  console.log(remember, "data");

  useEffect(() => {
    if (remember) {
      setUserdetails((old) => ({ ...old, email: remember?.email }));
      setUserdetails((old) => ({ ...old, password: remember?.password }));
    }
  }, [remember]);

  // =======================================check for userrole==============================

  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // ==============================================language==========================================

  const { t } = useTranslation();
  const login = t("login");

  // ==========================================get data from inputs========================================

  const handlechange = (e) => {
    setUserdetails((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending, mutate } = useMutation({
    mutationFn: userrole == "user" ? userLogin : coachLogin,
    onSuccess: (res) => {
      console.log(res);
      sessionStorage.setItem("token", res?.data?.token);
      dispatch(Userdata(res?.data));

      if (res?.success || res.message == "Please verify your account first") {
        // res?.data?.role== 0 ?  navigate("/get-started") : ""

        if (res?.data?.email_verified == 0) {
          navigate("/verify-email");
        } else {
          toast.success(res?.message);
          if (userrole == "coach" && res?.data?.profile_status == 0) {
            window.location.href = "/create-profile";
          } else if (userrole == "coach" && res?.data?.profile_status == 1) {
            window.location.href = "/get-started-coach";
          } else {
            window.location.href = "/get-started";
          }
        }
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message || "Something went wrong");
    },
  });

  // =============================================login=========================================

  const handlelogin = () => {
    let isvalid = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!userdetails?.email) {
      toast.error(ingerman=="de" ? "Bitte geben Sie Ihre E-Mail-Adresse ein":"Please enter email");
      isvalid = false;
    } else if (!emailRegex.test(userdetails?.email)) {
      toast.error(ingerman=="de" ? "Bitte geben Sie eine gültige Email-Adresse ein" : "Please Enter a Valid Email");
      isvalid = false;
    } else if (!userdetails?.password) {
      toast.error(ingerman=="de" ? "Bitte Passwort eingeben" : "Please Enter password");
      isvalid = false;
    }
    if (isvalid) {
      console.log("yiipppeeee", userdetails);
      
        mutate(userdetails);

      

      
    }
  };

  return (
    <section className="Login-sec">
      <Container style={{ position: "relative" }}>
        {/* {isPending && (
          <div
            style={{
              position: "absolute",
              width: "110%",
              height: "100%",
              zIndex: "1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              // backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <div class="loader"></div>
          </div>
        )} */}
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                <h2>{login?.welcome}</h2>
                <p>{login?.signIn}</p>
              </div>
              <Form className="contact-form">
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={userdetails?.email}
                    onChange={handlechange}
                    type="email"
                    placeholder={ingerman=="de" ? "Geben Sie hier ein..." :"Type here..." }
                  />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>

                <Form.Group
                  className="mb-3 ad-height-input position-relative"
                  controlId="formBasicEmail"
                >
                  <Form.Label>{ingerman=="de" ? "Passwort":"Password"}</Form.Label>
                  <Form.Control
                    name="password"
                    value={userdetails?.password}
                    onChange={handlechange}
                    type={show?"text":"password"}
                    placeholder={ingerman=="de" ? "Geben Sie hier ein..." :"Type here..." }
                  />
              
                  <span className="input-eye-show" style={{cursor:"pointer"}} onClick={()=>setshow(!show)}>

                  {
                    show  ? <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.12249 0C12.8263 0.154398 15.7665 1.80304 17.7323 5.09244C18.089 5.68935 18.0879 6.31372 17.7371 6.91363C16.1908 9.5586 13.9064 11.2207 10.8837 11.8138C6.65697 12.6434 2.37134 10.6459 0.261879 6.92184C-0.0809653 6.31657 -0.0925898 5.69929 0.259013 5.0997C1.82721 2.426 4.14225 0.782249 7.20619 0.182499C7.43486 0.137821 7.66576 0.0985113 7.89761 0.0778303C8.26227 0.045151 8.62837 0.0303112 9.12249 0ZM8.95608 10.877C9.36899 10.8432 9.74607 10.8138 10.123 10.7808C10.2359 10.7709 10.3488 10.7562 10.4601 10.7355C13.1844 10.2295 15.2601 8.80066 16.6877 6.44349C16.8706 6.14133 16.8641 5.87042 16.6867 5.56684C14.8045 2.34612 11.1281 0.633693 7.42642 1.30954C4.71997 1.80367 2.68106 3.27219 1.28261 5.61941C1.12576 5.88258 1.12608 6.1347 1.28436 6.39676C3.0282 9.28454 5.62509 10.7218 8.95608 10.877Z"
                        fill="#40413A"
                      />
                      <path
                        d="M5.50105 6.00556C5.50216 4.08348 7.07418 2.53051 9.00879 2.54014C10.9445 2.54993 12.496 4.0928 12.4954 6.00745C12.4949 7.92953 10.9249 9.48014 8.98777 9.47193C7.05284 9.46372 5.49993 7.9199 5.50105 6.00556ZM11.3493 6.00493C11.3484 4.73075 10.2819 3.67507 8.99685 3.67634C7.71098 3.6776 6.64662 4.73375 6.64726 6.00761C6.64789 7.28052 7.7156 8.33763 8.99971 8.33715C10.2841 8.33668 11.3503 7.27847 11.3493 6.00493Z"
                        fill="#40413A"
                      />
                    </svg> :<svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.126908 6.54176C0.794012 5.40463 1.65272 4.43371 2.64636 3.58536C3.97413 2.45177 5.4719 1.67112 7.17658 1.34325C9.10234 0.972909 10.972 1.1673 12.7756 1.96285C12.9506 2.03998 13.0479 2.01565 13.1777 1.8776C13.7148 1.30638 14.263 0.74622 14.8125 0.187536C15.1389 -0.144312 15.651 -0.0171777 15.7725 0.422631C15.8405 0.668494 15.7576 0.87011 15.5845 1.04739C14.8946 1.75342 14.2085 2.46342 13.5211 3.17195C10.1047 6.69308 6.68906 10.2151 3.26854 13.7321C3.15778 13.846 3.00625 13.947 2.85714 13.9855C2.61503 14.048 2.38379 13.9018 2.2736 13.6772C2.1255 13.3749 2.30394 13.132 2.60515 12.8287C3.45513 11.9734 3.84134 11.5005 3.97084 11.3898C3.89987 11.3385 3.8558 11.3038 3.80915 11.2733C2.30738 10.2916 1.0905 9.01675 0.131916 7.47668C0.130915 7.47521 0.130056 7.47359 0.129054 7.47211C-0.0446623 7.1907 -0.0406556 6.82729 0.126908 6.54176ZM6.22257 9.02826C5.07839 7.25678 5.72818 5.41333 6.60535 4.5138C7.47178 3.62533 9.25774 2.95308 10.9468 4.11411C11.3253 3.72179 11.7004 3.33301 12.0743 2.94541C12.072 2.94409 12.0527 2.92948 12.0312 2.92108C10.1014 2.17125 8.15592 2.12508 6.21098 2.83303C4.11365 3.59642 2.52388 5.03414 1.25706 6.88526C1.19196 6.98039 1.21113 7.04041 1.26694 7.12271C1.87065 8.01546 2.57382 8.81514 3.40061 9.49107C3.84592 9.85508 4.32185 10.1794 4.78791 10.5245C5.27028 10.0251 5.71931 9.56054 6.16791 9.09551C6.18809 9.07472 6.20511 9.04994 6.22257 9.02826ZM10.1177 4.99889C9.29795 4.4259 8.06147 4.61984 7.34643 5.4182C6.6022 6.24929 6.5939 7.45146 7.0684 8.14112C8.08236 7.09631 9.09747 6.05033 10.1177 4.99889Z"
                        fill="#40413A"
                      />
                      <path
                        d="M6.17077 12.3833C6.47199 12.0749 6.75674 11.7765 7.05152 11.4891C7.09072 11.4509 7.18359 11.4477 7.24512 11.4608C9.91468 12.0304 12.2929 11.3597 14.4273 9.66526C15.3113 8.96336 16.0606 8.12593 16.7006 7.18451C16.7011 7.18392 16.7015 7.18333 16.7018 7.18274C16.7758 7.07375 16.7745 6.927 16.7003 6.81815C15.9993 5.79031 15.1619 4.90258 14.1919 4.15614C14.4694 3.87149 14.7395 3.59421 15.0177 3.30882C15.5597 3.72326 16.064 4.20599 16.5334 4.72736C17.029 5.27779 17.4782 5.86538 17.8644 6.50253C18.0506 6.80974 18.0436 7.20324 17.8524 7.50722C16.4278 9.77219 14.5402 11.4636 12.0216 12.3364C10.1342 12.9904 8.22016 13.0034 6.30542 12.4314C6.26707 12.4196 6.22987 12.4044 6.17077 12.3833Z"
                        fill="#40413A"
                      />
                      <path
                        d="M8.1263 10.3663C8.47115 10.013 8.79497 9.67897 9.1228 9.34919C9.15257 9.31925 9.20623 9.30657 9.25058 9.30096C10.3186 9.1638 11.1077 8.35158 11.237 7.25648C11.2425 7.21062 11.2535 7.15472 11.2824 7.12419C11.6026 6.78615 11.9269 6.45238 12.2556 6.11154C12.5579 6.91122 12.4104 8.28595 11.5929 9.2666C10.6566 10.39 9.24014 10.7073 8.1263 10.3663Z"
                        fill="#40413A"
                      />
                    </svg> 
                  }
                    

                    
                  </span>
                </Form.Group>
                <Form.Group
                  // style={{ justifyContent: "end" }}
                  className="mb-3 check-box-pp"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    type="checkbox"
                    className="me-2"
                    label={ingerman=="de" ? "Erinnere dich an mich" : "Remember me" }
                    onChange={(e) => {
                      console.log(e.target.checked, "check");
                      if (e.target.checked == true) {
                        dispatch(
                          remmbermeData({
                            email: userdetails?.email,
                            password: userdetails?.password,
                          })
                        );
                      } else {
                        dispatch(remmbermeData(null));
                      }
                    }}
                    checked={remember?.email && remember?.password}
                  />
                  <Link style={{ color: "black" }} to="/forgot-password">
                    {login?.forgot}
                  </Link>
                </Form.Group>
                <div className="d-flex justify-content-center">
                  <button
                    // to="/verify-email"
                    onClick={handlelogin}
                    variant="primary"
                    type="button"
                    className="theme-btn"
                  >
                    {isPending ? ingerman=="de" ? "Anmelden.." : "Signing in..." : ingerman=="de" ? "Anmelden" : "Sign in" }
                  </button>
                </div>
                <div className="next-page-signin">
                  <p>
                    {login?.dont} <Link to="/sign-up">{ingerman=="de" ? "Melden Sie sich an" : "Sign Up"}</Link>
                  </p>
                </div>
                <div className="text-center">
                  {/* <Link to="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="64"
                      height="64"
                      viewBox="0 0 64 64"
                      fill="none"
                    >
                      <g filter="url(#filter0_d_4311_38125)">
                        <circle cx="32" cy="28" r="28" fill="white" />
                        <circle
                          cx="32"
                          cy="28"
                          r="27.85"
                          stroke="#C1C9B6"
                          stroke-width="0.3"
                        />
                      </g>
                      <path
                        d="M44.0976 28.1108C44.0976 27.2461 44.0139 26.3535 43.8744 25.5166H31.7959V30.454H38.7139C38.4349 32.044 37.5144 33.4388 36.1475 34.3314L40.276 37.5393C42.7028 35.2798 44.0976 31.9882 44.0976 28.1108Z"
                        fill="#4280EF"
                      />
                      <path
                        d="M31.7973 40.6083C35.2563 40.6083 38.1574 39.4646 40.2774 37.5119L36.1489 34.3319C35.0052 35.1129 33.5268 35.5593 31.7973 35.5593C28.4499 35.5593 25.6325 33.2998 24.6004 30.2871L20.3604 33.5508C22.5362 37.8745 26.9436 40.6083 31.7973 40.6083Z"
                        fill="#34A353"
                      />
                      <path
                        d="M24.5999 30.2587C24.0699 28.6687 24.0699 26.9392 24.5999 25.3492L20.3599 22.0576C18.5467 25.684 18.5467 29.9519 20.3599 33.5504L24.5999 30.2587Z"
                        fill="#F6B704"
                      />
                      <path
                        d="M31.7973 20.0774C33.6105 20.0495 35.3957 20.7469 36.7068 22.0021L40.3611 18.32C38.0458 16.1442 34.9773 14.9726 31.7973 15.0005C26.9436 15.0005 22.5362 17.7342 20.3604 22.0579L24.6004 25.3495C25.6325 22.309 28.4499 20.0774 31.7973 20.0774Z"
                        fill="#E54335"
                      />
                      <defs>
                        <filter
                          id="filter0_d_4311_38125"
                          x="0"
                          y="0"
                          width="64"
                          height="64"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.635294 0 0 0 0 0.666667 0 0 0 0 0.588235 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_4311_38125"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_4311_38125"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </Link> */}

                  <Googlelogin />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
