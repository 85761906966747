import React, { useEffect, useRef, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n, { changeLanguage, use } from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutCoach from "../Components/LayoutCoach";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { coachRegistrationdata } from "../Redux/Reducers/coachRegistrationSlice";
import { useMutation } from "@tanstack/react-query";
import { editcoachProfile } from "../Redux/Actions/coachAction";
import { Userdata } from "../Redux/Reducers/registrationSlice";

export default function UploadCertificate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const BASE_URL = "https://api.mindrepublic.com/";

    // // ======================================check for language=======================================

    const ingerman = useSelector((state) => state.homedata.selectedLang);
    console.log(ingerman);

  // =====================================get data from redux=============================

  const submittedData = useSelector(
    (state) => state.coachregistrationData.coachregister
  );
  console.log(submittedData, "submitteddata");

  // ===============================================coach data from redux=======================================

  const coachData = useSelector(
    (state) => state.coachregistrationData.coachregister
  );

  console.log(coachData, "coach");

  // ================================================get user data from redux===========================================

  const userData = useSelector((state) => state?.registerUserdata?.user);

  console.log(userData, "data");

  // =========================================react states=================================

  const fileInputRef = useRef(null);
  const [certificate, setCertificate] = useState("");
  const [prev, setPrev] = useState("");
  const [prevCer, setPrevCer] = useState("");
  const [task, setTask] = useState(null);

  useEffect(() => {
    if (userData && location?.state?.edit) {
      setCertificate(userData?.certification ? userData?.certification : "");
      setPrev(userData?.certification)
    }
    setTask(location?.state?.edit);
  }, []);

  console.log(prev,"prev")

  useEffect(() => {
    if (submittedData?.certificate) {
      setCertificate(submittedData?.certificate);

      setPrevCer(URL?.createObjectURL(submittedData?.certificate));
    }
  }, [submittedData]);

  const handleClick = () => {
    fileInputRef.current.click();
  };


  // =================================post data using react-query=====================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: editcoachProfile,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        toast.success(res?.message);
        navigate("/my-profile-coach");
        // navigate(
        //   "/verify-email"
        //   //    {
        //   //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
        //   // }
        // );
        // dispatch(registrationData({}))
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });


  // ==========================================handlers=======================================================

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    // Handle the selected file here
    console.log("Selected file:", file);
    setCertificate(file);
    setPrevCer(URL?.createObjectURL(file));
  };

  console.log(certificate, "cerificate");
  console.log(prevCer, "prevCer");

  return (
    <>
      {/* <LayoutCoach> */}
      <div className="right-side w-100">
        <div className="back-page-i">
          <Link to="/create-profile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
            >
              <path
                d="M12 22L2 12L12 2"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>

        <div className="main-content">
          <h2> {task == "edit" ?( ingerman=="de" ? "Zertifikat bearbeiten" : "Edit Certificate") : (ingerman=="de" ? "Zertifikat hochladen" : "Upload Certificate")} </h2>
          <div className="upload-certificate">
            <p>
            {
              ingerman=="de" ? `MindRepublic garantiert seinen Nutzern, dass es sich ausschließlich um qualifizierte Coaches handelt
              auf der Plattform angeboten und beworben. Trainer, die durchgemacht haben
              Mehrjährige Ausbildung (z.B. DGSF) oder mindestens 3 Jahre
              Berufserfahrung wird akzeptiert. Bitte beweisen Sie dies entweder
              Hochladen Ihres Zeugnisses oder Verfassen eines kurzen Anschreibens
              Beschreibung Ihres beruflichen Hintergrunds. wenn du irgendeine Frage hast,
              kontaktieren Sie bitte` : ` MindRepublic guarantees its users that only qualified coaches are
              offered and advertised on the platform. coaches who have undergone
              several years of training (e.g. DGSF) or have at least 3 years of
              professional experience are accepted. Please prove this by either
              uploading your certificate or writing a short cover letter
              detailing your professional background. if you have any question,
              please contact`
            }
              {""}
              <Link to="mailto:support@mindrepublic.com">
              <p>support@mindrepublic.com</p>  
              </Link>
            </p>

            <div className="upload-certificate-box">
              {(prev || prevCer) ? (
                <div
                  className="d-flex justify-content-center"
                  onClick={handleClick}
                >
                  {certificate?.type === "application/pdf"  ? (
                    <embed src={prevCer ? prevCer : `${BASE_URL}${prev}`} type="application/pdf" width="100" />
                  ) : (
                    <img style={{ width: "100px" }} src={prevCer ? prevCer : `${BASE_URL}${prev}`} alt="Preview" />
                  )}
                </div>
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center"
                    onClick={handleClick}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="77"
                      height="90"
                      viewBox="0 0 77 90"
                      fill="none"
                    >
                      <path
                        d="M2 83.7974V6.30513C2 3.92747 3.92748 2 6.30513 2H52.3586C53.501 2 54.5965 2.45403 55.404 3.26212L73.7402 21.6126C74.5469 22.4199 75 23.5144 75 24.6556V83.7974C75 86.1751 73.0725 88.1026 70.6949 88.1026H6.30513C3.92747 88.1026 2 86.1751 2 83.7974Z"
                        fill="#B79C6C"
                        stroke="#B79C6C"
                        strokeWidth="2.15256"
                      />
                      <path
                        d="M48.8299 32.2776L41.1291 24.5768C40.4564 23.9042 39.544 23.5264 38.5927 23.5264C37.6414 23.5264 36.729 23.9042 36.0563 24.5768L28.3555 32.2776L30.8919 34.814L36.7989 28.907V48.6388H40.3865V28.907L46.2935 34.814L48.8299 32.2776Z"
                        fill="white"
                      />
                      <line
                        x1="19.2227"
                        y1="66.9719"
                        x2="57.9688"
                        y2="66.9719"
                        stroke="white"
                        strokeWidth="2.79833"
                      />
                    </svg>
                  </div>
                  <p onClick={handleClick}>Upload Certificate</p>
                </>
              )}

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
            </div>
            <div
              onClick={() => {
                let isvalid = true;

                if (!certificate) {
                  toast.error(ingerman=="de" ? "Bitte laden Sie ein Zertifikat hoch":"Please upload a certificate");
                  isvalid = false;
                } else if (
                  certificate?.type == "video/mp4" ||
                  certificate?.type == "audio/mpeg"
                ) {
                  toast.error(ingerman=="de" ? "Das Zertifikat muss eine Datei sein": "Cerificate must be a file");
                  isvalid = false;
                } else if (isvalid) {
                  if (task == "edit") {
                    const formdata = new FormData();
                    formdata.append("certification", certificate);

                    mutate(formdata);
                  } else {
                    dispatch(
                      coachRegistrationdata({
                        ...coachData,
                        certificate: certificate,
                      })
                    );
                    navigate("/create-profile-three");
                  }
                }
              }}
              className="invoice-details-link"
            >
              <Link
              //  to="/create-profile-three"
              >
                {task == "edit" ?(ingerman=="de" ? "Einreichen" : "Submit")  :(ingerman=="de" ? "Nächste" : "Next") }
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* </LayoutCoach> */}
    </>
  );
}
