import React, { useState } from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormControl, FormLabel } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LayoutCoach from "../Components/LayoutCoach";
import { useSelector } from "react-redux";

export default function BankAccount() {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const navigate=useNavigate()
  
  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman,"lang");
  return (
    <>
      {/* <LayoutCoach> */}
        <div className="right-side w-100">
          {/* <div className="back-page-i">
            <Link to="/coaching">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div> */}

          <div className="main-content">
            <div className="appointment-created-img">
              <img src={require("../Assets/images/account-created.png")} />
            </div>
            <div className="appointment-created-txt">
              <h2>{ingerman=="de" ? "Account erstellt!" : "Account Created!"}</h2>
              <p className="invoice-para">
              {
                ingerman=="de" ? "Ihr Konto wurde erfolgreich erstellt." : "Your account had been created successfully."
              }
                
                <br />
                {
                  ingerman=="de" ? "Bitte warten Sie auf die Genehmigung durch den Administrator." : "please wait for Admin Approval."
                }
                
              </p>
            </div>
            <div onClick={()=>{
              sessionStorage.clear()
              navigate("/")
            }} className="invoice-details-link d-flex justify-content-center">
              <Link className="w-50">{ingerman=="de" ? "Erledigt" : "Done"}</Link>
            </div>
          </div>
        </div>
      {/* </LayoutCoach> */}
    </>
  );
}
