import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useSelector } from "react-redux";
import AudioPlayer from "../Utils/AudioComponent";

export default function ReflectionJourneyAudio() {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location,"location")

  // =========================================get welcome sudio data=================================

  const welcomeData = useSelector((state) => state.reflectionData.list);
  console.log(welcomeData,"welcomedata");

  // ===================================================React State==========================================

  const [isPlaying, setIsPlaying] = useState(false);
  const [filterData, setFilterData] = useState(null);

  // ========================================get reflection from a category============================

  const categoryDetail = useSelector(
    (state) => state.reflectionData.catDetails
  );
  // console.log(categoryDetail,"details======================================")

  // useEffect(() => {
  //   let filterData = categoryDetail?.data?.selfReflections?.filter(
  //     (reflection) => reflection?._id == location?.state?.id
  //   );
  //   setFilterData(filterData);
  // }, []);

  // console.log(filterData,"filter")

  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // ========================================play audio=====================================================

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };


  

console.log(isPlaying,"isplaying")

  return (
    <>
      <LayoutNew>
        <div className="right-side">
          <div className="back-page-i">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
                // setIsPlaying(false)
              }}
              to="/reflection-journey-view"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="main-content">
            <div className="right-headings">
              <p>
                {location?.state && ingerman == "de"
                  ? location?.state?.german_title
                  : location?.state?.title}
              </p>
              <h2>
                {" "}
                {location?.state
                  ? ingerman == "de"
                    ? location?.state?.germen_subTitle
                    : location?.state?.subTitle
                  : ingerman == "de"
                  ? welcomeData?.data?.welcome_audio?.germen_title
                  : welcomeData?.data?.welcome_audio?.title}
              </h2>
            </div>
            <div className="reflection-journey-audio">
              <img
                onClick={togglePlay}
                style={{ cursor: "pointer" }}
                src={
                  isPlaying
                    ? require("../Assets/images/pausebutton.png")
                    : require("../Assets/images/plauButton.png")
                }
              />

              {/* <audio id="audioPlayer" controls>
                  <source src={`${BASE_URL}${filterData[0]?.audio}`} />
                </audio> */}
              {welcomeData && (
                <AudioPlayer
                  filterAudio={
                    location?.state?.audio
                      ? ingerman=="de" ?  location?.state?.germen_audio : location?.state?.audio
                      :ingerman=="de" ? welcomeData?.data?.welcome_audio?.germen_audio
                      : welcomeData?.data?.welcome_audio?.audio
                  }
                  id={location?.state?.id}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  audio_completed={location?.state?.audio_completed}
                  ref_id={location?.state?.ref_id}
                />
              )}
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
