import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutCoach from "../Components/LayoutCoach";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { coachRegistrationdata } from "../Redux/Reducers/coachRegistrationSlice";
import { Userdata } from "../Redux/Reducers/registrationSlice";
import { useMutation } from "@tanstack/react-query";
import { editcoachProfile } from "../Redux/Actions/coachAction";

export default function CreateProfileThree() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location, "loc");

   // // ======================================check for language=======================================

   const ingerman = useSelector((state) => state.homedata.selectedLang);
   console.log(ingerman);
 


  
   // =====================================get data from redux=============================

   const submittedData = useSelector((state)=>state.coachregistrationData.coachregister)
   console.log(submittedData,"submitteddata")

  // ================================================get user data from redux===========================================

  const userData = useSelector((state) => state?.registerUserdata?.user);

  console.log(userData, "userdata");

  // ===============================================coach data from redux=======================================

  const coachData = useSelector(
    (state) => state.coachregistrationData.coachregister
  );

  console.log(coachData, "coach");

  // ===========================================react states===================================================

  const [title, setTitle] = useState("");
  const [bio, setbio] = useState("");
  const [task, setTask] = useState(null);


  useEffect(()=>{

    if(submittedData){
      setTitle(submittedData?.title)
      setbio(submittedData?.bio)
    }

  },[submittedData])

  console.log(title)

  useEffect(() => {
    if (userData && location?.state?.edit) {
      setTitle(userData?.coachTitle ? userData?.coachTitle : "");
      setbio(userData?.coachBio ? userData?.coachBio : "");
    }
    setTask(location?.state?.edit)
   
  }, []);

  // =================================post data using react-query=====================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: editcoachProfile,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        toast.success(res?.message);
        navigate("/my-profile-coach")
        // navigate(
        //   "/verify-email"
        //   //    {
        //   //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
        //   // }
        // );
        // dispatch(registrationData({}))
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  return (
    <>
      {/* <LayoutCoach> */}
      <div style={{ position: "relative" }} className="right-side w-100">
            {isPending && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
        <div className="back-page-i" onClick={() => navigate(-1)}>
          <Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
            >
              <path
                d="M12 22L2 12L12 2"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>

        <div className="main-content">
          <h2>{task == "edit" ? (ingerman=="de" ? "Bearbeiten Profil" : "Edit Profile") :(ingerman=="de" ? "Erstellen Profil" :"Create Profile" )} </h2>
          <div className="common-txtarea">
            <div className="add-life-event">
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  value={title}
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder={ingerman=="de" ? "Trainertitel" : "Coach title"}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={8}
                  placeholder={ingerman=="de" ? "Bio..." : "Bio..."}
                  value={bio}
                  name="bio"
                  onChange={(e) => setbio(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
          <div
            onClick={() => {
            
              let isvalid = true;
              if (!title) {
                toast.error(ingerman=="de" ? "Bitte geben Sie den Trainertitel ein" : "Please enter coach title");
                isvalid = false;
              } else if (!bio) {
                toast.error(ingerman=="de"  ? "Bitte geben Sie Bio ein" :"Please enter Bio");
                isvalid = false;
              } else if (isvalid) {
                if (task == "edit") {
                  const formdata = new FormData();
                  formdata.append("coachTitle", title);
                  formdata.append("coachBio", bio);
                  mutate(formdata)
                } else {
                  dispatch(
                    coachRegistrationdata({
                      ...coachData,
                      title: title,
                      bio: bio,
                    })
                  );
                  navigate("/create-profile-rate");
                }
              }
            }}
            className="invoice-details-link"
          >
            <Link
            // to="/create-profile-rate"
            >
               { task == "edit" ? "Submit" : "Next"}
            </Link>
          </div>
        </div>
      </div>
      {/* </LayoutCoach> */}
    </>
  );
}
