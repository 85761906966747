import React, { useEffect } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import {
  getQueAns,
  getReflectionquestions,
} from "../Redux/Actions/userActions";
import {
  storyTimeLine,
  storyTimeLineID,
} from "../Redux/Reducers/reflectionSlice";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { coachLogin } from "../Redux/Actions/coachAction";

export default function StoryTimelineAdd() {
  const navigate = useNavigate();
  const { id } = useParams();

  console.log(id, "iiiidddd");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storyTimeLineID(id));
  });

  // ===================================reflection id from redux========================================

  const reflectionId = useSelector((state)=>state.reflectionData.reflectionIdforedit)
  console.log(reflectionId,"ref")

  // =============================================get Story Timeline=====================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["getstoryTimeLine"],
    queryFn: () => getQueAns(id),
  });

  {
    isSuccess && dispatch(storyTimeLine(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data?.data[0]?.storyTimeline, "data");

  return (
    <>
      <LayoutNew>
        <div className="right-side" style={{ position: "relative" }}>
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )}
          <div onClick={() => navigate(`/reflection-journey-view/${reflectionId}`)} className="back-page-i">
            <Link to="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="main-content">
            <div className="right-headings">
              <h2>Story Timeline</h2>
              <div
                onClick={() =>
                  navigate("/add-life-event", {
                    state: {
                      id: id,
                    },
                  })
                }
              >
                <Link to="#">Add</Link>
              </div>
            </div>
            <div className="storytimeline-box">
              {data?.data[0]?.storyTimeline?.map((storytimeline) => {
                console.log(storyTimeLine?.data);
                return (
                  <>
                    <p className="timeline-label">{storytimeline?.category}</p>
                    <div className="timeline-box-flex">
                      {storytimeline?.data?.map((story) => {
                        console.log(story?.color, "color");
                        return (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate("/add-life-event", {
                                state: {
                                  edit: "edit",
                                  desc: story?.desc,
                                  rating: story?.rating,
                                  date: story?.date,
                                  category: storytimeline?.category,
                                  color: story?.color,
                                  timelineId: story?._id,
                                },
                              })
                            }
                            className="timeline-contain"
                          >
                            <div
                              // to="/wheel-of-life"
                              className="timeline-box "
                              style={{ backgroundColor: `${story?.color}` }}
                            >
                              <div className="top-count">
                                <p>{story?.rating}</p>
                              </div>
                              <p> {story?.desc}</p>
                              <p>{story?.date}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              })}

              {/* <div className="timeline-contain">
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
               
              </div> */}
            </div>

            {data?.data[0]?.storyTimeline?.length == 0 && "No data found yet!"}
            {/* <div className="storytimeline-box">
              <p className="timeline-label">Relationships</p>
              <div className="timeline-contain">
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
              </div>
              <div className="timeline-contain">
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
                <Link to="/wheel-of-life" className="timeline-box add-color-one">
                  <div className="top-count">
                    <p>-5</p>
                  </div>
                  <p> Start Study</p>
                  <p> Rating : -5</p>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
