import { createSlice } from "@reduxjs/toolkit";


export const meditationSlice = createSlice({
  name: "userMgmtSlice",
  initialState: {
    couseCat: null,
    courseId:null
  },
  reducers: {
    courseCategory:(state,action)=>{
        console.log(action,"action")
        state.couseCat=action.payload
    },
    

  },
  
});

export const {courseCategory} = meditationSlice.actions

export default meditationSlice.reducer
