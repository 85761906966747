import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutCoach from "../Components/LayoutCoach";
import { useQuery } from "@tanstack/react-query";
import { getCoachReviews } from "../Redux/Actions/userActions";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  getCoachReviewsinCoachTab,
  getReviews,
  getUserProfile,
} from "../Redux/Actions/coachAction";
export default function ReviewsCoach() {
  const BASE_URL = "https://api.mindrepublic.com/";
  // ================================================get user data from redux===========================================

  const userData = useSelector((state) => state?.registerUserdata?.user);
  console.log(userData);

  // ========================================fetch data by usequery===============================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["getReviews"],
    queryFn: getReviews,
  });

  {
    // isSuccess && dispatch(reflectionList(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data, "data");

  return (
    <LayoutCoach>
      <div className="right-side">
        <div className="back-page-i">
          <Link to="/settings-coach">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
            >
              <path
                d="M12 22L2 12L12 2"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>
        <div className="main-content">
          <h2>Reviews</h2>
          <div className="reviews-list-box">
            <ul>
              {data?.data?.map((review) => {
                return (
                  <li>
                    <div className="reviews-details p-0">
                      <div className="review-card">
                        <div className="review-view">
                          <div className="review-pro">
                            <img
                              src={
                                review?.user_id?.profile_image
                                  ? `${BASE_URL}${data?.data?.reviews[0]?.profile_image}`
                                  : require("../Assets/images/place_holder.png")
                              }
                            />
                            <h3>{review?.user_id?.full_name}</h3>
                          </div>
                          <div className="review-rate">
                            <p>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M9.23509 1.76562L11.4163 6.18445L16.2939 6.89739L12.7645 10.335L13.5974 15.1915L9.23509 12.8974L4.87274 15.1915L5.70568 10.335L2.17627 6.89739L7.05392 6.18445L9.23509 1.76562Z"
                                    fill="#F5B715"
                                  />
                                </svg>
                              </span>
                              {review?.rating}
                            </p>
                          </div>
                        </div>
                        <p>{review?.review}</p>
                      </div>
                    </div>
                  </li>
                );
              })}

              {/* <li>
                <div className="reviews-details p-0">
                  <div className="review-card">
                    <div className="review-view">
                      <div className="review-pro">
                        <img src={require("../Assets/images/coach-img.png")} />
                        <h3>Joanna Mccullough</h3>
                      </div>
                      <div className="review-rate">
                        <p>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M9.23509 1.76562L11.4163 6.18445L16.2939 6.89739L12.7645 10.335L13.5974 15.1915L9.23509 12.8974L4.87274 15.1915L5.70568 10.335L2.17627 6.89739L7.05392 6.18445L9.23509 1.76562Z"
                                fill="#F5B715"
                              />
                            </svg>
                          </span>
                          5,0
                        </p>
                      </div>
                    </div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </LayoutCoach>
  );
}
