import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { getQueAns } from "../Redux/Actions/userActions";
import { storyTimeLineID, wheelData } from "../Redux/Reducers/reflectionSlice";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import WheelChart from "../Utils/wheelChart";

export default function WheelOfLife() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

     // ===================================reflection id from redux========================================

     const reflectionId = useSelector((state)=>state.reflectionData.reflectionIdforedit)
     console.log(reflectionId,"ref")

  // =================================react state====================================

  const [graphData, setgraphData] = useState([]);
  const [graphCount, setgraphCount] = useState([]);

  // =============================================get Story Timeline=====================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["getWheel"],
    queryFn: () => getQueAns(id),
  });

  {
    isSuccess && dispatch(wheelData(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  useEffect(() => {
    dispatch(storyTimeLineID(id));
  });

  useEffect(() => {
    if (data?.data[0]?.wheelOfLife?.length > 0) {
      const series = data?.data[0]?.wheelOfLife?.map((x) => x?.point);
      setgraphCount(series, "series");
    }
    if (data?.data[0]?.wheelOfLife?.length > 0) {
      const garph =
        data?.data[0]?.wheelOfLife?.length > 0 &&
        data?.data[0]?.wheelOfLife?.map((x) => x?.category);
      setgraphData(garph);
    }
  }, [data?.data[0]?.wheelOfLife]);

  console.log(graphCount);

  return (
    <>
      <LayoutNew>
        <div className="right-side" style={{ position: "relative" }}>
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )}
          <div onClick={() => navigate(`/reflection-journey-view/${reflectionId}`)} className="back-page-i">
            <Link
            // to="/reflection-journey-view"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="main-content">
            <div className="right-headings">
              <h2>Wheel of Life</h2>
              <Link to="/add-wheel-of-life">Add</Link>
            </div>
            <div className="life-of-wheel">
              {data?.data[0]?.wheelOfLife?.length == 0 ? (
                "Create atleast two categories"
              ) : data?.data[0]?.wheelOfLife?.length == 1 ? (
                "Create another one category to see the wheel"
              ) : (
                <div style={{marginLeft:"120px"}}>
                  <WheelChart graphCount={graphCount} graphData={graphData} />
                </div>
              )}

              {/* <img src={require("../Assets/images/life-wheel.png")}  */}
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
