import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function YourPasswordReset() {
  return (
    <section className="Login-sec">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                <div className="sign-up-logo">
                  <img src={require("../Assets/images//password-key.png")} />
                </div>
                <h2>Your Password has 
                been reset!</h2>
                {/* <p>Your account had been created successfully.</p> */}
              </div>
              <Form className="contact-form">
                <div className="d-flex justify-content-center">
                  <Link
                    to="/login"
                    variant="primary"
                    type="submit"
                    className="theme-btn"
                  >
                    Done
                  </Link>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
