import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutCoach from "../Components/LayoutCoach";

export default function InboxCoach() {
  return (
    <>
      <LayoutCoach>
        <div className="right-side">
          <div className="main-content">
            <h2>Inbox</h2>
            <div className="inbox-coach-list">
              <Link to="/inbox-coach-chat" className="inbox-coach-box">
                <div className="profile-side">
                  <div className="active-profile-inbox">
                    <img src={require("../Assets/images/coach-img.png")} />
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <circle
                          cx="14"
                          cy="14"
                          r="13"
                          fill="#18DF80"
                          stroke="white"
                          stroke-width="2"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="profile-txt">
                    <h3>Georgia Griffin</h3>
                    <p>Lorium ipsum is dummy content</p>
                  </div>
                </div>
                <div className="profile-right-side">
                  <p>Yesterday</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </LayoutCoach>
    </>
  );
}
