import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCourseCategorybyId,
  manageLikes,
} from "../Redux/Actions/userActions";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { courseCategory, courseId } from "../Redux/Reducers/meditationSlice";
import { coachLogin } from "../Redux/Actions/coachAction";
import moment from "moment/moment";

export default function Theory() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // =======================================fetch data using usequery================================

  const { isPending, data, isError, isSuccess, refetch } = useQuery({
    queryKey: ["courseCategory", parseInt(id)],
    queryFn: () => getCourseCategorybyId(id),
  });

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  {
    isSuccess && dispatch(courseCategory(data));
  }

  console.log(data, "data");

  // =======================================like course=================================================

  const { isPending: pending, mutate } = useMutation({
    mutationFn: manageLikes,
    onSuccess: (res) => {
      console.log(res);
      if (res?.success) {
        //  queryClient.setQueryData(["courseCategory",id],res)
        refetch();
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (error) => {
      toast.error(error?.message);
    },
    // onSettled:() =>
  });

  return (
    <>
      <LayoutNew>
        <div style={{ position: "relative" }} className="right-side">
          {(isPending || pending) && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}
          <div className="back-page-i">
            <Link to="/meditation">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="notify-page-i">
            {data?.data?.medicationObj?.isLiked == "0" ? (
              <Link
                onClick={() => {
                  mutate({
                    type: 1,
                    course_id: id,
                  });
                }}
                to="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="32"
                  viewBox="0 0 35 32"
                  fill="none"
                >
                  <path
                    d="M17.5 31C17.5 31 1 21.4546 1 9.86365C1 7.81459 1.68722 5.82882 2.94472 4.2442C4.20223 2.65958 5.95235 1.57399 7.89732 1.17214C9.84229 0.770287 11.862 1.07699 13.6127 2.04007C15.3635 3.00316 16.7371 4.56312 17.5 6.45456C18.2629 4.56312 19.6365 3.00316 21.3873 2.04007C23.138 1.07699 25.1577 0.770287 27.1027 1.17214C29.0476 1.57399 30.7978 2.65958 32.0553 4.2442C33.3128 5.82882 34 7.81459 34 9.86365C34 21.4546 17.5 31 17.5 31Z"
                    fill="white"
                    stroke="#4CA890"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            ) : (
              <Link
                onClick={() => {
                  mutate({
                    type: 0,
                    course_id: id,
                  });
                }}
                to="#"
              >
                <svg
                  width="35"
                  height="32"
                  viewBox="0 0 35 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 31C17.5 31 1 21.4546 1 9.86365C1 7.81459 1.68722 5.82882 2.94472 4.2442C4.20223 2.65958 5.95235 1.57399 7.89732 1.17214C9.84229 0.770287 11.862 1.07699 13.6127 2.04007C15.3635 3.00316 16.7371 4.56312 17.5 6.45456C18.2629 4.56312 19.6365 3.00316 21.3873 2.04007C23.138 1.07699 25.1577 0.770287 27.1027 1.17214C29.0476 1.57399 30.7978 2.65958 32.0553 4.2442C33.3128 5.82882 34 7.81459 34 9.86365C34 21.4546 17.5 31 17.5 31Z"
                    fill="#4CA890"
                    stroke="#4CA890"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            )}
          </div>
          <div className="main-content">
            <h2>
              {ingerman == "de"
                ? data?.data?.medicationObj?.germen_title
                : data?.data?.medicationObj?.title}
            </h2>
            <p className="theory-para">
              {ingerman == "de"
                ? data?.data?.medicationObj?.germen_desc
                : data?.data?.medicationObj?.desc}
            </p>
            <hr />
            <div className="Theory-audio-listing">
              {data?.data?.courses?.map((course, i) => {
                const duration = moment.duration(ingerman=="de"? course?.germen_duration: course?.duration, "seconds");
                const formattedTime = moment
                  .utc(duration.asMilliseconds())
                  .format("HH:mm:ss");
                return (
                  <div onClick={() => {
                        navigate("/de-stress-audio", {
                          state: {
                            id: course?._id,
                            from:"Theory",
                            backpageid:id
                          },
                        });
                      }}
                      style={{ cursor: "pointer" }} className="Theory-audio-item">
                    <ol>
                      <li>
                        {i + 1}.{" "}
                        {ingerman == "de"
                          ? course?.germanyTitle
                          : course?.title}
                      </li>
                      <p>
                        {/* {(course?.duration / 60).toFixed(2)} 
                      min */}
                        {formattedTime[1]=="0" ? formattedTime.slice(3,8) : formattedTime} min
                      </p>
                    </ol>
                    <div
                      
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="22"
                        viewBox="0 0 12 22"
                        fill="none"
                      >
                        <path
                          d="M1.30541 1.00011L11.1516 11.1516L1.00019 20.9978"
                          stroke="black"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
