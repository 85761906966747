import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import LayoutNew from "../Components/LayoutNew";
import { useQuery } from "@tanstack/react-query";
import { getAllFaqs } from "../Redux/Actions/userActions";
import toast from "react-hot-toast";
import { getAllcoachFaqs } from "../Redux/Actions/coachAction";
import { useSelector } from "react-redux";
import LayoutCoach from "../Components/LayoutCoach";

export default function Faqs() {
  // =========================================chefk for user role========================================
  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  //  ===================================fetch data using usequery===================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["getAllFaqs"],
    queryFn: userrole == "user" ? getAllFaqs : getAllcoachFaqs,
  });

  {
    // isSuccess && dispatch(reflectionList(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data, "data");

  return (
    <>
      {userrole == "user" ? (
        <LayoutNew>
          <div style={{ position: "relative" }} className="right-side">
            {isPending && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            <div className="back-page-i">
              <Link to="/settings">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>

            <div className="main-content">
              <h2>FAQ</h2>
              <div className="faq-list-box">
                <Accordion defaultActiveKey="0">
                  {data?.data?.map((faq, index) => {
                    return (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{faq?.title}</Accordion.Header>
                        <Accordion.Body>
                          <div
                            dangerouslySetInnerHTML={{ __html: faq?.html }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </LayoutNew>
      ) : (
        <LayoutCoach>
          <div style={{ position: "relative" }} className="right-side">
            {isPending && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            <div className="back-page-i">
              <Link to="/settings-coach">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>

            <div className="main-content">
              <h2>FAQ</h2>
              <div className="faq-list-box">
                <Accordion defaultActiveKey="0">
                  {data?.data?.map((faq, index) => {
                    return (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{faq?.title}</Accordion.Header>
                        <Accordion.Body>
                          <div
                            dangerouslySetInnerHTML={{ __html: faq?.html }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </LayoutCoach>
      )}
    </>
  );
}
