import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { forgetPassword } from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { Userdata, forgetPass } from "../Redux/Reducers/registrationSlice";
import { coachforgetPassword } from "../Redux/Actions/coachAction";

export default function ForgotPassword() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

    // // ======================================check for language=======================================

    const ingerman = useSelector((state) => state.homedata.selectedLang);
    console.log(ingerman);
  // ===================================================React state===================================

  const [email, setEmail] = useState(null);

  // =======================================check for userrole==============================

  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // ==================================post data using react query================================

  const { isPending, mutate } = useMutation({
    mutationFn: userrole == "user" ? forgetPassword : coachforgetPassword,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        dispatch(forgetPass("forgetPass"));
        toast.success(res?.message);

        // navigate("/your-password-reset");
        navigate("/verify-email");
      } else {
        toast.error(res?.message);
      }
    },
  });

  // ==============================================save data====================================

  const handlesave = () => {
    let isvalid = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email) {
      toast.error("Please enter email");
      isvalid = false;
    } else if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email");
      isvalid = false;
    }
    if (isvalid) {
      mutate({
        email: email,
        type: 1,
        langType: ingerman == "de" ? 1 : 0,
      });
    }
  };

  return (
    <section className="Login-sec">
      <Container style={{ position: "relative" }}>
        {isPending && (
          <div
            style={{
              position: "absolute",
              width: "110%",
              height: "100%",
              zIndex: "1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              // backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <div class="loader"></div>
          </div>
        )}
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                <div className="sign-up-logo">
                  <img src={require("../Assets/images/upd-logo.png")} />
                </div>
                <h2>Forgot password</h2>
                <p>
                  Please enter your email address. You will receive a link to
                  create a new password via email.
                </p>
              </div>
              <Form className="contact-form">
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Type here..."
                  />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>

                <div className="d-flex justify-content-center">
                  <button
                    onClick={handlesave}
                    // to="/your-password-reset"
                    variant="primary"
                    type="button"
                    className="theme-btn"
                  >
                    Send
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
