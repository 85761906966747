import React from "react";
import ReactApexChart from "react-apexcharts";

const WheelChart = ({ graphData, graphCount }) => {
  const series = graphCount;

  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: graphData, // Assuming graphData is an array of labels
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: function (_, { dataPointIndex }) {
        // Only return the label without percentage
        return graphData[dataPointIndex];
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="pie" width={380} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default WheelChart;
