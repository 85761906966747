import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
// import React, { useState } from "react";
import OtpInput from "react-otp-input";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import swal from "sweetalert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resendOTP, verifyOTP } from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { resendCoachOTP, verifyCoachOTP } from "../Redux/Actions/coachAction";
import { coachRegistrationdata } from "../Redux/Reducers/coachRegistrationSlice";

export default function VerifyEmail() {
  const location = useLocation();
  const dispatch = useDispatch()
  const registerUser = useSelector((state) => state.registerUserdata.user);
  console.log(registerUser);
  const queryClient = useQueryClient();
  const navigate = useNavigate();


    // // ======================================check for language=======================================

    const ingerman = useSelector((state) => state.homedata.selectedLang);
    console.log(ingerman);

  // =======================================check for userrole==============================

  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // ======================================check for forget password============================

  const path = useSelector((state) => state.registerUserdata.forgetPass);
  console.log(path, "path");

  // ===============================================REACT STATE======================================
  const [otp, setOtp] = useState("");
  const [OTP, setOTP] = useState("");
  // const [id, setId] = useState("");

  // =============================================toast for otp=====================================

  // useEffect(() => {
  //   // setId(location?.state?.id);
  //   if(!location.state.check){
  //     setOTP(registerUser?.otp_email);

  //   swal({
  //     title: "OTP",
  //     text: `${registerUser?.otp_email}`,
  //     icon: "success",
  //     // dangerMode: true,
  //   });

  //   }

  // }, [registerUser,location]);
  // ===============================================LANGUAGE========================================

  const { t } = useTranslation();
  const otpVerify = t("otpVerify");

  // =============================================POST DATA==========================================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: userrole == "user" ? verifyOTP : verifyCoachOTP,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);

        sessionStorage?.setItem("token",res?.data?.token)
        dispatch(coachRegistrationdata(null))

        // userrole && !path && userrole == "user"
        //   ? navigate("/account-created-sign")
        //   : navigate("/create-profile");

        if (userrole && path) {
          // navigate("/your-password-reset");
          window.location.href="/reset-password"
          // navigate("/reset-password");
        } else if (userrole == "user") {
          // navigate("/account-created-sign");
          window.location.href = "/account-created-sign"
        } else if (userrole == "coach") {
          // navigate("/create-profile");
          window.location.href = "/create-profile"
        }else{
          return
        }

        // if (path && path == "forgetPass") {
        //   navigate("/your-password-reset");
        //   // navigate("/change-password");
        // }
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  const { isPending: resendLoading, mutate: resendMutate } = useMutation({
    mutationFn: userrole == "user" ? resendOTP : resendCoachOTP,
    onSuccess: (res) => {
      console.log(res, "res");
      if (res?.success) {
        toast.success(res?.message);
     
        // swal({
        //   title: "OTP",
        //   text: `${res?.data?.otp_email}`,
        //   icon: "success",
        //   // dangerMode: true,
        // });

        // navigate("/account-created-sign");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  //  ==============================================sendOTPAGAIN==========================================

  const sendOTPagain = () => {
    setOtp("")
    resendMutate({ user_id: registerUser?._id, type: 1,langType:ingerman == "de" ? 1 : 0 });
  };

  // ============================================SUBMIT=============================================

  const handlesave = () => {
    console.log("save");
    let isvalid = true;

    if (otp.length != 4) {
      toast.error("Please enter OTP");
      isvalid = false;
    }
    if (isvalid) {
      mutate({
        user_id: registerUser?._id,
        otp: otp,
        type: 1,
      });
    }
  };

  return (
    <section className="Login-sec">
      <Container style={{ position: "relative" }}>
        {(isPending || resendLoading) && (
          <div
            style={{
              position: "absolute",
              width: "110%",
              height: "100%",
              zIndex: "1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              // backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <div class="loader"></div>
          </div>
        )}
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                <h2>{otpVerify?.Verifyyouremail}</h2>
                <p>{otpVerify?.enterOTP}</p>
              </div>
              <Form className="contact-form ">
                <div className="otp-sec">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} style={{fontWeight: "bold", textAlign:"center",color:"black"}}/>}
                  />
                </div>
                <div className="text-center">
                  <p>
                    {otpVerify?.didnotrec}{" "}
                    <Link onClick={sendOTPagain} to="">
                      {otpVerify?.resend}
                    </Link>
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    onClick={handlesave}
                    className="theme-btn"
                  >
                    {otpVerify?.verify}
                  </button>
                </div>
                {/* <div className="next-page-signin">
                  <p>
                    Don't have an account?<Link to="/sign-up">Sign Up</Link>
                  </p>
                </div> */}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
