import React, { useEffect, useRef, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n, { setDefaultNamespace } from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import LayoutNew from "../Components/LayoutNew";
import { block, chat, getChats, report } from "../Redux/Actions/userActions";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import moment from "moment";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import {
  blockcoachside,
  coachLogin,
  getcoachChats,
  reportcoachside,
} from "../Redux/Actions/coachAction";
import LayoutCoach from "../Components/LayoutCoach";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function InboxChatBox() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const location = useLocation();
  const navigate = useNavigate();
  const BASE_URL = "https://api.mindrepublic.com/";

  console.log(location, "loc");

  // ====================================react state=====================================================

  const [roomid, setroomid] = useState(location?.state?.roomId);
  const [coachid, setcoachid] = useState(location?.state?.coachId);
  const [coachName, setCoachname] = useState();
  const [reason, setreason] = useState();
  const [reasonmessage, setreasonmessage] = useState();
  const [reportmodal, setreportmodal] = useState();

  useEffect(() => {
    if (location?.state) {
      setCoachname(location?.state?.coachName);
      setroomid(location?.state?.roomId);
      setcoachid(location?.state?.coachId);
    }
  }, location);

  console.log(coachName, "==================", location?.state?.coachName);
  console.log(roomid, "==================", location?.state?.roomId);

  // =========================================chefk for user role========================================
  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);

  // ================================================get user data from redux===========================================

  const userData = useSelector((state) => state?.registerUserdata?.user);
  console.log(userData, "userData");

  // ========================================fetch data ===============================

  const { data, isSuccess, isError, isPending, refetch } = useQuery({
    queryKey: ["reviewList"],
    queryFn: () =>
      userrole == "user" ? getChats(roomid) : getcoachChats(roomid),
  });

  // {
  //   isSuccess && dispatch(coachDetails(data));
  // }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  // ============================================socket==============================================

  const SOCKET_SERVER_URL = "https://api.mindrepublic.com/";
  // const SOCKET_SERVER_URL = "http://192.168.0.88:4011/";

  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Scroll to the bottom of the chat area
    console.log("first");
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [data]);

  // ========================connect socket=========================================

  useEffect(() => {
    try {
      const newSocket = io(SOCKET_SERVER_URL);
      setSocket(newSocket);
      console.log("socket connected");
      return () => {
        newSocket.disconnect();
      };
    } catch (error) {
      console.log(error, "error");
    }
  }, []);

  // ===============================socket to join chat===============================================

  useEffect(() => {
    if (roomid && socket) {
      console.log("Joining chat room:", roomid);
      socket.emit(
        "join_chat",
        {
          chat_id: roomid,
        },
        (check) => {
          console.log("Joined chat room:", check);
        }
      );
    }
  }, [socket]);

  // ========================emit socket=========================================

  const sendMessage = () => {
    if (message == "") {
      toast.error("Please Enter Message");
    } else {
      let lang;
      if (ingerman == "de") {
        lang = 1;
      } else {
        lang = 0;
      }

      socket?.emit(
        "send_message",
        {
          chat_id: roomid,
          sender_id: userData?._id,
          message: message,
          type: userrole == "user" ? 0 : 1,
          langType: lang,
        },
        (check) => {
          console.log(
            check,
            "------------------------------------------------------------------"
          );
        }
      );
      setMessage("");
      refetch();
    }
  };

  // =======================send files==========================================

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let lang;
      if (ingerman == "de") {
        lang = 1;
      } else {
        lang = 0;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        console.log("Base64 string:", base64String);
        if (base64String) {
          socket?.emit(
            "send_message",
            {
              chat_id: roomid,
              sender_id: userData?._id,
              message: message,
              type: userrole == "user" ? 0 : 1,
              langType: lang,
              image: base64String,
            },
            (response) => {
              if (response.error) {
                console.error("Error emitting message:", response.error);
              } else {
                console.log("Message emitted successfully:", response);
                // Perform any additional actions on successful emission
              }
            }
          );
          // refetch();
        } else {
          console.error("Base64 string is null or empty");
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      reader.readAsDataURL(file); // Start reading the file as base64
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of form submission
      sendMessage(""); // Call sendMessage when Enter key is pressed
      refetch();
    }
  };

  // ========================listen socket=========================================

  useEffect(() => {
    socket?.on("recieve_message", () => {
      refetch();
    });

    return () => {
      socket?.off("recieve_message");
    };
  }, [socket, refetch]);

  // =====================================block=====================================

  // ===========================================POST DATA USING REACT QUERY==========================

  const { isPending: blockpending, mutate } = useMutation({
    mutationFn: userrole == "user" ? block : blockcoachside,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        refetch();
        handleClose();

        // res?.data?.role== 0 ?  navigate("/get-started") : ""
        //  sessionStorage.clear()
        // navigate("/your-password-reset");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ===========================================POST DATA USING REACT QUERY==========================

  const { isPending: reportpending, mutate: reportmutate } = useMutation({
    mutationFn: userrole == "user" ? report : reportcoachside,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        refetch();
        handleClose();

        // res?.data?.role== 0 ?  navigate("/get-started") : ""
        //  sessionStorage.clear()
        // navigate("/your-password-reset");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  console.log(data, "datatatatatattatata");

  return (
    <>
      {userrole == "user" ? (
        <LayoutNew>
          <div style={{ position: "relative" }} className="right-side">
            {(isPending || blockpending || reportpending) && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            <div onClick={() => navigate("/inbox")} className="back-page-i">
              <Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>

            {(data?.data?.blockBy == userData?._id ||
              data?.data?.blocked == 0) && (
              <div className="notify-page-i three-menu-hide">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img
                      src={require("../Assets/images/dot-menu.svg").default}
                    />
                    {/* <img src={require("../Assets/images/dot-menu.svg").Default}/> */}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        data?.data?.blocked == 1
                          ? mutate({
                              // reason: reason,
                              // desc: reasonmessage,
                              chat_id: roomid,
                              blockTo: coachid,
                              blockBy: userData?._id,
                              status: 0,
                            })
                          : handleShow();
                      }}
                    >
                      {data?.data?.blocked == 1 ? (ingerman=="de" ? "Entsperren" :"Unblock") :( ingerman=="de" ? "Blockieren" : "Block")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleShow();
                        setreportmodal(true);
                      }}
                    >
                      {ingerman == "de" ? "Melden" : "Report"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <div className="main-content">
              <h2>{coachName}</h2>

              <div className="inbox-chat-box">
                <div className="support-chat-box-main">
                  <div className="support-inner">
                    <div
                      fluid
                      ref={chatContainerRef}
                      className="support-msg-box"
                    >
                      {data?.data?.chats?.map((chat) => {
                        if (chat?.docModel === "Coach") {
                          return (
                            <div
                              className="user-box right-part-txt"
                              key={chat.id}
                            >
                              <div className="user-msg-box">
                                <p>
                                  {chat?.image !== "" ? (
                                    <img
                                      style={{ width: "100px" }}
                                      src={`${BASE_URL}${chat?.image}`}
                                    />
                                  ) : (
                                    chat?.message
                                  )}
                                </p>
                              </div>
                              <div className="user-top-hdng">
                              <div className="tooltip-container">
                                <h4>
                                  {/* {moment(chat?.createdAt).format("hh:mm a")} */}
                                  {moment(chat?.createdAt).fromNow()}
                                </h4>
                                <span className="tooltip-text">{moment(chat?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                </div>
                              </div>
                            </div>
                          );
                        } else if (chat?.docModel === "User") {
                          return (
                            <div
                              className="user-box left-part-txt"
                              key={chat.id}
                            >
                              <div className="user-msg-box">
                                <p>
                                  {chat?.image !== "" ? (
                                    <img
                                      style={{ width: "100px" }}
                                      src={`${BASE_URL}${chat?.image}`}
                                    />
                                  ) : (
                                    chat?.message
                                  )}
                                </p>
                              </div>
                              <div className="user-top-hdng">
                              <div className="tooltip-container">
                                <h4>
                                  {/* {moment(chat?.createdAt).format("hh:mm a")} */}
                                  {moment(chat?.createdAt).fromNow()}
                                </h4>
                                <span className="tooltip-text">{moment(chat?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                </div>
                              </div>
                              
                            </div>
                          );
                        }
                        return null; // Handle other cases or unexpected values
                      })}
                    </div>
                  </div>
                  {data?.data?.blocked == 1 ? (
                    <p className="text-danger" style={{ textAlign: "center" }}>
                      {" "}
                      {ingerman=="de" ? "Sie können keine Nachricht an senden " : "You can not send message to "} {coachName}
                    </p>
                  ) : (
                    <form>
                      <div class="send-box">
                        <div class="mb-3 input-group">
                          {/* <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="attach-icon">@</InputGroup.Text>
                        <Form.Control
                          placeholder="Type your message here..."
                          aria-label="send"
                          aria-describedby="basic-addon2"
                          name="message"
                          type="text"
                          class="send-feild form-control"
                          value=""
                        />
                      </InputGroup> */}
                          <InputGroup>
                            <input
                              type="file"
                              id="file-input"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                            <label
                              htmlFor="file-input"
                              style={{ cursor: "pointer", display: "block" }}
                            >
                              <InputGroup.Text
                                id="basic-addon1"
                                className="attach-icon"
                                // style={{position:"absolute"}}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="26"
                                  viewBox="0 0 24 26"
                                  fill="none"
                                >
                                  <path
                                    d="M23 12.2512L12.5989 22.9525C11.3247 24.2635 9.59649 25 7.79448 25C5.99247 25 4.26427 24.2635 2.99006 22.9525C1.71584 21.6415 1 19.8634 1 18.0094C1 16.1554 1.71584 14.3773 2.99006 13.0663L13.3912 2.365C14.2406 1.491 15.3928 1 16.5941 1C17.7954 1 18.9476 1.491 19.7971 2.365C20.6465 3.23899 21.1238 4.42438 21.1238 5.6604C21.1238 6.89641 20.6465 8.0818 19.7971 8.9558L9.38464 19.6571C8.9599 20.0941 8.38383 20.3396 7.78316 20.3396C7.18249 20.3396 6.60643 20.0941 6.18169 19.6571C5.75695 19.2201 5.51834 18.6274 5.51834 18.0094C5.51834 17.3914 5.75695 16.7987 6.18169 16.3617L15.7905 6.48716"
                                    stroke="#123258"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </InputGroup.Text>
                            </label>
                          </InputGroup>
                          <input
                            value={message}
                            placeholder={
                              ingerman == "de"
                                ? "Schreiben Sie ihre Nachricht hier..."
                                : "Type your message here..."
                            }
                            aria-label="send"
                            aria-describedby="basic-addon2"
                            name="message"
                            type="text"
                            class="send-feild form-control"
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeyDown}
                          />
                          <button
                            onClick={sendMessage}
                            class="input-group-text"
                            id="basic-addon2"
                            type="button"
                          >
                            {ingerman == "de" ? "Schicken" : "Send"}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </LayoutNew>
      ) : (
        <LayoutCoach>
          <div style={{ position: "relative" }} className="right-side">
            {(isPending || blockpending || reportpending) && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            <div onClick={() => navigate("/inbox")} className="back-page-i">
              <Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>
            {(data?.data?.blockBy == userData?._id ||
              data?.data?.blocked == 0) && (
              <div className="notify-page-i three-menu-hide">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img
                      src={require("../Assets/images/dot-menu.svg").default}
                    />
                    {/* <img src={require("../Assets/images/dot-menu.svg").Default}/> */}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        data?.data?.blocked == 1
                          ? mutate({
                              // reason: reason,
                              // desc: reasonmessage,
                              chat_id: roomid,
                              blockTo: coachid,
                              blockBy: userData?._id,
                              status: 0,
                            })
                          : handleShow();
                      }}
                    >
                      {data?.data?.blocked == 1 ? (ingerman=="de" ? "Entsperren" :"Unblock") :( ingerman=="de" ? "Blockieren" : "Block")}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        handleShow();
                        setreportmodal(true);
                      }}>
                      {ingerman == "de" ? "Bericht" : "Report"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            <div className="main-content">
              <h2>{coachName}</h2>

              <div className="inbox-chat-box">
                <div className="support-chat-box-main">
                  <div className="support-inner">
                    <div
                      fluid
                      ref={chatContainerRef}
                      className="support-msg-box"
                    >
                      {data?.data?.chats?.map((chat) => {
                        if (chat?.docModel === "User") {
                          return (
                            <div
                              className="user-box right-part-txt"
                              key={chat.id}
                            >
                              <div className="user-msg-box">
                                <p>
                                  {chat?.image !== "" ? (
                                    <img
                                      style={{ width: "100px" }}
                                      src={`${BASE_URL}${chat?.image}`}
                                    />
                                  ) : (
                                    chat?.message
                                  )}
                                </p>
                              </div>
                              <div className="user-top-hdng">
                              <div className="tooltip-container">
                                <h4>
                                  {/* {moment(chat?.createdAt).format("hh:mm a")} */}
                                  {moment(chat?.createdAt).fromNow()}
                                </h4>
                                <span className="tooltip-text">{moment(chat?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                </div>
                              </div>
                            
                            </div>
                          );
                        } else if (chat?.docModel === "Coach") {
                          return (
                            <div
                              className="user-box left-part-txt"
                              key={chat.id}
                            >
                              <div className="user-msg-box">
                                <p>
                                  {chat?.image !== "" ? (
                                    <img
                                      style={{ width: "100px" }}
                                      src={`${BASE_URL}${chat?.image}`}
                                    />
                                  ) : (
                                    chat?.message
                                  )}
                                </p>
                              </div>
                              <div className="user-top-hdng">
                              <div className="tooltip-container">
                                <h4>
                                  {/* {moment(chat?.createdAt).format("hh:mm a")} */}
                                  {moment(chat?.createdAt).fromNow()}
                                </h4>
                                <span className="tooltip-text">{moment(chat?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                </div>
                             
                              </div>
                            </div>
                          );
                        }
                        return null; // Handle other cases or unexpected values
                      })}

                      {data?.data?.blocked == 1 ? (
                        <p
                          className="text-danger"
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                         {ingerman=="de" ? "Sie können keine Nachricht an senden " : "You can not send message to "}{coachName}
                        </p>
                      ) : (
                        <form>
                          <div class="send-box">
                            <div class="mb-3 input-group">
                              {/* <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="attach-icon">@</InputGroup.Text>
                        <Form.Control
                          placeholder="Type your message here..."
                          aria-label="send"
                          aria-describedby="basic-addon2"
                          name="message"
                          type="text"
                          class="send-feild form-control"
                          value=""
                        />
                      </InputGroup> */}
                              <InputGroup>
                                <input
                                  type="file"
                                  id="file-input"
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                />
                                <label
                                  htmlFor="file-input"
                                  style={{
                                    cursor: "pointer",
                                    display: "block",
                                  }}
                                >
                                  <InputGroup.Text
                                    id="basic-addon1"
                                    className="attach-icon"
                                    // style={{position:"absolute"}}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="26"
                                      viewBox="0 0 24 26"
                                      fill="none"
                                    >
                                      <path
                                        d="M23 12.2512L12.5989 22.9525C11.3247 24.2635 9.59649 25 7.79448 25C5.99247 25 4.26427 24.2635 2.99006 22.9525C1.71584 21.6415 1 19.8634 1 18.0094C1 16.1554 1.71584 14.3773 2.99006 13.0663L13.3912 2.365C14.2406 1.491 15.3928 1 16.5941 1C17.7954 1 18.9476 1.491 19.7971 2.365C20.6465 3.23899 21.1238 4.42438 21.1238 5.6604C21.1238 6.89641 20.6465 8.0818 19.7971 8.9558L9.38464 19.6571C8.9599 20.0941 8.38383 20.3396 7.78316 20.3396C7.18249 20.3396 6.60643 20.0941 6.18169 19.6571C5.75695 19.2201 5.51834 18.6274 5.51834 18.0094C5.51834 17.3914 5.75695 16.7987 6.18169 16.3617L15.7905 6.48716"
                                        stroke="#123258"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </InputGroup.Text>
                                </label>
                              </InputGroup>
                              <input
                                value={message}
                                placeholder={
                                  ingerman == "de"
                                    ? "Schreiben Sie ihre Nachricht hier..."
                                    : "Type your message here..."
                                }
                                aria-label="send"
                                aria-describedby="basic-addon2"
                                name="message"
                                type="text"
                                class="send-feild form-control"
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                              />
                              <button
                                onClick={sendMessage}
                                class="input-group-text"
                                id="basic-addon2"
                                type="button"
                              >
                                {ingerman == "de" ? "Schicken" : "Send"}
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutCoach>
      )}

      <Modal className="theme-color-ad" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-none">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="report-popup-box">
            <h2>{ingerman=="de" ? "Grund für" : "Reason for "} {reportmodal ? (ingerman=="de" ? "Bericht" : "Report")  :( ingerman=="de" ? "Blockieren": "Block")} </h2>
            <div className="report-option-list">
              <Form>
                <div className="report-list-item mb-3">
                  <p>{ingerman=="de" ? "Verdächtige Person" : "Suspect Person"} </p>

                  <Form.Check
                    type="radio"
                    id="suspect-person"
                    name="report-reason"
                    value="Suspect Person"
                    onChange={(e) => setreason(e.target.value)}
                  />
                </div>
                <div className="report-list-item mb-3">
                  <p>{ingerman=="de" ? "Belästigung" : "Harassment"} </p>

                  <Form.Check
                    type="radio"
                    id="harassment"
                    name="report-reason"
                    value="Harassment"
                    onChange={(e) => setreason(e.target.value)}
                  />
                </div>
                <div className="report-list-item mb-3">
                  <p>Stalking </p>

                  <Form.Check
                    type="radio"
                    id="stalking"
                    name="report-reason"
                    value="Stalking"
                    onChange={(e) => setreason(e.target.value)}
                  />
                </div>
                <div className="report-list-item mb-3">
                  <p>{ingerman=="de" ? "Fake-Konto" : "Fake Account"}</p>

                  <Form.Check
                    type="radio"
                    id="fake-account"
                    name="report-reason"
                    value="Fake Account"
                    onChange={(e) => setreason(e.target.value)}
                  />
                </div>
                <div className="report-list-item mb-3">
                  <p>{ingerman=="de" ? "Andere" : "Others"}</p>

                  <Form.Check
                    type="radio"
                    id="others"
                    name="report-reason"
                    value="Others"
                    onChange={(e) => setreason(e.target.value)}
                  />
                </div>
                <Form.Group
                  className=""
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={ingerman=="de" ? "Geben Sie Nachricht ein..." : "Type Message..."}
                    onChange={(e) => setreasonmessage(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center ">
          <Button
            variant="primary"
            onClick={() => {
              if (!reason) {
                toast.error("Please select the reason");
              } else if (reason == "Others" && !reasonmessage) {
                toast.error("Please enter a message");
              } else {
                reportmodal
                  ? reportmutate({
                      reason: reason,
                      desc: reasonmessage,
                      chat_id: roomid,
                      reportTo: coachid,
                      reportBy: userData?._id,
                      status: 1,
                    })
                  : mutate({
                      reason: reason,
                      desc: reasonmessage,
                      chat_id: roomid,
                      blockTo: coachid,
                      blockBy: userData?._id,
                      status: 1,
                    });
              }
            }}
            className="theme-btn text-capitalize"
          >
           {ingerman=="de" ? "Einreichen" : "Submit"} 
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
