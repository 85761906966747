import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import {
  Container,
  Row,
  Col,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutNew from "../Components/LayoutNew";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelAppointment,
  chat,
  getCoachDetails,
  getCoachReviews,
} from "../Redux/Actions/userActions";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  appointmentData,
  appointmentID,
  coachDetails,
  coachId,
} from "../Redux/Reducers/CoachSlice";
import RatingComponent from "../Utils/ratingStars";
import moment from "moment/moment";
import LayoutCoach from "../Components/LayoutCoach";
import {
  acceptRejectAppointment,
  cancelAppointmentRefund,
  coachchat,
  coachLogin,
  getAppointmentDetails,
  refundAppointmentPayment,
} from "../Redux/Actions/coachAction";

export default function RequestedDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate = useNavigate();

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);
  // =========================================chefk for user role========================================
  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  const [coachName, setCoachName] = useState(null);
  const [coachName1, setCoachName1] = useState(null);
  const [coachdetails, setcoachdetails] = useState(null);
  const [country_code, setcountry_code] = useState(null);
  const [createdAt, setcreatedAt] = useState(null);
  const [slot, setslot] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null);
  const [status, setStatus] = useState(null);
  const [img, setimg] = useState(null);
  const [appointment_id, setappointment_id] = useState(null);

  console.log(location, "location");

  // ================================get appointment data=======================================

  const {
    isPending: apppending,
    data,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: ["getAppointmentDetails"],
    queryFn: () => getAppointmentDetails(appointment_id),
    enabled:
      !!appointment_id && userrole=="coach" &&
      location?.state?.coachDetails?.full_name === undefined,
  });

  // {
  //   isSuccess &&
  //     dispatch(reflectionCategoryDetails(data)) &&
  //     dispatch(reflectionIdforEditStoryTimeline(id));
  // }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data, "datadatadta");

  // =====================================================================================================

  useEffect(() => {
    if (location?.state?.coachDetails?.full_name) {
      setCoachName(location?.state?.coachDetails?.full_name);
      setcountry_code(location?.state?.coachDetails?.country_code);
      setphoneNumber(location?.state?.coachDetails?.phone_number);
      setcreatedAt(location?.state?.createdAt);
      setslot(location?.state?.slot);
      setStatus(location?.state?.status);
      setimg(location?.state?.img);
      setappointment_id(location?.state?.appointmentId);
      setcoachdetails(location?.state?.coachDetails);
    }

    if (location?.state?.notification) {
      setCoachName(data?.data?.user_id?.full_name);
      setcountry_code(data?.data?.user_id?.country_code);
      setphoneNumber(data?.data?.user_id?.phone_number);
      setcreatedAt(data?.data?.date);
      setslot(data?.data?.timeSlot);
      setStatus(data?.data?.status);
      setimg(data?.data?.user_id?.img);
      setappointment_id(location?.state?.appointmentId);
      setcoachdetails(data?.data?.user_id);
    }
  }, [data]);

  // ======================================================================================================

  const handlePayment = () => {
    // console.log(coachdetails);
    dispatch(appointmentID(appointment_id));
    dispatch(coachDetails(coachdetails));
    dispatch(appointmentData({ slot, createdAt }));
    // console.log(status, "status");
    // if (status == 3) {
    navigate("/invoice-details", {
      state: {
        back: "back",
      },
    });
    // }
  };

  console.log(appointment_id, "iididididididid");

  // =============================================Go for chat========================================================

  const { isPending, mutate } = useMutation({
    mutationFn: userrole == "user" ? chat : coachchat,
    onSuccess: (res) => {
      if (res?.success) {
        navigate("/inbox-chat-box", {
          state: {
            roomId: res?.data?._id,
            coachName: coachName,
            coachId: coachdetails?._id,
          },
        });
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending: permissionPending, mutate: permissionMutate } =
    useMutation({
      mutationFn: acceptRejectAppointment,
      onSuccess: (res) => {
        console.log(res);
        if (res?.success) {
          toast.success(res?.message);
          navigate("/dashboard");
        } else {
          toast.error(res?.message);
        }
      },
      onError: (res) => {
        toast.error(res?.message);
      },
    });

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending: cancel, mutate: cancelMutate } = useMutation({
    mutationFn: cancelAppointmentRefund,
    onSuccess: (res) => {
      console.log(res);
      if (res?.success) {
        toast.success(res?.message);
        navigate("/dashboard");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending: refund, mutate: refundMutate } = useMutation({
    mutationFn: refundAppointmentPayment,
    onSuccess: (res) => {
      console.log(res);
      if (res?.success) {
        toast.success(res?.message);
        navigate("/dashboard");
      } else {
        toast.error(res?.message);
        navigate("/dashboard");
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // =================================================post=====================================

  const {
    isPending: userCancelappointment,
    mutate: userCancelappointmentmutate,
  } = useMutation({
    mutationFn: cancelAppointment,
    onSuccess: (res) => {
      console.log(res);
      if (res?.success) {
        toast.success(res?.message);
        navigate("/appointments");
      } else {
        toast?.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  const canceluserAppointmentRefund = () => {
    console.log("cancel");
    userCancelappointmentmutate({
      appointment_id: appointment_id,
      langType: ingerman == "de" ? 1 : 0,
    });
  };

  return (
    <>
      {userrole == "user" ? (
        <LayoutNew>
          <div style={{ position: "relative" }} className="right-side">
            {(isPending ||
              permissionPending ||
              cancel ||
              refund ||
              userCancelappointment) && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            <div onClick={() => navigate(-1)} className="back-page-i">
              <Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>
            <div className="main-content">
              <h2>
                {ingerman == "de" ? "Anfragedetails" : "Requested Details"}
              </h2>
              <div className="requested-details-box">
                <div className="requested-head">{/* <h3>Coach</h3> */}</div>
                <div className="requested-box d-block">
                  <div className="requested-profile-part">
                    <div className="photo-side ad-requ-detail-height">
                      <img
                        src={
                          img
                            ? `${BASE_URL}${img}`
                            : require("../Assets/images/place_holder.png")
                        }
                      />
                    </div>
                    <div className="requested-pro-detail">
                      <div className="requested-pro-cat">
                        <h3>{coachName}</h3>
                        {country_code} {phoneNumber}
                      </div>
                      <div className="request-date">
                        <h3>
                          {ingerman == "de"
                            ? "Anfragedetails"
                            : "Requested Details"}
                        </h3>
                        <div className="date-time">
                          <div className="">
                            <h4>{ingerman == "de" ? "Datum" : "Date"}</h4>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.5 5H10.5"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8 1V3"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4 1V3"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {moment(createdAt).format("DD MMMM YYYY")}
                            </p>
                          </div>
                          <div className="">
                            <h4>{ingerman == "de" ? "Zeit" : "Time"}</h4>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_4329_26413)">
                                  <path
                                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                    stroke="#585858"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 3V6L8 7"
                                    stroke="#585858"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_4329_26413">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              {slot}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-requested-side">
                    <div>
                      <div
                        onClick={() => {
                          mutate({
                            coach_id: coachdetails?._id,
                          });
                        }}
                      >
                        <Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                          >
                            <path
                              d="M18 0C22.7682 0 27.3582 1.8899 30.7242 5.27373C34.1082 8.65936 36 13.2293 36 18.0171C36 24.3168 32.6862 30.1665 27.2862 33.4243C21.8862 36.6821 15.1722 36.8621 9.594 33.8923H9.5382C9 33.6763 8.5662 33.2983 8.0622 33.0463C7.506 32.8123 6.8742 32.7763 6.3 32.9581C4.9662 33.4243 3.6162 33.8023 2.2302 34.1263C1.5102 34.1443 1.2942 33.7123 1.2942 33.0103C1.6182 31.5884 2.0502 30.1827 2.556 28.8165C2.7522 28.2226 2.7 27.5926 2.394 27.0328L2.034 26.3327C0.7002 23.7948 0 20.9689 0 18.1089V17.9991C0 13.2293 1.8882 8.63956 5.2722 5.27373C8.6562 1.8899 13.2282 0 18 0ZM26.2962 15.7132C25.0362 15.7132 23.994 16.7392 23.994 18.0171C23.994 19.277 25.0362 20.321 26.2962 20.321C27.5742 20.321 28.6002 19.277 28.6002 18.0171C28.6002 16.7392 27.5742 15.7132 26.2962 15.7132ZM18 15.7132C16.722 15.7132 15.6942 16.7392 15.6942 18.0171C15.6942 19.277 16.722 20.321 18 20.321C19.278 20.321 20.3022 19.277 20.3022 18.0171C20.3022 16.7392 19.278 15.7132 18 15.7132ZM9.7002 15.7132C8.4222 15.7132 7.3962 16.7392 7.3962 18.0171C7.3962 19.277 8.4222 20.321 9.7002 20.321C10.9602 20.321 12.006 19.277 12.006 18.0171C12.006 16.7392 10.9602 15.7132 9.7002 15.7132Z"
                              fill="black"
                              fill-opacity="0.5"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        dispatch(coachId(coachdetails?._id));
                        navigate("/coach-details");
                      }}
                    >
                      <Link className="view-coach-btn">
                        {ingerman == "de"
                          ? "Trainerprofil anzeigen"
                          : "View Coach Profile"}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="requested-pay-btn">
                  {userrole == "user" ? (
                    status == 0 ? (
                      <h3>
                        {ingerman == "de" ? "Nicht akzeptiert" : "Not Accepted"}
                      </h3>
                    ) : status == 1 ? (
                      <div onClick={handlePayment}>
                        <Link to="" className="req-pay-btn">
                          {ingerman == "de" ? "Zahlen" : "Pay"}
                        </Link>
                      </div>
                    ) : status == 2 ? (
                      <h3>{ingerman == "de" ? "Abgelehnt" : "Rejected"}</h3>
                    ) : status == 3 ? (
                      <div onClick={handlePayment}>
                        <Link to="" className="req-pay-btn">
                          {ingerman == "de" ? "Zahlen" : "Pay"}
                        </Link>
                      </div>
                    ) : status == 4 ? (
                      <div onClick={canceluserAppointmentRefund}>
                        <Link to="" className="req-pay-btn">
                          {ingerman == "de"
                            ? "Stornieren"
                            : "Cancel Appointment"}
                        </Link>
                      </div>
                    ) : (
                      <h3>
                        {ingerman == "de"
                          ? "Termin abgesagt"
                          : "Appointment Cancelled"}
                      </h3>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </LayoutNew>
      ) : (
        <LayoutCoach>
          <div style={{ position: "relative" }} className="right-side">
            {(isPending || permissionPending || cancel || refund) && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            <div onClick={() => navigate(-1)} className="back-page-i">
              <Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M12 22L2 12L12 2"
                    stroke="black"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>
            <div className="main-content">
              <h2>
                {ingerman == "de" ? "Anfragedetails" : "Requested Details"}
              </h2>
              <div className="requested-details-box">
                <div className="requested-head">{/* <h3>Coach</h3> */}</div>
                <div className="">
                  <div className="requested-box d-block">
                    <div className="requested-profile-part">
                      {/* <div className="appointment-date-card">
                  <img src={require("../Assets/images/date-bg.png")} />
                  <div className="appointment-date-box">
                    <h2>21</h2>
                    <p>January</p>
                  </div>
                </div> */}

                      <div
                        style={{ height: "120px" }}
                        className="appointment-date-card"
                      >
                        <img
                          style={{ height: "130px", width: "130px" }}
                          src={require("../Assets/images/date-bg.png")}
                        />
                        <div className="appointment-date-box">
                          <h2>{moment(createdAt).format("DD")}</h2>
                          <p>{moment(createdAt).format("MMMM")}</p>
                        </div>
                      </div>
                      <div className="photo-side ad-requ-detail-height">
                        <img
                          style={{ borderRadius: "8px" }}
                          src={
                            img
                              ? `${BASE_URL}${img}`
                              : require("../Assets/images/place_holder.png")
                          }
                        />
                      </div>
                      <div className="requested-pro-detail">
                        <div className="requested-pro-cat">
                          <h3>{coachName || coachName1}</h3>
                          {country_code} {phoneNumber}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <div className="request-date">
                        <h3>
                          {ingerman == "de"
                            ? "Anfragedetails"
                            : "Requested Details"}
                        </h3>
                        <div className="date-time d-flex gap-5">
                          <div className="">
                            <h4>{ingerman == "de" ? "Datum" : "Date"}</h4>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.5 5H10.5"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8 1V3"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M4 1V3"
                                  stroke="#585858"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {moment(createdAt).format("DD MMMM YYYY")}
                            </p>
                          </div>
                          <div className="">
                            <h4>{ingerman == "de" ? "Zeit" : "Time"}</h4>
                            <p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_4329_26413)">
                                  <path
                                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                                    stroke="#585858"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 3V6L8 7"
                                    stroke="#585858"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_4329_26413">
                                    <rect width="12" height="12" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              {slot}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="right-requested-side">
                        <div>
                          <div
                          // onClick={() => {
                          //   mutate({
                          //     coach_id: location?.state?.coachDetails?._id,
                          //   });
                          // }}
                          >
                            {/* <Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                          >
                            <path
                              d="M18 0C22.7682 0 27.3582 1.8899 30.7242 5.27373C34.1082 8.65936 36 13.2293 36 18.0171C36 24.3168 32.6862 30.1665 27.2862 33.4243C21.8862 36.6821 15.1722 36.8621 9.594 33.8923H9.5382C9 33.6763 8.5662 33.2983 8.0622 33.0463C7.506 32.8123 6.8742 32.7763 6.3 32.9581C4.9662 33.4243 3.6162 33.8023 2.2302 34.1263C1.5102 34.1443 1.2942 33.7123 1.2942 33.0103C1.6182 31.5884 2.0502 30.1827 2.556 28.8165C2.7522 28.2226 2.7 27.5926 2.394 27.0328L2.034 26.3327C0.7002 23.7948 0 20.9689 0 18.1089V17.9991C0 13.2293 1.8882 8.63956 5.2722 5.27373C8.6562 1.8899 13.2282 0 18 0ZM26.2962 15.7132C25.0362 15.7132 23.994 16.7392 23.994 18.0171C23.994 19.277 25.0362 20.321 26.2962 20.321C27.5742 20.321 28.6002 19.277 28.6002 18.0171C28.6002 16.7392 27.5742 15.7132 26.2962 15.7132ZM18 15.7132C16.722 15.7132 15.6942 16.7392 15.6942 18.0171C15.6942 19.277 16.722 20.321 18 20.321C19.278 20.321 20.3022 19.277 20.3022 18.0171C20.3022 16.7392 19.278 15.7132 18 15.7132ZM9.7002 15.7132C8.4222 15.7132 7.3962 16.7392 7.3962 18.0171C7.3962 19.277 8.4222 20.321 9.7002 20.321C10.9602 20.321 12.006 19.277 12.006 18.0171C12.006 16.7392 10.9602 15.7132 9.7002 15.7132Z"
                              fill="black"
                              fill-opacity="0.5"
                            />
                          </svg>
                        </Link> */}
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            console.log(
                              userrole == "coach" && status == 5,
                              "hsgdasgasdgsagdj"
                            );
                            userrole == "user"
                              ? mutate({
                                  coach_id: coachdetails?._id,
                                })
                              : userrole == "coach" && status == 5
                              ? refundMutate({
                                  appointment_id: appointment_id,
                                  user_id: coachdetails?._id,
                                  langType: ingerman == "de" ? 1 : 0,
                                })
                              : mutate({
                                  user_id: coachdetails?._id,
                                });
                          }}
                        >
                          <Link className="view-coach-btn">
                            <div style={{ marginRight: "7px" }}>
                              <svg
                                width="25"
                                height="36"
                                viewBox="0 0 37 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.5 0C23.2682 0 27.8582 1.8899 31.2242 5.27373C34.6082 8.65936 36.5 13.2293 36.5 18.0171C36.5 24.3168 33.1862 30.1665 27.7862 33.4243C22.3862 36.6821 15.6722 36.8621 10.094 33.8923H10.0382C9.5 33.6763 9.0662 33.2983 8.5622 33.0463C8.006 32.8123 7.3742 32.7763 6.8 32.9581C5.4662 33.4243 4.1162 33.8023 2.7302 34.1263C2.0102 34.1443 1.7942 33.7123 1.7942 33.0103C2.1182 31.5884 2.5502 30.1827 3.056 28.8165C3.2522 28.2226 3.2 27.5926 2.894 27.0328L2.534 26.3327C1.2002 23.7948 0.5 20.9689 0.5 18.1089V17.9991C0.5 13.2293 2.3882 8.63956 5.7722 5.27373C9.1562 1.8899 13.7282 0 18.5 0ZM26.7962 15.7132C25.5362 15.7132 24.494 16.7392 24.494 18.0171C24.494 19.277 25.5362 20.321 26.7962 20.321C28.0742 20.321 29.1002 19.277 29.1002 18.0171C29.1002 16.7392 28.0742 15.7132 26.7962 15.7132ZM18.5 15.7132C17.222 15.7132 16.1942 16.7392 16.1942 18.0171C16.1942 19.277 17.222 20.321 18.5 20.321C19.778 20.321 20.8022 19.277 20.8022 18.0171C20.8022 16.7392 19.778 15.7132 18.5 15.7132ZM10.2002 15.7132C8.9222 15.7132 7.8962 16.7392 7.8962 18.0171C7.8962 19.277 8.9222 20.321 10.2002 20.321C11.4602 20.321 12.506 19.277 12.506 18.0171C12.506 16.7392 11.4602 15.7132 10.2002 15.7132Z"
                                  fill="white"
                                />
                              </svg>
                            </div>

                            {status == 5
                              ? ingerman == "de"
                                ? "Rückerstattungsbetrag"
                                : "Refund Amount"
                              : ingerman == "de"
                              ? "Jetzt chatten"
                              : "Chat Now"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="requested-pay-btn">
                  {userrole == "user" ? (
                    status == 0 ? (
                      <h3>
                        {ingerman == "de" ? "Nicht akzeptiert" : "Not Accepted"}
                      </h3>
                    ) : status == 1 ? (
                      <div onClick={handlePayment}>
                        <Link to="" className="req-pay-btn">
                          {ingerman == "de" ? "Zahlen" : "Pay"}
                        </Link>
                      </div>
                    ) : status == 2 ? (
                      <h3>{ingerman == "de" ? "Abgelehnt" : "Rejected"}</h3>
                    ) : status == 3 ? (
                      <div onClick={handlePayment}>
                        <Link to="" className="req-pay-btn">
                          {ingerman == "de" ? "Zahlen" : "Pay"}
                        </Link>
                      </div>
                    ) : status == 4 ? (
                      <h3>
                        {ingerman == "de" ? "Zahlung erledigt" : "Payment Done"}
                      </h3>
                    ) : (
                      <h3>
                        {ingerman == "de"
                          ? "Termin abgesagt"
                          : "Appointment Cancelled"}
                      </h3>
                    )
                  ) : status == 0 ? (
                    <div className="requested-retails-btns">
                      <div>
                        <Link
                          className="theme-btn"
                          onClick={() => {
                            permissionMutate({
                              id: appointment_id,
                              langType: ingerman == "de" ? 1 : 0,
                              type: "1",
                            });
                          }}
                        >
                          {ingerman == "de" ? "Akzeptieren" : "Accept"}
                        </Link>
                      </div>
                      <div>
                        <Link
                          className="white-btn"
                          onClick={() => {
                            permissionMutate({
                              id: appointment_id,
                              langType: ingerman == "de" ? 1 : 0,
                              type: "0",
                            });
                          }}
                        >
                          {ingerman == "de" ? "Ablehnen" : "Reject"}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    status == 4 && (
                      <div>
                        <Link
                          className="view-coach-btn dark-btn"
                          onClick={() => {
                            cancelMutate({
                              appointment_id: appointment_id,
                              langType: ingerman == "de" ? 1 : 0,

                              user_id: coachdetails?._id,
                            });
                          }}
                        >
                          {ingerman == "de"
                            ? "Stornieren"
                            : "Cancel Appointment"}
                        </Link>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </LayoutCoach>
      )}
    </>
  );
}
