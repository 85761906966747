import React, { useEffect, useRef, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { contactMessage } from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function Contact() {

    // // ======================================check for language=======================================

    const ingerman = useSelector((state) => state.homedata.selectedLang);
    console.log(ingerman);


  const dispatch=useDispatch()
  const { t } = useTranslation();
  const firstnameref = useRef(null);
  const lastnameref = useRef(null);
  const emailref = useRef(null);
  const reasonref = useRef(null);
  const messageref = useRef(null);
  const termref = useRef(null);
  const firstnamereferr = useRef(null);
  const lastnamereferr = useRef(null);
  const emailreferr = useRef(null);
  const reasonreferr = useRef(null);
  const messagereferr = useRef(null);
  const termreferr = useRef(null);
  

  
  const handlesave = () => {
    let isvalid = true;

    if (!firstnameref.current.value) {
      firstnamereferr.current.innerText = "Required";
      isvalid = false;
    }
    if (!lastnameref.current.value) {
      lastnamereferr.current.innerText = "Required";
      isvalid = false;
    }
    if (!emailref.current.value) {
      emailreferr.current.innerText = "Required";
      isvalid = false;
    }
    if (!reasonref.current.value) {
      reasonreferr.current.innerText = "Required";
      isvalid = false;
    }
    if (!messageref.current.value) {
      messagereferr.current.innerText = "Required";
      isvalid = false;
    }
    if (!termref.current.checked) {
      termreferr.current.innerText = "Please accept Privacy Policy";
      isvalid = false;
    }

    if (isvalid) {
      console.log("first");
      dispatch(
        contactMessage({
          first_name: firstnameref?.current?.value,
          last_name: lastnameref?.current?.value,
          email: emailref?.current?.value,
          reason: reasonref?.current?.value,
          message: messageref?.current?.value,
        })
      ).then(res=>{
        if(res?.payload?.success){
          firstnameref.current.value=""
          lastnameref.current.value=""
          emailref.current.value=""
          reasonref.current.value=""
          messageref.current.value=""
          termref.current.checked=false
          

        }
      })
    }
  };

  const handleerror = (name) => {
    console.log(name);
    if (name === "email") {
      emailreferr.current.innerText = "";
    } else if (name === "firstname") {
      firstnamereferr.current.innerText = "";
    } else if (name === "lastname") {
      lastnamereferr.current.innerText = "";
    } else if (name === "term") {
      termreferr.current.innerText = "";
    } else if (name === "message") {
      messagereferr.current.innerText = "";
    } else if (name === "reason") {
      reasonreferr.current.innerText = "";
    }
  };

  return (
    <Layout>
      <section>
        <Container>
          <Row>
            <Col lg={8} className="mx-auto">
              <div className="data-protection-page">
                <h2 className="text-center mb-4 Platform-font">
                  <b>{ingerman=="de" ? "Kontakt uns" : "Contact Us"}
                   </b>
                </h2>

                <Form className="contact-form">
                  <Row>
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 ad-height-input"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>{ingerman=="de" ? "Vorname" : "First Name"}</Form.Label>
                        <Form.Control
                          onChange={() => handleerror("firstname")}
                          ref={firstnameref}
                          type="text"
                          placeholder={ingerman=="de" ? "Digitare qui..." : "Type here..."}
                        />
                        {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                        <Form.Label
                          className="text-danger"
                          ref={firstnamereferr}
                        ></Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 ad-height-input"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>{ingerman=="de" ? "Nachname" : "Last Name"}</Form.Label>
                        <Form.Control
                          onChange={() => handleerror("lastname")}
                          ref={lastnameref}
                          type="text"
                          placeholder={ingerman=="de" ? "Digitare qui..." : "Type here..."}
                        />
                        <Form.Label
                          className="text-danger"
                          ref={lastnamereferr}
                        ></Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 ad-height-input"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control
                          onChange={() => handleerror("email")}
                          ref={emailref}
                          type="email"
                          placeholder={ingerman=="de" ? "Digitare qui..." : "Type here..."}
                        />
                        <Form.Label
                          className="text-danger"
                          ref={emailreferr}
                        ></Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 ad-height-input"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>
                        {ingerman=="de" ? "Grund für die Kontaktaufnahme mit MindRepublic" : "Reason for contacting MindRepublic"}
                        </Form.Label>
                        <Form.Control
                          onChange={() => handleerror("reason")}
                          ref={reasonref}
                          type="text"
                          placeholder={ingerman=="de" ? "Digitare qui..." : "Type here..."}
                        />
                        <Form.Label
                          className="text-danger"
                          ref={reasonreferr}
                        ></Form.Label>
                      </Form.Group>
                    </Col>

                    <Col lg={6}></Col>

                    <Col lg={6}></Col>
                  </Row>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>{ingerman=="de" ? "Nachricht" : "Message"}</Form.Label>
                    <Form.Control
                      onChange={() => handleerror("message")}
                      ref={messageref}
                      as="textarea"
                      rows={3}
                      placeholder={ingerman=="de" ? "Digitare qui..." : "Type here..."}
                    />
                    <Form.Label
                      className="text-danger"
                      ref={messagereferr}
                    ></Form.Label>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 check-box-pp"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      ref={termref}
                      type="checkbox"
                      className="me-2"
                      label={ingerman=="de" ? "Ich habe die gelesen und akzeptiere sie" :"I have read and accept the " }
                      onChange={() => handleerror("term")}
                    />

                    <Link to="/data-protection">{ingerman=="de" ? "Datenschutzrichtlinie" : "Privacy Policy"}</Link>
                    <span>
                      <Form.Label
                        className="text-danger"
                        ref={termreferr}
                      ></Form.Label>
                    </span>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="button"
                    onClick={handlesave}
                    className="theme-btn"
                  >
                   {
                    ingerman=="de" ? "Einreichen" : "Submit"
                   } 
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
