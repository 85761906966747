import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutCoach from "../Components/LayoutCoach";
import {
  coachLogin,
  createStripeAccount,
  getWallet,
  withdrawMoney,
} from "../Redux/Actions/coachAction";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { useSelector } from "react-redux";

export default function WalletCoach() {

  
  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);
  // ===================================react state==================================================

  const [fetchWithdrawalData, setFetchWithdrawalData] = useState(false);
  const [account, setaccount] = useState(false);

  //  ===================================fetch data using usequery===================================

  const { isPending, data, isSuccess, isError } = useQuery({
    queryKey: ["getWallet"],
    queryFn: getWallet,
  });

  {
    // isSuccess && dispatch(reflectionList(data));
  }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data, "data");

  //  ===================================fetch data using usequery===================================

  const {
    isPending: withdrawPending,
    data: withDrawData,
    isSuccess: withDrawSuccess,
    isError: withDrawError,
  } = useQuery({
    queryKey: ["withdrawMoneys"],
    queryFn: withdrawMoney,
    enabled: fetchWithdrawalData,
  });

  // {
  //   isSuccess && console.log("success")
  // }

  {
    withDrawError &&
      toast.error(withDrawData?.message || "Something went wrong");
  }

  useEffect(() => {
    if (withDrawData!==undefined) {
      if (!withDrawData?.success) {
        toast?.error(withDrawData?.message);
      }
    }
  }, [withDrawData]);

  const handleWithdraw = () => {
    setFetchWithdrawalData(true);
  };

  console.log(withDrawData, "withDrawData");

  //  ===================================fetch data using usequery===================================

  const {
    isPending: accountPending,
    data: accountData,
    isSuccess: accountSuccess,
    isError: accountError,
  } = useQuery({
    queryKey: ["createStripeAccount"],
    queryFn: createStripeAccount,
    enabled: account,
  });

  // {
  //   isSuccess && window.location.href=`${accountData?}`
  // }

  {
    accountError &&
      toast.error(accountError?.message || "Something went wrong");
  }

  useEffect(() => {
    if (accountData) {
      if (!accountData?.success) {
        toast?.error(accountData?.message);
      }
      if(isSuccess){
        console.log(accountData)
        window.location.href=`${accountData?.data?.url}`
      }
    }
  }, [accountData]);


  return (
    <>
      <LayoutCoach>
        <div style={{ position: "relative" }} className="right-side">
          {isPending && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                // backgroundColor: "rgba(0,0,0,0.1)",
              }}
            >
              <div class="loader"></div>
            </div>
          )}
          <div className="back-page-i">
            <Link to="/settings-coach">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>

          <div className="main-content">
            <h2>Wallet</h2>
            <div className="wallet-box-btns">
            {
              data?.data?.wallet?.balance &&  <div className="wallet-amount">
                <p>{data?.data?.wallet?.balance}</p>
              </div>
            }
             
              <div className="wallet-action-btns">
                <div onClick={handleWithdraw}>
                  <Link to="">{ingerman=="de" ? "Zurückziehen" : "Withdraw"}</Link>
                </div>
                <div onClick={()=>setaccount(true)}>
                  <Link to="">{ingerman=="de" ? "Bankdaten" : "Bank details"}</Link>
                </div>
              </div>
            </div>
            <div>
              {data?.data?.transactions?.map((transaction) => {
                return (
                  <div
                    className="invoice-detail-card"
                    style={{ marginTop: "10px", backgroundColor: transaction?.transaction_type  == 1 ? 'red' : 'green'  }}
                  >
                    <div className="invoice-card-list">
                      <p>Transection Id : </p>
                      <p>{transaction?.transaction_id}</p>
                    </div>

                    <div className="invoice-card-list">
                      <p>Amount</p>
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4401_6392)">
                            <path
                              d="M19.0308 17.4182C17.7312 18.7852 16.0674 19.5381 14.3457 19.5381C11.5238 19.5381 9.0805 17.5565 7.93058 14.692H15.7688C16.4485 14.692 16.9995 14.1409 16.9995 13.4612C16.9995 12.7815 16.4485 12.2305 15.7688 12.2305H7.30515C7.25564 11.8221 7.23065 11.4111 7.23031 10.9997C7.23031 10.5282 7.26351 10.0661 7.32543 9.61505H15.7688C16.4485 9.61505 16.9995 9.06398 16.9995 8.3843C16.9995 7.70462 16.4485 7.15351 15.7688 7.15351H7.99606C9.17063 4.37287 11.5749 2.46123 14.3457 2.46123C16.0674 2.46123 17.7312 3.21422 19.0308 4.581C19.4986 5.07335 20.2772 5.09358 20.7706 4.62533C21.2633 4.15687 21.2828 3.37786 20.8151 2.88526C19.0464 1.02465 16.749 0 14.346 0C10.2429 0 6.73558 2.98001 5.37454 7.15387H2.07645C1.39677 7.15387 0.845703 7.70493 0.845703 8.38461C0.845703 9.06429 1.39677 9.61535 2.07645 9.61535H4.84717C4.7973 10.0695 4.76878 10.5308 4.76878 11C4.76878 11.4164 4.79054 11.8263 4.83034 12.2308H2.07645C1.39677 12.2308 0.845703 12.7818 0.845703 13.4615C0.845703 14.1412 1.39677 14.6923 2.07645 14.6923H5.32569C6.64961 18.9468 10.1924 22 14.3457 22C16.7489 22 19.0458 20.975 20.8144 19.1147C21.2825 18.622 21.2633 17.8431 20.7703 17.3747C20.2778 16.9062 19.4986 16.9258 19.0308 17.4182Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4401_6392">
                              <rect width="22" height="22" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        {transaction?.total_amount}
                      </p>
                    </div>
                    <div className="invoice-card-list">
                      <p>Created At </p>
                      <p>
                        {moment(transaction?.createdAt).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                );
              })}

              {
                 <p style={{textAlign:"center",marginTop:"100px"}}>  {data?.data?.transactions?.length==0 && (ingerman=="de" ? "Keine Daten gefunden" : "Data not found")}</p>
              }
            </div>
          </div>
        </div>
      </LayoutCoach>
    </>
  );
}
