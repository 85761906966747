import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resetPassword, userLogin } from "../Redux/Actions/userActions";
import { coachresetPassword } from "../Redux/Actions/coachAction";
import { useSelector } from "react-redux";

export default function ResetPassword() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // ==============================================language==========================================

  const { t } = useTranslation();
  const login = t("login");
  console.log(login);

   // =======================================check for userrole==============================

   const userrole = useSelector((state) => state.registerUserdata.role);
   console.log(userrole, "role");

  // ================================================REACT STATES====================================
  const [userdetails, setUserdetails] = useState({
    // email: "",
    password: "",
    consfirmPassword: "",
  });

  // ==========================================get data from inputs==================================

  const handlechange = (e) => {
    setUserdetails((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  // ===========================================POST DATA USING REACT QUERY==========================

  const { isPending, mutate } = useMutation({
    mutationFn:userrole=="user" ? resetPassword : coachresetPassword,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        // res?.data?.role== 0 ?  navigate("/get-started") : ""
         sessionStorage.clear()
        navigate("/your-password-reset");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ===============================================login==================================================

  const handlelogin = () => {
    let isvalid = true;

    if (!userdetails?.password) {
      toast.error("Please Enter password");
      isvalid = false;
    }
    else if (!userdetails?.consfirmPassword) {
      toast.error("Please Enter confirm password");
      isvalid = false;
    }
    else if (
      userdetails?.consfirmPassword &&
      userdetails?.consfirmPassword !== userdetails?.password
    ) {
      toast.error("Password does not match");
      isvalid = false;
    }
    if (isvalid) {
      mutate({
        "password": userdetails?.password,
      });
    }
  };

  return (
    <section className="Login-sec">
      <Container style={{ position: "relative" }}>
        {isPending && (
          <div
            style={{
              position: "absolute",
              width: "110%",
              height: "100%",
              zIndex: "1",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              // backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <div class="loader"></div>
          </div>
        )}
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                <h2>Reset Password</h2>
                <p>Enter your new password and confirm</p>
              </div>
              <Form className="contact-form">
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    value={userdetails?.password}
                    onChange={handlechange}
                    type="password"
                    placeholder="Type here..."
                  />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>

                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    name="consfirmPassword"
                    value={userdetails?.consfirmPassword}
                    onChange={handlechange}
                    type="password"
                    placeholder="Type here..."
                  />
                </Form.Group>
                {/* <Form.Group
                  style={{ justifyContent: "end" }}
                  className="mb-3 check-box-pp"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    type="checkbox"
                    className="me-2"
                    label="Remember me"
                  />
                  <Link to="/forgot-password">{login?.forgot}</Link>
                </Form.Group> */}
                <div className="d-flex justify-content-center">
                  <button
                    // to="/verify-email"
                    onClick={handlelogin}
                    variant="primary"
                    type="button"
                    className="theme-btn"
                  >
                    Change Password
                  </button>
                </div>
                {/* <div className="next-page-signin">
                  <p>
                    {login?.dont} <Link to="/sign-up">Sign Up</Link>
                  </p>
                </div> */}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
