import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import InputGroup from "react-bootstrap/InputGroup";
import Layout from "../Components/Layout";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function ChangePasswordSign() {
  return (
    <section className="Login-sec">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="sign-proc-box">
              <div className="sign-in-head">
                {/* <div className="sign-up-logo">
                  <img src={require("../Assets/images/upd-logo.png")} />
                </div> */}
                <h2>Change Password</h2>
                <p>Enter your new password and confirm</p>
              </div>
              <Form className="contact-form">
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control type="password" placeholder="Type here..." />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" placeholder="Type here..." />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>
                <Form.Group
                  className="mb-3 ad-height-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control type="password" placeholder="Type here..." />
                  {/* <Form.Text className="text-muted">
                          We'll never share your email with anyone else.
                        </Form.Text> */}
                </Form.Group>

                <div  className="d-flex justify-content-center">
                  <Link
                    to="/"
                    variant="primary"
                    type="submit"
                    className="theme-btn"
                  >
                    Change Password
                  </Link>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
