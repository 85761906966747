import userApi from "../../../services/userApi";

// user Registration

export const registerCoach = async (details) => {
  const { data } = await userApi.post("coach/auth/register", details);

  return data;
};

// verify OTP

export const verifyCoachOTP = async (details) => {
  const { data } = await userApi.post("coach/auth/verifyOtp", details);

  return data;
};

// resend

export const resendCoachOTP = async (details) => {
  const { data } = await userApi.post("coach/auth/resendOtp", details);

  return data;
};

// login

export const coachLogin = async (details) => {
  const { data } = await userApi.post("coach/auth/login", details);

  return data;
};

// forget password

export const coachforgetPassword = async (details) => {
  const { data } = await userApi.post("coach/auth/forgotPass", details);

  return data;
};

// reset password

export const coachresetPassword = async (details) => {
  const { data } = await userApi.put("coach/auth/resetPass", details);

  return data;
};

// profile CReation

export const createProfile = async (details) => {
  const { data } = await userApi.post("coach/createProfile", details);

  return data;
};

// social login
export const coachsocialLogin = async (details) => {
  const { data } = await userApi.post("coach/auth/socialLogin", details);

  return data;
};

// logout

export const coachlogOut = async (details) => {
  const { data } = await userApi.get("coach/logOut", details);

  return data;
};

// Appointment list

export const getAppointments = async (details) => {
  
 
    const { data } = await userApi.get("coach/getAppointments");
    return data;
 
};

// Dashboard

export const dashboard = async (details) => {
  
 
  const { data } = await userApi.get(`coach/dashboard?type=${details}`);
  return data;

};

// acceptRejectAppointment

export const acceptRejectAppointment = async (details) => {
  const { data } = await userApi.post("coach/acceptRejectAppointment", details);

  return data;
};
// cancelAppointmentRefund

export const cancelAppointmentRefund = async (details) => {
  const { data } = await userApi.post("coach/cancelAppointmentRefund", details);

  return data;
};

// getChatRooms

export const getcoachChatRooms = async (details) => {

  const { data } = await userApi.get(`coach/getChatRooms`);

  return data;
};


// getChat

export const getcoachChats = async (details) => {

  const { data } = await userApi.get(`coach/getChats?chat_id=${details}`);

  return data;
};

// Chat

export const coachchat = async (details) => {
  const { data } = await userApi.post("coach/chat", details);

  return data;
};

// getUserProfile

export const getUserProfile = async (details) => {
  const { data } = await userApi.get(`coach/getUserProfile`);

  return data;
};

// refundAppointmentPayment

export const refundAppointmentPayment = async (details) => {
  const { data } = await userApi.post(`coach/refundAppointmentPayment`,details);

  return data;
};

// getNotifications

export const getCoachNotifications = async (details) => {
  console.log(details,"details")
  const { data } = await userApi.get(`coach/getNotifications?page=${details}`);

  return data;
};


// change password

export const changeCoachPassword = async (details) => {
  const { data } = await userApi.put("coach/changePassword", details);

  return data;
};


// faq

export const getAllcoachFaqs = async (details) => {
  const { data } = await userApi.get("coach/getAllFaqs", details);

  return data;
};

// coach detail

export const getCoachReviewsinCoachTab = async (details) => {
  const { data } = await userApi.get(`coach/getCoachReviews?id=${details}`);

  return data;
};

// getInvoice

export const generatecoachInvoice = async (details) => {
  const { data } = await userApi.get("coach/generateInvoiceForCoach", details);

  return data;
};

// accountdelete

export const coachaccountdelete = async (details) => {
  const { data } = await userApi.post("coach/coachaccountdelete", details);

  return data;
};

// getWallet

export const getWallet = async (details) => {
  const { data } = await userApi.get("coach/getWallet", details);

  return data;
};

// withdrawMoney

export const withdrawMoney = async () => {
  const { data } = await userApi.get("coach/withdrawMoney" );

  return data;
};

// createStripeAccount

export const createStripeAccount = async () => {
  const { data } = await userApi.get("coach/createAccount" );

  return data;
};

// createStripeAccount

export const getReviews = async () => {
  const { data } = await userApi.get("coach/getReviews" );

  return data;
};

// changeAutoApprove

export const changeAutoApprove = async (details) => {
  const { data } = await userApi.post("coach/changeAutoApprove", details);

  return data;
};

// editProfile

export const editcoachProfile = async (details) => {
  const { data } = await userApi.post("coach/editProfile", details);

  return data;
};

// block

export const blockcoachside = async (details) => {

  const { data } = await userApi.post(`coach/block`,details);

  return data;
};

// report
export const reportcoachside = async (details) => {

  const { data } = await userApi.post(`coach/report`,details);

  return data;
};

// report
export const getAppointmentDetails = async (details) => {


  const { data } = await userApi.get(`coach/getAppointmentDetails?id=${details}`);

  return data;
};





