import React, { useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import toast from "react-hot-toast";
import {
  editStoryTimeline,
  saveStoryTimeline,
} from "../Redux/Actions/userActions";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import moment from "moment/moment";

export default function AddLifeEvent() {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location);
  // ===============================================get points======================================

  const userData = useSelector((state) => state.registerUserdata.user);
  console.log(userData, "user");

  const storyTimeLineId = useSelector((state) => state.reflectionData.id);

  const refId = useSelector((state)=>state.reflectionData.reflectionIdforedit)



  // ===================================================React State======================================================

  const [storyTmelineDetails, setstoryTmelineDetails] = useState({
    desc: location?.state?.desc ? location?.state?.desc : "",
    category: location?.state?.category ? location?.state?.category : "",
    rating: location?.state?.rating ? location?.state?.rating : "",
    date: location?.state?.date
      ? moment(location?.state?.date).format("YYYY-MM-DD")
      : "",
    color: location?.state?.color ? location?.state?.color : "",
  });

  const handlechange = (e) => {
    setstoryTmelineDetails((old) => ({
      ...old,
      [e.target.name]: e.target.value,
    }));
  };

  

  // ==========================================post data using react query=====================================

  const { isPending, mutate } = useMutation({
    mutationFn: saveStoryTimeline,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        navigate(`/story-timeline-add/${storyTimeLineId}`);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });
  // ==========================================update data using react query=====================================

  const { isPending: updatePending, mutate: updateMutate } = useMutation({
    mutationFn: editStoryTimeline,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        navigate(`/story-timeline-add/${storyTimeLineId}`);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ========================================save timeline=======================================================

  const handleSave = () => {
    let isvalid = true;
    if (!storyTmelineDetails?.desc) {
      toast.error("Please write description");
      isvalid = false;
    } else if (!storyTmelineDetails?.category) {
      toast.error("Please write category");
      isvalid = false;
    } else if (!storyTmelineDetails?.rating) {
      toast.error("Please give rating");
      isvalid = false;
    } else if (
      storyTmelineDetails?.rating > 5 ||
      storyTmelineDetails?.rating < -5
    ) {
      toast.error("Rating must be in between of -5 to 5");
      isvalid = false;
    } else if (!storyTmelineDetails?.date) {
      toast.error("Please select a date");
      isvalid = false;
    } else if (!storyTmelineDetails?.color) {
      toast.error("Please select color");
      isvalid = false;
    } else {
      let formattedDate = moment(storyTmelineDetails?.date).format(
        "DD MMMM YYYY"
      );
      storyTmelineDetails.user_id = userData?._id;
      storyTmelineDetails.reflectionId = storyTimeLineId;
      storyTmelineDetails.date = formattedDate;
      // if (location?.state?.edit) {
      //   storyTmelineDetails.reflectionId = "664703be615c9447822395e2";
      // }

      if (location?.state?.edit) {
        updateMutate({
          // id: storyTimeLineId,
          id:refId,
          timelineId:location?.state?.timelineId,
          storyTimeline: storyTmelineDetails,
        });
      } else {
        mutate({
          id: storyTimeLineId,
          storyTimeline: storyTmelineDetails,
        });
      }
    }
  };

  return (
    <>
      <LayoutNew>
        <div className="right-side" style={{ position: "relative" }}>
          {(isPending || updatePending) && (
            <div
              style={{
                position: "absolute",
                width: "110%",
                height: "100%",
                zIndex: "1",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className="loader"></div>
            </div>
          )}

          <div onClick={() => navigate(-1)} className="back-page-i">
            <Link to="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="main-content">
            <div className="right-headings">
              <h2>{location?.state?.edit ? "Edit" : "Add"} life Event</h2>
            </div>
            <div className="add-life-event">
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Description</Form.Label>
                <Form.Control
                  value={storyTmelineDetails?.desc}
                  name="desc"
                  onChange={handlechange}
                  type="text"
                  placeholder="Type Something..."
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Category</Form.Label>
                <Form.Control
                  type="text"
                  value={storyTmelineDetails?.category}
                  name="category"
                  onChange={handlechange}
                  placeholder="E.g.  family, career, relationship, etc"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Rating</Form.Label>
                <Form.Control
                  type="text"
                  value={storyTmelineDetails?.rating}
                  name="rating"
                  onChange={handlechange}
                  placeholder="-5 to +5"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Date</Form.Label>
                <Form.Control
                  type="date"
                  value={storyTmelineDetails?.date}
                  name="date"
                  onChange={handlechange}
                  placeholder="-5 June 2023"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-2">Color</Form.Label>
                <Form.Control
                  type="color"
                  value={storyTmelineDetails?.color}
                  name="color"
                  onChange={handlechange}
                  placeholder="-5 June 2023"
                />
              </Form.Group>
              <div
                onClick={handleSave}
                className="bck-nxt-btns justify-content-end"
              >
                <Link
                // to="/wheel-of-life"
                >
                  Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </LayoutNew>
    </>
  );
}
