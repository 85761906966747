import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import LayoutNew from "../Components/LayoutNew";
import { useQuery } from "@tanstack/react-query";
import { getChatRooms } from "../Redux/Actions/userActions";
import toast from "react-hot-toast";
import moment from "moment";
import { getcoachChatRooms } from "../Redux/Actions/coachAction";
import { useSelector } from "react-redux";
import LayoutCoach from "../Components/LayoutCoach";

export default function Inbox() {
  const BASE_URL = "https://api.mindrepublic.com/";
  const navigate = useNavigate();

    // // ======================================check for language=======================================

    const ingerman = useSelector((state) => state.homedata.selectedLang);
    console.log(ingerman);

  // =========================================chefk for user role========================================
  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // ========================================fetch data ===============================

  const { data, isSuccess, isError, isPending } = useQuery({
    queryKey: ["reviewList"],
    queryFn: userrole == "user" ? getChatRooms : getcoachChatRooms,
  });

  // {
  //   isSuccess && dispatch(coachDetails(data));
  // }

  {
    isError && toast.error(data?.message || "Something went wrong");
  }

  console.log(data);

  return (
    <>
      {userrole == "user" ? (
        <LayoutNew>
          {" "}
          <div style={{ position: "relative" }} className="right-side">
            {isPending && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            {/* <div className="back-page-i">
            <Link to="/settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div> */}

            <div className="main-content">
              <h2>{ingerman=="de" ? "Posteingang" : "Inbox"}</h2>

              <div className="coaching-listing">
                {data?.data?.length > 0 ? (
                  data?.data?.map((room) => {
                    return (
                      <div
                        onClick={() =>
                          navigate("/inbox-chat-box", {
                            state: {
                              roomId: room?._id,
                              coachName:
                                userrole == "user"
                                  ? room?.coach_id?.full_name
                                  : room?.user_id?.full_name,
                              coachId:
                                userrole == "user"
                                  ? room?.coach_id?._id
                                  : room?.user_id?._id,
                            },
                          })
                        }
                      >
                        <Link className="inbox-card-item">
                          <div className="inbox-img-detail">
                            <img
                              src={`${BASE_URL}${
                                userrole == "user"
                                  ? room?.coach_id?.profile_image
                                  : room?.user_id?.profile_image
                              }`}
                            />
                            <div className="inbox-card-info">
                              <h3>
                                {userrole == "user"
                                  ? room?.coach_id?.full_name
                                  : room?.user_id?.full_name}
                              </h3>
                              <p>{room?.message}</p>
                            </div>
                          </div>

                          <div className="inbox-time text-end">
                            {moment(room?.time).fromNow()}
                            {room?.user_unread_count > 0 && (
                              <p
                                className="mt-4 read-messages"
                                style={{ color: "white" }}
                              >
                                {room?.user_unread_count}
                              </p>
                            )}
                          </div>
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <p style={{textAlign:"center",marginTop:"100px"}}>{ingerman=="de" ? "Keine Daten gefunden" : "Data not found"}</p> 
                )}
              </div>
            </div>
          </div>
        </LayoutNew>
      ) : (
        <LayoutCoach>
          {" "}
          <div style={{ position: "relative" }} className="right-side">
            {isPending && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <div class="loader"></div>
              </div>
            )}
            {/* <div className="back-page-i">
            <Link to="/settings">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div> */}

            <div className="main-content">
              <h2>{ingerman=="de" ? "Posteingang" : "Inbox"}</h2>

              <div className="coaching-listing">
                {data?.data?.length > 0 ? (
                  data?.data?.map((room) => {
                    return (
                      <div
                        onClick={() =>
                          navigate("/inbox-chat-box", {
                            state: {
                              roomId: room?._id,
                              coachName:
                                userrole == "user"
                                  ? room?.coach_id?.full_name
                                  : room?.user_id?.full_name,
                              coachId:
                                userrole == "user"
                                  ? room?.coach_id?._id
                                  : room?.user_id?._id,
                            },
                          })
                        }
                      >
                        <Link className="inbox-card-item">
                          <div className="inbox-img-detail">
                            <img
                              src={
                                room?.coach_id?.profile_image ||
                                room?.user_id?.profile_image
                                  ? `${BASE_URL}${
                                      userrole == "user"
                                        ? room?.coach_id?.profile_image
                                        : room?.user_id?.profile_image
                                    }`
                                  : require("../Assets/images/place_holder.png")
                              }
                            />
                            <div className="inbox-card-info">
                              <h3>
                                {userrole == "user"
                                  ? room?.coach_id?.full_name
                                  : room?.user_id?.full_name}
                              </h3>
                              <p>{room?.message}</p>
                            </div>
                          </div>

                          <div className="inbox-time text-end">
                            {moment(room?.time).fromNow()}
                            {room?.user_unread_count > 0 && (
                              <p
                                className="mt-4 read-messages"
                                style={{ color: "white" }}
                              >
                                {room?.user_unread_count}
                              </p>
                            )}
                          </div>
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <p style={{textAlign:"center",marginTop:"100px"}}>{ingerman=="de" ? "Keine Daten gefunden" : "Data not found"}</p> 
                )}
              </div>
            </div>
          </div>
        </LayoutCoach>
      )}
    </>
  );
}
