import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutCoach from "../Components/LayoutCoach";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { coachRegistrationdata } from "../Redux/Reducers/coachRegistrationSlice";
import { Userdata } from "../Redux/Reducers/registrationSlice";
import { editcoachProfile } from "../Redux/Actions/coachAction";
import { useMutation } from "@tanstack/react-query";

export default function CreateProfileRate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location, "loc");

  // // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

   // =====================================get data from redux=============================

   const submittedData = useSelector((state)=>state.coachregistrationData.coachregister)
   console.log(submittedData,"submitteddata")
  // ===============================================coach data from redux=======================================

  const coachData = useSelector(
    (state) => state.coachregistrationData.coachregister
  );

  console.log(coachData, "coach");

  // ================================================get user data from redux===========================================

  const userData = useSelector((state) => state?.registerUserdata?.user);

  console.log(userData, "data");

  // ==============================================react state====================================================

  const [rate, setrate] = useState(null);
  const [task, setTask] = useState(null);


  useEffect(()=>{

    if(submittedData){
      setrate(submittedData?.rate)
      
    }



  },[submittedData])

  useEffect(() => {
    if (userData && location?.state?.edit) {
      setrate(userData?.rateperhour ? userData?.rateperhour : "");
    }
    setTask(location?.state?.edit);
  }, []);

  // =================================post data using react-query=====================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: editcoachProfile,
    onSuccess: (res) => {
      if (res?.success) {
        dispatch(Userdata(res?.data));
        toast.success(res?.message);
        navigate("/my-profile-coach");
        // navigate(
        //   "/verify-email"
        //   //    {
        //   //   state: { id: res?.data?._id, otp: res?.data?.otp_email },
        //   // }
        // );
        // dispatch(registrationData({}))
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  

  return (
    <>
      {/* <LayoutCoach> */}
      <div className="right-side w-100">
        <div className="back-page-i" onClick={() => navigate(-1)}>
          <Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="24"
              viewBox="0 0 14 24"
              fill="none"
            >
              <path
                d="M12 22L2 12L12 2"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Link>
        </div>

        <div className="main-content">
          <h2>{task == "edit" ?( ingerman=="de" ? "Bearbeiten Honorar": "Edit Rate") : ingerman=="de" ? "Honorar" : "Rate"}</h2>
          <div className="common-txtarea">
            <div className="add-life-event">
              <Form.Group className="mb-3">
                <Form.Label className="mb-3">{ingerman=="de" ? "Pro Stunde" : "Per Hour"}</Form.Label>
                <Form.Control
                  value={rate}
                  name="rate"
                  onChange={(e) => setrate(e.target.value)}
                  type="text"
                  placeholder="$10.00"
                />
              </Form.Group>
            </div>
          </div>
          <div
            onClick={() => {
              let isvalid = true;
              if (!rate) {
                toast.error(ingerman=="de" ? "Bitte geben Sie den Stundensatz ein": "Please enter rate per hour");
              } else if (!/^\d+$/.test(rate)) {
                toast.error(ingerman=="de" ? "Der Stundensatz muss eine Zahl sein": "Rate per hour must be a number");
              } else {
                if (task == "edit") {
                  const formdata = new FormData();
                  formdata.append("rateperhour", rate);

                  mutate(formdata);
                } else {
                  dispatch(
                    coachRegistrationdata({
                      ...coachData,
                      rate: rate,
                    })
                  );
                  navigate("/time-availability");
                }
              }
            }}
            className="bck-nxt-btns justify-content-end  ad-top-margin"
          >
            <Link
            // to="/time-availability"
            >
             { task == "edit" ? "Submit" : "Next"}
            </Link>
          </div>
        </div>
      </div>
      {/* </LayoutCoach> */}
    </>
  );
}
