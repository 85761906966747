import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCourseCategory } from "../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { courseCategory } from "../Redux/Reducers/meditationSlice";

export default function Score() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
 const location=useLocation()


  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

//   ===================================user data==================================================

  const userData = useSelector((state)=>state.registerUserdata?.user)

  return (
    <LayoutNew>
      <div className="right-side">
        <div className="main-content">
          <h2>Hi {userData?.full_name}</h2>
          <div className="meditation-listing">
            <div className="meditation-card-item">
              <div className="medi-icon-txt">
                <div className="medi-catg-nme">
                  <h3>You have scored {location?.state?.score} marks</h3>
                  {/* <p> Course</p> */}
                </div>
              </div>
              <div className="">
                <Link to="/reflection-journey-page">Done</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutNew>
  );
}
