import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";

const LayoutNew = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

 

  const handleClick = () => {
    setIsActive(!isActive);
  };
  const pages = [
    "/edit-profile",
    "/theory",
    "/coach-details",
    "/inbox-chat-box",
    "/reflection-journey-view",
    "/reflection-journey-audio",
    "/questionnaire-type",
    "/questionnaire-option",
    "/story-timeline-add",
    "/add-life-event",
    "/wheel-of-life",
    "/add-wheel-of-life",
    "/de-stress-audio",
    "/make-appointment",
    "/invoice-details",
    "/appointment-created",
    "/change-password",
    "/faqs",
    "/favorite-course",
    "/change-language",
    "/user-imprint",
    "/change-password-coach"
  ];
  return (
    <section className={isActive ? "main-layout active toggle-sidebar" : "main-layout toggle-sidebar"}>
      <button
        className={
          pages?.includes(window.location.pathname)
            ? "mob-toggle-btn d-none"
            : "mob-toggle-btn "
        }
        onClick={handleClick}
      >
        <svg
          width="25"
          height="20"
          viewBox="0 0 25 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.513 0.00242627C16.0966 0.00242627 19.6811 0.00158626 23.2647 0.00326537C24.2725 0.00410492 25.0199 0.746272 24.9989 1.71512C24.9796 2.56979 24.2876 3.28425 23.4326 3.3321C23.3637 3.3363 23.294 3.33546 23.2243 3.33546C16.0739 3.33546 8.92357 3.33546 1.77319 3.33546C0.895556 3.33546 0.226205 2.83341 0.0464795 2.04842C-0.191195 1.01157 0.568019 0.0250933 1.63209 0.00662303C2.35184 -0.00597031 3.07242 0.00326537 3.793 0.00326537C6.69968 0.00158626 9.60636 0.00242627 12.513 0.00242627Z"
            fill="black"
          />
          <path
            d="M12.471 11.6672C8.88742 11.6672 5.30299 11.6689 1.7194 11.6663C0.728392 11.6655 -0.0157051 10.9233 0.000251852 9.9654C0.0145291 9.11074 0.702358 8.3904 1.55563 8.33834C1.63374 8.33331 1.71184 8.33499 1.78995 8.33499C8.93193 8.33499 16.0739 8.33499 23.2151 8.33499C24.1221 8.33499 24.7847 8.83956 24.9611 9.66232C25.1736 10.6513 24.4421 11.6101 23.4309 11.6638C23.2495 11.6731 23.0664 11.668 22.8842 11.668C19.4131 11.6672 15.9421 11.6672 12.471 11.6672Z"
            fill="black"
          />
          <path
            d="M12.5088 16.6668C16.1016 16.6668 19.6936 16.6651 23.2864 16.6676C24.2749 16.6684 25.0181 17.4156 24.9997 18.3744C24.9829 19.2291 24.2934 19.9461 23.4384 19.9964C23.3863 19.9998 23.3343 19.9998 23.2822 19.9998C16.0974 19.9998 8.91172 20.0006 1.72691 19.9989C0.868592 19.9989 0.209321 19.4725 0.0379936 18.6657C-0.174485 17.6675 0.577169 16.702 1.60177 16.6709C2.10484 16.6558 2.6079 16.6668 3.1118 16.6668C6.24356 16.6659 9.37616 16.6659 12.5088 16.6668Z"
            fill="black"
          />
        </svg>
      </button>
      <Sidebar  />
      {children}
    </section>
  );
};

export default LayoutNew;
