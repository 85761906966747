import { createSlice } from "@reduxjs/toolkit";


export const coachRegistrationSlice = createSlice({
  name: "coachRegistrationSlice",
  initialState: {
    coachregister: null,
    locationId:null
    
  },
  reducers: {
    coachRegistrationdata:(state,action)=>{
        console.log(action,"action")
        state.coachregister=action.payload
    },
    coachLocationId:(state,action)=>{
        state.locationId=action.payload
    }
    

  },
  
});

export const {coachRegistrationdata,coachLocationId} = coachRegistrationSlice.actions

export default coachRegistrationSlice.reducer