import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutCoach from "../Components/LayoutCoach";
import { useSelector } from "react-redux";

export default function GetStartedCoach() {

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman, "lang");


  return (
    <LayoutCoach>
      <div className="right-side">
        <div className="main-content">
          <h2>{ingerman=="de" ? "Willkommen bei MindRepublic!":"Welcome to MindRepublic!"}</h2>
          <div className="get-started-content">
            <p>
            {
              ingerman=="de" ? "Coaching war noch nie so einfach Terminsfindung, Zoom-Lizenz, Zahlungsabwicklung und Rechnungsstellung werden von MindRepublic übernommen. Als CoachIn kannst Du Dich endlich auf das fokussieren, was zählt: Deine Coachees." :  
               `Coaching has never been easier Scheduling, Zoom licensing, payment
              processing, and invoicing are all handled by MindRepublic. As a
              coach, you can finally focus on what matters: your coachees.`
            }
           <br/><br/>
           {
            ingerman=="de" ? "Buchung über MindRepublic" : "Booking through MindRepublic"
           }
              <br/>
              {
                ingerman=="de" ? `Richte zunächst Deine Verfügbarkeiten und lege ein Honorar fest. Sobald ein/e NutzerIn ein Coaching bei Dir anfragt, wirst Du in der App und per Mail benachrichtigt. Bestätige den Termin und der/die NutzerIn wird aufgefordert vorab zu zahlen. Nach der Zahlung werden die Zoom-Zugangsdaten an Dich und Deinen Coachee per Mail verteilt. Kommt der Termin nicht zustande, wird das Geld zurückgebucht.` :
                `First, set your availability and
              decide on a fee. Once a user requests coaching with you, you'll be
              notified in the app and by email. Confirm the appointment, and the
              user will be prompted to pay in advance. After payment, Zoom
              access details will be emailed to both you and your coachee. If
              the appointment doesn't happen, the money will be refunded.`
              } <br/><br/> {ingerman=="de" ? `Möchtest Du es NutzerInnen erlauben, Dich direkt buchen zu können, ohne dass Deine vorab eingegebenen Verfügbarkeiten nochmal bestätigen musst, so lege dies in Deinem Profil unter Einstellungen (siehe „Automatische Genehmigung“) fest.` : 
              `If you
              want to allow users to book you directly without having to confirm
              your previously entered availability again, you can set this in
              your profile under settings (see "Automatic Approval").`}<br/><br/>{ingerman=="de" ? `Benutze den Chat, um mit Deinen Coachees in Verbindung zu treten. Alles, was ihr erarbeitet habt, könnt ihr auch über MindRepublic teilen. Nutze die App oder die Website hierfür.` : 
              `Use the
              chat to connect with your coachees. Anything you work on together
              can also be shared through MindRepublic. Use the app or the
              website for this..`}
            </p>

            <p>{ingerman=="de" ? `Was muss ich tun?` : "What do I need to do?"}</p>
            <p>{ingerman=="de" ? "Um als Coach durchzustarten, erledige bitte folgendes:" : "To get started as a coach, please do the following:"}</p>
            <p>{
              ingerman=="de" ? `• Erstelle ein aussagekräftiges Profil (siehe „Profil“ unten rechts im Menü). Potentielle Coachees interessieren sich dafür, was Du genau anbietest, wie Dein bisheriger Werdegang war und was Dich besonders macht.` :` • Create a meaningful profile (see "Profile" at the bottom right
              of the menu). Potential coachees are interested in what you offer,
              your background, and what makes you unique.`
            }
             
            </p>
            <p>
            {
              ingerman=="de" ? `• Richte Deine Wallet ein (siehe „Profil“, klicke sodann auf das Zahnrad oben rechts und dann auf „Wallet“). Coachees bezahlen Coaching Sessions im Voraus via Stripe als Zahlungsabwickler. Damit Du das Geld erhalten kannst, benötigst Du eine Wallet bei Stripe, welche Du bequem hier einrichten kannst.` : `• Set up your wallet (see "Profile," then click on the gear icon
              at the top right, and then on "Wallet"). Coachees pay for coaching
              sessions in advance via Stripe as the payment processor. To
              receive your money, you need a Stripe wallet, which you can easily
              set up here.`
            }
              
            </p>
            {/* <div className="">
              <p>{ingerman=="de" ? `Weitere Informationen hierzu finden Sie unter` : "For further information on this, please visit"}</p>
              <Link to="http://www.mindrepublic.io/bookacoaching">
                http://www.mindrepublic.io/bookacoaching
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </LayoutCoach>
  );
}
