import React from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function TermsConditions() {
  const { t } = useTranslation();
  const TermCondition = t("TermCondition");
  console.log(TermCondition, "TermCondition");

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  return (
    <Layout>
      <section>
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="data-protection-page">
                <h2 className="text-center Platform-font">
                  {TermCondition?.gtc}
                </h2>
                <p className="text-center">
                  <b>{TermCondition?.date}</b>
                </p>

                <div className="terms-and-conditions ">
                  <ol>
                    <li>
                      <b>{TermCondition?.GeneralandScope?.GeneralandScope} </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>1.1</span>
                        <p>
                          {TermCondition?.GeneralandScope?.p1}
                          <Link to="https://www.mindrepublic.com">
                            https://www.mindrepublic.com;
                          </Link>
                          {TermCondition?.GeneralandScope?.p2}
                        </p>
                      </li>
                      <li>
                        <span>1.2</span>
                        <p> {TermCondition?.GeneralandScope?.p3}</p>
                      </li>
                      <li>
                        <span>1.3</span>
                        <p> {TermCondition?.GeneralandScope?.P7}</p>
                      </li>
                      <li>
                        <span>1.4</span>
                        <p> {TermCondition?.GeneralandScope?.p4}</p>
                      </li>

                      <li>
                        <span>1.5</span>
                        <p> {TermCondition?.GeneralandScope?.p5}</p>
                      </li>
                      <li>
                        <span>1.6</span>
                        <p> {TermCondition?.GeneralandScope?.P6}</p>
                      </li>
                      <li>
                        <span>1.7</span>
                        <p>
                          {TermCondition?.GeneralandScope?.P9}
                          {localStorage.getItem("selectedLang") == "en" ? (
                            <a href="mailto:support@mindrepublic.com">
                              support@mindrepublic.com
                            </a>
                          ) : (
                            <a href="mailto:support@mindrepublic.com">
                              support@mindrepublic.com
                            </a>
                          )}
                          {TermCondition?.GeneralandScope?.P8}
                        </p>
                      </li>
                    </ul>
                    <li>
                      <b> {TermCondition?.Confidentiality?.Confidentiality} </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>2.1</span>
                        <p> {TermCondition?.Confidentiality?.P1}</p>
                      </li>
                    </ul>
                    <li>
                      <b>{TermCondition?.UseoftheServices?.UseoftheServices}</b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>3.1</span>
                        <p> {TermCondition?.UseoftheServices?.P1}</p>
                      </li>
                      <li>
                        <span>3.2</span>
                        <p> {TermCondition?.UseoftheServices?.P2}</p>
                      </li>
                      <li>
                        <span>3.3</span>
                        <p> {TermCondition?.UseoftheServices?.P3}</p>
                      </li>
                      <li>
                        <span>3.4</span>
                        <p> {TermCondition?.UseoftheServices?.P4}</p>
                      </li>
                      <li>
                        <span>3.5</span>
                        <p> {TermCondition?.UseoftheServices?.P5}</p>
                      </li>
                      <li>
                        <span>3.6</span>
                        <p> {TermCondition?.UseoftheServices?.P6}</p>
                      </li>
                      <li>
                        <span>3.7</span>
                        <p> {TermCondition?.UseoftheServices?.P7}</p>
                      </li>
                      <li>
                        <span>3.8</span>
                        <p> {TermCondition?.UseoftheServices?.P8}</p>
                      </li>
                      <li>
                        <span>3.9</span>
                        <p> {TermCondition?.UseoftheServices?.P9}</p>
                      </li>
                      <li>
                        <span>3.10</span>
                        <p> {TermCondition?.UseoftheServices?.P10}</p>
                      </li>
                      <li>
                        <span>3.11</span>
                        <p> {TermCondition?.UseoftheServices?.P11}</p>
                      </li>
                    </ul>
                    <li>
                      <b> {TermCondition?.Coachings?.Coachings}</b>
                    </li>

                    <ul className="number-list-add">
                      <li>
                        <span>4.1</span>
                        <p> {TermCondition?.Coachings?.P1}</p>
                      </li>
                      <li>
                        <span>4.2</span>
                        <p> {TermCondition?.Coachings?.P2}</p>
                      </li>
                      <li>
                        <span>4.3</span>
                        <p> {TermCondition?.Coachings?.P3}</p>
                      </li>
                      <li>
                        <span>4.4</span>
                        <p> {TermCondition?.Coachings?.P4}</p>
                      </li>
                    </ul>
                    <li>
                      <b>
                        {
                          TermCondition?.PersonalSuitability
                            ?.PersonalSuitability
                        }
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>5.1</span>
                        <p> {TermCondition?.PersonalSuitability?.P1}</p>
                      </li>
                      <li>
                        <span>5.2</span>
                        <p> {TermCondition?.PersonalSuitability?.P2}</p>
                      </li>
                    </ul>
                    <li>
                      <b>{TermCondition?.Rights?.Rights} </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>6.1</span>
                        <p> {TermCondition?.Rights?.P1}</p>
                      </li>
                      <li>
                        <span>6.2</span>
                        <p> {TermCondition?.Rights?.P2}</p>
                      </li>
                      <li>
                        <span>6.3</span>
                        <p> {TermCondition?.Rights?.P3}</p>
                      </li>
                      <li>
                        <span>6.4</span>
                        <p> {TermCondition?.Rights?.P4}</p>
                      </li>
                      <li>
                        <span>6.5</span>
                        <p> {TermCondition?.Rights?.P5}</p>
                      </li>
                      <li>
                        <ul className="number-list-add">
                          <li>
                            <span>6.5.1</span>
                            <p> {TermCondition?.Rights?.P6}</p>
                          </li>
                          <li>
                            <span>6.5.2</span>
                            <p> {TermCondition?.Rights?.P7}</p>
                          </li>
                          <li>
                            <span>6.5.3</span>
                            <p> {TermCondition?.Rights?.P8}</p>
                          </li>
                          <li>
                            <span>6.5.4</span>
                            <p> {TermCondition?.Rights?.P9}</p>
                          </li>
                          <li>
                            <span>6.5.5</span>
                            <p> {TermCondition?.Rights?.P10}</p>
                          </li>
                          <li>
                            <span>6.5.6</span>
                            <p> {TermCondition?.Rights?.P11}</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <li>
                      <b>{TermCondition?.Rights?.P12}</b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>7.1</span>
                        <p> {TermCondition?.Rights?.P13}</p>
                      </li>
                      <li>
                        <span>7.2</span>
                        <p> {TermCondition?.Rights?.P14}</p>
                      </li>
                      <li>
                        <span>7.3</span>
                        <p>
                          {TermCondition?.Rights?.P15}<br/>
                          {/* {TermCondition?.Rights?.Accountholder} */}
                          <p>{ingerman=="de" ?  "Kontoinhaber" : "Account holder"}: Christian Hoops</p><p>MindRepublic</p> <p>IBAN: DE97100110012933599914</p> <p>BIC: NTSBDEB1XXX</p>
                        </p>
                      </li>

                      <li>
                        <span>7.4</span>
                        <p> {TermCondition?.Rights?.P16}</p>
                      </li>
                      <li>
                        <span>7.5</span>
                        <p> {TermCondition?.Rights?.P17}</p>
                      </li>
                    </ul>
                    <li>
                      <b> {TermCondition?.Liability?.Liability} </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>8.1</span>
                        <p> {TermCondition?.Liability?.P1}</p>
                      </li>
                      <ul className="number-list-add">
                        <li>
                          <span>8.1.1</span>
                          <p> {TermCondition?.Liability?.P2}</p>
                        </li>
                        <li>
                          <span>8.1.2</span>
                          <p> {TermCondition?.Liability?.P3}</p>
                        </li>
                        <li>
                          <span>8.1.3</span>
                          <p>{TermCondition?.Liability?.P4}</p>
                        </li>
                      </ul>
                      <li>
                        <span>8.2</span>
                        <p> {TermCondition?.Liability?.P5}</p>
                      </li>
                    </ul>
                    <li>
                      <b>
                        {
                          TermCondition?.OnlineDisputeResolution
                            ?.OnlineDisputeResolution
                        }
                      </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>9.1</span>
                        <p>
                          {" "}
                          {TermCondition?.OnlineDisputeResolution?.P1}
                          <Link to="https://ec.europa.eu/consumers/odr/">
                            https://ec.europa.eu/consumers/odr/
                          </Link>
                        </p>
                      </li>
                      <li>
                        <span>9.2</span>
                        <p> {TermCondition?.OnlineDisputeResolution?.P2}</p>
                      </li>
                    </ul>
                    <li>
                      <b> {TermCondition?.DataProtection?.DataProtection} </b>
                    </li>
                    <ul className="number-list-add p-0">
                      <li>
                        <p>
                          {" "}
                          {TermCondition?.DataProtection?.P1}
                          <Link to="https://mindrepublic.com/data-protection">
                            https://mindrepublic.com/data-protection
                          </Link>
                          .
                        </p>
                      </li>
                    </ul>
                    <li>
                      <b> {TermCondition?.chnagestoTandC?.chnagestoTandC}</b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>11.1</span>
                        <p> {TermCondition?.chnagestoTandC?.P1}</p>
                      </li>
                      <li>
                        <span>11.2</span>
                        <p> {TermCondition?.chnagestoTandC?.P2}</p>
                      </li>
                      <li>
                        <span>11.3</span>
                        <p> {TermCondition?.chnagestoTandC?.P3}</p>
                      </li>
                    </ul>
                    <li>
                      <b> {TermCondition?.Miscellaneous?.Miscellaneous} </b>
                    </li>
                    <ul className="number-list-add">
                      <li>
                        <span>12.1</span>
                        <p> {TermCondition?.Miscellaneous?.P1}</p>
                      </li>
                      <li>
                        <span>12.2</span>
                        <p> {TermCondition?.Miscellaneous?.P2}</p>
                      </li>
                      <li>
                        <span>12.3</span>
                        <p> {TermCondition?.Miscellaneous?.P3}</p>
                      </li>
                      <li>
                        <span>12.4</span>
                        <p> {TermCondition?.Miscellaneous?.P4}</p>
                      </li>
                    </ul>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
