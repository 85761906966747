import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Container, Row, Col, FormControl } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LayoutNew from "../Components/LayoutNew";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import {
  getNotifications,
  redeemAppointmentPayment,
} from "../Redux/Actions/userActions";
import moment from "moment/moment";
import toast from "react-hot-toast";
import { getCoachNotifications } from "../Redux/Actions/coachAction";
import { useSelector } from "react-redux";
import LayoutCoach from "../Components/LayoutCoach";

export default function Notification() {
  const navigate = useNavigate();

  // ======================================check for language=======================================

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  const [lang, setlang] = useState(ingerman);

  // =========================================chefk for user role========================================
  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  // =================================================redeem=====================================

  const { isPending, mutate } = useMutation({
    mutationFn: redeemAppointmentPayment,
    onSuccess: (res) => {
      console.log(res);
      if (res?.success) {
        toast.success(res?.message);
        refetch();
        // navigate("/dashboard");
      } else {
        toast?.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ==============================================infifnite scrolling=================================

  const [page, setPage] = useState(1);
  // Infinite scrolling setup
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isError,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["notifications"],
    queryFn: ({ pageParam = page }) =>
      userrole == "user"
        ? getNotifications(pageParam)
        : getCoachNotifications(pageParam),
    // initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      console.log(lastPage, "page");
      // Determine next page based on the length of lastPage.data.arr
      if (lastPage && lastPage.data.arr.length > 0) {
        let totalPages = Math.ceil(lastPage.data.totalNotification / 10);
        let nextPage = page + 1;
        return nextPage <= totalPages ? nextPage : undefined;

        // Assuming each page increments by 1
      }
      return undefined; // No more pages
    },
  });

  // useEffect(() => {
  //   refetch(); // Refetch data when ingerman changes
  // }, [lang]);

  // Handle scrolling
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    } else {
      fetchNextPage();
      setPage((prevPage) => setPage(prevPage + 1)); // Increment page number
      // console.log("first")
      // setPage(page => page + 1); // Fetch next page using React Query's fetchNextPage function
    }
  };

  useEffect(() => {
    console.log("first");
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  if (isError) return toast?.error("Something went wrong");

  console.log(data, "data");
  console.log(page, "page");

  console.log(
    data?.pages[0]?.data?.arr?.length,
    "data?.pages?.data?.arr?.length"
  );

  return (
    <>
      {userrole == "user" ? (
        <LayoutNew>
          <div className="right-side" style={{ position: "relative" }}>
            {(isFetching || isFetchingNextPage || isPending) && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div className="loader"></div>
              </div>
            )}
            <div className="main-content">
              <h2>
                {ingerman == "de" ? "Benachrichtigungen" : "Notifications"}
              </h2>
              <div className="notification-list-box">
                {data?.pages?.flatMap((pageData) => {
                  return pageData?.data?.arr?.map((noti) => {
                    console.log(noti, "asdas");
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (noti?.notification_type === 11) {
                            e.stopPropagation(); // prevent click event from propagating to the div
                          } else {
                            switch (noti?.notification_type) {
                              case 0:
                                navigate("/inbox");
                                break;
                              case 1:
                                navigate("/requested-details", {
                                  state: {
                                    appointmentId: noti?.appointment_id,
                                    notification: "notification",
                                  },
                                });
                                break;
                              case 4:
                                break;
                              case 6:
                             
                                break;
                              case 9:
                                navigate("/requested-details", {
                                  state: {
                                    appointmentId: noti?.appointment_id,
                                    notification: "notification",
                                  },
                                });
                                break;
                              case 13:
                                break;
                              default:
                                navigate("/appointments");
                            }
                          }
                        }}
                        className="notification-box"
                      >
                        <div className="notification-check">
                          <spna>
                            <svg
                              width="33"
                              height="33"
                              viewBox="0 0 33 33"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="33"
                                height="33"
                                rx="4"
                                fill="#A2AA96"
                              />
                              <g clip-path="url(#clip0_4396_400)">
                                <path
                                  d="M23.8334 11L13.7501 21.0833L9.16675 16.5"
                                  stroke="white"
                                  stroke-width="2.0625"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4396_400">
                                  <rect
                                    width="19.25"
                                    height="13.75"
                                    fill="white"
                                    transform="translate(6.875 9.625)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </spna>
                          <h3>{noti?.title}</h3>
                        </div>
                        <p>{noti?.body}</p>
                        <p>
                          {moment(noti?.createdAt).format("DD MMMM YYYY")}{" "}
                          {ingerman == "de" ? "bei" : "at"}{" "}
                          {moment(noti?.createdAt).format("hh:mm a")}{" "}
                        </p>

                        {noti?.notification_type == 11 && (
                          <div className="d-flex ">
                            <div className="w-full">
                              <Link
                                className="theme-btn"
                                onClick={() => {
                                  mutate({
                                    appointment_id: noti?.appointment_id,
                                    user_id: noti?.user_id,
                                    langType: ingerman == "de" ? 1 : 0,
                                    notification_id: noti?._id,
                                  });
                                }}
                              >
                                {ingerman=="de" ? "Tilgen":"Redeem"}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  });
                })}

                <p style={{ textAlign: "center", marginTop: "100px" }}>
                  {" "}
                  {data?.pages[0]?.data?.arr?.length == 0 &&
                    (ingerman == "de"
                      ? "Keine Daten gefunden"
                      : "Data not found")}
                </p>
              </div>
            </div>
          </div>
        </LayoutNew>
      ) : (
        <LayoutCoach>
          <div className="right-side" style={{ position: "relative" }}>
            {(isFetching || isFetchingNextPage) && (
              <div
                style={{
                  position: "absolute",
                  width: "110%",
                  height: "100%",
                  zIndex: "1",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div className="loader"></div>
              </div>
            )}
            <div className="main-content">
              <h2>
                {ingerman == "de" ? "Benachrichtigungen" : "Notifications"}
              </h2>
              <div className="notification-list-box">
                {data?.pages?.flatMap((pageData) => {
                  return pageData?.data?.arr?.map((noti) => {
                    return (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          switch (noti?.notification_type) {
                            case 0:
                              navigate("/inbox");
                              break;
                            case 1:
                              navigate("/requested-details", {
                                state: {
                                  appointmentId: noti?.appointment_id,
                                  notification: "notification",
                                },
                              });
                              break;
                            case 4:
                              break;
                            case 6:
                              navigate("/reviews-coach");
                              break;
                            case 9:
                              navigate("/requested-details", {
                                state: {
                                  appointmentId: noti?.appointment_id,
                                  notification: "notification",
                                },
                              });
                              break;
                            case 13:
                              break;
                            default:
                              navigate("/dashboard");
                          }
                        }}
                        className="notification-box"
                      >
                        <div className="notification-check">
                          <spna>
                            <svg
                              width="33"
                              height="33"
                              viewBox="0 0 33 33"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="33"
                                height="33"
                                rx="4"
                                fill="#A2AA96"
                              />
                              <g clip-path="url(#clip0_4396_400)">
                                <path
                                  d="M23.8334 11L13.7501 21.0833L9.16675 16.5"
                                  stroke="white"
                                  stroke-width="2.0625"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4396_400">
                                  <rect
                                    width="19.25"
                                    height="13.75"
                                    fill="white"
                                    transform="translate(6.875 9.625)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </spna>
                          <h3>{noti?.title}</h3>
                        </div>
                        <p>{noti?.body}</p>
                        <p>
                          {moment(noti?.createdAt).format("DD MMMM YYYY")} at{" "}
                          {moment(noti?.createdAt).format("hh:mm a")}{" "}
                        </p>
                      </div>
                    );
                  });
                })}

                <p style={{ textAlign: "center", marginTop: "100px" }}>
                  {" "}
                  {data?.pages[0]?.data?.arr?.length == 0 &&
                    (ingerman == "de"
                      ? "Keine Daten gefunden"
                      : "Data not found")}
                </p>
              </div>
            </div>
          </div>
        </LayoutCoach>
      )}
    </>
  );
}
