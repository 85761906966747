import React, { useState } from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, FormControl, FormLabel } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LayoutNew from "../Components/LayoutNew";
import { logOut } from "../Redux/Actions/userActions";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import LayoutCoach from "../Components/LayoutCoach";
import { coachlogOut } from "../Redux/Actions/coachAction";

export default function LogoutPage() {

  const ingerman = useSelector((state) => state.homedata.selectedLang);
  console.log(ingerman);

  // =======================================check for userrole==============================

  const userrole = useSelector((state) => state.registerUserdata.role);
  console.log(userrole, "role");

  const navigate = useNavigate();

  const logout = async () => {
   
    let data =  userrole == "user" ? await logOut() : await coachlogOut();
    console.log(data, "data");
    if (data?.success) {
      toast.success(data?.message);
      sessionStorage.clear();
      localStorage.clear();

      navigate("/");
    } else {
      toast.error(data?.message);
    }
  };

  return (
    <>
      {userrole == "user" ? (
        <LayoutNew>
          <div className="right-side">
            <div className="main-content">
              <div className="appointment-created-img">
                <img src={require("../Assets/images/logout-graphic.png")} />
              </div>
              <div className="appointment-created-txt">
                <h2>
                {
                  ingerman=="de" ? "Bist du sicher, dass du willst" : "Are you sure you want"
                }
                  
                  <br />{ingerman=="de" ? "abmelden ?" : "to sign out ?"} 
                </h2>
              </div>
              <div className="sign-out-btn">
                <div onClick={logout}>
                  <Link to="#" className="dark-btn">
                   {ingerman=="de" ? "Ja" : 'Yes'} 
                  </Link>
                </div>
                <div onClick={() => navigate(-1)}>
                  <Link to="#" className="theme-btn">
                  {ingerman=="de" ? "NEIN" :"No"}  
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </LayoutNew>
      ) : (
        <LayoutCoach>
          <div className="right-side">
            <div className="main-content">
              <div className="appointment-created-img">
                <img src={require("../Assets/images/logout-graphic.png")} />
              </div>
              <div className="appointment-created-txt">
                <h2>
                {
                  ingerman=="de" ? "Bist du sicher, dass du willst" : "Are you sure you want"
                }
                  
                  <br />{ingerman=="de" ? "abmelden ?" : "to sign out ?"} 
                </h2>
              </div>
              <div className="sign-out-btn">
                <div onClick={logout}>
                  <Link to="#" className="dark-btn">
                  {ingerman=="de" ? "Ja" : 'Yes'} 
                  </Link>
                </div>
                <div onClick={() => navigate(-1)}>
                  <Link to="#" className="theme-btn">
                  {ingerman=="de" ? "NEIN" :"No"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </LayoutCoach>
      )}
    </>
  );
}
